import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import moment from 'moment';

// eslint-disable-line no-unused-vars
import { getConfiguredDataEndDate  } from 'common/config/customerConfiguration';
import { shouldDisableDimensions } from 'helpers/chartDataHelper';
import {
  COMPARE_YEAR_TYPES,
  DATE_OPTIONS_TYPE,
  DATE_COMPARISON_TYPE_OPTIONS,
  OVERTIME_TIME_FRAME_OPTIONS,
  OVERTIME_VISUALIZATION_TYPES,
  COMPARISON_PERIOD_DISPLAY_NAMES_ENTRY,
  DEFAULT_BENCHMARK_COLOR,
  LAST_PERIOD_COMPARISON_TYPE,
  RELATIVE_DATE_TYPE
} from 'appConstants';
import LegendPicker from './LegendPicker';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { getPrimaryMetricName } from 'helpers/displayNameHelper';
import { isPreviousPeriodRange, getYearTextByRange } from 'helpers/dateHelper';
import { formatValueToCurrency } from 'helpers/numberHelper';
import * as commonPropTypes from 'common/propTypes';
import { disableMetricTotal } from 'common/config/viewConfiguration';

class Legend extends Component {
  state = {
    showSelectItems: true
  }

  handleLegendEntriesChange = (newLegendEntries) => {
    this.props.onLegendConfigsChange(newLegendEntries);
  }

  handleLegendItems = (show) => {
    this.setState({showSelectItems : show});
  }

  handleKeyDown = (e, legendItemEntry) => {
    if(isEnterButtonPressed(e)) {
      this.props.onLegendClick(legendItemEntry)
    }
  }

  isCustomDateType = () => {
    const { dateRangeMode } = this.props;
    return (dateRangeMode === DATE_OPTIONS_TYPE.CUSTOM_RANGE);
  }

  isYearlyDateType = () => {
    const { dateRangeMode } = this.props;
    return (dateRangeMode === DATE_OPTIONS_TYPE.YEARLY);
  }

  isRelativeDateType = () => {
    const { dateRangeMode } = this.props;
    return (dateRangeMode === RELATIVE_DATE_TYPE);
  }


  sortLegendEntries = (selectedLegendEntries) => {
    const {
      dateRange,
      currentSelectedTimeFrame,
      compareYearRanges,
      isDimensionHighToLow
    } = this.props;
    let sortedEntries =  _.isEmpty(selectedLegendEntries) ? [] : _.cloneDeep(selectedLegendEntries);
    if(!isDimensionHighToLow){
      sortedEntries =  _.chain(selectedLegendEntries).
        compact().
        sortBy(['dimension']).
        sortBy((e) => {return !_.get(e, 'isTotalLine', false)}).
        value();
    }
    if(shouldDisableDimensions(dateRange, currentSelectedTimeFrame, compareYearRanges)) {
      return _.orderBy(sortedEntries, 'traceId', 'desc');
    } else {
      return sortedEntries;
    }
  }

  renderLegendItems() {
    const {
      legendEntries, renderType, onLegendClick, isCurrencyDimensionField, compareToRanges
    } = this.props;
    const isAreaChart = _.isEqual(renderType, _.get(OVERTIME_VISUALIZATION_TYPES.AREA, 'type', ''));
    const { name: compareName } = _.first(compareToRanges) || {};

    const legendItems = this.sortLegendEntries(legendEntries).map((legendItemEntry) => {
      let {
        color, traceId, visible, areaChartColor, hideTrace, primaryTrace, dimension
      } = legendItemEntry || {};

      if(hideTrace) {
        return null;
      }
      const legendLableClassNames = classNames('legend-label', { 'active': visible });
      const traceColor = isAreaChart ? areaChartColor : color;
      const style = {
        backgroundColor: visible ? traceColor : '',
        borderColor: visible ? traceColor : ''
      };

      if((
        this.isCustomDateType() ||
        this.isRelativeDateType()) &&
        (compareName === COMPARE_YEAR_TYPES.SAME_LAST_PERIOD ||
          compareName === LAST_PERIOD_COMPARISON_TYPE ||
          compareName === COMPARE_YEAR_TYPES.PRIOR_PERIOD
        )
      ) {
        traceId = primaryTrace ? `${dimension} - (This period)` : `${dimension} - (Previous period)`;
      }
      const newTraceId = formatValueToCurrency(traceId, isCurrencyDimensionField);

      return (
        <div className="over-time-legend-item" key={traceId}>
          <div
            role="button"
            tabIndex={0}
            className="d-flex"
            onClick={() => onLegendClick(legendItemEntry)}
            onKeyDown={(e) => this.handleKeyDown(e, legendItemEntry)}
            aria-label={`Legend item - ${traceId}`}>
            <span className="fake-check-box" style={style}></span>
            <label className={legendLableClassNames}>
              <div className="trace-name forge-typography--body2">{newTraceId}</div>
            </label>
          </div>
        </div>
      );
    });

    return (_.size(legendEntries) > 0 ? 
      <div>{legendItems}</div> : 
      <div className="trace-name forge-typography--body2">No categories selected</div>
    );
  }

  renderLegends() {
    const { isComboChart, benchMarkEntries } = this.props;

    if(isComboChart && _.isEmpty(benchMarkEntries)) {
      return;
    }

    return(
      <div className="legend-details">
        <div className="legend-info-inner filter-wrapper">
          {this.renderLegendWithoutComboChart()}
          {this.renderBenchmarkLegendItems()}
        </div>
      </div>
    );
  }

  renderLegendWithoutComboChart() {
    const {
      isComboChart,
      isSecondaryMetricEnabled,
      isProjectionEnabled,
      isComparisonEnabled,
      viewEntry,
      dateRangeMode,
      dateRange,
      secondaryMetricEntry
    } = this.props;

    if(isComboChart) {
      return;
    }

    let currentYearSuffixText = '';
    const comparisonPeriod = _.get(COMPARISON_PERIOD_DISPLAY_NAMES_ENTRY, 'current');
    currentYearSuffixText = isComparisonEnabled ? ` ${comparisonPeriod}` : '';
    const primaryMetricName = getPrimaryMetricName(viewEntry);
    if(this.isYearlyDateType()) {
      const dateRangeText = getYearTextByRange(dateRange, dateRangeMode);
      currentYearSuffixText = ` - ${dateRangeText}`;
    }

    return(
      <div>
        <div className="legend-info-details">
          <img alt="" src="../images/dash.png" />
          <span className="forge-typography--body2">
            {primaryMetricName + currentYearSuffixText }
          </span>
        </div>
        {isSecondaryMetricEnabled && <div className="legend-info-details secondary-text">
          <img alt="" src="../images/dash_dot.png" />
          <span className="forge-typography--body2">
            {_.get(secondaryMetricEntry, 'name') + currentYearSuffixText}
          </span>
        </div>}
        {isProjectionEnabled && <div className="legend-info-details projected-year">
          <img alt="" src="../images/dot.png" />
          <span className="forge-typography--body2">
            { 'Projection' + currentYearSuffixText }
          </span>
        </div>}
        {this.renderComparisonLegendItems()}
      </div>
    );
  }

  renderComparisonLegendItems() {
    const {
      isSecondaryMetricEnabled,
      isProjectionEnabled,
      isComparisonEnabled,
      renderType,
      comparisonType,
      secondaryMetricEntry,
      compareToRanges,
      viewEntry,
      currentSelectedTimeFrame,
      dateRange,
      dateRangeMode
    } = this.props;

    let lastPeriodOrYearSuffixText = '';
    const isAreaChart = _.isEqual(renderType, OVERTIME_VISUALIZATION_TYPES.AREA.type);
    const comparisonPeriod = _.get(COMPARISON_PERIOD_DISPLAY_NAMES_ENTRY, 'previous');
    const isYearOnYear = !_.isEqual(currentSelectedTimeFrame, OVERTIME_TIME_FRAME_OPTIONS.ROLLING);

    if(!isComparisonEnabled || isAreaChart || !isYearOnYear) {
      return;
    }
    const name = _.get(_.find(DATE_COMPARISON_TYPE_OPTIONS, { value: comparisonType }), 'label', '');
    const compareToDateRange = _.first(compareToRanges);
    const enablePreviousComparison = isPreviousPeriodRange(
      dateRange, compareToDateRange
    ) || this.isCustomDateType() || this.isRelativeDateType();
    lastPeriodOrYearSuffixText = enablePreviousComparison ? ` ${comparisonPeriod}` : '';
    const primaryMetricName = getPrimaryMetricName(viewEntry);
    let dateRangeText  = getYearTextByRange(compareToDateRange, dateRangeMode);
    const comparisonEndYear = moment(_.get(compareToDateRange, 'endDate','')).format('YYYY');
    if (name !== COMPARE_YEAR_TYPES.SAME_LAST_PERIOD || name != COMPARE_YEAR_TYPES.PRIOR_PERIOD) {
      lastPeriodOrYearSuffixText = `- ${(name||'').replace('same period', '')}`;
    }
    const isPreviousYear = moment(comparisonEndYear, 'YYYY').isBefore(getConfiguredDataEndDate());
    const secondaryMetricName = _.get(secondaryMetricEntry, 'name');
    let comparisonPrimaryMetricLegendText = primaryMetricName + ` - ${dateRangeText}`,
        comparisonSecondaryMetricLegendText = secondaryMetricName + ` ${dateRangeText}`,
        compareYearText = enablePreviousComparison ? ` ${lastPeriodOrYearSuffixText}` : '';

    if(enablePreviousComparison) {
      comparisonPrimaryMetricLegendText = `${primaryMetricName} ${compareYearText}`;
      comparisonSecondaryMetricLegendText = `${secondaryMetricName} ${compareYearText}`;
    }

    return(
      <div>
        {/* comparison legends */}
        {isComparisonEnabled &&
          <div className="legend-info-details previous-year">
            <img src="../images/dash_thin.png" className="thin-img" />
            <sapn className="forge-typography--body2">
              {comparisonPrimaryMetricLegendText}
            </sapn>
          </div>
        }
        {isComparisonEnabled && isSecondaryMetricEnabled &&
          <div className="legend-info-details previous-year secondary-text">
            <img src="../images/dash_dot_thin.png" className="thin-img" />
            <sapn className="forge-typography--body2">
              {comparisonSecondaryMetricLegendText}
            </sapn>
          </div>
        }
        {isProjectionEnabled && !isPreviousYear &&
          <div className="legend-info-details previous-year projected-year">
            <svg> <line x1="30" y1="0"></line> </svg>
            <sapn className="forge-typography--body2">
              {`${comparisonEndYear} - ` + 'Projection'}
            </sapn>
          </div>
        }
      </div>
    );
  }

  renderBenchmarkLegendItems () {
    const { benchMarkEntries } = this.props;

    if(_.isEmpty(benchMarkEntries)) {
      return;
    }

    return _.map(benchMarkEntries, (benchmark, index) => {
      return this.renderBenchmarkLegendItem(benchmark, index)
    });
  }

  renderBenchmarkLegendItem(benchmark, index) {
    const benchmarkName = _.get(benchmark, 'name', '');
    const colorCode = _.get(benchmark, 'color', DEFAULT_BENCHMARK_COLOR) || DEFAULT_BENCHMARK_COLOR;
    const lineType = _.get(benchmark, 'lineType', 'dot');
    return(
       <div key={index}>
        {lineType === 'dot' &&
          <div className="legend-info-details previous-year secondary-text">
            <span className="line-style dot" style={{color: `${colorCode}`}}>&#8943;&#8943;</span>
            <span className="ml-1 forge-typography--body2">{benchmarkName}</span>
          </div>
        }
        {lineType === 'dashdot' &&
          <div className="legend-info-details previous-year secondary-text">
            <span
              className="line-style dashdot"
              style={{color: `${colorCode}`}}>&#8722; &#8729; &#8722;</span>
            <span className="ml-1 forge-typography--body2">{benchmarkName}</span>
          </div>
        }
        {lineType === 'longdash' &&
          <div className="legend-info-details previous-year secondary-text">
            <span className="line-style longdash" style={{color: `${colorCode}`}}>&#9472; &#9472;</span>
            <span className="forge-typography--body2">{benchmarkName}</span>
          </div>
        }
        {lineType === '' &&
          <div className="legend-info-details previous-year secondary-text">
            <span className="line-style longline" style={{color: `${colorCode}`}}>&#9472;&#9472;</span>
            <span className="ml-1 forge-typography--body2">{benchmarkName}</span>
          </div>
        }
        {lineType === 'dash' &&
          <div className="legend-info-details previous-year secondary-text">
            <span className="line-style" style={{color: `${colorCode}`}}>&#8722; &#8722; &#8722;</span>
            <span className="ml-1 forge-typography--body2">{benchmarkName}</span>
          </div>
        }
      </div>
    );
  }

  renderLegendPicker () {
    const {
      legendEntries,
      apiParams,
      renderType,
      templateId,
      isComboChart,
      dateRange,
      currentSelectedTimeFrame,
      isCurrencyDimensionField,
      compareYearRanges,
      isDimensionHighToLow,
      viewEntry,
      onUpdateShowLegendTotal
    } = this.props;

    if(isComboChart ||
      shouldDisableDimensions(dateRange, currentSelectedTimeFrame, compareYearRanges)) {
      return (
        <div className="filter-wrapper">
          <div className="legend-title align-self-center forge-typography--body2 mb-2"> Categories</div>
        </div>
      )
    }
    const showMetricTotal = !disableMetricTotal(viewEntry);
    return(
      <LegendPicker
        isCurrencyDimensionField={isCurrencyDimensionField}
        templateId={templateId}
        legendEntries={_.cloneDeep(legendEntries)}
        apiParams={apiParams}
        renderType={renderType}
        onLegendEntriesChange={this.handleLegendEntriesChange}
        onDisplayLegendItems={this.handleLegendItems}
        isDimensionHighToLow={isDimensionHighToLow}
        showMetricTotal ={showMetricTotal}
        onUpdateShowLegendTotal={onUpdateShowLegendTotal}
      />
    )
  }

  render() {
    const classNameLegend = this.state.showSelectItems ? "legend-wrapper over-time-legend-container" :
      "legend-wrapper over-time-legend-container mb-0";
    return (
      <div className={classNameLegend}>
        {this.renderLegends()}
        <div className="categories filter-options">
          {this.renderLegendPicker()}
          { this.state.showSelectItems && this.renderLegendItems()}
        </div>
      </div>
    );
  }
}

Legend.propTypes = {
  compareYearRanges: PropTypes.arrayOf(
    PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string
  })),
  dateRange: PropTypes.exact({
    startDate: PropTypes.string,
    endDate: PropTypes.string,
  }),
  compareToRanges: PropTypes.arrayOf(
    {
      name: PropTypes.string,
      range: PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string
      })
  }),
  apiParams: PropTypes.object,
  renderType: PropTypes.oneOf(_.map(OVERTIME_VISUALIZATION_TYPES, 'type')),
  viewEntry: commonPropTypes.viewEntryPropTypes,
  secondaryMetricEntry: commonPropTypes.secondaryMetricEntryPropTypes,
  currentSelectedTimeFrame: PropTypes.oneOf(_.values(OVERTIME_TIME_FRAME_OPTIONS)),
  isComparisonEnabled: PropTypes.bool,
  isProjectionEnabled: PropTypes.bool,
  isSecondaryMetricEnabled: PropTypes.bool,
  legendEntries: PropTypes.array,
  onLegendClick: PropTypes.func,
  onLegendConfigsChange: PropTypes.func,
  isComboChart: PropTypes.bool,
  isCurrencyDimensionField: PropTypes.bool,
  templateId: commonPropTypes.templateIdPropTypes,
  dateRangeMode: PropTypes.string,
  onSecondaryMetricChange:PropTypes.func,
  onBenchMarkChange: PropTypes.func,
  benchMarkEntries: PropTypes.array,
  benchMarkNames: PropTypes.array,
  isDimensionHighToLow: PropTypes.bool,
  comparisonType: PropTypes.any,
  onUpdateShowLegendTotal: PropTypes.func
}

export default Legend;
