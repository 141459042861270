import {
  UPDATE_FORECAST_DATE_RANGE,
  UPDATE_FUTURE_FORECAST_DATE_RANGE,
  UPDATE_FORECAST_TEMPLATE_ID,
  UPDATE_FORECAST_SELECTED_METRIC,
  UPDATE_FORECAST_MODEL_OPTIONS,
  SET_MIN_DATES_TEMPLATES_FOR_FORECAST,
  UPDATE_FORECAST_PREPARE_DATA_TIME,
  UPDATE_FORECAST_OPTIONS,
  UPDATE_FORECAST_OVERTIME_CHART_VIEW,
  UPDATE_FORECAST_AXIS_GRANULARITY,
  UPDATE_PROJECTION_ADJUSTED_VALUES,
  UPDATE_TO_DEFAULT_SETTING,
  UPDATE_FORECAST_PAGE_VIEW,
  UPDATE_FORECAST_BOOKMARK_ID
} from 'actions/forecastingActions';
import _ from 'lodash';
import { DATE_FORMAT, FORECAST_PAGE_TYPES } from 'appConstants';
import { getDefaultFutureForecastByFiscalYear } from 'pages/Forecasting/ForecastHelper.js';
import moment from 'moment';


export const defaultForecastingOptions = () => {
  const defaultEndDate = moment().format(DATE_FORMAT);

  const futureForecastDetails = getDefaultFutureForecastByFiscalYear();

  return {
    selectedForecastMetric: {},
    selectedForecastTemplateId: '',
    currentForecastDateRange: {
      dateRange: { startDate: '2018-01-01', endDate: defaultEndDate }
    },
    futureForecastDateRange: {
      dateRange: { startDate: defaultEndDate, endDate: futureForecastDetails.forecastDate },
      forecastMonth: `${futureForecastDetails.forecastMonth}`,
      forecastYear: `${futureForecastDetails.forecastYear}`
    },
    forecastModelOptions: [],
    minDatesTemplateForForecast: null,
    currentChartView:'',
    projectionAdjustedValues: [],
    currentForecastPage: FORECAST_PAGE_TYPES.SELECT_METRIC_PAGE
  }
}

export default function (state = defaultForecastingOptions(), action) {
  switch (action.type) {
    case UPDATE_FORECAST_DATE_RANGE:
      return {
        ...state,
        currentForecastDateRange: _.get(action, 'forecastDateRange', {})
      }
    case UPDATE_FUTURE_FORECAST_DATE_RANGE:
      return {
        ...state,
        futureForecastDateRange: _.get(action, 'futureForecastDateRange', {})
      }
    case UPDATE_FORECAST_TEMPLATE_ID:
      return {
        ...state,
        selectedForecastTemplateId: _.get(action, 'selectedForecastTemplateId', '')
      }
    case UPDATE_FORECAST_SELECTED_METRIC:
      return {
        ...state,
        selectedForecastMetric: _.get(action, 'selectedForecastMetric', {})
      }
    case UPDATE_FORECAST_MODEL_OPTIONS:
      return {
        ...state,
        forecastModelOptions: _.get(action, 'forecastModelOptions', [])
      }
    case UPDATE_FORECAST_PREPARE_DATA_TIME:
      return {
        ...state,
        forecastPrepareDataTime: _.get(action, 'forecastPrepareDataTime', "")
      }
    case SET_MIN_DATES_TEMPLATES_FOR_FORECAST:
      return {
        ...state,
        minDatesTemplateForForecast: _.get(action, 'minDates')
      }
    case UPDATE_FORECAST_OVERTIME_CHART_VIEW:
      return {
        ...state,
        currentChartView: _.get(action, 'currentChartView')
      }
    case UPDATE_FORECAST_OPTIONS:
      return {
        ...state,
        ...action.forecastOptions || {}
      }
    case UPDATE_FORECAST_AXIS_GRANULARITY:
      return {
        ...state,
        axisGranularity: _.get(action, 'axisGranularity')
      }
    case UPDATE_PROJECTION_ADJUSTED_VALUES:
      return {
        ...state,
        forecastPrepareDataTime: _.get(action, 'forecastPrepareDataTime', ""),
        projectionAdjustedValues: _.get(action, 'projectionAdjustedValues')
      }
    case UPDATE_FORECAST_PAGE_VIEW:
      return {
        ...state,
        currentForecastPage: _.get(action, 'currentForecastPage')
      }
    case UPDATE_TO_DEFAULT_SETTING:
      return {
        ...defaultForecastingOptions(),
        minDatesTemplateForForecast: _.get(state, 'minDatesTemplateForForecast')
      }
    case UPDATE_FORECAST_BOOKMARK_ID:
      return {
        ...state,
        bookmarkId: _.get(action, 'bookmarkId')
      }
    default:
      return state;
  }
}
