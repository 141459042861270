import React from 'react';
import PropTypes from 'prop-types';
import { buildQueryString } from 'helpers/HttpHelper';
import { ForgeTooltip } from '@tylertech/forge-react';
import { TOOLTIP_CONSTANTS } from '@tylertech/forge';

const PropertyPdf = ({propertyDetails, value}) => {
  const queryParams = {
    country: _.get(propertyDetails, 'comp_finder_country', '').replace(/County/i, "").trim(),
    quickRefId: _.get(propertyDetails, 'comp_finder_quick_ref_id', ''),
    taxYear: _.get(propertyDetails, 'comp_finder_tax_year', '')
  };
  const href = `/api/advanced_search/get_external_pdf.pdf?${buildQueryString(queryParams)}`;
  const delay = TOOLTIP_CONSTANTS.numbers.DEFAULT_DELAY;

  return (
    <a href={href} target='_blank' rel='noopener noreferrer'>
      {value}
      <ForgeTooltip
          text="View PRC in new tab"
          delay={delay} position="top" />
    </a>
  );
};


PropertyPdf.propTypes = {
  propertyDetails: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
};


export default PropertyPdf;
