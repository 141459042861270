import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import InputTag from 'common/components/InputTag/InputTag';
import UserFromBellerophonContext from "context/UserFromBellerophonContext";
import { SHARE_EMAIL_LIMIT, SHARE_EMAIL_SPLITTER } from 'appConstants';
import {
  isValidEmails,
  isAnyWildCardUsers
} from 'pages/SubscriptionsManager/components/Bookmark/helper';


class AddUserSection extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      emailInputs: '',
      showEmailError: false,
      initialEmailValue: ''
    };
  }

  static contextType = UserFromBellerophonContext;

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (this.AddUserSectionRef && !this.AddUserSectionRef.contains(e.target)) {
      this.handleCancelClick();
    }
  }

  handleEmailInputsChanges = (emails) => {
    let filteredEmails = emails.filter(email => !_.isEmpty(email.replaceAll(" ","")));
    this.setState({ emailInputs: filteredEmails.join(SHARE_EMAIL_SPLITTER) });
  }

  handleAddUserClick = () => {
    let { emailInputs } = this.state;
    if(_.isEmpty(emailInputs)) {
      emailInputs = this.state.initialEmailValue;
      this.setState({ emailInputs: emailInputs});
    }
    const modifiedEmailInputs = _.isArray(emailInputs) ? emailInputs : emailInputs.split(SHARE_EMAIL_SPLITTER)
    const userEmails = _.map(
      _.compact(modifiedEmailInputs),
      _.trim
    );

    if (isValidEmails(userEmails) && !this.emailIsAlreadyExist(userEmails) &&
      (_.size(userEmails) <= SHARE_EMAIL_LIMIT) && !_.isEmpty(userEmails) &&
      !isAnyWildCardUsers(userEmails)) {
      this.props.onAddUserClick({ userEmails });
    } else {
      this.setState({ showEmailError: true });
    }
  }

  handleCancelClick = () => {
    this.props.onCancelUser();
  }

  // Checking if the email has a value for controlling add user button.
  handleInitialEmailValueChange = (initialEmailValue) => {
    let initialEmailValueWithoutSpace = initialEmailValue.replaceAll(" ","");
    this.setState({ initialEmailValue: initialEmailValueWithoutSpace});
  }

  emailIsAlreadyExist(userEmails) {
    const { users, hiddenUsers } = this.props;

    const allUsers = users.concat(hiddenUsers);

    const userExist = _.some(userEmails, (userEmailItem) => {
      const userDetails = _.find(allUsers, (user) => {
        return user.email.toUpperCase() === userEmailItem.toUpperCase();
      });
      return !_.isNil(userDetails);
    });

    return userExist
  }

  renderEmailError() {
    const { emailInputs, showEmailError } = this.state;

    if (!showEmailError) {
      return;
    }
    const modifiedEmailInputs = _.isArray(emailInputs)
          ? emailInputs
          : emailInputs.split(SHARE_EMAIL_SPLITTER);
    const userEmails = _.map(_.compact(modifiedEmailInputs), _.trim);
    let invalidMessage = '';

    if (this.emailIsAlreadyExist(userEmails)) {
      invalidMessage = `Already a user with the email ${emailInputs} has access to this application.`;
    }

    if (_.size(userEmails) > SHARE_EMAIL_LIMIT) {
      return (
        <div className="invalid-feedback ml-2">
          Maximum recipient email limit is {SHARE_EMAIL_LIMIT}
        </div>
      );
    } else {
      if (!isValidEmails(userEmails)) {
        invalidMessage = 'Invalid email has been entered.';
      }

      if (isAnyWildCardUsers(userEmails)) {
        invalidMessage = 'User email cannot contain an asterisk';
      }
    }

    return (
      <div className="invalid-feedback ml-2">
        {invalidMessage}
      </div>
    );
  }

  render() {
    const { emailInputs } = this.state;
    const splittedEmails = _.isArray(emailInputs) ? emailInputs : emailInputs.split(SHARE_EMAIL_SPLITTER);
    const selectedEmails = _.isEmpty(emailInputs) ? [] : splittedEmails;

    return (
      <div className="share-options" ref={(ref) => this.AddUserSectionRef = ref}>
        <div className="share-options-wrapper align-items-start">
          <div className="share-input">
            <InputTag
              className="form-control mr-2"
              placeholder="Enter an email address or multiple email addresses
              separated by a space, comma, or semicolon."
              onChange={(event) => this.handleEmailInputsChanges(event)}
              onInitialeEmailValueChange={
                (initialEmailValue) => this.handleInitialEmailValueChange(initialEmailValue)
              }
              tags={selectedEmails}
            />
            {this.renderEmailError()}
          </div>
          <button
            tabIndex="0"
            disabled={_.isEmpty(emailInputs) && _.isEmpty(this.state.initialEmailValue)}
            onClick={this.handleAddUserClick}
            className="btn btn-outline-primary text-nowrap">
            Add user
          </button>
          <button
            tabIndex="0"
            onClick={this.handleCancelClick}
            className="btn btn-outline-primary border-0">
            Cancel
          </button>
        </div>
      </div>
    );
  }
}

AddUserSection.propTypes = {
  users: PropTypes.array,
  hiddenUsers: PropTypes.array,
  showEmailError: PropTypes.bool,
  onAddUserClick: PropTypes.func,
  onCancelUser: PropTypes.func
};

export default AddUserSection;
