import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from 'classnames';
import * as commonPropTypes from 'common/propTypes';

import TableFilter from './TableFilter';
import { ForgeIcon } from '@tylertech/forge-react';

import {
  getWidthAndHeightForFilterBadge,
  isClickOutSideFilter
} from 'common/components/Filters/FilterDomHelper';
import { isMobileView } from 'helpers/DomPageHelper';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

const FILTER_ITEM_WIDTH = 350;

class TableFilterIcon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilter: false,
      showFilterPosition: false,
      tableSearchEntries: [],
      searchFieldEntry: {}
    };

    this.columnFilterButtonRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (isClickOutSideFilter(e, this.columnFilterChipRef)) {
      this.setState({ showFilter: false })
    }
  }

  onCloseToggle = () => {
    this.setState({ showFilter: false })
  }

  handleKeyDownFilterIcon = (e) => {
    if (isEnterButtonPressed(e)) {
      this.toggleColumnFilterButton(e);
    }
  }

  toggleColumnFilterButton = (e) => {
    const { customColumnEntries, columnName } = this.props;
    const { showFilter } = this.state;

    let showFilterPosition = false;
    const boundingClientRect = this.columnFilterButtonRef.current.getBoundingClientRect();

    if (boundingClientRect.x + FILTER_ITEM_WIDTH > window.innerWidth && !isMobileView()) {
      showFilterPosition = true;
    }
    const searchFieldEntry = _.find(customColumnEntries, { field: columnName });
    e.stopPropagation();
    this.setState({
      showFilter: !showFilter,
      showFilterPosition,
      searchFieldEntry
    });
  }

  isExistFilterByColumn = (columnName) => {
    const { tableSearchEntries, customColumnEntries } = this.props;
    const filterEntry = _.find(customColumnEntries, { field: columnName });
    if (!_.isUndefined(filterEntry)) {
      const filter = _.find(tableSearchEntries, { column: filterEntry['column'] });
      return !_.isEmpty(filter);
    }{
      return false
    }
  }

  getFilterIconName = (showFilter, columnName) => {
    const isFilterExist = this.isExistFilterByColumn(columnName);

    if (showFilter) {
      return "filter_list";
    } else {
      return isFilterExist ? "filter_variant_check" : "filter_list";
    }
  }

  renderColumnFilterIcon() {
    const { showFilter, searchFieldEntry } = this.state;
    const { index, columnName } = this.props;
    const filterName = _.get(searchFieldEntry, 'name', '');

    const uniqueId = `filter-icon-${index}-${columnName}`;
    const filterClassNames = classNames({ 'd-flex': showFilter });
    const iconName = this.getFilterIconName(showFilter, columnName);

    return (
      <div className={filterClassNames}
        ref={this.columnFilterButtonRef}
        key={uniqueId}
        id={uniqueId}
        onClick={(e) => this.toggleColumnFilterButton(e)}
        >
        <ForgeIcon
          name={iconName} className="column-filter-icon mr-1 tx-18" />
        {showFilter && <span className='filter-label show-filter-text'> {filterName}</span>}
      </div>
    );
  }

  renderSearchFilter = () => {
    const { index, columnName } = this.props;
    const { showFilter, showFilterPosition } = this.state;
    const isFilterExist = this.isExistFilterByColumn(columnName);

    const filterClassNames = classNames('global-filter',
      { 'show-filter-chip': showFilter },
      { 'filter-position': showFilterPosition }
    );
    const style = showFilter ? getWidthAndHeightForFilterBadge(this.columnFilterChipRef) : {};
    const uniqueBodyId = `table-filter-${index}-${columnName}`;
    const filterChipClass = classNames('filter-chip',
      { 'filter-chip-active': isFilterExist });

    return (
      <div key={uniqueBodyId} id={uniqueBodyId} className={filterClassNames}
        ref={(ref) => this.columnFilterChipRef = ref} style={style}>
        <div className={filterChipClass} tabIndex={0} onKeyDown={this.handleKeyDownFilterIcon}>
          {this.renderColumnFilterIcon(index, columnName)}
          {this.renderFilterBody(index, columnName)}
        </div>
      </div>
    )
  }

  renderFilterBody = () => {
    const { showFilter, searchFieldEntry } = this.state;
    const {
      tableSearchEntries,
      onUpdateSearchObjectAndQuickFilters,
      index, columnName
    } = this.props;

    if (!showFilter || _.isEmpty(searchFieldEntry)) {
      return null;
    }

    return (
      <div className="filter-chip-show">
        <TableFilter
          key={`${index}-${columnName}`}
          tableSearchEntries={tableSearchEntries}
          quickFilters={this.props.quickFilters}
          searchFieldEntry={searchFieldEntry}
          templateId={this.props.templateId}
          onUpdateSearchObjectAndQuickFilters={onUpdateSearchObjectAndQuickFilters}
          onClose={this.onCloseToggle} />
      </div>
    )
  }

  render() {
    return (
      this.renderSearchFilter()
    )
  }
}

TableFilterIcon.propTypes = {
  index: PropTypes.number,
  columnName: PropTypes.string,
  templateId: commonPropTypes.templateIdPropTypes,
  tableSearchEntries: PropTypes.array,
  customColumnEntries: PropTypes.array,
  quickFilters: commonPropTypes.quickFiltersPropTypes,
  onUpdateSearchObjectAndQuickFilters: PropTypes.func,
};

export default TableFilterIcon;
