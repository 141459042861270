import _ from 'lodash';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import {
  getBienniumStartFiscalYear,
  getConfiguredDataEndDate,
  getConfiguredDefaultBienniumFiscalYear,
  getDateDataBeginsFrom,
  getDateRangeStartMonth,
  isCustomDateBeginType
} from 'common/config/customerConfiguration';
import { isBienniumFiscalYear } from 'modules/visualization/LineChart/Helpers/bienniumFiscalYearHelper';
import { getMinDate } from 'helpers/dateUtils';
import { DATE_FORMAT, DATE_OPTIONS_TYPE } from 'appConstants';

import {
  getDateRangeForYearly,
  getDateRangeDifference,
  isDateBetweenTwoDates
} from './dateHelper';

const moment = extendMoment(Moment);

export const getFiscalYearEnding = (date) => {
  const configuredDataEndDate = getConfiguredDataEndDate();
  if (moment(date).isAfter(configuredDataEndDate)) {
    return configuredDataEndDate;
  } else {
    return moment(date);
  }
}

export function getFiscalYearDateRangeForDate(newDate) {
  const month = getDateRangeStartMonth();
  let year = moment(newDate).format('YYYY');
  const monthFromDate = moment(newDate).format('MM');
  if (Number(monthFromDate) > Number(month)) {
    year = Number(year) + 1;
  }
  const date = moment().set({ 'year': year, 'month': (month - 1) }).endOf("month").format("DD");

  // Formatting date to get only YYYY-MM-DD
  const startDate = moment().set({ 'year': year, 'month': month, 'date': 1 }).
    subtract(1, 'year').format(DATE_FORMAT);
  const endDate = moment().set({ 'year': year, 'month': (month - 1), 'date': date }).format(DATE_FORMAT);
  return { startDate, endDate };
}

export const getAllFiscalYears = (selectedYear, currentTemplateId,
  includeDataBeginDate = true, isIncludeSelectedYear = false) => {
  const fiscalYearStartMonth = getDateRangeStartMonth();
  const configuredDataEndDate = getConfiguredDataEndDate();
  const dataEndDate = (configuredDataEndDate.get('month') >= fiscalYearStartMonth &&
    fiscalYearStartMonth > 1) ? configuredDataEndDate.add('year', 1) : configuredDataEndDate;
  const dataEndDateYear = moment(dataEndDate).format('YYYY');

  const dataBeginDate = moment(getDateDataBeginsFrom(currentTemplateId)).format('YYYY');
  let noOfYears = 11;
  if (!_.isEmpty(getDateDataBeginsFrom(currentTemplateId)) && includeDataBeginDate) {
    noOfYears = _.ceil(moment(dataEndDateYear).diff(dataBeginDate, 'years')) + 1;
  }
  return _.compact(_.map(_.times(noOfYears), (value) => {
    const year = moment(dataEndDateYear).subtract(value, 'year').format('YYYY');
    return isIncludeSelectedYear ? year : (!_.isEqual(year, selectedYear) ? year : '');
  }));
}


export const getCompareSamePeriodLastYearOptions = ({ primaryDateRange, primaryDateType, templateId }) => {
  const { startDate, endDate } = primaryDateRange;
  const isBienniumYear = isBienniumFiscalYear({ dateRangeMode: primaryDateType });
  const minDate = getMinDate();
  const dateRangeDifference = getDateRangeDifference(primaryDateRange, 'day');
  const startYear = Number(moment(startDate).format('YYYY'));
  const endYear = Number(moment(endDate).format('YYYY'));
  if (primaryDateType == DATE_OPTIONS_TYPE.YEARLY && dateRangeDifference <= 365) {
    const selectedYear = moment(endDate).format('YYYY');
    return _.compact(_.map(getAllFiscalYears(selectedYear, templateId), (year) => {
      // if(endYear > year){
      return { name: year, range: getComparisonYearDateRange(primaryDateRange, year) }
      // }
    }));
  } else if(isBienniumYear) {
    const allBienniumFiscalYears = getAllBienniumFiscalYears(true);
    const ranges = [];
    _.each(allBienniumFiscalYears, (periodRange) => {
      if (!_.isEqual(primaryDateRange, _.get(periodRange, 'range'))) {
        ranges.unshift(periodRange);
      }
    });

    return _.take(ranges, 20);
  } else {
    const dataEndDateYear = Number(moment().format('YYYY'));
    const yearDifference = endYear - startYear;
    let minStartYear = Number(moment(minDate).format('YYYY'));
    minStartYear = minStartYear == startYear ? (minStartYear - (yearDifference + 1)) : minStartYear;
    const ranges = [];
    _.each(_.rangeRight(minStartYear, dataEndDateYear + 1), (year) => {
      const endRangeYear = year + yearDifference;
      const periodRange = {
        name: endRangeYear == year ? year : `${year} - ${endRangeYear}`,
        range: getComparisonYearDateRangeForPeriod(primaryDateRange, year, endRangeYear)
      }
      if (!_.isEqual(primaryDateRange, _.get(periodRange, 'range'))) {
        ranges.push(periodRange);
      }
    });
    return _.take(ranges, 20);
  }
}

export const getAllFiscalYearsBasedOnDateRange = (dateRange, templateId, isSamePeriod = false) => {
  if (isCustomDateBeginType()) {
    if (isDateBetweenTwoDates(dateRange)) {
      return [];
    }
  }

  const selectedYear = moment(dateRange.endDate).format('YYYY');
  const years = getAllFiscalYears(selectedYear, templateId);

  let options = _.map(years, (year) => {
    const fiscalYear = getFiscalYearByStartMonth(year);
    const newDateRange = isSamePeriod ?
      getComparisonYearDateRange(dateRange, year) :
      getDateRangeForYearly(fiscalYear);

    return { name: year, range: newDateRange };
  });
  options = _.sortBy(options, 'name').reverse();

  return options;
}


export const getFiscalYearByStartMonth = (selectedYear) => {
  if (getDateRangeStartMonth() < 1 && !_.isEmpty(selectedYear)) {
    return String(Number(selectedYear) + 1);
  } else {
    return selectedYear;
  }
}

export const getBienniumStartDate = () => {
  let startMonth = getDateRangeStartMonth();
  if (startMonth === '' || _.isNil(startMonth)) {
    startMonth = Number(moment().format('MM'));
  }
  const startYear = getBienniumStartFiscalYear() || Number(moment().format('YYYY'));
  const options = { 'year': startYear, 'month': startMonth };
  return moment().set(options).startOf("month").format("YYYY-MM-DD");
}

export const getAllBienniumFiscalYears = (isWithPastYears = false) => {
  let bienniumStartDate = getBienniumStartDate();
  if(isWithPastYears){
    bienniumStartDate = moment(bienniumStartDate).subtract(12, 'year').format("YYYY-MM-DD")
  }
  const diffreceYearCount = moment().diff(bienniumStartDate, 'years') + 1;

  const years = _.chain(Math.ceil(diffreceYearCount / 2)).
    times((index) => {
      return { startYearCount: (index * 2), endYearCount: (index * 2 + 2) };
    }).
    map(({ startYearCount, endYearCount }) => {
      const startDateMomentObj = moment(bienniumStartDate).add(startYearCount, 'year');
      let endDateMomentObj = moment(bienniumStartDate).add(endYearCount, 'year').subtract(1, 'day');
      endDateMomentObj = getFiscalYearEnding(endDateMomentObj);
      const startDate = startDateMomentObj.format(DATE_FORMAT);

      if (moment(startDate).isAfter(moment().format(DATE_FORMAT))) {
        return null;
      }
      let startYear = startDateMomentObj.format('YYYY');
      if (getDateRangeStartMonth() > 1) {
        startYear = Number(startYear) + 1;
      }

      const endYear = endDateMomentObj.format('YYYY');
      if (startYear == endYear) {
        endDateMomentObj = moment(endDateMomentObj).add(1, 'year');
      }
      let endDate = endDateMomentObj.format(DATE_FORMAT);

      return {
        name: `${startYear} - ${endDateMomentObj.format('YY')}`,
        range: { startDate, endDate }
      };
    }).
    value();

  return _.compact(years);
}

export const getDefaultBienniumDateRange = () => {
  const bienniumStartDate = getBienniumStartDate();
  const defaultBienniumFiscalYear = getConfiguredDefaultBienniumFiscalYear();
  let endDateMomentObj = moment(bienniumStartDate).add(2, 'year').subtract(1, 'day');
  endDateMomentObj = getFiscalYearEnding(endDateMomentObj);

  let dateRange = {
    startDate: moment(bienniumStartDate).format(DATE_FORMAT),
    endDate: endDateMomentObj.format(DATE_FORMAT)
  };
  if (defaultBienniumFiscalYear) {
    dateRange = JSON.parse(defaultBienniumFiscalYear);
  }
  dateRange = {
    ...dateRange,
    endDate: getFiscalYearEnding(dateRange['endDate']).format(DATE_FORMAT)
  }
  return dateRange;
}

export const getBienniumFiscalYearLabel = (dateRange) => {
  let startYear = moment(dateRange.startDate).format('YYYY');
  let startYearMonth = Number(moment(dateRange.startDate).format('MM'));
  const allBienniumFiscalYears = getAllBienniumFiscalYears();
  const matchedBienniumFiscalYear = _.find(allBienniumFiscalYears, (bienniumFiscalYear) => {
    const { range } = bienniumFiscalYear;
    return _.isEqual(range, dateRange);
  });
  const { startDate, endDate } = getDefaultBienniumDateRange();

  if (!_.isEmpty(matchedBienniumFiscalYear)) {
    if (getDateRangeStartMonth() > 1 || startYearMonth > 1) {
      startYear = Number(startYear) + 1;
    }
    return `${startYear} - ${moment(dateRange.endDate).format('YY')}`;
  } else {
    startYear = moment(startDate).format('YYYY');
    if (getDateRangeStartMonth() > 1 || startYearMonth > 1) {
      startYear = Number(startYear) + 1;
    }
    const defaultLabel = `${startYear} - ${moment(endDate).format('YY')}`;
    return _.get(allBienniumFiscalYears, [0, 'name'], defaultLabel);
  }
};

export const getComparisonYearDateRange = (dateRange, year, periodType = '') => {
  const startMonth = Number(moment(dateRange.startDate).format('M')) - 1;
  const endMonth = Number(moment(dateRange.endDate).format('M')) - 1;
  const startDay = Number(moment(dateRange.startDate).format('DD'));
  const endDay = Number(moment(dateRange.endDate).format('DD'));
  let subtractYear = startMonth > 1 ? 1 : 0;

  if (periodType == 'quarter') {
    subtractYear = 0;
  }
  // Formatting date to get only YYYY-MM-DD
  const startDate = moment().set({ 'year': year, 'month': startMonth, 'date': startDay }).
    subtract(subtractYear, 'year').format(DATE_FORMAT);
  const endDate = moment().set({ 'year': year, 'month': endMonth, 'date': endDay }).format(DATE_FORMAT);

  return { startDate, endDate }
}

export const getComparisonYearDateRangeForPeriod = (dateRange, startYear, endYear) => {
  const startMonth = Number(moment(dateRange.startDate).format('M')) - 1;
  const endMonth = Number(moment(dateRange.endDate).format('M')) - 1;
  const startDay = Number(moment(dateRange.startDate).format('DD'));
  const endDay = Number(moment(dateRange.endDate).format('DD'));

  // Formatting date to get only YYYY-MM-DD
  const startDate = moment().set(
    { 'year': startYear, 'month': startMonth, 'date': startDay }).format(DATE_FORMAT);
  const endDate = moment().set({ 'year': endYear, 'month': endMonth, 'date': endDay }).format(DATE_FORMAT);

  return { startDate, endDate }
}

export const getPerviousComparisonYearDateRangeForRange = (dateRange) => {
  const { startDate, endDate } = dateRange;
  const perviousStartYear = moment(startDate).format('YYYY') - 1;
  const perviousEndYear = moment(endDate).format('YYYY') - 1;

  return getComparisonYearDateRangeForPeriod(dateRange, perviousStartYear, perviousEndYear);
}
