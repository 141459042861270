import _ from 'lodash';
import moment from 'moment';

import {
  getConfiguredDataEndDate,
  getConfiguredDateType,
  isTimeDurationEnabled
} from 'common/config/customerConfiguration';

import { getFormattedValue } from 'helpers/chartDataHelper';
import { isRateOfChangeEnable } from 'common/config/visualizationConfiguration';
import {
  DATE_OPTIONS_TYPE,
  HISTORICAL_FORECAST_COLOR, PROPHET_FORECAST_COLOR, EXPONENTIAL_FORECAST_COLOR,
  DATE_FORMAT
} from 'appConstants';
import {
  getDateRangeForYearly,
  getYearTextByRange
} from 'helpers/dateHelper';
import { getFiscalYearByStartMonth } from 'helpers/fiscalPeriodUtils';
import { getPeriodType, isMorethanTwoYears } from 'modules/visualization/LineChart/vizOptionsHelper';
import { FORECAST_MODEL_TYPES } from 'appConstants';
import { getQuarterMonths } from 'modules/visualization/LineChart/Helpers/overtimeHelper';

const isFiscalYear = _.isEqual(getConfiguredDateType(), DATE_OPTIONS_TYPE.YEARLY);
const YEAR_FORMAT = 'YYYY';

export const getFlyoutData = (data, options) => {
  const { dateRange, compareYearRanges,  isDimensionHighToLow, dateRangeMode } = options;
  const filteredCompareYearRanges = filterCompareYearRanges(compareYearRanges, dateRange);
  // const comparisonDateRange = _.size(filteredCompareYearRanges) == 1 ? _.first(compareYearRanges) : {}
  const isMoreThanOneCompareSelected = compareYearRanges.length >= 2;
  const isMoreThanOneYears = true;
  const inBetweenYearsForDateRange = [getYearTextByRange(dateRange, dateRangeMode)] ;
  const inBetweenYearsForComparisonDateRange = _.map(filteredCompareYearRanges, (comparisonDateRange) => {
    return getYearTextByRange(comparisonDateRange, dateRangeMode);
  });

  const fiscalYears = _.map(inBetweenYearsForDateRange, (year, index) => {
    const isLast = ((inBetweenYearsForDateRange.length - 1) === index);
    return { year: year, segmentType: 'current', isLast, yearRange: isMoreThanOneYears ? dateRange : [] };
  });
  const comparisonYears = _.map(inBetweenYearsForComparisonDateRange, (year, index) => {
    const isLast = ((inBetweenYearsForComparisonDateRange.length - 1) === index);
    return {
      year: year, segmentType: isMoreThanOneCompareSelected ? 'current' : 'comparison',
      isLast: isMoreThanOneCompareSelected ? false : isLast,
      yearRange: isMoreThanOneYears ? compareYearRanges[index] : []
    };
  });

  const isMultipleYearSelected = isMorethanTwoYears(options);
  let years = [...comparisonYears, ...fiscalYears];
  const customYears = _.map(years, (yearItem) => {
    const { year, yearRange, segmentType } = yearItem
    const fiscalYear = getFiscalYearByStartMonth(year);
    const range = _.isEmpty(yearRange) ? getDateRangeForYearly(fiscalYear) : yearRange;
    return { name: year, range,  segmentType };
  });
  const newOptions = { ...options, isMoreThanOneYears };
  let formattedData = flyoutDataFormatter(data, newOptions, isMultipleYearSelected, customYears);
  const showRateOfChange = shouldShowRateOfChange(options, isMultipleYearSelected);

  if(isDimensionHighToLow && !_.get(options, 'isForecastingView', false)) {
    formattedData = getReorderDimensionWiseFormatData(formattedData, options)
  }

  if (isMultipleYearSelected) {
    formattedData =  _.orderBy(formattedData, ['category'], ['desc']);
    years = _.orderBy([...comparisonYears, ...fiscalYears], ['year'], ['desc']);
  }

  return { formattedData, years, showRateOfChange };
}

const getReorderDimensionWiseFormatData = (formattedData, options) => {
  const { dimensionConfigs } = options;
  let newOrderFormattedData = [];
  let dimensionConfigData = [];
  if (!_.isEmpty(dimensionConfigs)) {
    dimensionConfigData = _.map(dimensionConfigs, (datum) => {
      return { traceId: datum.traceId, dimension: datum.dimension};
    });
  }

  _.forEach(dimensionConfigData, (datum) => {
    const findFormatData = _.find(formattedData, { category: datum.traceId });
    const findDimension = _.find(formattedData, { category: datum.dimension });

    if (!_.isEmpty(findFormatData)) {
      newOrderFormattedData.push(findFormatData);
    } else if(!_.isEmpty(findDimension)){
      const findExistingDimension = _.find(newOrderFormattedData, { category: findDimension.category });
      if(_.isEmpty(findExistingDimension)){
        newOrderFormattedData.push(findDimension);
      }
    }
  });

  return !_.isEmpty(newOrderFormattedData) ? newOrderFormattedData : formattedData
}

export const getMetricValues = (metricValues, metricType, segmentType) => {
  return _.filter(metricValues, (metricValue) => {
    return (
      (metricValue.value === metricType) &&
      (metricValue.segmentType === segmentType) &&
      !metricValue.isProjection
    );
  });
}

export const getFlyoutPosition = (data, viewMode) => {
  const isSmallView = (viewMode === 'small');
  if (!isSmallView) {
    return '';
  }

  return getFlyoutPositionByWindowSize(data);

}

export const getFlyoutPositionByWindowSize = (data) => {
  const windowQuarterWidth = (window.innerWidth) / 4;
  if (data.event.x < windowQuarterWidth) {
    return 'top';
  } else if (data.event.x > (3 * windowQuarterWidth)) {
    return 'left';
  } else {
    return '';
  }
}

export const getMetricItems = (formattedData, metricType = 'primary', withProjection = true) => {
  return _.chain(formattedData).
    map((datum) => {
      return _.map(datum.metricValues, (metricData) => {
        let conditions = (!!metricData.isProjection == withProjection) && metricData.value === metricType;
        let addCondition = (metricData.isProjection && metricData.ignoreProjection) || conditions;
        conditions = withProjection ? (conditions && !metricData.ignoreProjection) : addCondition;
        if (conditions) {
          return metricData;
        } else {
          return null;
        }
      });
    }).
    flattenDeep().
    compact().
    value();
}

export const getRateOfChange = (currentPeriodValue, previousPeriodValue, isAddPercentage = true) => {
  if (_.isEmpty(_.compact(currentPeriodValue)) && _.isEmpty(_.compact(previousPeriodValue))) {
    return '';
  }
  const difference = Number(_.head(currentPeriodValue)) - Number(_.head(previousPeriodValue));
  let percentage;

  if (Number(previousPeriodValue) == 0) {
    percentage = 100;
  } else if (Number(currentPeriodValue) == 0) {
    percentage = -100
  } else {
    percentage = (difference / Number(previousPeriodValue)) * 100;
  }
  return isAddPercentage ? `${_.round(percentage, 2)}%` : _.round(percentage, 2);
}

export const getActualChange = (currentPeriodValue, previousPeriodValue) => {
  const difference = Number(currentPeriodValue) - Number(previousPeriodValue);
  return difference;
}

/*---------------------------------------------------------------------------------
Output:
---------------------------------------------------------------------------------
 formattedData =>
  [
    { category: 'Total', color: 'red', metricValues: [{}, {}] },
    { category: 'Open', color: 'orange', metricValues: [{}, {}]},
    ....
  ];
    metricValues ===> {
      year: '2018',
      metricValue: '$10.8',
      value: 'primary',
      segmentType: 'current' || 'comparison' || 'tailingDrop',
      isProjection: true,
      dimension: 'Total',
      color: 'red',
      rawValue: '10.8'
    }
*/
const flyoutDataFormatter = (data, vizOptions, isMultipleYearSelected, years) => {
  const {
    viewEntry, secondaryMetricEntry, isRolling, isForecastingView
  } = vizOptions;
  const periodType = getPeriodType(vizOptions);
  const granularity = _.get(vizOptions, 'axisGranularity');
  const groupedPoints = _.groupBy(data, (point) => _.get(point, 'data.meta.dimension'));
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);
  return _.map(groupedPoints, (groupedPoint, category) => {
    let color = _.get(groupedPoint, '[0].data.meta.color', {});
    const projectionName = _.get(groupedPoint, '[0].data.meta.projectionName', {});
    const isProjection = _.get(groupedPoint, '[0].data.isProjection', {});
    color = isForecastingView ? getForecastLineColor(color, category) : color;
    let metricValues = [];
    let isProjectionStarted = false;
    _.each(groupedPoint, (groupedPointItem) => {
      const metaData = _.get(groupedPointItem, 'data.meta', {});
      const metaSegmentType = metaData['segmentType'];
      const rawValue = _.get(groupedPointItem, 'y', '0');
      const xValue = _.get(groupedPointItem, 'x');
      const originalTextValues = _.get(groupedPointItem,'data.text_values',[]);
      const pointIndex = _.get(groupedPointItem,'pointIndex',0);
      const originalYValue = _.nth(originalTextValues, pointIndex) || rawValue;
      const isSecondaryMetric = _.get(groupedPointItem, 'data.meta.value') === "secondary";
      const entry = isSecondaryMetric ? secondaryMetricEntry : viewEntry;
      let formattedYValue = isSecondsFormat ? getDurationFlyoutText(originalYValue, entry) :
        getFormattedValue(_.get(groupedPointItem, 'y', '0'), entry);
      const customData = _.get(groupedPointItem, 'data.customData');
      const lastCustomData = _.last(customData);
      const dimensionPeriod = _.get(lastCustomData, 'period');
      const headCustomData = _.head(customData);
      const yValue = isSecondaryMetric ? headCustomData.secondary_total : headCustomData.value;
      const periodMonth = moment(headCustomData.period).format('MMM DD');
      const periodDate = moment(headCustomData.period).format('YYYY-MM-DD');
      const splittedXValue = _.get(_.toString(xValue).split(' - '), '0');
      const isSameDate = isRolling ?
        moment(headCustomData.period).isSame(groupedPointItem.x, 'month') ||
        (periodMonth === splittedXValue) ||
        _.get(headCustomData, 'quarterText') === splittedXValue :
        (moment(headCustomData.period).format('MMM') === xValue || periodDate == xValue);
      const ignoreProjection = (_.get(headCustomData, 'ignoreProjection')
        && (yValue == rawValue) &&
        isSameDate
      );
      isProjectionStarted = getIsProjectionStarted(xValue, granularity, vizOptions);

      const adjustedData = isForecastingView ?
        forecastAdjustedData(customData, vizOptions, xValue, entry, formattedYValue) : {};

      const findYear = getMatchYear(years, dimensionPeriod, metaSegmentType, periodType);
      const year = _.get(findYear, 'name') || moment(dimensionPeriod).format(YEAR_FORMAT);
      const ProjectionType = _.get(groupedPointItem, 'data.ProjectionType');
      const tailingDropStartDate = _.isString(_.get(metaData, 'tailingDropStartDate', ''))  ?
        moment(metaData['tailingDropStartDate']) : metaData['tailingDropStartDate'];
      let hideProjection = false;
      if (isForecastingView && !_.isEmpty(ProjectionType)){
        hideProjection = moment(periodDate).isBefore(tailingDropStartDate)
      }

      const extraMetaData = {
        year, metricValue: formattedYValue, rawValue, ignoreProjection, bienniumFiscalYear: '',
        hideProjection, ...adjustedData, originalYValue
      };
      const metaDataWithValue = _.merge({}, metaData, extraMetaData);
      metricValues.push(metaDataWithValue);
    });
    if (isForecastingView && isProjection && !_.isEmpty(projectionName)){
      category = projectionName;
    }
    return { category, color, metricValues, isProjectionStarted };
  });
}

export const getDurationFlyoutText = (rawValue, entry) => {
  const showSuffixInFlyout = JSON.parse(_.get(entry, 'show_suffix_in_flyout', false));

  if (!_.isEmpty(rawValue) || !isNaN(rawValue)) {
    let timeFormatValue = rawValue;
    if (showSuffixInFlyout) {
      const suffix = _.get(entry, 'plural_suffix', '');
      timeFormatValue = `${timeFormatValue} ${suffix}`
    }
    return timeFormatValue
  }
}

const forecastAdjustedData = (customData, vizOptions, xValue, entry, formattedYValue) => {
  const renderType= _.get(vizOptions, 'renderType');
  const granularity= _.get(vizOptions, 'axisGranularity');
  const granularityWeek = _.includes(['week', 'quarter'], granularity);
  const customDataFind = _.find(customData, (datum) => {
    if(moment(datum.period).format('YYYY-MM-DD') === moment(xValue).format('YYYY-MM-DD')) {
      return datum;
    } else if(granularityWeek && datum['periodLabel'] == xValue ){
      return datum;
    }
  })

  if(_.isEmpty(customDataFind)){
    return {}
  }

  const isAccumulated = _.get(customDataFind, 'isAccumulated', false);
  let value = Number(_.get(customDataFind, 'value', '0'));
  const accumulateValue = Number(_.get(customDataFind, 'accumulateValue', '0'));
  value = isAccumulated ? (accumulateValue || 0) : (value || 0);

  const forecastData = {
    adjustValue : getFormattedValue(_.get(customDataFind, 'adjustValue', '0'), entry),
    originalValue : renderType == 'burn_up' ? formattedYValue : getFormattedValue(value, entry),
    rawOriginalValue : value,
    rawAdjustValue : (Number(_.get(customDataFind, 'adjustValue', '0')) || 0),
    note: _.get(customDataFind, 'note')
  }

  return forecastData
}

const getForecastLineColor = (color, type) => {
  if(FORECAST_MODEL_TYPES[0].name == type){
    return PROPHET_FORECAST_COLOR
  } else if(FORECAST_MODEL_TYPES[1].name == type){
    return HISTORICAL_FORECAST_COLOR
  } else if(FORECAST_MODEL_TYPES[2].name == type){
    return EXPONENTIAL_FORECAST_COLOR
  } else {
    return color
  }
}

const shouldShowRateOfChange = (vizOptions, isMultipleYearSelected) => {
  const { compareYearRanges, viewEntry, renderType } = vizOptions;
  const isComparisonEnabled = _.size(compareYearRanges) == 1;
  const isRateOfChangeEnabled = isRateOfChangeEnable(viewEntry, renderType);

  return (isRateOfChangeEnabled && isComparisonEnabled && isFiscalYear && !isMultipleYearSelected);
}

export const filterCompareYearRanges = (compareYearRanges, dateRange) => {
  return _.filter(compareYearRanges, (compareYearRange) => {
    return (
      _.get(compareYearRange, 'startDate') !== _.get(dateRange, 'startDate') &&
      _.get(compareYearRange, 'endDate') !== _.get(dateRange, 'endDate')
    );
  });
}

const getMatchYear = (years, dimensionPeriod, metaSegmentType, periodType) => {
  return _.find(years, (yearItem) => {
    const { range, segmentType } = yearItem;
    const { startDate, endDate } = range;

    if(periodType === 'year'){
      return (
        segmentType == metaSegmentType &&
        moment(startDate).year() <= moment(dimensionPeriod).year() &&
        moment(endDate).year() >= moment(dimensionPeriod).year()
      );
    }

    return (
      segmentType == metaSegmentType &&
      moment(startDate) <= moment(dimensionPeriod) &&
      moment(endDate) >= moment(dimensionPeriod)
    );
  });
}

const getIsProjectionStarted = (xValue, granularity, vizOptions) => {
  const { isForecastingView, dateRange } = vizOptions;
  const today = getConfiguredDataEndDate();
  const todayDate = moment(today).format(DATE_FORMAT);
  const currentDate = isForecastingView ? new Date(dateRange.endDate) : new Date(todayDate);
  const quarterMonths = getQuarterMonths();

  let flyoutDate;

  if (granularity === 'quarter') {
    const [quarter, year] = xValue.split(' ');
    const month = parseInt(_.findKey(quarterMonths, val => val === quarter));
    flyoutDate = new Date(year, month, 1);
  } else {
    flyoutDate = new Date(xValue);
  }

  const getQuarter = (date) => {
    const month = date.getMonth();
    return quarterMonths[month];
  };

  switch (granularity) {
    case 'year':
      return flyoutDate.getFullYear() < currentDate.getFullYear();
    case 'month':
      return (flyoutDate.getFullYear() < currentDate.getFullYear() ||
        (flyoutDate.getFullYear() === currentDate.getFullYear() &&
        flyoutDate.getMonth() < currentDate.getMonth()));
    case 'quarter': {
      const flyoutQuarter = getQuarter(flyoutDate);
      const currentQuarter = getQuarter(currentDate);
      return (flyoutDate.getFullYear() < currentDate.getFullYear() ||
        (flyoutDate.getFullYear() === currentDate.getFullYear() &&
        flyoutQuarter < currentQuarter));
    }
    case 'week': {
      // to check current date lies within 7 days from flyout date
      const millisecondsPerDay = 24 * 60 * 60 * 1000;
      const differenceInDays = Math.floor((currentDate - flyoutDate) / millisecondsPerDay);
      return differenceInDays >= 7;
    }
    default:
      return flyoutDate < currentDate;
  }
};
