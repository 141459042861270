import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import DateRangeFilterForCollection from 'common/components/SecondaryHeader/DateRangeFilterForCollection';

import { getDateRangeTextEntry } from 'helpers/dateHelper';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import {
  configuredDefaultDateType
} from 'common/config/customerConfiguration';
import { getRelativeDateFilterEntry } from 'helpers/commonFiltersHelper';
import {
  addFilterChipTitle,
  getWidthAndHeightForFilterBadge,
  isClickOutSideFilter,
  // applyFilters
} from 'common/components/Filters/FilterDomHelper';
import { isMobileView } from 'helpers/DomPageHelper';
import { getMaxDate, getMinDate } from 'helpers/dateUtils';
import { datePropTypes } from 'common/propTypes';
import ForgePopupOption from 'common/components/ForgePopupOption/ForgePopupOption';
import GlobalEvents from 'common/components/GlobalEvents';

const FILTER_ITEM_WIDTH = 360;

class DateRangeFilterWithBadge extends Component {
  constructor(props, context) {
    super(props, context);
    this.filterChipRef = React.createRef();
    this.addFilterButtonRef = React.createRef();
    this.addFilterButtonRefForge = React.createRef();
    this.state = {
      showFilterPosition: false,
      isValueChanged: false
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleUpdateValueChange = (isChanged) => {
    this.setState({
      isValueChanged: isChanged
    })
  }

  handleClickOutside = (e) => {

    if (isClickOutSideFilter(e, this.addFilterButtonRefForge)) {
      this.setState({ showDatePickerDropDown: false });
    }
  }

  handleClickClose = (type = '') => {
    if (!_.isEmpty(document.getElementById("apply-filter-id"))) {
      if (type != 'cancel') {
        // applyFilters();
        GlobalEvents.emit('ON_OUTSIDE_CLICK');
      }
      this.setState({ showDatePickerDropDown: false });
    }

  }

  onMouseEnter = (filterName, filterValuesContent) => {
    addFilterChipTitle(this.filterChipRef, filterName, filterValuesContent);
  }

  handleKeyDown = (e, showDatePickerDropDown) => {
    if (isEnterButtonPressed(e)) {
      this.setState({ showDatePickerDropDown: !showDatePickerDropDown });
    }
  }

  handleBadgerClick = (e, showDatePickerDropDown) => {
    const boundingClientRect = this.addFilterButtonRef.current.getBoundingClientRect()
    let showFilterPosition = false;

    if (boundingClientRect.x + FILTER_ITEM_WIDTH > window.innerWidth && !isMobileView()) {
      showFilterPosition = true;
    }
    this.setState({ showDatePickerDropDown: !showDatePickerDropDown, showFilterPosition });
  }

  onDateFilterChange = (dateType, dateRange, relativeDateFilterEntry, comparisonPeriod) => {
    const { onDateFilterChange } = this.props;
    this.setState({ showDatePickerDropDown: false });
    onDateFilterChange({
      comparisonPeriod,
      dateType,
      dateRange,
      relativeDateFilterEntry: getRelativeDateFilterEntry(relativeDateFilterEntry)
    });

  }

  renderDatePickerDropDown() {
    const { showDatePickerDropDown } = this.state;
    const {
      currentDrilldownTemplateId, filterName, shouldHideCustomDateRange, comparisonLabel,
      minDate, maxDate, hideRelativeDateRange, showComparisonPeriod, comparisonPeriod,
      onUpdateUserPreference, previousDateRangeConfig
    } = this.props;
    const dateProps = {
      dateRange: _.get(this.props, 'dateRange', {}),
      dateType: _.get(this.props, 'dateType', configuredDefaultDateType),
      relativeDateFilterEntry: _.get(this.props, 'relativeDateFilterEntry', {}),
      onUpdateUserPreference: onUpdateUserPreference
    };
    if (!showDatePickerDropDown) {
      return null;
    }
    const newMinDate = minDate ? minDate : getMinDate(currentDrilldownTemplateId);
    const newMaxDate = maxDate ? maxDate : getMaxDate();
    return (
      <DateRangeFilterForCollection
        previousDateRangeConfig={previousDateRangeConfig}
        {...dateProps}
        comparisonLabel={comparisonLabel}
        hideRelativeDateRange={hideRelativeDateRange}
        showComparisonPeriod={showComparisonPeriod}
        shouldHideCustomDateRange={shouldHideCustomDateRange}
        minDate={newMinDate}
        maxDate={newMaxDate}
        currentDrilldownTemplateId={currentDrilldownTemplateId}
        onClose={this.handleClickClose}
        onDateFilterChange={this.onDateFilterChange}
        showFilterLabel={true}
        filterName={filterName}
        comparisonPeriod={comparisonPeriod}
      />
    );
  }

  renderForgePopupContent = () => {
    const { showDatePickerDropDown } = this.state;

    return (
      <div onKeyDown={this.handleKeyDown}>
        <ForgePopupOption
          showPopup={showDatePickerDropDown}
          targetPopupRef={this.addFilterButtonRefForge}
          closeFilterChip={this.handleClickClose}
          isIncludeOutsideClose={true}>
          {this.renderDatePickerDropDown()}
        </ForgePopupOption>
      </div>
    )
  }

  renderBadge() {
    const { dateRange, dateType, filterName,
      showFilterText, style, isInlineLabel } = this.props;
    const { showDatePickerDropDown } = this.state;
    const rangeEntry = getDateRangeTextEntry(dateRange, dateType);
    let buttonText = _.get(rangeEntry, 'text');
    const newStyle = showDatePickerDropDown ? style : {};
    const className = "color-theme-primary cursor-pointer d-flex align-items-center text-nowrap gap-6";

    return (
      <div
        ref={this.addFilterButtonRefForge}
        style={newStyle}
        tabIndex="0"
        onKeyDown={(e) => this.handleKeyDown(e, showDatePickerDropDown)} >
        {!_.isEmpty(filterName) && !isInlineLabel &&
          <div className="control-labels ml-0 forge-typography--caption">{filterName}</div>}
        <div
          className={`forge-typography--body1 ${className}`}
          ref={this.addFilterButtonRef}
          onClick={(e) => this.handleBadgerClick(e, showDatePickerDropDown)}>
          {!showFilterText && <div onMouseEnter={() => this.onMouseEnter(filterName, buttonText)}
            ref={this.filterChipRef}>
            <span>{buttonText}</span>
          </div>}
          {!_.isEmpty(filterName) && isInlineLabel &&
            <div className="control-labels ml-0">{filterName}</div>}
          <i className="icons-chevron-down"></i>
        </div>

        {this.renderForgePopupContent()}
        {/* {this.renderDatePickerDropDown()} */}
      </div>
    )
  }

  render() {
    const { disabledFilters } = this.props;
    const { showDatePickerDropDown, showFilterPosition } = this.state;
    const dateRangeBadgeClassNames = classNames(
      "date-range-filter", "forge-popup-host",
      { 'filter-position': showFilterPosition },
      { "show-filter-chip": showDatePickerDropDown },
      {"disabled": disabledFilters }
    );
    const style = showDatePickerDropDown ? getWidthAndHeightForFilterBadge(this.filterRef) : {};

    return (
      <div className={dateRangeBadgeClassNames} ref={(ref) => this.filterRef = ref} style={style}>
        {this.renderBadge()}
      </div>
    );
  }
}

DateRangeFilterWithBadge.propTypes = {
  dateRange: PropTypes.object,
  dateType: PropTypes.string,
  currentDrilldownTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  filterName: PropTypes.string,
  onDateFilterChange: PropTypes.func,
  shouldHideCustomDateRange: PropTypes.bool,
  minDate: datePropTypes,
  maxDate: datePropTypes,
  showFilterText: PropTypes.bool,
  hideRelativeDateRange: PropTypes.bool,
  showComparisonPeriod: PropTypes.bool,
  comparisonPeriod: PropTypes.string,
  style: PropTypes.object,
  comparisonLabel: PropTypes.string,
  onUpdateUserPreference: PropTypes.func,
  disabledFilters: PropTypes.bool,
  isInlineLabel: PropTypes.bool,
  previousDateRangeConfig: PropTypes.object
}

DateRangeFilterWithBadge.defaultProps = {
  shouldHideCustomDateRange: false,
  showFilterText: false,
  hideRelativeDateRange: false,
  showComparisonPeriod: false,
  style: {},
  comparisonLabel: '',
  isInlineLabel: false,
  onUpdateUserPreference: _.noop,
  disabledFilters: false,
  previousDateRangeConfig: {}
}

export default DateRangeFilterWithBadge;
