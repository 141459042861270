// Vendor Imports
import _ from 'lodash';
import React from 'react';
import classNames from 'classnames';

// Project Imports
import {
  getFlyoutData,
  getRateOfChange,
  getMetricValues,
  getMetricItems
} from './helper';
import { LINE_CHART_COMPARE_TRACE_COLORS } from 'appConstants';
import { getNullValueLabel } from 'common/config/templateConfiguration';
import { getPrimaryMetricName } from 'helpers/displayNameHelper';
import { shouldDisableDimensions } from 'helpers/chartDataHelper';
import { isBienniumFiscalYear } from 'modules/visualization/LineChart/Helpers/bienniumFiscalYearHelper';
import { formatValueToCurrency } from 'helpers/numberHelper';

export const getFlyoutContent = (data, options) => {
  const {
    viewEntry,
    secondaryMetricEntry,
    projectionEnabled,
    renderTimeFrame,
    compareYearRanges,
    isForecastingView,
    axisGranularity
  } = options;
  const { formattedData, years, showRateOfChange } = getFlyoutData(data, options);
  if (isForecastingView) {
    const formattedObject = _.first(formattedData);
    const metricValue = _.first(_.get(formattedObject, 'metricValues'));
    const isProjectionStarted = _.get(formattedObject, 'isProjectionStarted');
    const isProjection = _.get(metricValue, 'isProjection', false);

    const formattedLastObject = _.last(formattedData);
    const lastMetricValue = _.last(_.get(formattedLastObject, 'metricValues'));
    const isProjectionLast = _.get(lastMetricValue, 'isProjection', false);
    const ignoreProjection = _.get(lastMetricValue, 'ignoreProjection', false);
    const hideProjection = _.get(lastMetricValue, 'hideProjection', false);
    const isWeekGranularity = axisGranularity === 'week'

    if (!isProjection && (!isProjectionLast || ignoreProjection)) {
      return getForecastAdjustFlyout(metricValue);
    } else if (hideProjection && !isWeekGranularity && isProjectionStarted){
      return getForecastAdjustFlyout(metricValue);
    } else if (isProjectionStarted){
      return getForecastAdjustFlyout(metricValue);
    }
  }
  options['disabledDimensions'] = shouldDisableDimensions(
    options.dateRange, renderTimeFrame, compareYearRanges);
  const primaryItems = getMetricItems(formattedData, 'primary', false);
  const secondaryItems = getMetricItems(formattedData, 'secondary', false);

  const primaryProjectionData = projectionEnabled ? getMetricItems(formattedData, 'primary') : null;
  const secondaryProjectionData = !_.isEmpty(secondaryMetricEntry) && projectionEnabled ?
    getMetricItems(formattedData, 'secondary') :
    null;
  const showRateOfChangeAndSecondaryMetric = !_.isEmpty(secondaryMetricEntry) && showRateOfChange;
  const primaryMetricName = getPrimaryMetricName(viewEntry);
  const secondaryMetricName = _.get(secondaryMetricEntry, 'name');
  const primaryMetricContent = getFlyoutTableColumnContent(formattedData, years, options, 'primary');
  const secondaryMetricContent = !_.isEmpty(secondaryMetricEntry) ?
    (<td>{getFlyoutTableColumnContent(formattedData, years, options, 'secondary')}</td>) :
    null;

  if (_.isEmpty(secondaryItems) && _.isEmpty(secondaryProjectionData)
    && _.isEmpty(primaryItems) && _.isEmpty(primaryProjectionData)
    && _.isEmpty(formattedData)) {
    return null;
  }

  return (
    <table className="flyout-table">
      <thead>
        <tr>
          {!_.isEmpty(secondaryItems) && !_.isEmpty(secondaryMetricEntry) && <th>{secondaryMetricName}</th>}
          {showRateOfChangeAndSecondaryMetric && <th>Change</th>}
          {!_.isEmpty(secondaryProjectionData) && <th>Projected</th>}
          {!_.isEmpty(primaryItems) && <th>{primaryMetricName}</th>}
          {showRateOfChange && <th>Change</th>}
          {!_.isEmpty(primaryProjectionData) && <th>Projected</th>}
          <th>Category</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          {!_.isEmpty(secondaryItems) && secondaryMetricContent}
          {
            showRateOfChangeAndSecondaryMetric &&
            <td>{getRateOfChangeContent(formattedData, 'secondary')}</td>
          }
          {!_.isEmpty(secondaryProjectionData) &&
            <td>{getProjectionContent(formattedData, 'secondary', options)}</td>
          }
          {!_.isEmpty(primaryItems) && <td>{primaryMetricContent}</td>}
          {showRateOfChange && <td>{getRateOfChangeContent(formattedData, 'primary')}</td>}
          {!_.isEmpty(primaryProjectionData) &&
            <td>{getProjectionContent(formattedData, 'primary', options)}</td>
          }
          <td>{getCategorysContent(formattedData, options)}</td>
        </tr>
      </tbody>
    </table>
  );
}

const getForecastAdjustFlyout = (formattedData) => {
  const { rawAdjustValue, adjustValue, originalValue, note } = formattedData;
  const adjustValueText = (rawAdjustValue > 0 || rawAdjustValue < 0) ? adjustValue : 'None';
  const noteText = !_.isEmpty(note) ? note : 'None';

  return (
    <table className="flyout-table">
      <thead>
      </thead>
      <tbody>
        <tr>
          <td className='font-weight-bold'>Adjusted value</td>
          <td>:</td>
          <td>{adjustValueText}</td>
        </tr>
        <tr>
          <td className='font-weight-bold'>Original value</td>
          <td>:</td>
          <td>{originalValue}</td>
        </tr>
        <tr>
          <td className='font-weight-bold'>Note</td>
          <td>:</td>
          <td>
            <div className='wrap-white-spaces'>{noteText}</div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function getFlyoutTableColumnContent(formattedData, years, options, metricType) {
  const { isRolling, disabledDimensions, templateId, dateRangeMode, isForecastingView } = options;
  const columnOptions = {
    years, metricType, isRolling, templateId, disabledDimensions, dateRangeMode, isForecastingView
  };
  let bienniumFiscalYears = [];
  const tableRowContent = _.map(formattedData, (metricDatum, index) => {
    const subTableColumnContent = getSubTableColumnContent(metricDatum, columnOptions, bienniumFiscalYears);
    return (<tr key={index}>{subTableColumnContent}</tr>);
  });
  const newYears = isBienniumFiscalYear(columnOptions) ? _.uniqBy(bienniumFiscalYears, 'year') : years;
  const tableHeadColumnContent = (isRolling || disabledDimensions) ?
    (<td></td>) :
    getTableHeadContent(newYears);

  return (
    <table>
      <thead>
        <tr>{tableHeadColumnContent}</tr>
      </thead>
      <tbody>
        {tableRowContent}
      </tbody>
    </table>
  );
}

function getSubTableColumnContent(metricData, columnOptions, bienniumFiscalYears) {
  const { color, metricValues } = metricData;
  const {
    years, metricType, isRolling, templateId, disabledDimensions, isForecastingView
  } = columnOptions;
  const nullValueLabel = getNullValueLabel(templateId);

  if (isRolling) {
    return getRollingTableColumnContent(metricData, metricType, isRolling, nullValueLabel);
  }
  const renderedYears = [];
  const subTableColumnContent = _.map(years, (yearItem, index) => {
    const { year, segmentType, isLast } = yearItem;
    // const metricValueItem = _.find(metricValues,
    // { year, segmentType, value: metricType, isProjection: false });
    const metricValueItem = _.find(metricValues, (metric) => {
      return (
        (metric.value === metricType) &&
        (metric.segmentType === segmentType) &&
        !metric.isProjection
      );
    });
    const metricYear = _.get(metricValueItem, 'year', '');
    const isAlreadyRendered = !isForecastingView && _.includes(renderedYears, metricYear);
    if (((disabledDimensions) && _.isNil(metricValueItem)) || isAlreadyRendered) {
      return null;
    }

    const metricValue = _.get(metricValueItem, 'metricValue', nullValueLabel);
    const metricColor = _.get(metricValueItem, 'color', color);
    const bienniumFiscalYear = _.get(metricValueItem, 'bienniumFiscalYear', year);
    const colorContent = (isLast || disabledDimensions) ?
      getColorContent(metricColor, metricType, segmentType) :
      null;
    if(!isForecastingView && metricYear) {
      renderedYears.push(metricYear);
    }
    bienniumFiscalYears.push({ year: bienniumFiscalYear });
    return getColumnContent(metricValue, colorContent, index);
  });
  return _.without(subTableColumnContent, null);
}

function getColumnContent(value, colorContent, key) {
  return (
    <td key={key}>
      <div className="d-flex justify-content-end">
        <div>{value}</div>
        {colorContent}
      </div>
    </td>
  );
}

function getRollingTableColumnContent(metricData, metricType, isRolling, nullValueLabel) {
  const { color, metricValues } = metricData;
  const metricValueItem = _.find(metricValues, (metric) => {
    return ((metric.value === metricType) && !metric.isProjection);
  });
  const metricValue = _.get(metricValueItem, 'metricValue', nullValueLabel);
  const colorContent = getColorContent(color, metricType);
  const tableColumnKey = `${color}-${metricValue}`;

  return getColumnContent(metricValue, colorContent, tableColumnKey);
}

function getCategorysContent(formattedData, { disabledDimensions, isCurrencyDimensionField }) {
  const tableRowContent = _.map(formattedData, (datum, index) => {
    return (<tr key={index}>
      <td className="text-left">{formatValueToCurrency(datum.category, isCurrencyDimensionField)}</td>
    </tr>
    );
  });

  return (
    <table>
      {!disabledDimensions && <thead><tr className="category-th"><th></th></tr></thead>}
      <tbody>
        {tableRowContent}
      </tbody>
    </table>
  );
}

function getRateOfChangeContent(formattedData, metricType) {
  const rateOfChanges = _.map(formattedData, (metricDatum, index) => {
    const { metricValues } = metricDatum;
    const currentPeriodValues = _.map(
      getMetricValues(metricValues, metricType, 'current'),
      'rawValue'
    );
    const comparisonPeriodValues = _.map(
      getMetricValues(metricValues, metricType, 'comparison'),
      'rawValue'
    );

    return (
      <tr key={index}>
        <td>
          <div className="d-flex justify-content-end">
            {getRateOfChange(currentPeriodValues, comparisonPeriodValues)}
          </div>
        </td>
      </tr>
    )
  });
  return (
    <table className="rate-of-change-table year-on-year">
      <thead>
        <tr>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {rateOfChanges}
      </tbody>
    </table>
  );
}

function getProjectionContent(formattedData, metricType, { isRolling, disabledDimensions }) {
  const projectionTableClasses = classNames('projection-table', { 'year-on-year': !isRolling });
  const projectionContent = _.map(formattedData, (metricDatum, index) => {
    const { metricValues, color } = metricDatum;
    const meticData = _.find(metricValues, { isProjection: true, value: metricType });
    const metricValue = _.get(meticData, 'metricValue', 0);

    return (
      <tr key={index}>
        <td>
          <div className="d-flex justify-content-end">
            <div>{metricValue}</div>
            {getProjectionColorContent(color, metricType)}
          </div>
        </td>
      </tr>
    )
  });

  return (
    <table className={projectionTableClasses}>
      {!disabledDimensions && <thead><tr className="category-th"><th></th></tr></thead>}
      <tbody>
        {projectionContent}
      </tbody>
    </table>
  );
}

function getTableHeadContent(years) {
  return _.map(years, (yearItem, index) => {
    return (<th key={index}>{yearItem.year}</th>);
  });
}

function getColorContent(color, metricType, segmentType = 'current') {
  let newColor = color;
  if (segmentType === 'comparison') {
    const colorConfig = _.find(LINE_CHART_COMPARE_TRACE_COLORS, { color });
    newColor = _.get(colorConfig, 'lite', color);
  }
  switch (metricType) {
    case 'secondary':
      return getSecondaryColorContent(newColor, segmentType);
    case 'primary':
      return getPrimaryColorContent(newColor, segmentType);
    default:
      return getPrimaryColorContent(newColor, segmentType);
  }
}

function getSecondaryColorContent(color, segmentType) {
  const secondaryMetricClasses = classNames('pl-3', {
    'last-period': (segmentType === 'comparison')
  });


  return (
    <div className={secondaryMetricClasses}>
      <svg><line x1="30" y1="0" style={{ stroke: color }}></line></svg>
    </div>
  );
}

function getPrimaryColorContent(color, segmentType) {
  const primaryMetricClasses = classNames('legend-color', {
    'secondary': (segmentType === 'comparison')
  });

  return (
    <div className="pl-3">
      <span className={primaryMetricClasses} style={{ backgroundColor: color }}></span>
    </div>
  );
}

function getProjectionColorContent(color, metricType) {
  const projectionClasses = classNames('pl-3 projection-color', {
    'last-period': (metricType === 'secondary')
  });

  return (
    <div className={projectionClasses}>
      <svg><line x1="30" y1="0" style={{ stroke: color }}></line></svg>
    </div>
  );
}
