import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';

import ForgeDropdowns from 'common/components/ForgeDropdowns/ForgeDropdowns';
import { ForgeButton, ForgeCheckbox } from '@tylertech/forge-react';
import { pdfDownload } from './pdfExport';
import GlobalEvents from 'common/components/GlobalEvents';

const ExportModal = (props) => {
  const [ isMapDownload, setIsMapDownload] = useState(false);
  const {
    onClose,
    container,
    updateMapDownload,
    templateId
  } = props;
  const [ currentDownloadType, setCurrentDownloadType ] = useState('pdf');

  const renderExportModal = () => {
    const options = [
      {name: 'CSV', value: 'csv'},
      {name: 'PDF', value: 'pdf'}
    ]
    return (<Modal size="md" show={true} className="export-modal" centered>
        <div className="modal-wrapper">
          <Modal.Header>
            <Modal.Title> Export </Modal.Title>
            <button className='close' aria-label="close" onClick={() => {onClose(false)}}>
              <i className="icons-close"></i>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className='data-filter'>
              <ForgeDropdowns
                key="data-format"
                value={currentDownloadType}
                label="Data format"
                options={options}
                handleOnSelect={handleDownloadChange}
              />
              { currentDownloadType == 'pdf' &&
                <>
                  <div className="options-checkbox">
                    <ForgeCheckbox dense={true}>
                      <input type="checkbox"
                        checked={isMapDownload}
                        id="checkbox-field"
                        onClick={() => handleMapInclude()}
                      />
                      <label className="m-0" for="checkbox-field">Include map</label>
                    </ForgeCheckbox>
                  </div>
                </>
              }
          </div>
          </Modal.Body>
          <Modal.Footer>
            {renderExportFooter()}
          </Modal.Footer>
        </div>
      </Modal>)
  }

  const handleMapInclude = () => {
    setIsMapDownload(!isMapDownload);
  }

  const renderExportFooter = () => {
    return (
      <div className="ml-auto">
        <ForgeButton type="outlined" className="mr-2">
          <button type="button" onClick={() => {onClose(false)}}>
            <span>Cancel</span>
          </button>
        </ForgeButton>

        <ForgeButton type="raised" className="">
          <button type="button" onClick={() => handleDownload()}>
            <span>Download</span>
          </button>
        </ForgeButton>
      </div>
    )
  }

  const handleDownloadChange = (option) => {
    const type = _.get(option, 'value');
    setCurrentDownloadType(type);
  }

  const handleDownload = () => {
    if(currentDownloadType == 'csv') {
      GlobalEvents.emit("EXPORT_CSV_REPORT_PAGE");
      onClose(false);
    } else {
      const downloadOption = {
        isMapViewDownload: isMapDownload,
        templateId
      }

      if(isMapDownload){
        updateMapDownload(container, downloadOption);
      } else {
        pdfDownload(container, downloadOption);
      }
      onClose(false);
    }
  };

  return (
    <div>
      {renderExportModal()}
    </div>
  );
};


ExportModal.propTypes = {
  onClose: PropTypes.func,
  searchField: PropTypes.string,
  container: PropTypes.func,
  updateMapDownload: PropTypes.func,
  isCustomSearch: PropTypes.bool,
  detailsEntries: PropTypes.array,
  templateId: PropTypes.string
};


export default ExportModal;
