import abortableFetch from 'common/abortableFetch';

const shouldLogSoqlErrors = () => {
  return _.includes(document.cookie, "insights-debug-surface=true");
};

function handleErrors(response) {
  if (!response.ok) {
    return response.clone().text().then((responseText) => {
      throw Error(`status ${response.statusText} ${shouldLogSoqlErrors() ? responseText : ''}`);
    });
  }
  return response;
}

export const responseHandler = (response) =>{
  if (shouldLogSoqlErrors()){
    console.log(_.get(response, 'soql_queries', ''));
  }
  return _.get(response, 'api_data', response);
}

export const fetchApiData = (apiUrl, abortableFetchController = new AbortController()) => {
  return abortableFetch(apiUrl, { signal: abortableFetchController.signal }).
  then(handleErrors).
  then(response => response.json()).
  then(responseHandler).
  catch((error) => {
    if (error.name !== 'AbortError') {
      console.error("Error on fetching data", error);
    }
    throw error;
  });
}

export const fetchApiDataWithStatus = (apiUrl, abortableFetchController = new AbortController()) => {
  return abortableFetch(apiUrl, { signal: abortableFetchController.signal }).
  then(ResponseHandlerWithStatus).
  catch((error) => {
    if (error.name !== 'AbortError') {
      console.error("Error on fetching data", error);
    }
    throw error;
  });
}

const ResponseHandlerWithStatus = (response) =>{
  const status = response['status'];
  const isValid = response.ok;
  return response.text().
    then((response) => {
      let res = response;
      if(isValid){
        res = JSON.parse(response);
        if (shouldLogSoqlErrors()){
          console.log(_.get(response, 'soql_queries', ''));
        }
      }

      return {
        api_data: _.get(res, 'api_data', res),
        status,
        isValid
      }
  });
}
