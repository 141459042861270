import _ from 'lodash';
import { getDefaultDateRange } from 'helpers/dateHelper';
import {
  relativeDateFilterEntryDefaultState,
  getRelativeDateFilterEntry } from 'helpers/commonFiltersHelper';
import { configuredDefaultDateType } from 'common/config/customerConfiguration';

import {
  UPDATE_COMPARISON_MODE_ON,
  UPDATE_COMPARISON_TYPE,
  UPDATE_DATA_RANGE_FILTERS,
  UPDATE_DATE_TYPE,
  UPDATE_GLOBAL_FILTER,
  UPDATE_RELATIVE_DATE_FILTER,
  UPDATE_ALL_METRICS_FILTER,
  UPDATE_COMMON_DATE_FILTERS,
  UPDATE_ALL_METRICS_DATE_FILTER,
  UPDATE_SLA_DATA_RANGE_FILTERS,
  UPDATE_SLA_GLOBAL_FILTER,
  UPDATE_COLLECTION_DATE_FILTERS,
  UPDATE_COMPARISON_DATE_RANGES
} from 'actions/commonFiltersActions';

export const commonFiltersReducersDefaultState = (state) => {
  let dateRange = getDefaultDateRange(relativeDateFilterEntryDefaultState);
  if(!_.isEmpty(_.get(state, 'dateRange'))) {
    dateRange = _.get(state, 'dateRange');
  }
  let dateType =_.get(state, 'dateType') || configuredDefaultDateType
  const dateFilters = {
    dateRange,
    dateType,
    relativeDateFilterEntry: _.get(state, 'relativeDateFilterEntry', relativeDateFilterEntryDefaultState),
    independentDateFilters: _.get(state, 'independentDateFilters', {}),
    additionalDateFilters: _.get(state, 'additionalDateFilters', {})
  }
  let slaDateFilters = _.get(state, 'slaDateFilters', {})

  if(_.isEmpty(_.get(state, 'slaDateFilters.dateRange'))) {
    slaDateFilters['dateRange'] = dateRange;
    slaDateFilters['dateType'] = dateType;
  }


  return {
    lastPeriodDateRange: {},
    allMetricFilters: _.get(state, 'allMetricFilters', []),
    globalFilters: _.get(state, 'globalFilters', []),
    slaDateFilters,
    slaGlobalFilters: _.get(state, 'slaGlobalFilters', []),
    allMetricDateFilters: dateFilters,
    ...dateFilters
  };
};

export default function(state = commonFiltersReducersDefaultState(), action) {
  switch (action.type) {
    case UPDATE_DATA_RANGE_FILTERS:
      return {
        ...state,
        dateRange: action.dateRange
      }

    case UPDATE_GLOBAL_FILTER: {
      return {
        ...state,
        globalFilters: _.get(action, 'globalFilters', [])
      }
    }

    case UPDATE_ALL_METRICS_FILTER: {
      return {
        ...state,
        allMetricFilters: _.get(action, 'allMetricFilters', [])
      }
    }

    case UPDATE_ALL_METRICS_DATE_FILTER: {
      return {
        ...state,
        allMetricDateFilters: _.get(action, 'dateFilters', [])
      }
    }

    case UPDATE_SLA_GLOBAL_FILTER: {
      return {
        ...state,
        slaGlobalFilters: _.get(action, 'globalFilters', [])
      }
    }

    case UPDATE_SLA_DATA_RANGE_FILTERS: {
      return {
        ...state,
        slaDateFilters: _.get(action, 'dateFilters', [])
      }
    }

    case UPDATE_DATE_TYPE: {
      return {
        ...state,
        dateType: _.get(action, 'dateType')
      }
    }

    case UPDATE_COMPARISON_MODE_ON: {
      return {
        ...state,
        comparisonModeOn: _.get(action, 'comparisonModeOn')
      }
    }

    case UPDATE_COMPARISON_TYPE: {
      return {
        ...state,
        comparisonType: _.get(action, 'comparisonType')
      }
    }

    case UPDATE_COMPARISON_DATE_RANGES: {
      return {
        ...state,
        comparisonDateRanges: _.get(action, 'dateRanges', [])
      }
    }

    case UPDATE_RELATIVE_DATE_FILTER: {
      return {
        ...state,
        relativeDateFilterEntry: getRelativeDateFilterEntry(_.get(action, 'relativeDateFilterEntry'))
      }
    }
    case UPDATE_COMMON_DATE_FILTERS: {
      return {
        ...state,
        dateRange: _.get(action, 'dateFilters.dateRange'),
        dateType: _.get(action, 'dateFilters.dateType'),
        relativeDateFilterEntry: getRelativeDateFilterEntry(
          _.get(action, 'dateFilters.relativeDateFilterEntry')),
        additionalDateFilters: _.get(action, 'dateFilters.additionalDateFilters')
      }
    }
    case UPDATE_COLLECTION_DATE_FILTERS: {
      return {
        ...state,
        collectionDateFilters: _.get(action, 'collectionDateFilters')
      }
    }
    default:
      return state;
  }
}
