import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NoDataWrapper }  from 'common/components/NoDataFound/NoDataWrapper';

class NoDataPage extends Component {
  render(){
    const svgImage = '../images/accounts-spreadsheet-spot-hero.svg';
    const noDataText =
      `Accuracy analysis is only available with 36 continuous months of historical data.
      Please adjust your date range in the lefthand pane to see more.`;
    return (
      <div className="accuracy-no-data">
        <img src={svgImage} />
        <span className="text-color">{ noDataText }</span>
      </div>
    );
  }
}

NoDataPage.propTypes = {
  slaOption: PropTypes.object,
  hidden: PropTypes.bool
};

// Hoc Component (NoDataWrapper) for 'No Data'
export default NoDataWrapper(NoDataPage);
