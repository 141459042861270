import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import classNames from 'classnames';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { 
  GEO_LOCATION_COLUMN_TYPES, 
  STRING_TYPES_FIELD, 
  NUMBER_TYPES_FIELD 
} from 'appConstants';

import TableFilterBadge from './TableFilterBadge';
import TableFilter from './TableFilter';
import TableRow from './TableRow';
import TableFilterIcon from './TableFilterIcon';

import {
  getNewSortableColumns,
  getColumnNames
} from './TableHelper';
import { getNewTableSearchEntries } from "./SearchHelper";
import {
  setDrilldownDimensions,
  updateDrilldownDimensionField,
  updateQuickFilters
} from 'actions/drilldownActions';

import { 
  updateCardLoadingStatusForImageGenerator,
  allowLeafPageOnTableClickByTemplate
} from 'helpers/visualizationHelper';
class Table extends Component {
  constructor(props) {
    super(props);
    this.columnFilterButtonRef = React.createRef();
    this.columnFilterChipRef = React.createRef();

    this.state = {
      tableSearchEntries: [],
      showFilter: false,
      showFilterPosition: false,
      filterColumnName: '',
      searchFieldEntry: {},
      uniqueFilterId: '',
      isEmptyRowData: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { quickFilters, templateEntry, currentDrilldownViewEntry } = this.props;
    const { tableSearchEntries, isEmptyRowData } = this.state;
    const templateId = _.get(templateEntry, 'template_id');
    const newTableSearchEntries = getNewTableSearchEntries(templateId, quickFilters, tableSearchEntries);
    const isTableSearchEntriesChanged = !_.isEqual(tableSearchEntries, newTableSearchEntries);
    const isQuickFilterChanged = !_.isEqual(quickFilters, prevProps.quickFilters);
    const isCurrentDrilldownViewEntrychanged = !_.isEqual(
        currentDrilldownViewEntry, prevProps.currentDrilldownViewEntry
      );

    if (isTableSearchEntriesChanged && isQuickFilterChanged) {
      this.setState({ tableSearchEntries: newTableSearchEntries });
    }
    if (isCurrentDrilldownViewEntrychanged) {
      this.setState({ isEmptyRowData: !isEmptyRowData });
    }
  }

  componentDidMount() {
    this.props.onDataLoad(false);
    this.updateLoadingStatusForScreenshot(false);
  }

  updateLoadingStatusForScreenshot = (status) => {
    const { cardImageId } = this.props;
    setTimeout(() => {
      updateCardLoadingStatusForImageGenerator(cardImageId, status);
    }, 50);
  }

  renderTooltip = (index, columnName, isSortByAscending = false, isSortable = false) => {
    const startCaseColumnName = this.getFormattedColumnName(columnName)
    const tooltip = (<Tooltip id={`tooltip-${index}`}>{startCaseColumnName}</Tooltip>);

    return (
      <OverlayTrigger key={index} placement="bottom" overlay={tooltip}>
        <div onClick={() => this.onClickTableHeader(columnName, isSortByAscending, isSortable)}>
          <span className="column-name mr-1">{startCaseColumnName}</span>
        </div>
      </OverlayTrigger>
    );
  }

  handleUpdateSearchEntries = (tableSearchEntries) => {
    this.setState({ tableSearchEntries });
  }

  handleUpdateTableFilters = (tableQuickFilters) => {
    this.setState({ tableQuickFilters });
  }

  onClickTableHeader = (columnName, isSortByAscending, isSortable) => {
    if (isSortable) {
      this.onClickTableSortedHeader(columnName, isSortByAscending);
    } else {
      this.onClickUnsortableHeader(columnName);
    }
  }

  onClickUnsortableHeader = (columnName) => {
    if (_.includes(['note'], columnName)) {
      return;
    }
    const { sortColumns, onClick } = this.props;
    const newSortColumns = _.cloneDeep(sortColumns);
    newSortColumns.push({ columnName, ascending: true });

    onClick(newSortColumns);
  }

  onClickTableSortedHeader = (columnName, isSortByAscending) => {
    if (_.includes(['note'], columnName)) {
      return;
    }
    const { sortColumns, onClick } = this.props;
    const newSortColumns = getNewSortableColumns(columnName, sortColumns, isSortByAscending);

    onClick(newSortColumns);
  }

  onCloseToggle = () => {
    this.setState({ showFilter: false })
  }

  onClickTableRow = (entry) => {
    const { onRowClick } = this.props;
    if (_.isFunction(onRowClick)) {
      onRowClick(entry);
    }
  }

  handleKeyDown = (e, entry) => {
    if (isEnterButtonPressed(e)) {
      this.onClickTableRow(entry)
    }
  }

  handleFilterKeyDown = (e) => {
    if (isEnterButtonPressed(e)) {
      this.setState({ showFilter: !this.state.showFilter });
    }
  }

  updateSearchObjectAndQuickFilters = (searchEntries, newQuickFilters) => {
    const {
      drilledDownDimensions,
      currentDrilldownDimensionField,
      dispatchUpdateDimensionsAndQuickFilters } = this.props;

    this.handleUpdateSearchEntries(searchEntries);
    dispatchUpdateDimensionsAndQuickFilters(
      drilledDownDimensions,
      currentDrilldownDimensionField,
      newQuickFilters)
  }

  renderSearchFilter = (index, columnName) => {
    const { tableSearchEntries } = this.state;
    const { customColumnEntries } = this.props;

    const searchField = _.find(customColumnEntries, { field: columnName });
    const isValidFilter = _.includes([STRING_TYPES_FIELD, NUMBER_TYPES_FIELD],
      _.get(searchField, 'renderType', null));

    return isValidFilter && (
      <TableFilterIcon
          key={`filter-Icon-${index}-${columnName}`}
          columnName = {columnName}
          index = {index}
          tableSearchEntries={tableSearchEntries}
          quickFilters={this.props.quickFilters}
          customColumnEntries={this.props.customColumnEntries}
          templateId={this.props.templateId}
          onUpdateSearchObjectAndQuickFilters={this.updateSearchObjectAndQuickFilters} />
    )
  }

  renderFilterBody = (index, columnName) => {
    const { filterColumnName, showFilter, tableSearchEntries, searchFieldEntry } = this.state;

    if (!showFilter || _.isEmpty(searchFieldEntry) || !_.isEqual(filterColumnName, columnName)) {
      return null;
    }

    return (
      <div className="filter-chip-show">
        <TableFilter
          key={`${index}-${columnName}`}
          tableSearchEntries={tableSearchEntries}
          quickFilters={this.props.quickFilters}
          searchFieldEntry={searchFieldEntry}
          templateId={this.props.templateId}
          onUpdateSearchObjectAndQuickFilters={this.updateSearchObjectAndQuickFilters}
          onClose={this.onCloseToggle} />
      </div>
    )
  }

  renderColumnName = (index, columnName) => {
    const columnClassName = classNames({
      'exclude-poiter': _.includes(['note'], columnName)
    });

    return (
      <th className={columnClassName} key={index} >
        <span className="d-flex align-items-center" >
          {this.renderTooltip(index, columnName)}
          {this.props.showSearchOption &&
            this.renderSearchFilter(index, columnName)}
        </span>
      </th>
    );
  }

  renderColumnNameWithIcon = (sortedColumn, index, columnName) => {
    const isSortByAscending = _.get(sortedColumn, 'ascending', false);
    const sortBy = _.get(sortedColumn, 'ascending');
    const isNote = _.includes(['note'], columnName);
    let iconContent = '';

    if (!_.isNil(sortBy) && !isNote) {
      const iconName = sortBy ? "icons-arrow-up2" : "icons-arrow-down2";
      iconContent = (
        <i className={iconName}
          onClick={() => this.onClickTableSortedHeader(columnName, isSortByAscending)}></i>
      );
    }

    const columnClassName = classNames({
      'exclude-poiter': isNote
    });

    return (
      <th className={columnClassName}
        key={index} >
        <div className="d-flex align-items-center" >
          <span className="d-flex align-items-center" >
            {this.renderTooltip(index, columnName, isSortByAscending, true)}
            {this.props.showSearchOption &&
              this.renderSearchFilter(index, columnName)}
          </span>
          <span className="column-icon align-self-center">{iconContent}</span>
        </div>
      </th>
    );
  }

  renderFilterBadge() {
    const { quickFilters } = this.props;
    const { tableSearchEntries } = this.state;
    const headerColumns = getColumnNames(this.props);

    return (
      <TableFilterBadge
        headerColumns={headerColumns}
        quickFilters={quickFilters}
        tableSearchEntries={tableSearchEntries}
        onUpdateSearchObjectAndQuickFilters={this.updateSearchObjectAndQuickFilters} />
    )
  }

  renderTableRow(entry, columnEntries) {
    const { customColumnEntries, apiParams, templateEntry } = this.props;
    const { tableSearchEntries, isEmptyRowData } = this.state;
    return (
      <TableRow
        templateEntry={templateEntry}
        rowEntry={entry}
        columnEntries={columnEntries}
        apiParams={apiParams}
        customColumnEntries={customColumnEntries}
        tableSearchEntries={tableSearchEntries}
        isEmptyRowData={isEmptyRowData}>
      </TableRow>
    )
  }

  getFormattedColumnName = (columnName) => {
    const { customColumnEntries } = this.props;
    const columnNameFromCustomEntries = _.get(_.find(customColumnEntries, ['field', columnName]), 'name', '');
    return _.isEmpty(columnNameFromCustomEntries) ? _.startCase(columnName) : columnNameFromCustomEntries;
  }

  renderTableHeader() {
    const { tableData, sortColumns, tableDefaultSort, customColumnEntries } = this.props;
    const { tableSearchEntries } = this.state;
    if (_.isEmpty(tableData) && _.isEmpty(tableSearchEntries)) {
      return null;
    }
    const columnNames = _.map(getColumnNames(this.props), 'field');

    return columnNames.map((columnName, index) => {
      const sortedColumn = !_.isEmpty(sortColumns) ?
        _.find(sortColumns, { columnName }) : _.find([tableDefaultSort], { columnName });
      const columnType = _.get(_.find(customColumnEntries, ['field', columnName]), 'column');
      if (_.includes(GEO_LOCATION_COLUMN_TYPES, columnType)) {
        return null;
      }

      if (_.isNil(sortedColumn)) {
        return this.renderColumnName(index, columnName);
      } else {
        return this.renderColumnNameWithIcon(sortedColumn, index, columnName);
      }
    });
  }

  renderNoSearchResultFoundInfoText() {
    const { tableData, quickFilters, isLoadingData } = this.props;
    if (!_.isEmpty(tableData) || _.isEmpty(quickFilters) || isLoadingData) {
      return null;
    }

    return (
      <div className="no-result-info text-center tx-14 mt-10">
        <div>There are no results for your search.</div>
        <div className="my-5">
          Try changing the terms or checking the spelling of the text you<br />
          searched for.</div>
        <div>Results are restricted by existing filters.</div>
      </div>
    );
  }

  renderTableRows() {
    const { tableData, onRowClick, currentRecordIndex, templateEntry } = this.props;
    if (_.isEmpty(tableData)) {
      return null;
    }
    const tableRowClasses = classNames('table-row', {
      'pointer': _.isFunction(onRowClick),
      'highlight': allowLeafPageOnTableClickByTemplate(templateEntry) && _.isFunction(onRowClick)
    });

    const columnEntries = getColumnNames(this.props);
    return tableData.map((entry, index) => {
      const clickedRowClass = currentRecordIndex === index ? 'active' : '';

      return (
        <tr
          className={`${tableRowClasses} ${clickedRowClass}`}
          key={index}
          onClick={() => this.onClickTableRow(entry)}
          tabIndex={0}
          onKeyDown={(e) => this.handleKeyDown(e, entry)}>
          {this.renderTableRow(entry, columnEntries)}
        </tr>
      );
    });
  }

  renderTableAccordionHeader() {
    const { templateEntry } = this.props;
    if (templateEntry['dedupe_last_startegy_option'] == 'all') {
      return <th className="table-accordion">{null}</th>
    } else {
      return null
    }
  }

  render() {
    const { tableSearchEntries } = this.state;
    return (
      <div className="details-table">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                {this.renderTableAccordionHeader()}
                {this.renderTableHeader()}
              </tr>
              {!_.isEmpty(tableSearchEntries) &&
                <tr>
                  {this.renderTableAccordionHeader()}
                  {this.renderFilterBadge()}
                </tr>
              }
            </thead>
            <tbody>
              {this.renderTableRows()}
            </tbody>
          </table>
        </div>
        {this.renderNoSearchResultFoundInfoText()}
      </div>
    );
  }
}

Table.propTypes = {
  onClick: PropTypes.func,
  tableData: PropTypes.array,
  templateEntry: PropTypes.object,
  apiParams: PropTypes.object,
  sortColumns: PropTypes.array,
  onRowClick: PropTypes.func,
  onDataLoad: PropTypes.func,
  isLoadingData: PropTypes.bool,
  customColumnEntries: PropTypes.array,
  tableDefaultSort: PropTypes.object,
  showSearchOption: PropTypes.bool,
  quickFilters: PropTypes.array,
  totalRecordsCount: PropTypes.number,
  drilledDownDimensions: PropTypes.array,
  currentDrilldownDimensionField: PropTypes.string,
  templateId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  dispatchUpdateDimensionsAndQuickFilters: PropTypes.func,
  currentRecordIndex: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  cardImageId: PropTypes.string,
  currentDrilldownViewEntry: PropTypes.object
}

Table.defaultProps = {
  onDataLoad: _.noop,
  onClick: _.noop
}

function mapStateToProps(state) {
  return {
    templateId: _.get(state, 'drilldown.currentDrilldownTemplateId', ''),
    currentDrilldownDimensionField: _.get(state, 'drilldown.currentDrilldownDimensionField', ''),
    drilledDownDimensions: _.cloneDeep(_.get(state, 'drilldown.drilledDownDimensions', [])),
    currentDrilldownViewEntry: _.get(state, 'drilldown.currentDrilldownViewEntry', {})
  }
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateDimensionsAndQuickFilters: (dimensions, dimensionField, quickFilters) => {
      dispatch(setDrilldownDimensions(dimensions));
      dispatch(updateDrilldownDimensionField(dimensionField));
      dispatch(updateQuickFilters(quickFilters));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Table);
