import { getCurrentViewEntry, getCurrentTemplateEntryById } from 'common/config/templateConfiguration';
import {
  getGlobalFiltersFromCardEntries
} from 'pages/dashboard/components/Collections/collectionHelper';
import {
  enableCollectionLevelDateFilters,
} from 'common/config/customerConfiguration';
import {
  getAvailableVisualizationType
} from 'helpers/visualizationHelper';
import {
  getDefaultVisualizationView
} from 'common/config/visualizationConfiguration';
import { getDrilldownEntryParams } from 'pages/dashboard/components/TemplateCards/TemplateCard';
import { VISUALIZATION_TYPES, CARD_TYPES } from 'appConstants';
import { getNumberOfCardsPerRow } from 'helpers/DomPageHelper';
import { getDefaultRadarEntry } from '../RadarCards/helper';
import { isDateColumnConfigured } from 'common/config/templateConfiguration';
import { getCardId } from 'pages/embed/helper';

export const formatCardEntry = ({
  cardEntries, cardEntry, globalFilters, collectionEntry, commonFilters: templateCommonFilters
}) => {
  const isBookmarkCard = _.get(cardEntry, 'isBookmarkCard', false);
  const isMyViewsCollection = isMyViews(collectionEntry);
  const commonFilters = _.get(cardEntry, 'commonFilters', {});
  const cardGlobalFilters = _.get(commonFilters, 'globalFilters', []);
  const isNoneDateSelected = _.get(cardEntry, 'bookmarkOptions.isNoneDateSelected', false);
  const isNoneAdditionalDateFilters = _.get(cardEntry, 'bookmarkOptions.isNoneAdditionalDateFilters', false);
  const additionalDateFilters = _.get(commonFilters, 'additionalDateFilters', {});
  const collectionDateFilters = _.get(collectionEntry, 'dateFilters', {});
  const collectionAdditionalDateFilters = _.get(
    collectionDateFilters, 'additionalDateFilters', additionalDateFilters
  );
  let formattedCardEntry;

  if(!enableCollectionLevelDateFilters() || (!isBookmarkCard && !isNoneDateSelected)) {
    if(isBookmarkCard || isMyViewsCollection) {
      formattedCardEntry = getNewCardEntry({
        cardEntries, globalFilters, commonFilters, cardGlobalFilters, cardEntry
      });
      if(isNoneAdditionalDateFilters) {
        formattedCardEntry['commonFilters']['additionalDateFilters'] = collectionAdditionalDateFilters;
      }
      return formattedCardEntry;
    } else {
      return cardEntry;
    }
  } else if (isNoneDateSelected && isMyViewsCollection) {
    formattedCardEntry = getNewCardEntry({
      cardEntries,
      globalFilters,
      commonFilters: _.isEmpty(collectionDateFilters) ? commonFilters : collectionDateFilters,
      cardGlobalFilters,
      cardEntry
    });
    if(!isNoneAdditionalDateFilters) {
      formattedCardEntry['commonFilters']['additionalDateFilters'] = additionalDateFilters;
    }
  } else if (isNoneDateSelected && !isBookmarkCard) {
    const allMetricDateFilters = _.get(templateCommonFilters, 'allMetricDateFilters', {});
    const { templateEntry, viewEntry } = getTemplateAndViewEntryFromBookmark(cardEntry);

    formattedCardEntry = {
      ...cardEntry,
      templateEntry,
      viewEntry,
      commonFilters: {
        ...allMetricDateFilters,
        globalFilters: cardGlobalFilters
      }
    };
    if(!isNoneAdditionalDateFilters) {
      formattedCardEntry['commonFilters']['additionalDateFilters'] = additionalDateFilters;
    }

  } else if (isNoneDateSelected && isBookmarkCard) {
    formattedCardEntry = getNewCardEntry({
      cardEntries,
      globalFilters,
      commonFilters: collectionDateFilters,
      cardGlobalFilters,
      cardEntry
    });
    if(!isNoneAdditionalDateFilters) {
      formattedCardEntry['commonFilters']['additionalDateFilters'] = additionalDateFilters;
    }
  } else {
    formattedCardEntry = getNewCardEntry({
      cardEntries, globalFilters, commonFilters, cardGlobalFilters, cardEntry
    });
    if(isNoneAdditionalDateFilters) {
      formattedCardEntry['commonFilters']['additionalDateFilters'] = collectionAdditionalDateFilters;
    }
  }

  return formattedCardEntry;
}

export const getTemplateAndViewEntryFromBookmark = (cardEntry) => {
  let { currentDrilldownViewEntry, currentDrilldownTemplateId } = _.get(
    cardEntry,
    'drilldown',
    {}
  );
  let viewId = _.get(currentDrilldownViewEntry, 'view_id');
  const isForecast= JSON.parse(_.get(cardEntry, 'is_forecast', false));
  if(isForecast) {
    viewId = _.get(cardEntry, 'forecastOptions.selectedForecastMetric.view_id')
    currentDrilldownTemplateId = _.get(cardEntry, 'forecastOptions.selectedForecastTemplateId')
  }
  const viewEntry = getCurrentViewEntry(currentDrilldownTemplateId, viewId);
  const templateEntry = getCurrentTemplateEntryById(currentDrilldownTemplateId);
  const isRadar= JSON.parse(_.get(cardEntry, 'is_radar', false));
  if(isRadar) {
    return getDefaultRadarEntry();
  }

  return { viewEntry, templateEntry };
}

const getNewCardEntry = ({ cardEntries, globalFilters, commonFilters, cardGlobalFilters, cardEntry }) => {
  const matchedCollectionFilters = getGlobalFiltersFromCardEntries(cardEntries, globalFilters);
  const newGlobalFilters = _.uniqBy([...cardGlobalFilters, ...matchedCollectionFilters], 'field');


  return {
    ...cardEntry,
    commonFilters: {
      ...commonFilters,
      globalFilters: newGlobalFilters
    }
  };
}

export const getDateFilters = (cardEntry, collectionEntry, commonFilters, templateCommonFilters) => {
  const isNoneDateSelected = _.get(cardEntry, 'bookmarkOptions.isNoneDateSelected', false);
  const isBookmarkCard = _.get(cardEntry, 'isBookmarkCard', false);
  const templateEntry = _.get(cardEntry, 'templateEntry', {});

  if(enableCollectionLevelDateFilters() && isDateColumnConfigured(templateEntry)) {
    if(isNoneDateSelected && isMyViews(collectionEntry)) {
      return _.get(collectionEntry, 'dateFilters', {});
    } else if (isNoneDateSelected && !isBookmarkCard) {
      return templateCommonFilters
    } else {
      return _.get(collectionEntry, 'dateFilters', {});
    }
  } else {
    return commonFilters;
  }
}

export const getVisibleBookmarks = (bookmarks) => {
  return _.filter(bookmarks, (bookmark) => {
    const { viewEntry } = getTemplateAndViewEntryFromBookmark(bookmark);
    return !_.isEmpty(viewEntry) ||
      bookmark['is_radar'] || bookmark['is_forecast']  || bookmark['is_custom_advance_search'];
  });
}

export const isMyViews = (collection) => {
  if(_.isObject(collection)) {
    return _.get(collection, 'is_my_view_collection', false);
  } else {
    return (collection === 'bookmarks');
  }
}

export const getCardSizeAndLimitBasedOnCardType = (cardEntries, cardType, cardsPerRow) => {
  let row = 1;
  let rowCount = 0;

  const calculateCardSizeAndLimits = _.map(cardEntries, (cardEntry, index) => {
    const availableVizType = getVisualizationTypeFromCardEntry(cardEntry, cardType);
    const count = (availableVizType === VISUALIZATION_TYPES.TABLE.type) ? cardsPerRow : 1;

    rowCount = rowCount + count;

    if (rowCount > cardsPerRow) {
      rowCount = count;
      row = row + 1;
    }

    return { cardsLimit: index + 1, row };
  });

  return calculateCardSizeAndLimits;
}

export const getCardCountConfig = (cardsSizeAndLimit, numberOfRow) => {
  if (!_.isEmpty(cardsSizeAndLimit)) {
    const filteredLimit = _.filter(cardsSizeAndLimit, (cardSizeAndLimitItem) => {
      const { row } = cardSizeAndLimitItem;
      return (row < (numberOfRow + 1));
    });

    return _.last(filteredLimit);
  } else {
    return numberOfRow * getNumberOfCardsPerRow();
  }
}

const getVisualizationTypeFromCardEntry = (cardEntry, cardType) => {
  const newCardType = _.get(cardEntry, 'isBookmarkCard') ? CARD_TYPES.BOOKMARK : cardType;
  let templateId, visualizationType, viewEntry;

  if (newCardType == CARD_TYPES.BOOKMARK) {
    visualizationType = _.get(cardEntry, 'drilldown.currentVisualizationType');
    templateId = _.get(cardEntry, 'drilldown.currentDrilldownTemplateId', {});
    viewEntry = _.get(cardEntry, 'drilldown.currentDrilldownViewEntry', {});
  } else {
    const { templateEntry, viewEntry: newViewEntry } = cardEntry;
    viewEntry = _.get(getDrilldownEntryParams(cardEntry, newViewEntry), 'currentDrilldownViewEntry');
    templateId = _.get(templateEntry, 'template_id');
    visualizationType = getDefaultVisualizationView(viewEntry);
  }

  return getAvailableVisualizationType(templateId, visualizationType, viewEntry);
}

export const getAllMetricCardEntry=({templateEntry,viewEntry})=>{
  return {
    card_id: getCardId(templateEntry,viewEntry),
    name: viewEntry.name,
    collection_id: 'allMetrics'
  }
}

export const getCardEntry=(templateEntry,viewEntry,collectionId)=>{
  return {
    card_id: getCardId(templateEntry,viewEntry),
    name: viewEntry?.name,
    collection_id: collectionId
  }
}

export const getCardEntryByTagId=(templateEntry,viewEntry,collectionTagId)=>{
  return {
    card_id: getCardId(templateEntry,viewEntry),
    name: viewEntry?.name,
    collection_tag_id: collectionTagId,
    template_id: templateEntry?.template_id
  }
}