/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import 'react-app-polyfill/stable';
import 'react-app-polyfill/ie11';
import { defineComponents } from '@tylertech/forge';
import 'common/Icons/ForgeIcons';

import ReactOnRails from 'react-on-rails';
import DemoVisualization from 'modules/visualization/DemoVisualization';
import LineChart from 'modules/visualization/LineChart';
import 'babel-polyfill';
import { toast } from 'react-toastify';
import { removeRadarSessionStorage } from 'pages/Radar/radarSessionStorageHelper';

import {
  renderTableColumnFlyout,
  triggerOnBarClick,
  triggerOnScatterTextPointClick,
  triggerOnScatterRowClickEvent,
  triggerOnSliderRangeEvent
} from 'modules/visualization/helper';
import { renderLabelFlyout } from 'modules/visualization/SnapshotVisualization/tickLabelToolTipHelper';
import { isEnterButtonPressed, isEscapeButtonPressed } from 'helpers/mouseEventsHelper';
import { getEmbedLink } from '../pages/embed/helper';

import Main from '../Main';
import Embed from '../Embed';
import UserAccountRequest from '../UserAccountRequest';
import $ from 'jquery';
import { PLOTLY_RANGE_UPDATE_DATE_TYPE } from 'appConstants';

// This will define all components in Forge,
defineComponents();
// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  Main,
  DemoVisualization,
  Embed,
  LineChart,
  UserAccountRequest
});

$(document).
  on('mouseenter', 'table td .multiline-ellipses', function() {
    renderTableColumnFlyout(this);
  }).
  on('mouseleave', 'table td .multiline-ellipses', function() {
    renderTableColumnFlyout(this, true);
  }).
  on('mouseenter', '.bar-chart svg .yaxislayer-above text', function(e) {
    renderLabelFlyout(this, e);
  }).
  on('mouseleave', '.bar-chart svg .yaxislayer-above text', function(e){
    renderLabelFlyout(this, e, true);
  }).
  on('mouseenter', '.bar-chart svg .ygrid', function(e) {
    renderLabelFlyout(this, e);
  }).
  on('mouseleave', '.bar-chart svg .ygrid', function(e) {
    renderLabelFlyout(this, e, true);
  }).
  // bar and grouped bar chart
  on('keydown', '.bar-chart svg .ytick', onKeyDownChart).
  // Toast container
  on('keydown', onKeyDownToast).
  // distribution chart
  on('keydown', '.h-100 svg .barlayer .points g', onKeyDownChart).
  // pie chart
  on('keydown', '.bar-chart svg .pielayer .trace .slice', onKeyDownChart).
  // scatter chart
  on('keydown', '.bar-chart svg .scatterlayer .points .point', onKeyDownChart).
  on('mousedown', '.bar-chart svg .ygrid', function(e) {
    onClickScatterChartYGrid(this, e, true);
  }).
  on('keydown', '.bar-chart svg .ygrid', function(e) {
    onClickScatterChartYGrid(this, e);
  }).
  on('mouseup', '.dragcover.cursor-ew-resize', function(e) {
    onTriggerSliderRange(e, true);
  }).
  on('mousemove', '.dragcover.cursor-ew-resize', function(event) {
    setTimeout(() => {
      onTriggerSliderRange(event);
    });
  }).
  on('mouseup', '.dragcover.cursor-col-resize', function(e) {
    onTriggerSliderRange(e, true);
  }).
  on('mousemove', '.dragcover.cursor-col-resize', function(event) {
    setTimeout(() => {
      onTriggerSliderRange(event);
    });
  }).
  on('mouseenter', '.rangeslider-slidebox', function() {
    onTriggerSliderRangeOnChange('both');
  }).
  on('mouseenter', '.rangeslider-grabber-min', function() {
    onTriggerSliderRangeOnChange('start_date');
  }).
  on('mouseenter', '.rangeslider-grabber-max', function() {
      onTriggerSliderRangeOnChange('end_date');
  }).
  // Scatter chart If more than 50 dimension click see more text
  on('mousedown', '.bar-chart svg .scatterlayer .text .textpoint text', onMouseDownChart);

function onClickScatterChartYGrid(container, event, isMouseClick = false){
  if(isEnterButtonPressed(event) || isMouseClick) {
    triggerOnScatterRowClickEvent(container, event);
  }
}

function onTriggerSliderRange(event, isApply= false){
  triggerOnSliderRangeEvent(event, isApply)
}

function onTriggerSliderRangeOnChange(type){
  sessionStorage.setItem(PLOTLY_RANGE_UPDATE_DATE_TYPE, type);
}

function onKeyDownChart(e) {
  if(isEnterButtonPressed(e)) {
    triggerOnBarClick(this, e);
  }
}

function onKeyDownToast(e){
  if (isEscapeButtonPressed(e)) {
    toast.dismiss();
  }
}

function onMouseDownChart(e) {
  triggerOnScatterTextPointClick(e);
}

// on radar page reload session storage removed to track event on mini map selection
window.onload = function() {
  removeRadarSessionStorage();
};

window.getEmbedLink = getEmbedLink;
