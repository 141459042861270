import _ from 'lodash';
import React from 'react';

import { getMargins } from 'modules/PlotlyTooltip/helper';
import { getFlyoutPosition, getDurationFlyoutText } from './helper';
import { getFormattedValue } from 'helpers/chartDataHelper';
import { formatValueToCurrency } from 'helpers/numberHelper';
import { isTimeDurationEnabled } from 'common/config/customerConfiguration';

export const getAreaChartPopupConfigs = ({
  chartContainer,
  data,
  viewEntry,
  viewMode,
  isCurrencyDimensionField
}) => {
  const point = data.points[0];
  const margins = getMargins(chartContainer);
  const xaxis = point.xaxis;
  const yaxis = point.yaxis;
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);

  const tbodyContent = _.map(data.points, (point, index) => {
    const color = _.get(point, 'data.line.color', '#fff');
    const yAxisValue = _.get(point, 'y', 0);
    const originalTextValues = _.get(point,'data.text_values',[]);
    const pointIndex = _.get(point,'pointIndex',0);
    const originalYValue = _.nth(originalTextValues, pointIndex) || yAxisValue;
    const yValue = isSecondsFormat ? getDurationFlyoutText(originalYValue, viewEntry) :
      getFormattedValue(yAxisValue, viewEntry);
    const name = _.get(point, 'data.meta.dimension', '');

    return (
      <tr key={index}>
        <td>
          <div className="legend-values">
            <div className="mr-3">{yValue}</div>
            <span className="legend-color" style={{ backgroundColor: color }}></span>
          </div>
        </td>
        <td className="text-left">{formatValueToCurrency(name, isCurrencyDimensionField)}</td>
      </tr>
    );
  });

  return [{
    isTimelineChart: true,
    position: getFlyoutPosition(data, viewMode),
    anchor: {
      x: xaxis.d2p(point.x) + margins.x - 6,
      y: yaxis.d2p(yaxis.range[0]) + margins.y
    },
    chartContainer,
    html: (
      <table className="flyout-table">
        <tbody>{_.without(tbodyContent, null)}</tbody>
      </table>
    )
  }];
};
