import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  SNAPSHOT_VISUALIZATION_TYPES,
  DRILLDOWN_VIEW_TYPE,
  COMPARE_VIEW_DRILLDOWN_OPTIONS,
  DEFAULT_SELECT_DROPDOWN_OPTION,
  PIE_CHART_ANNOTATION_OPTIONS
} from 'appConstants';
import _ from 'lodash';
import SolutionDropDown from 'common/components/SolutionDropDown/SolutionDropDown';
import VarianceModal from 'pages/drilldown/components/VarianceModal/VarianceModal';
import UndoButton from 'pages/drilldown/visualizations/UndoButton';
import {
  getSortDropdownOptions,
  getGroupBySortDropdownOptions,
  getSnapshotVisualizationTypes,
  getSecondaryMetricEntries
} from 'helpers/visualizationHelper';

import { getSnapshotChartType } from 'common/config/visualizationConfiguration';

import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { updateGroupBychartApproach, updateGroupBychartViewType } from 'actions/snapshotActions';
import BenchmarkOptions from 'pages/drilldown/components/BenchmarkOptions';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import ForgeMenuOptions from 'common/components/MenuDropDown/ForgeMenuOptions';
import MenuDropDownPopupSelector from 'common/components/MenuDropDown/MenuDropDownPopupSelector';
import SnapshotLegends from './visualizationRenders/SnapshotLegends';
import { isMobileOrTablet } from 'helpers/DomPageHelper';

class SnapshotOptions extends Component {
  state = {
    showVarianceModal: false
  }

  handleVarianceButtonClick = () => {
    this.setState({ showVarianceModal: true });
  }

  handleVarianceModalClose = () => {
    this.setState({
      showVarianceModal: false
    });
  }

  renderScatterPlotSecondaryMetricOptions() {
    const {
      currentDrilldownViewEntry,
      currentSecondaryMetricEntry,
      onScatterPlotSecondaryMetricChange,
      currentSnapshotView
    } = this.props;

    const secondaryMetrics = getSecondaryMetricEntries(
      currentDrilldownViewEntry, 'snapshot', currentSnapshotView);
    const titleText = _.get(currentSecondaryMetricEntry, 'name') ||
      _.get(DEFAULT_SELECT_DROPDOWN_OPTION, 'name');
    if (_.isEmpty(secondaryMetrics)) {
      return null;
    }
    const dropdownOptions = [DEFAULT_SELECT_DROPDOWN_OPTION].concat(secondaryMetrics);
    return (
      <div className="filter-wrapper">
        <SolutionDropDown
          size='sm'
          className='mr-4 custom-drop-down'
          title={titleText}
          options={dropdownOptions}
          onSelect={(option) => onScatterPlotSecondaryMetricChange(option)} />
      </div>
    );
  }

  renderSortByDropdown() {
    const {
      currentDrilldownDimensionField,
      currentDrilldownViewEntry,
      isLeafPage,
      currentBarChartSortOption,
      onBarChartSortOptionChange,
      isNumericDimension,
      currentDrilldownTemplateId
    } = this.props;

    if (isLeafPage || isNumericDimension) {
      return null;
    }

    const dropdownOptions = getSortDropdownOptions(
      currentDrilldownDimensionField, currentDrilldownViewEntry, currentDrilldownTemplateId);
    return (
      <div className="filter-wrapper">
        <div className="options-label" aria-label="Sort">Sort by</div>
        <SolutionDropDown
          size='sm'
          id="sort-by"
          title={currentBarChartSortOption.name}
          options={dropdownOptions}
          onSelect={(option) => onBarChartSortOptionChange(option)} />
      </div>
    );
  }

  renderGroupBySortDropDown() {
    const {
      currentDrilldownViewEntry,
      isLeafPage,
      currentGroupBySortOption,
      onGroupBySortOptionChange,
      currentDrilldownGroupByEntry,
      isNumericGroup,
      isNumericDimension,
      currentDrilldownTemplateId
    } = this.props;
    const isGroupByNoneSelected = currentDrilldownGroupByEntry.name === 'None';
    // The numeric dimension and group don't need sorting option.
    // Because its always should be in 'ASC' order only.
    if (isLeafPage || isGroupByNoneSelected || isNumericGroup || isNumericDimension) {
      return null;
    }

    const dropdownOptions = getGroupBySortDropdownOptions(
      currentDrilldownGroupByEntry, currentDrilldownViewEntry, currentDrilldownTemplateId);
    return (
      <div className="filter-wrapper">
        <label className="d-block mb-0 options-label">Sort group by</label>
        <SolutionDropDown
          size='sm'
          id="group-sort-by"
          title={currentGroupBySortOption.name}
          options={dropdownOptions}
          onSelect={(option) => onGroupBySortOptionChange(option)} />
      </div>
    );
  }

  renderDimensionSortByDropdown() {
    const {
      currentDrilldownDimensionField,
      isLeafPage,
      currentDrilldownViewEntry,
      currentDimensionSortOption,
      onDimensionSortOptionChange,
      currentGroupByChartApproach,
      isNumericGroup,
      isNumericDimension,
      currentDrilldownTemplateId
    } = this.props;

    if (
      isLeafPage ||
      currentGroupByChartApproach === _.get(DRILLDOWN_VIEW_TYPE, [1, 'type'], 'combine_view') ||
      isNumericGroup ||
      isNumericDimension
    ) {
      return null;
    }

    const dropdownOptions = getSortDropdownOptions(
      currentDrilldownDimensionField, currentDrilldownViewEntry, currentDrilldownTemplateId);
    return (
      <div className="filter-wrapper">
        <label className="d-block mb-0 options-label">Sort by</label>
        <SolutionDropDown
          size='sm'
          id="dimension-sort-by"
          title={currentDimensionSortOption.name}
          options={dropdownOptions}
          onSelect={(option) => onDimensionSortOptionChange(option)} />
      </div>
    );
  }

  renderBarchartSecondaryMetricsDropdown() {
    const {
      currentDrilldownViewEntry,
      currentSecondaryMetricEntry,
      onBarChartSecondaryMetricChange,
      isLeafPage,
      currentSnapshotView
    } = this.props;

    const secondaryMetrics = getSecondaryMetricEntries(
      currentDrilldownViewEntry, 'snapshot', currentSnapshotView);

    const titleText = _.get(currentSecondaryMetricEntry, 'name') ||
      _.get(DEFAULT_SELECT_DROPDOWN_OPTION, 'name');
    if (isLeafPage || _.isEmpty(secondaryMetrics)) {
      return null;
    }
    const dropdownOptions = [DEFAULT_SELECT_DROPDOWN_OPTION].concat(secondaryMetrics);

    return (
      <div className="filter-wrapper">
        <SolutionDropDown
          size='sm'
          className='mr-4 custom-placeholder-line-clamp'
          title={titleText}
          options={dropdownOptions}
          onSelect={(option) => onBarChartSecondaryMetricChange(option)} />
      </div>
    );
  }

  renderViewDropDown() {
    const { currentGroupByViewType, currentDrilldownGroupByEntry } = this.props;

    const isGroupByNone = _.get(currentDrilldownGroupByEntry, 'name', '') == 'None';
    if (!isGroupByNone) {
      return null;
    }

    return (
      <div className="filter-wrapper">
        <label className="d-block mb-0 options-label">View</label>
        <SolutionDropDown
          size='sm'
          id="view-sort"
          className='p-0 mr-4'
          title={currentGroupByViewType}
          options={COMPARE_VIEW_DRILLDOWN_OPTIONS}
          onSelect={this.handleGroupByChartViewTypeChange} />
      </div>
    );
  }

  handleGroupByChartViewTypeChange = (option) => {
    this.props.dispatchUpdateGroupByChartViewType(option.name);
  }

  renderCompareCombineOptions() {
    const { currentDrilldownGroupByEntry,
      currentDrilldownViewEntry,
      currentGroupByChartApproach } = this.props;
    let DrilldownShowList = DRILLDOWN_VIEW_TYPE;
    const isGroupByNoneSelected = currentDrilldownGroupByEntry.name === 'None';
    if (isGroupByNoneSelected) {
      DrilldownShowList = _.reject(DRILLDOWN_VIEW_TYPE, {
        type: _.get(DRILLDOWN_VIEW_TYPE, [1, 'type'], 'combine_view')
      });
    }
    const showCompareDropDown = _.get(currentDrilldownViewEntry,
      'visualization.snapshot.show_compare_combain', 'false');
    const currentDrilldownViewOption = _.find(DRILLDOWN_VIEW_TYPE, { type: currentGroupByChartApproach });

    if (showCompareDropDown === 'false' || isGroupByNoneSelected) {
      return null;
    }

    return (
      <div className="filter-wrapper">
        <label className="d-block mb-0 options-label">Show</label>
        <SolutionDropDown
          size='sm'
          id="combine"
          className='p-0 mr-4'
          title={currentDrilldownViewOption.name}
          options={DrilldownShowList}
          onSelect={this.handleGroupByChartApproachChange} />
      </div>
    );
  }

  handleGroupByChartApproachChange = (option) => {
    this.props.dispatchUpdateGroupBychartApproach(option.type);
  }

  handleKeyDown = (e, triggerEventFunction) => {
    if (isEnterButtonPressed(e)) {
      triggerEventFunction(e);
    }
  }

  renderChartValueFlag() {
    const { currentSnapshotView,
      currentSecondaryMetricEntry,
      onAddChartValueFlagChange,
      showChartValues
    } = this.props;
    if (currentSnapshotView == SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type ||
      _.get(currentSecondaryMetricEntry, 'render_type') === 'bullet') {
      return null
    }
    return (
      <div className="filter-wrapper">
        <div
          tabIndex={0}
          className="custom-control custom-checkbox"
          onKeyDown={(e) => this.handleKeyDown(e, onAddChartValueFlagChange)}>
          <input
            tabIndex={-1}
            checked={showChartValues}
            type="checkbox"
            className="custom-control-input"
            id="rangeCheck"
            onChange={onAddChartValueFlagChange} />
          <label className="custom-control-label show-value" htmlFor="rangeCheck">Show Values</label>
        </div>
      </div>
    )
  }

  renderPieChartOptions() {
    const { pieChartAnnotationOptions, togglePieChartAnnotationOptions, currentSnapshotView } = this.props;
    if (currentSnapshotView !== SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type) {
      return null
    }

    const annotaopnOptions = (_.map(PIE_CHART_ANNOTATION_OPTIONS, (entry) => {
      const { name, type } = entry;
      const isActive = _.get(pieChartAnnotationOptions, type);
      return (
        <div key={name} className="custom-control custom-checkbox">
          <input
            tabIndex={-1}
            key={type}
            checked={isActive}
            type="checkbox"
            className="custom-control-input"
            id={type}
            onChange={() => togglePieChartAnnotationOptions(type, isActive)} />
          <label key={`${type}_label`} className="custom-control-label" htmlFor={type}>{name}</label>
        </div>
      )
    }));

    return (
      <div className="annotation-options" key="annotation_options">
        {annotaopnOptions}
      </div>
    )
  }

  // Bar chart Options
  renderBarChartOption() {
    return (
      <div className="filter-options">
        <div>
          {this.renderSortByDropdown()}
          {this.renderChartValueFlag()}
        </div>
      </div>
    );
  }
  // Grouped Barchart Options
  renderGroupBarchartChartOption() {
    const { currentDrilldownGroupByEntry } = this.props;
    const isGroupByNoneSelected = currentDrilldownGroupByEntry.name === 'None';
    return (
      <div className="filter-options">
        {this.renderCompareCombineOptions()}
        {this.renderViewDropDown()}
        {this.renderGroupBySortDropDown()}
        {isGroupByNoneSelected ? this.renderDimensionSortByDropdown() : null}
        {this.renderChartValueFlag()}
      </div>
    );
  }

  renderGroupScatterChartOption() {
    const { currentDrilldownGroupByEntry } = this.props;
    const isGroupByNoneSelected = currentDrilldownGroupByEntry.name === 'None';
    return (
      <div className="filter-options">
        {this.renderViewDropDown()}
        {this.renderGroupBySortDropDown()}
        {isGroupByNoneSelected ? this.renderDimensionSortByDropdown() : null}
      </div>
    )
  }

  renderScatterPlotOptions() {
    return (
      <div className="filter-options">
        {this.renderSortByDropdown()}
      </div>
    );
  }

  renderVarianceModal() {
    const { showVarianceModal } = this.state;

    if (!showVarianceModal) {
      return null;
    }

    return (
      <VarianceModal
        onClose={this.handleVarianceModalClose} />
    );
  }

  renderSecondaryMetricOptions() {
    const { currentSnapshotView } = this.props;
    if (currentSnapshotView == SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type) {
      return null
    }

    let renderSecondary;
    if (currentSnapshotView == SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type) {
      renderSecondary = this.renderBarchartSecondaryMetricsDropdown();
    } else if (currentSnapshotView == SNAPSHOT_VISUALIZATION_TYPES.SCATTER_PLOT.type) {
      renderSecondary = this.renderScatterPlotSecondaryMetricOptions();
    }

    if (_.isEmpty(renderSecondary)) {
      return null
    }

    return (
      <div className="filter-options">
        <div className="filter-wrapper">
          <div className="options-label">Secondary metric</div>
          <div className="snap-shot-legend-item">
            {renderSecondary}
          </div>
        </div>
      </div>
    )
  }

  renderFilterOption() {
    const { currentDrilldownViewEntry, currentSnapshotView } = this.props;
    const chartType = getSnapshotChartType(currentDrilldownViewEntry);

    if (currentSnapshotView == SNAPSHOT_VISUALIZATION_TYPES.PIE_CHART.type) {
      return null
    }

    if (currentSnapshotView == SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type) {
      if (chartType == 'groupChart') {
        return this.renderGroupBarchartChartOption();
      } else {
        return this.renderBarChartOption();
      }
    } else if (currentSnapshotView == SNAPSHOT_VISUALIZATION_TYPES.SCATTER_PLOT.type) {
      if (chartType == 'groupChart') {
        return this.renderGroupScatterChartOption();
      } else {
        return this.renderScatterPlotOptions();
      }
    }
  }

  renderBenchmarkContainer() {
    const { onBenchmarkOptionChange,
      benchMarkNames,
      currentSnapshotView
    } = this.props;
    // const renderLegend = this.renderLegends()
    const renderSecondaryMetricDropdown = this.renderSecondaryMetricOptions();
    let categoryRender = null;
    if (!_.isEmpty(renderSecondaryMetricDropdown)) {
      categoryRender = (<div className="categories">
        {renderSecondaryMetricDropdown}
      </div>)
    }

    return (
      <div className="legend-wrapper snap-shot-legend-container">
        <BenchmarkOptions onBenchmarkOptionChange={onBenchmarkOptionChange}
          renderType={currentSnapshotView}
          benchMarkNames={benchMarkNames} />
        {categoryRender}
      </div>
    )
  }

  onSelectSnapshotType = (chart) => {
    const { onSnapshotViewChange } = this.props;
    onSnapshotViewChange(_.get(chart, 'detail.value'))
  }

  renderSnapshotChartViewOptions() {
    const {
      currentSnapshotView,
      currentDrilldownViewEntry
    } = this.props;

    const chartTypes = getSnapshotVisualizationTypes(currentDrilldownViewEntry);

    let menuLabelName = '';
    let menuLabelIcon = '';
    const customChartTypes = _.map(chartTypes, (chartType) => {
      if (chartType.type == currentSnapshotView) {
        menuLabelName = chartType.name;
        menuLabelIcon = chartType.icon;
      }

      return {
        value: chartType.type,
        label: chartType.name,
        leadingIconType: 'component',
        leadingIcon: chartType.type == currentSnapshotView ? 'check' : 'forge-empty-icon'
      }
    });

    return (
      <ForgeMenuOptions
        className="forge-list-custom"
        customIconName={menuLabelIcon}
        menuLabelName={menuLabelName}
        menuOptions={customChartTypes}
        onSelectOption={this.onSelectSnapshotType} />
    )
  }

  renderSnapshotOptionDropDown = () => {
    const {
      currentDrilldownViewEntry,
      isNumericDimension,
      isPieChart
    } = this.props;
    const isGroupChart = getSnapshotChartType(currentDrilldownViewEntry) === 'groupChart';
    const disableFilterButton = (!isGroupChart && isNumericDimension);

    return (
      <>
        <div className="vertical-filter-wrapper">
          {
            !disableFilterButton && !isPieChart &&
            <div className="filter-bar-wrapper">
              <div className="filter-bar-container">
                {this.renderFilterOption()}
              </div>
            </div>
          }
          {this.renderPieChartOptions()}
          {this.renderBenchmarkContainer()}
        </div>
        {this.renderVarianceModal()}
      </>
    )
  }

  renderOptions = () => {
    return (
      <MenuDropDownPopupSelector toggleButtonLabel="Options"
        trailingIconName="keyboard_arrow_down" className="custom-dropdown-inner">
        {this.renderSnapshotOptionDropDown()}
      </MenuDropDownPopupSelector>
    );
  }

  getLegendOptions = () => {
    const {
      toggleClickLegendButton,
      legends,
      dimensionName,
      isPieChart,
      isBulletBarChart,
      isBarChart,
      isStackBarChart,
      isRangeChart,
      isCurrencyDimensionField,
      benchMarkEntries,
      showLegend,
      isOpenLegend
    } = this.props

    return {
      toggleClickLegendButton,
      legends,
      dimensionName,
      isPieChart,
      isBulletBarChart,
      isBarChart,
      isStackBarChart,
      isRangeChart,
      isCurrencyDimensionField,
      benchMarkEntries,
      isMobileView : isMobileOrTablet(),
      fromMobileView: false,
      showLegend,
      isOpenLegend
    }
  }

  render() {
    const { isEmbed } = this.props;

    return (
      <div className="snapshot-options">
        <div className="rside-action">
          <UndoButton></UndoButton>
          {!isEmbed && this.renderSnapshotChartViewOptions()}
          {this.renderOptions()}
          {/* {this.renderSnapshotLegends()} */}
          <SnapshotLegends legendOption = { this.getLegendOptions() } />
        </div>
      </div>
    )
  }
}

SnapshotOptions.propTypes = {
  onToggleSnapShotButton: PropTypes.func,
  onSnapshotViewChange: PropTypes.func,
  currentBarChartSortOption: PropTypes.object,
  isLeafPage: PropTypes.bool,
  currentSecondaryMetricEntry: commonPropTypes.secondaryMetricEntryPropTypes,
  onBarChartSecondaryMetricChange: PropTypes.func,
  onDrilldownOptionChange: PropTypes.func,
  onBarChartSortOptionChange: PropTypes.func,
  onGroupBySortOptionChange: PropTypes.func,
  onDimensionSortOptionChange: PropTypes.func,
  onDrilldownViewTypeChange: PropTypes.func,
  currentSnapshotView: PropTypes.string,
  isScatterPlotRangeSelected: PropTypes.bool,
  onScatterPlotSecondaryMetricChange: PropTypes.func,
  togglePieChartAnnotationOptions: PropTypes.func,
  onBenchmarkOptionChange: PropTypes.func,
  currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
  currentDrilldownDimensionField: PropTypes.string,
  isNumericDimension: PropTypes.bool,
  currentGroupBySortOption: PropTypes.object,
  currentDrilldownGroupByEntry: commonPropTypes.groupByEntryPropTypes,
  currentDimensionSortOption: PropTypes.object,
  isNumericGroup: PropTypes.bool,
  currentGroupByChartApproach: PropTypes.string,
  dispatchUpdateGroupByChartViewType: PropTypes.func,
  dispatchUpdateGroupBychartApproach: PropTypes.func,
  onAddChartValueFlagChange: PropTypes.func,
  showChartValues: PropTypes.bool,
  isPieChart: PropTypes.bool,
  isBulletBarChart: PropTypes.bool,
  currentGroupByViewType: PropTypes.string,
  pieChartAnnotationOptions: PropTypes.object,
  isCurrencyDimensionField: PropTypes.bool,
  legends: PropTypes.array,
  dimensionName: PropTypes.string,
  benchMarkEntries: PropTypes.array,
  benchMarkNames: PropTypes.array,
  isEmbed: PropTypes.bool,
  toggleClickLegendButton: PropTypes.func,
  isBarChart: PropTypes.bool,
  isStackBarChart: PropTypes.bool,
  isRangeChart: PropTypes.bool,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  showLegend: PropTypes.bool,
  isOpenLegend: PropTypes.bool
}

const mapDispatchToProps = {
  dispatchUpdateGroupBychartApproach: updateGroupBychartApproach,
  dispatchUpdateGroupByChartViewType: updateGroupBychartViewType
};

function mapStateToProps(state) {
  return {
    currentDrilldownViewEntry: _.get(state.drilldown, 'currentDrilldownViewEntry', {}),
    currentDrilldownDimensionField: _.get(state, 'drilldown.currentDrilldownDimensionField'),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId'),
    currentDrilldownGroupByEntry: _.get(state, 'drilldown.currentDrilldownGroupByEntry', ''),
    currentGroupByChartApproach: _.get(state, 'visualization.snapshot.currentGroupByChartApproach', ''),
    currentGroupByViewType: _.get(state, 'visualization.snapshot.currentGroupByViewType', ''),
    pieChartAnnotationOptions: _.get(state, 'visualization.snapshot.pieChartAnnotationOptions'),
    isEmbed: _.get(state, 'embedOptions.isEmbed', false)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SnapshotOptions);
