import _ from 'lodash';
import {
  getAxisRange,
  getApiDataEntries,
  getBenchMarkValues,
  getColor,
  getTraceName,
  getBarWidth,
  getBarOffset,
  isBulletTypeChart,
  isGroupByStackBar,
  isParallelOrBulletChart,
  isParallelTypeChart,
  isCompareView,
  isStackTypeChart,
  isGroupByAndNoneStackBar,
  isGroupByBar,
  getCompareRangeYears
} from '../vizOptionsHelper';
import { getCompareLegendsItems, getLegendsItems } from '../legendHelper';
import {
  getTickValsAndTickText,
  getBarMode,
  getChartHeight,
  getTickXLabelsAndMaxMin
} from '../plotlyLayoutHelper';
import { getAnnotations } from '../DataFormatter/annotationsFormatter';
import {
  prefixText,
  suffixText,
  isIndependentAxesValues,
  formatChartTotalText
} from 'helpers/chartDataHelper';
import { VIEW_MODE } from 'modules/visualization/constants';
import { BAR_GAP_VALUE, BENCHMARK_TEXT, DEFAULT_BENCHMARK_COLOR } from 'appConstants';
import { getTickText } from 'helpers/displayNameHelper';
import { formatValueToCurrency } from 'helpers/numberHelper';
import { isTimeDurationEnabled, timeDurationDataUnit } from 'common/config/customerConfiguration';
import { getSecondsAsNumericTime } from 'helpers/visualizationHelper';
const LABEL_TEXT_COUNT = 12;

export const toPlotlyTraces = (vizOptions, apiData, formattedData) => {
  const { afterRender, isComparisonEnabled } = vizOptions;
  const apiDataEntries = getApiDataEntries(apiData, vizOptions);
  const barData = isComparisonEnabled ?
    getCompareBarWithBenchmarkData(formattedData, vizOptions)
    : getBarWithBenchmarkData(formattedData, vizOptions);

  const layout = getLayout(vizOptions, apiDataEntries, formattedData);
  const config = getConfig();
  const compareRanges = isComparisonEnabled ? getCompareRangeYears(formattedData, vizOptions) : [];
  const legendsItems = isComparisonEnabled ? getCompareLegendsItems(compareRanges)
    : getLegendsItems(apiDataEntries, vizOptions);
  afterRender(legendsItems);
  return { data: barData, layout, config };
};

function getBarWithBenchmarkData(formattedData, vizOptions) {
  const { benchMarkEntries, groupByEntry } = vizOptions;
  const barFirstValue = _.chain(formattedData).first().get([0, 'value']).value();
  const barLastData = _.last(_.last(formattedData));
  const barLastValue = _.get(barLastData, 'value');
  const benchMarkStartDimesion = !_.isEmpty(groupByEntry) ?
    (isGroupByAndNoneStackBar(vizOptions) ? barFirstValue : barLastValue) : '';

  const benchMarkLines = _.map(benchMarkEntries, (benchmark) => {
    return getMultipleBenchMarkLine([benchMarkStartDimesion, ' '], benchmark, vizOptions)
  });

  let barData = [];
  if (isGroupByStackBar(vizOptions)) {
    barData = _.map(formattedData, (datum) => {
      return getPlotlyBarData(datum, vizOptions);
    });
  } else {
    barData = _.map(formattedData, (data) => {
      const primaryBarData = getPlotlyBarData(data, vizOptions);
      if (isParallelOrBulletChart(vizOptions)) {
        const secondaryBarData = getPlotlyBarData(data, vizOptions, true);
        return [primaryBarData, secondaryBarData];
      }
      return [primaryBarData];
    });
  }
  return _.flattenDeep([...barData, benchMarkLines]);
}

function getCompareBarWithBenchmarkData(formattedData, vizOptions) {
  const { benchMarkEntries, groupByEntry } = vizOptions;
  const barFirstValue = _.chain(formattedData).first().get([0, 'value']).value();
  const barLastData = _.last(_.last(formattedData));
  const barLastValue = _.get(barLastData, 'value');
  const benchMarkStartDimesion = !_.isEmpty(groupByEntry) ?
    (isGroupByAndNoneStackBar(vizOptions) ? barFirstValue : barLastValue) : '';

  const benchMarkLines = _.map(benchMarkEntries, (benchmark) => {
    return getMultipleBenchMarkLine([benchMarkStartDimesion, ' '], benchmark, vizOptions)
  });

  const compareRanges = _.reverse(getCompareRangeYears(formattedData, vizOptions));

  let maxYears = _.filter(compareRanges, (range) => {
    return !_.isUndefined(range.period) && range.period !== BENCHMARK_TEXT
  });

  const flattenBarData = _.flatten(formattedData);
  const uniqDimension = _.uniq(_.map(flattenBarData, 'value'));

  let barData = [];

  _.forEach(maxYears, (maxYear) => {
    const barColor = maxYear['color'];
    let x1Data = [];

    _.forEach(uniqDimension, (dimension) => {

      const formattedCompare = _.find(flattenBarData, function (datum) {
        return datum['period'] == maxYear['period'] && datum['value'] == dimension;
      });

      if (!_.isEmpty(formattedCompare)) {
        x1Data.push(formattedCompare)
      } else {
        const emptyDimension = getEmptyDimension(dimension, barColor, maxYear['period']);
        x1Data.push(emptyDimension);
      }
    });

    const primaryBarData = getPlotlyBarData(x1Data, vizOptions, false, barColor);
    barData = _.concat(barData, [primaryBarData]);
  })

  var finalData = _.flattenDeep([...barData, benchMarkLines]);

  return finalData;
}

function getEmptyDimension(dimension, color, period) {

  return {
    label: null,
    color: color,
    secondaryLabel: null,
    actualLabel: null,
    actualSecondaryLabel: null,
    value: dimension,
    text: null,
    secondaryText: null,
    customData: [],
    ticktext: dimension,
    isGroup: false,
    period: period
  }
}

function getPlotlyBarData(formattedData, vizOptions, isSecondaryBar = false, barColor = '') {
  const { groupByEntry, secondaryMetricEntry, groupType,
    viewMode, showChartValues, viewEntry, isComparisonEnabled } = vizOptions;
  const traceName = getTraceName(vizOptions, isSecondaryBar);
  const label = isSecondaryBar ? 'secondaryLabel' : 'label';
  const xaxis = isIndependentAxesValues(vizOptions) ? 'x2' : '';
  const barWidth = getBarWidth(groupByEntry, secondaryMetricEntry, isSecondaryBar);
  const barOffset = getBarOffset(groupByEntry, secondaryMetricEntry, isSecondaryBar);
  const color = isComparisonEnabled ? barColor : getColor(vizOptions, isSecondaryBar, formattedData);
  const hoverText = isSecondaryBar ? 'secondaryText' : 'text';
  const isSmallView = (viewMode === VIEW_MODE.SMALL);
  const isBarWidthRequire = (isParallelOrBulletChart(vizOptions) || isSmallView || isSecondaryBar);
  let isAvailableSeeMoreDimension = false;
  const textValues = _.map(formattedData, (datum) => {
    if (showChartValues || _.get(datum, 'isSeeMoreDimension', false)) {
      isAvailableSeeMoreDimension = true;
      return datum[hoverText]
    } else {
      return `<span style="fill:#ffffff"> ${datum[hoverText]}</span>`
    }
  });

  let barData = {
    x: _.map(formattedData, label),
    y: _.map(formattedData, 'value'),
    name: traceName,
    type: "bar",
    orientation: "h",
    text: textValues,
    meta: _.map(formattedData, (entry) => _.merge(entry, {
      isSecondaryBar, color: isComparisonEnabled ? barColor : entry.color
    })),
    hoverinfo: "none",
    visible: true,
    marker: { color },
    legendgroup: traceName,
    showlegend: false,
    cliponaxis: false,
    width: isBarWidthRequire && !isComparisonEnabled ? barWidth : '',
    offset: barOffset,
    textposition:'none',
    textangle: 0
  };

  // In dashboard we need to show the annotation text like the total text,
  // Because of values were bleeding out the card.

  const isSecondsFormat = isTimeDurationEnabled(viewEntry) && !isStackTypeChart(vizOptions);

  if (isSmallView && !isSecondsFormat) {
    barData.text = formattedAnnotationText(_.map(formattedData, label), vizOptions);
  }

  const isNotStackChart = !isStackTypeChart(vizOptions);
  const isNotBulletTypeChart = !isBulletTypeChart(vizOptions);
  const isGroupByBarChart = isGroupByBar(vizOptions);

  let shouldDisplayLabels = (showChartValues && !isStackTypeChart(vizOptions) &&
    (!isBulletTypeChart(vizOptions) || isComparisonEnabled) &&
    (isParallelTypeChart(vizOptions) || _.isEmpty(groupByEntry) ||
      _.get(groupByEntry, 'name') === 'None'));

  shouldDisplayLabels = showChartValues ? (shouldDisplayLabels || isGroupByBarChart) :
    (isNotStackChart && (isNotBulletTypeChart || isComparisonEnabled) && isGroupByBarChart);

  if (shouldDisplayLabels) {
    barData = _.merge({}, barData, {
      textposition: (isSmallView) ? 'auto' : (isAvailableSeeMoreDimension ? 'outside' : 'auto')
    });
  }

  if (isSecondaryBar) {
    return _.merge({}, barData, { xaxis });
  } else {
    const tickvals = _.map(formattedData, 'value');
    const ticktext = _.map(formattedData, 'ticktext');

    if (isCompareView(groupType) && !_.isEmpty(groupByEntry) && _.get(groupByEntry, 'name') !== 'None') {
      const customdata = _.map(formattedData, "customData");
      return _.merge({}, barData, { tickvals, ticktext, customdata });
    } else {
      return _.merge({}, barData, { tickvals, ticktext });
    }
  }
}

function formattedAnnotationText(values, vizOptions) {
  const { viewEntry } = vizOptions;
  return _.map(values, (value) => {
    return formatChartTotalText(value, {viewOptions: viewEntry}, true, false, true);
  });
}

function getLayout(vizOptions, apiDataEntries, formattedData) {
  const {
    viewEntry, viewMode, secondaryMetricEntry,
    showChartValues, isCurrencyDimensionField, isComparisonEnabled
  } = vizOptions;
  const isSmallView = (viewMode === VIEW_MODE.SMALL);
  const barMode = getBarMode(vizOptions);
  const chartHeight = getChartHeight(apiDataEntries, vizOptions, formattedData);
  const annotationOptions = (!isParallelOrBulletChart(vizOptions) && !isGroupByBar(vizOptions)) ?
    getAnnotations(apiDataEntries, vizOptions, formattedData) :
    { annotations: '', xAxisMaxValue: 0 };
  const { annotations, xAxisMaxValue } = annotationOptions;
  const { tickvals, ticktext } = getTickValsAndTickText(formattedData, vizOptions);
  const textOptions = { viewOptions: viewEntry, secondaryComparison: secondaryMetricEntry };
  let barGapValue = isSmallView && isStackTypeChart(vizOptions) ? '' : BAR_GAP_VALUE;   // 0.5
  barGapValue = isComparisonEnabled ? 0.2 : barGapValue
  const rangeFirstValue = _.first(tickvals)
  const rangeLastValue = _.last(tickvals)
  const isGroupChartBar = isGroupByBar(vizOptions);
  const isGroupByStackChart = isGroupByStackBar(vizOptions);
  const isSecondsFormatSecondary = isTimeDurationEnabled(secondaryMetricEntry);
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);
  const compareRanges = isComparisonEnabled ? getCompareRangeYears(formattedData, vizOptions) : [];
  const groupBarGap = _.size(compareRanges) == 1 && isComparisonEnabled ? 0.4 : 0.1;

  let layout = {
    "shapes": [],
    "showlegend": false,
    "autosize": true,
    "barmode": barMode,
    "margin": isSmallView ? { t: 10, b: 10, r: 2, pad: 5 } : { l: 1, t: 1, b: 1, r: 1, pad: 5 },
    "bargap": isBulletTypeChart(vizOptions) && !isComparisonEnabled ? 1 : barGapValue,
    "dragmode": false,
    "showspikes": false,
    "bargroupgap": _.includes(['group', 'parallel'], barMode) ? groupBarGap : '',
    "xaxis": {
      "rangemode": 'tozero',
      "showticklabels": true,
      "showgrid": false,
      "zeroline": true,
      "showcrossline": true,
      "automargin": true,
      "side": "top",
      "tickprefix": prefixText(textOptions),
      "ticksuffix": isSmallView ? "" : `${suffixText(textOptions, false)}`,
      "tickfont": {
        "color": '#1d2124',
        "family": isSmallView ? 'Roboto' : '',
        "size": isSmallView ? 8 : 'auto'
      }
    },
    "yaxis": {
      "autorange":'reversed',
      "showticklabels": true,
      "showdividers": true,
      "dividercolor": 'block',
      "dividerwidth": 1,
      "automargin": true,
      "tickfont": {
        "family": isSmallView ? 'Roboto' : '',
        "size": isSmallView ? 12 : 'auto'
      },
      "type": 'category',
      "dtick": 1,
      "range": (isGroupChartBar || isComparisonEnabled) ?
        [rangeFirstValue, rangeLastValue] : [-0.9, _.size(tickvals)]
    },
    "xaxis2": {
      "showticklabels": isIndependentAxesValues(vizOptions),
      "showgrid": false,
      "zeroline": true,
      "showcrossline": true,
      "automargin": true,
      "overlaying": "x",
      "side": "bottom",
      "tickfont": {
        "color": '#1d2124',
        "family": isSmallView ? 'Roboto' : '',
        "size": isSmallView ? 8 : 'auto'
      },
      "tickprefix": prefixText(textOptions, true),
      "ticksuffix": isSmallView ? "" : `${suffixText(textOptions, false, true)}`,
    },
    "annotations": (showChartValues || isGroupChartBar || isGroupByStackChart) ? annotations : '',
    "height": chartHeight || 2000,
    "hovermode": "y"
  };

  if (isSmallView) {
    layout.yaxis.tickvals = tickvals;
    let newTickvals = tickvals;
    if (isCurrencyDimensionField) {
      newTickvals = _.map(tickvals, (tickvalItem) => {
        return formatValueToCurrency(tickvalItem, true);
      });
    }

    layout.yaxis.ticktext = getTickText(newTickvals, LABEL_TEXT_COUNT);
  } else {
    layout.yaxis.tickvals = tickvals;
    layout.yaxis.ticktext = ticktext;
  }

  if (_.includes(['group', 'bullet'], barMode)) {
    const axisRange = getAxisRange(formattedData, vizOptions);
    layout = _.merge({}, layout, axisRange);
  }

  // need to refactor
  if (isSecondsFormatSecondary) {
    const { minXLabel, maxXLabel } = getTickXLabelsAndMaxMin(formattedData, 'secondaryLabel', vizOptions);
    layout.xaxis2.range = [minXLabel, maxXLabel];
    layout.xaxis2.tickformat = "%X";
    layout.xaxis2.type = "liner";
  }

  if (isSecondsFormat) {
    const { minXLabel, maxXLabel } = getTickXLabelsAndMaxMin(formattedData, 'label', vizOptions);
    let maxValue = maxXLabel;
    if (maxXLabel == 0) maxValue = 1;
    const addDuration = isSmallView ? 3600000 : 300000;
    if (isStackTypeChart(vizOptions)) {
      maxValue = _.max([xAxisMaxValue, maxXLabel]) + addDuration;
    }
    layout.xaxis.tickformat = "%X";
    layout.xaxis.type = "liner";
    layout.xaxis.range = [minXLabel, maxValue];

    if (!isSecondsFormatSecondary) {
      layout.xaxis2.range = "";
    }
  }

  if (isStackTypeChart(vizOptions)) {
    const yaxisRanges = {
      yaxis: { autorange: 'reversed' }
    }
    layout = _.merge({}, layout, yaxisRanges);
  }
  return layout;
}

function getConfig() {
  return {
    "displayModeBar": false,
    "scrollZoom": false,
    "editable": false,
    "showLink": false,
    "responsive": false
  };
}

const getBenchMarkLine = (yAxisData, value, benchMarkEntry) => {
  const formattedName = _.get(benchMarkEntry, 'name', '');
  const benchmarkColor = _.get(benchMarkEntry, 'color', DEFAULT_BENCHMARK_COLOR) || DEFAULT_BENCHMARK_COLOR;
  const lineType = _.get(benchMarkEntry, 'lineType', 'dot');

  return {
    y: yAxisData,
    x: [Number(value), Number(value)],
    mode: 'lines+markers+text',
    hoverinfo: 'none',
    customdata: [['benchMark', formattedName]],
    showlegend: false,
    type: 'line',
    line: {
      color: benchmarkColor,
      width: 1,
      dash: lineType
    },
    clickmode: false
  };
}

const getMultipleBenchMarkLine = (yAxisData, benchMarkEntry, vizOptions) => {
  const { viewEntry } = vizOptions;

  const isSecondsFormat = isTimeDurationEnabled(viewEntry)
  const dataUnit = timeDurationDataUnit(viewEntry);
  const benchMarkValues = getBenchMarkValues(benchMarkEntry);
  const benchMarkLines = _.map(benchMarkValues, (value) => {
    const benchValue = isSecondsFormat ? getSecondsAsNumericTime(value, dataUnit) : value;
    return getBenchMarkLine(yAxisData, benchValue, benchMarkEntry)
  });

  return benchMarkLines;
}
