import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ForgeIcon,
  ForgeTooltip,
  ForgeBadge,
  ForgeButton
} from '@tylertech/forge-react';
import ForgePopupOption from 'common/components/ForgePopupOption/ForgePopupOption';
import {
  forecastAccuracyPastPerformanceTooltip,
  forecastAccuracyMapeScoreTooltip,
  forecastAccuracyMaeScoreTooltip
} from 'common/config/customerConfiguration';
import { getFormattedNumberValue } from 'helpers/chartDataHelper';
import { FORECASTING_TYPE } from 'appConstants';

const AccuracyTable = (props) => {
  const {
    forecastAccuracyData,
    forecastModelOptions,
    viewEntry
  } = props;
  const [showPopContent, setShowPopContent] = useState(false);
  const [popupTargetRef, setPopupTargetRef] = useState();
  const [popupContent, setPopupContent] = useState();
  const pastPerformanceRef = useRef();
  const mapeRef = useRef();
  const maeRef = useRef();

  const onIconClick = (title) => {
    switch (title) {
      case 'Past performance':
        setPopupTargetRef(pastPerformanceRef);
        setPopupContent(pastPerformancePopupContent(title, forecastAccuracyPastPerformanceTooltip));
        break;
      case 'MAPE score':
        setPopupTargetRef(mapeRef);
        setPopupContent(pastPerformancePopupContent(title, forecastAccuracyMapeScoreTooltip));
        break;
      case 'MAE score':
        setPopupTargetRef(maeRef);
        setPopupContent(pastPerformancePopupContent(title, forecastAccuracyMaeScoreTooltip));
        break;
    }
    setShowPopContent(!showPopContent);
  };

  const openForecastingConceptPage = (scrollId) => {
    setShowPopContent(false);
    const href = `/#!/forecasting/concepts?scroll=${scrollId}`;
    window.open(href, '_blank');
  }

  const renderInfoPageLink = (title) => {
    if (title == 'Past performance'){
      return null;
    }

    return (
      <ForgeButton type="outlined">
        <button onClick={() => openForecastingConceptPage(title)}>
          <ForgeIcon slot="end" name="open_in_new"></ForgeIcon>
          Learn more
        </button>
      </ForgeButton>
    )
  }

  const renderCloseButton = () => {
    return (
      <ForgeButton type="raised">
        <button onClick={() => setShowPopContent(false)}>
          Close
        </button>
      </ForgeButton>
    )
  }

  const pastPerformancePopupContent = (title, content) => {
    return (
      <div>
        <div className="forge-typography--heading5" slot="start">{title}</div>
        <div className='accuracy-table-popup-body forge-typography--body2'>
          {content}
        </div>
        <div no-border className='accuracy-table-popup-footer'>
          {renderInfoPageLink(title)}
          {renderCloseButton()}
        </div>
      </div>
    )
  };

  const renderHeader = () => {
    return (
      <thead>
        <tr>
          <th>
            Forecast model
          </th>
          <th>
            Past performance
            <ForgeIcon
              ref={pastPerformanceRef}
              onClick={() => onIconClick('Past performance')}
              name="info_outline" class="filter-icon tx-25 align-middle ml-2"></ForgeIcon>
          </th>
          <th>
            MAPE score
            <ForgeIcon
              ref={mapeRef}
              onClick={() => onIconClick('MAPE score')}
              name="info_outline" class="filter-icon tx-25 align-middle ml-2"></ForgeIcon>
          </th>
          <th>
            MAE score
            <ForgeIcon
              ref={maeRef}
              onClick={() => onIconClick('MAE score')}
              name="info_outline" class="filter-icon tx-25 align-middle ml-2"></ForgeIcon>
          </th>
        </tr>
      </thead>
    );
  }

  const renderPastPerformance = (mapeScore) => {
    let theme, text, toolTip;
    if (mapeScore <= 10) {
      theme = 'success';
      text = 'High';
      toolTip = '”High” means MAPE is less than 10';
    } else if (mapeScore > 10 && mapeScore <= 20) {
      theme = 'warning';
      text = 'Medium';
      toolTip = '”Medium” means MAPE between 10 - 20';
    } else {
      theme = 'danger';
      text = 'Low';
      toolTip = '“Low” means MAPE is greater than 20';
    }

    return (
      <ForgeBadge theme={theme}>
        {text}
        <ForgeTooltip position="top">{toolTip}</ForgeTooltip>
      </ForgeBadge>
    )
  };

  const renderHistoricValues = () => {
    const historicModals = _.filter(forecastModelOptions,
      { 'type': FORECASTING_TYPE.HISTORICAL_AVG });
    return _.map(historicModals, (currentModal) => {
      const forecastTotal = _.get(forecastAccuracyData, 'forecast_total');
      const historicForecast = _.find(forecastTotal, FORECASTING_TYPE.HISTORICAL_AVG);
      const historicForecastTotal = _.get(historicForecast, FORECASTING_TYPE.HISTORICAL_AVG);
      const accuracyData = _.find(historicForecastTotal, `percent_${currentModal.value}`);
      const historicalMape = _.ceil(_.get(accuracyData, `percent_${currentModal.value}.accuracy.mape`), 2);
      const historicalMae = getFormattedNumberValue(
        _.ceil(_.get(accuracyData, `percent_${currentModal.value}.accuracy.mae`), 2),
        viewEntry);
      return (
        <tr>
          <td>{currentModal['name']}</td>
          <td>
            {renderPastPerformance(historicalMape)}
          </td>
          <td>{historicalMape}%</td>
          <td>{historicalMae}</td>
        </tr>
      )
    });
  };

  const renderBody = () => {
    const isExponential = !_.isEmpty(_.find(forecastModelOptions,
      { 'type': FORECASTING_TYPE.SIMPLE_EXPONENTIAL }));
    const isHistorical = !_.isEmpty(_.find(forecastModelOptions,
      { 'type': FORECASTING_TYPE.HISTORICAL_AVG }));
    const isProphet = !_.isEmpty(_.find(forecastModelOptions, { 'type': FORECASTING_TYPE.PROPHET }));
    const forecastTotal = _.get(forecastAccuracyData, 'forecast_total');
    let prophetMape, prophetMae, exponentialMape, exponentialMae;

    if (isProphet) {
      const accuracyData = _.find(forecastTotal, "Prophet");
      prophetMape = _.ceil(_.get(accuracyData, 'Prophet.accuracy.mape'), 2);
      prophetMae = getFormattedNumberValue(
        _.ceil(_.get(accuracyData, 'Prophet.accuracy.mae'), 2),
        viewEntry);
    }

    if (isExponential) {
      const accuracyData = _.find(forecastTotal, FORECASTING_TYPE.SIMPLE_EXPONENTIAL);
      exponentialMape = _.ceil(_.get(accuracyData,
        `${FORECASTING_TYPE.SIMPLE_EXPONENTIAL}.accuracy.mape`), 2);
      exponentialMae = getFormattedNumberValue(
        _.ceil(_.get(accuracyData, `${FORECASTING_TYPE.SIMPLE_EXPONENTIAL}.accuracy.mae`), 2),
        viewEntry);
    }

    return (
      <tbody>
        {isHistorical && renderHistoricValues()}
        {isExponential && <tr>
          <td>Simple exponential smoothing</td>
          <td>
            {renderPastPerformance(exponentialMape)}
          </td>
          <td>{exponentialMape}%</td>
          <td>{exponentialMae}</td>
        </tr>}
        {isProphet && <tr>
          <td>Prophet</td>
          <td>
            {renderPastPerformance(prophetMape)}
          </td>
          <td>{prophetMape}%</td>
          <td>{prophetMae}</td>
        </tr>}
      </tbody>
    )
  }

  const renderToolTipPopUp = () => {
    return (
      <ForgePopupOption
        placement='top'
        showPopup={showPopContent}
        targetPopupRef={popupTargetRef}
        closeFilterChip={() => setShowPopContent(false)}
        isIncludeOutsideClose={true}>
        {popupContent}
      </ForgePopupOption>
    )
  }

  return (
    <div className="table-responsive">
      <table className="table accuracy-table">
        {renderHeader()}
        {renderBody()}
        {renderToolTipPopUp()}
      </table>
    </div>
  );
};


AccuracyTable.propTypes = {
  forecastAccuracyData: PropTypes.object,
  forecastModelOptions: PropTypes.array,
  viewEntry: PropTypes.object,
};


export default AccuracyTable;
