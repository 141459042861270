import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

import {
  OVERTIME_VISUALIZATION_TYPES,
  LINE_CHART_TRACE_COLORS,
  COMPARE_VIEW_STACK_COLORS
} from 'appConstants';

import LoadingSpinner from 'common/components/LoadingSpinner';
import { fetchApiData } from 'helpers/apiResponseHelper';
import { buildQueryString } from 'helpers/HttpHelper';
import { getNullValueLabel } from 'common/config/templateConfiguration';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import { setBodyBottomPadding } from 'helpers/DomPageHelper';
import { formatValueToCurrency } from 'helpers/numberHelper';
import { getDimensionNameByParams } from 'helpers/displayNameHelper';
import { templateIdPropTypes } from 'common/propTypes';
import ForgeCloseButton from 'common/components/ForgeCloseButton/ForgeCloseButton';
import { ForgeButton, ForgeIcon, ForgeIconButton } from '@tylertech/forge-react';
import { getConfiguredMetricColor } from 'common/config/visualizationConfiguration';

const MAX_LEGEND_LIMIT = 10;
const DIMENSION_LIMIT = 10;

class LegendPicker extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      showPickerDropdown: false,
      searchResults: [],
      selectedLegendEntries: _.uniqBy(_.get(props, 'legendEntries', []), 'dimension'),
      loading: false,
      searchText: null,
      enableColorBoxes: {},
      page: 0,
      haveMoreRecords: false,
      disableApplyButton: true
    };

    this.abortFetchController = new AbortController();
  }

  componentDidMount() {
    this.fetchColumnValues();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    const { legendEntries, apiParams } = _.cloneDeep(this.props);
    const { showPickerDropdown } = this.state;

    if (!_.isEqual(apiParams, prevProps.apiParams) ||
      !_.isEqual(showPickerDropdown, prevState.showPickerDropdown)) {
      this.setState({ page: 0 }, () => {
        this.fetchColumnValues();
      })
    }

    if (!_.isEqual(
      _.uniq(_.map(legendEntries, 'dimension')),
      _.uniq(_.map(prevProps.legendEntries, 'dimension')))) {
      this.setState({
        selectedLegendEntries: _.uniqBy(legendEntries, 'dimension'),
        searchText: '',
        page: 0
      })
    }
  }

  isEndOfElement = (element) => {
    //visible height + pixel scrolled = total height
    return element.offsetHeight + element.scrollTop >= (element.scrollHeight - 5);
  }

  handleScroll = () => {
    const { haveMoreRecords, page, loading } = this.state;
    if (this.legendDropdown && haveMoreRecords && !loading) {
      if (this.isEndOfElement(this.legendDropdown)) {
        this.setState({ page: page + 1, loading: true }, () => {
          this.fetchColumnValues();
        });
      }
    }
  }

  isSelectedEntriesUpdated = (legendEntries, selectedLegendEntries) => {
    return !_.isEqual(
      _.uniqBy(_.filter(legendEntries, 'primaryTrace'), 'dimension'),
      _.uniqBy(_.filter(selectedLegendEntries, 'primaryTrace'), 'dimension')
    );
  }

  handleClickOutside = (e) => {
    const { showPickerDropdown, selectedLegendEntries } = this.state;
    if (this.legendPickerRef && !this.legendPickerRef.contains(e.target)) {
      if (showPickerDropdown) {
        setBodyBottomPadding(showPickerDropdown, 'compareYear');
      }
      // hiding field filter drop down
      this.setState({ showPickerDropdown: false, searchText: '' }, () => {
        if (showPickerDropdown) {
          this.props.onLegendEntriesChange(selectedLegendEntries);
          this.props.onDisplayLegendItems(true);
        }
      });
    }

    if (_.isEmpty(this.colorPickerRef) || (this.colorPickerRef && !this.colorPickerRef.contains(e.target))) {
      this.closeAllColorPalette();
    }
  }

  handleApplyButton = () => {
    const { onLegendEntriesChange } = this.props;
    const { selectedLegendEntries, showPickerDropdown } = this.state;
    setBodyBottomPadding(showPickerDropdown, 'LegendPicker');

    if (showPickerDropdown) {
      onLegendEntriesChange(selectedLegendEntries);
      this.setState({ showPickerDropdown: false, searchText: '' });
      this.props.onDisplayLegendItems(true)
    }
  }

  closeAllColorPalette() {
    const { selectedLegendEntries } = this.state;
    let newEnableColorBoxes = _.get(this.state, 'enableColorBoxes', {});
    _.times(selectedLegendEntries.length, (index) => { newEnableColorBoxes[index] = false });
    this.setState({ enableColorBoxes: newEnableColorBoxes });
  }

  formatLegendEntries = (items) => {
    return _.map(items, (item) => {
      return {
        traceId: item,
        visible: false,
        isTotalLine: false,
        color: "",
        dimension: item,
        primaryTrace: true
      };
    })
  }

  fetchColumnValues = () => {
    let haveMoreRecords;
    let { page, searchResults, searchText } = this.state;
    const { apiParams, isDimensionHighToLow } = this.props;
    let params = {
      ...apiParams,
      searchText,
      page,
      limit: DIMENSION_LIMIT,
      sortOrderDimensionHighToLow: isDimensionHighToLow
    };

    params = _.omit(params, 'selectedDimensions');
    const apiUrl = `/api/visualization/dimension_entries.json?${buildQueryString(params)}`;
    this.abortFetchController.abort();
    this.abortFetchController = new AbortController();
    this.setState({ loading: true });
    fetchApiData(apiUrl, this.abortFetchController).
      then((response) => {
        haveMoreRecords = (response.length >= MAX_LEGEND_LIMIT);
        if (page > 0) {
          searchResults = searchResults.concat(response);
        } else {
          searchResults = response;
        }
        this.setState({ searchResults, loading: false, haveMoreRecords });
      }).catch((err) => {
        if (err.name !== 'AbortError') {
          console.log('Error on column Values ', err); // eslint-disable-line no-console
          this.setState({ searchResults: [], loading: false });
        }
      });
  }

  isLegendHasSelectionValues = () => {
    const { loading, selectedLegendEntries, searchResults, showPickerDropdown } = this.state;
    const withoutTotalEntries = _.filter(selectedLegendEntries, (entry) => {
      return !_.get(entry, 'isTotalLine');
    });
    const legendEntryNames = _.map(withoutTotalEntries, 'traceId');
    if (loading || showPickerDropdown) {
      return true;
    } else {
      if (searchResults.length > 1 || withoutTotalEntries > 1) {
        return true;
      } else if (_.isEmpty(_.difference(searchResults, legendEntryNames))) {
        return false;
      } else {
        return true;
      }
    }
  }

  toggleDropDown = () => {
    const { legendEntries, onLegendEntriesChange } = this.props;
    const { showPickerDropdown, selectedLegendEntries } = this.state;

    if (showPickerDropdown) {
      // updating legends on closing dropdown
      this.closeAllColorPalette();
    } else {
      this.setState({
        selectedLegendEntries: _.uniqBy(legendEntries, 'dimension')
      });
    }
    setBodyBottomPadding(showPickerDropdown, 'LegendPicker');
    this.props.onDisplayLegendItems(showPickerDropdown)
    this.setState({
      showPickerDropdown: !showPickerDropdown,
      searchText: '',
      disableApplyButton: true
    }, () => {
      if (showPickerDropdown) {
        onLegendEntriesChange(selectedLegendEntries);
      }
    });
  }

  handleToggleDropDown = (e) => {
    if (isEnterButtonPressed(e)) {
      this.toggleDropDown();
    }
  }

  toggleColorBoxes = (index) => {
    const { selectedLegendEntries } = this.state;
    let newEnableColorBoxes = _.get(this.state, 'enableColorBoxes', {});
    _.times(selectedLegendEntries.length, (i) => {
      if (i != index) {
        newEnableColorBoxes[i] = false
      }
    });
    newEnableColorBoxes[index] = !newEnableColorBoxes[index]
    this.setState({ enableColorBoxes: newEnableColorBoxes });
  }

  handleToggleColorBoxes = (e, index) => {
    if (isEnterButtonPressed(e)) {
      this.toggleColorBoxes(index);
    }
  }

  handleColorChange = (index, color) => {
    let { selectedLegendEntries, enableColorBoxes } = this.state;
    const { renderType } = this.props;
    const isAreaChart = _.isEqual(renderType, _.get(OVERTIME_VISUALIZATION_TYPES.AREA, 'type', ''));
    if (isAreaChart) {
      selectedLegendEntries[index].areaChartColor = color;
    } else {
      selectedLegendEntries[index].color = color;
    }

    enableColorBoxes[index] = false;
    this.setState({
      selectedLegendEntries,
      enableColorBoxes,
      disableApplyButton: false
    });
  }

  handleKeyDownColorChange = (e, index, color) => {
    if (isEnterButtonPressed(e)) {
      this.handleColorChange(index, color);
    }
  }

  handleSearchChange = (event) => {
    let searchText = event.target.value;
    this.setState({ searchText, page: 0 }, () => {
      this.fetchColumnValues();
    });

  }

  handleDeleteLegend = (index) => {
    const { legendEntries, onUpdateShowLegendTotal } = this.props;
    let selectedLegendEntries = _.get(this.state, 'selectedLegendEntries', {});
    
    const isTotalLine = _.get(selectedLegendEntries[index], 'isTotalLine') == true;
    if(isTotalLine){
      onUpdateShowLegendTotal(false)
    }

    selectedLegendEntries.splice(index, 1);
    this.setState({
      selectedLegendEntries,
      disableApplyButton: !this.isSelectedEntriesUpdated(legendEntries, selectedLegendEntries)
    });
  }

  handleKeyDownDeleteLegend = (e, index) => {
    if (isEnterButtonPressed(e)) {
      this.handleDeleteLegend(index);
    }
  }

  handleAddLegend = (entry, index) => {
    const { templateId, legendEntries, onUpdateShowLegendTotal } = this.props;
    const nullValueLabel = getNullValueLabel(templateId);
    const totalTraceColor = getConfiguredMetricColor(templateId, 'primary');

    let selectedLegendEntries = _.get(this.state, 'selectedLegendEntries', {});

    const withoutTotal = _.filter(selectedLegendEntries, (entry) => {
      return !entry['isTotalLine'];
    });

    const isTotalLine = _.get(entry, 'isTotalLine') == true;
    if(isTotalLine){
      onUpdateShowLegendTotal(true)
    }

    const colorIndex = (index % LINE_CHART_TRACE_COLORS.length);
    const areaColorIndex = (index % COMPARE_VIEW_STACK_COLORS.length);
    if (withoutTotal.length < MAX_LEGEND_LIMIT) {
      entry['color'] = entry['isTotalLine'] ? totalTraceColor : LINE_CHART_TRACE_COLORS[colorIndex];
      entry['areaChartColor'] = COMPARE_VIEW_STACK_COLORS[areaColorIndex];
      entry['traceId'] = _.isEmpty(entry['traceId']) ? nullValueLabel : entry['traceId'];
      entry['dimension'] = _.isEmpty(entry['traceId']) ? nullValueLabel : entry['traceId'];
      entry['visible'] = true;
      entry['primaryTrace'] = true;
      if(isTotalLine){
        selectedLegendEntries.unshift(entry);
      } else{
        selectedLegendEntries.push(entry);
      }

      this.setState({
        selectedLegendEntries,
        disableApplyButton: !this.isSelectedEntriesUpdated(legendEntries, selectedLegendEntries)
      });
    }
  }

  handleClearAllButton = () => {
    const { selectedLegendEntries } = this.state;
    const { legendEntries } = this.props;
    const removedLegendEntries = _.filter(selectedLegendEntries, (legendItemEntry) => {
      return (_.get(legendItemEntry, 'isTotalLine'));
    });
    this.setState({
      selectedLegendEntries: removedLegendEntries,
      disableApplyButton: !this.isSelectedEntriesUpdated(legendEntries, removedLegendEntries)
    });
  }

  handleClearAllButtonKeyDown = (e) => {
    if (isEnterButtonPressed(e)) {
      this.handleClearAllButton();
    }
  }

  handleKeyDownAddLegend = (e, entry, index) => {
    if (isEnterButtonPressed(e)) {
      this.handleAddLegend(entry, index);
    }
  }

  renderApplyButton() {
    const { disableApplyButton } = this.state;

    return (
      <div className='ml-auto'>
        <ForgeButton type="raised">
          <button className='apply-button'
            disabled={disableApplyButton}
            type="button"
            onClick={this.handleApplyButton}
          >
            <span>Apply</span>
          </button>
        </ForgeButton>
      </div>
    );
  }

  renderColorPicker = (itemIndex, selectedColor) => {
    const { renderType } = this.props;
    const isAreaChart = _.isEqual(renderType, _.get(OVERTIME_VISUALIZATION_TYPES.AREA, 'type', ''));
    let colorEntries = (isAreaChart ? COMPARE_VIEW_STACK_COLORS : LINE_CHART_TRACE_COLORS);

    const colorList = _.map(colorEntries, (color, index) => {
      const colorBoxClassName = classNames(
        'fake-check-box',
        { 'active': _.isEqual(color.toLowerCase(), (selectedColor || '').toLowerCase()) }
      );

      const style = { backgroundColor: color, borderColor: color };
      return (
        <span
          key={index}
          tabIndex={0}
          className={colorBoxClassName}
          style={style}
          onKeyDown={(e) => this.handleKeyDownColorChange(e, itemIndex, color)}
          onClick={() => this.handleColorChange(itemIndex, color)}>
        </span>
      )
    });
    return (
      <div ref={(ref) => this.colorPickerRef = ref}>
        <div className="filter-item-dropdown color-picker-container dropdown-menu show">
          <div className="arrow"></div>
          <div className="color-picker-wrapper">
            {colorList}
          </div>
        </div>
      </div>
    )
  }

  renderSelectedItemsList() {
    const { selectedLegendEntries, enableColorBoxes } = this.state;
    const { renderType, isCurrencyDimensionField } = this.props;
    const isAreaChart = _.isEqual(renderType, _.get(OVERTIME_VISUALIZATION_TYPES.AREA, 'type', ''));
    const legendItems = selectedLegendEntries.map((legendItemEntry, index) => {
      let { color, dimension, areaChartColor, isTotalLine} = legendItemEntry;
      const traceColor = isAreaChart ? areaChartColor : color;
      const style = {
        backgroundColor: traceColor,
        borderColor: traceColor
      };
      if (isCurrencyDimensionField) {
        dimension = formatValueToCurrency(dimension, true);
      }

      if (isTotalLine && isAreaChart) {
        return null
      }
 
      return (
        <div className="over-time-legend-item" key={dimension + index}>
          <div className="d-flex align-items-center">
            <div
              tabIndex={0}
              className="fake-check-box"
              style={style}
              onClick={() => this.toggleColorBoxes(index)}
              onKeyDown={(e) => this.handleToggleColorBoxes(e, index)}>
            </div>
            {enableColorBoxes[index] && this.renderColorPicker(index, traceColor)}
            <label className='legend-label d-flex align-items-center justify-content-between'>
              <div className="trace-name forge-typography--body2">
                {dimension}
              </div>
              <div
                tabIndex={0}
                onKeyDown={(e) => this.handleKeyDownDeleteLegend(e, index)}
                onClick={() => this.handleDeleteLegend(index)}>
                {/* <i className="icons-times pointer align-self-center text-dark"></i> */}
                <ForgeCloseButton />
              </div>
            </label>
          </div>
        </div>
      );
    });

    return (<div>{legendItems}</div>);
  }


  renderSearchedItemsList() {
    const { templateId, isCurrencyDimensionField, showMetricTotal, renderType } = this.props;
    const nullValueLabel = getNullValueLabel(templateId);
    const { searchResults, selectedLegendEntries } = this.state;
    if (_.isEmpty(searchResults)) {
      return null;
    }

    const totalFormat  = {
      traceId: 'Total',
      visible: false,
      isTotalLine: true,
      color: "",
      dimension: 'Total',
      primaryTrace: true
    }

    const isAreaChart = _.isEqual(renderType, _.get(OVERTIME_VISUALIZATION_TYPES.AREA, 'type', ''));
    const formattedEntries = this.formatLegendEntries(searchResults);
    if(showMetricTotal && !isAreaChart){
      formattedEntries.unshift(totalFormat);
    }

    const legendItems = formattedEntries.map((entry, index) => {
      const { dimension } = entry;
      let legendName = _.isEmpty(dimension) ? nullValueLabel : dimension;
      const isAlreadySelectedItem = _.isEmpty(_.find(selectedLegendEntries, ['dimension', legendName]));
      const selectedItemTotal = _.find(selectedLegendEntries, { visible: false, isTotalLine: true });

      if (!isAlreadySelectedItem && _.isEmpty(selectedItemTotal)) {
        return null;
      }

      if (isCurrencyDimensionField) {
        legendName = formatValueToCurrency(legendName, true);
      }

      return (
        <div className="over-time-legend-item" key={legendName + index}>
          <div
            tabIndex={0}
            className="d-flex"
            onKeyDown={(e) => this.handleKeyDownAddLegend(e, entry, index)}
            onClick={() => this.handleAddLegend(entry, index)}>
            <span className="fake-check-box"></span>
            <label className="legend-label">
              <div className="trace-name forge-typography--body2">{legendName}</div>
            </label>
          </div>
        </div>
      );
    });

    return (<div>{legendItems}</div>);
  }

  renderErrorInfo() {
    const { selectedLegendEntries } = this.state;
    const withoutTotal = _.filter(selectedLegendEntries, (entry) => {
      return !entry['isTotalLine'];
    });
    let pickerInfoText = 'Select up to 10 categories';
    if (withoutTotal.length >= MAX_LEGEND_LIMIT) {
      pickerInfoText = 'Category limit reached. Please unselect some to select others.';
    }

    return (
      <div className="picker-info my-2 d-flex align-items-center">
        <span className='forge-typography--caption'>{pickerInfoText}</span>
        {!_.isEmpty(withoutTotal) && <span
          className="float-right forge-typography--caption reset-link text-uppercase text-nowrap"
          tabIndex={0}
          aria-label="Clear all categories"
          onClick={this.handleClearAllButton}
          onKeyDown={this.handleClearAllButtonKeyDown}>Clear all</span>
        }
      </div>
    );
  }

  renderPickerDropDown() {
    const { showPickerDropdown, loading, searchText } = this.state;

    if (!showPickerDropdown) {
      return null;
    }
    return (
      <div>
        <div className="show">
          <div className="legend-picker-head">
            <input
              value={searchText}
              onChange={(e) => this.handleSearchChange(e)}
              className="form-control rounded placeholder-text"
              placeholder="Search" />
            {this.renderErrorInfo()}
          </div>
          <div className="position-relative">
            <LoadingSpinner isLoading={loading} className="loading" />
            <div
              onScroll={this.handleScroll}
              className="legend-picker-inner"
              ref={(ref) => this.legendDropdown = ref} >

              <div className="selected-items-list mb-3">
                {this.renderSelectedItemsList()}
              </div>
              {this.renderSearchedItemsList()}
            </div>
          </div>
          <div className="dropdown-filter-footer m-0 px-0">
            {this.renderApplyButton()}
          </div>
        </div>
      </div>
    );
  }

  renderPickerWithDropDown() {
    const { showPickerDropdown } = this.state;

    const dimensionName = getDimensionNameByParams(this.props);
    const iconName = showPickerDropdown ? 'close' : 'control_point';
    return (
      <div className="legend-picker">
        <div ref={(ref) => this.legendPickerRef = ref} className="dropdown w-100">
          <div
            onClick={this.toggleDropDown}
            onKeyDown={(e) => this.handleToggleDropDown(e)}
            className="d-flex align-itms-center justify-content-between pointer mb-2"
            tabIndex={0}
            aria-label={`Add ${dimensionName}`}>
            <div
              className="options-label align-self-center forge-typography--body2">
              {dimensionName}
            </div>

            <ForgeIconButton toggle dense={true}>
              <button type="button" className='text-primary' aria-label={`Add ${dimensionName}`}>
                <ForgeIcon name={iconName} />
              </button>
            </ForgeIconButton>
          </div>          
          {this.renderPickerDropDown()}
        </div>
      </div>
    );
  }

  renderPickerTitleOnly() {
    return (
      <>
        <div className="legend-title align-self-center mb-2"> {getDimensionNameByParams(this.props)}</div>
      </>
    )
  }

  render() {
    return (
      <div className="legend-picker">
        <div ref={(ref) => this.legendPickerRef = ref} className="dropdown w-100">
          {this.isLegendHasSelectionValues() ?
            this.renderPickerWithDropDown() :
            this.renderPickerTitleOnly()
          }
        </div>
      </div>
    )
  }
}

LegendPicker.propTypes = {
  legendEntries: PropTypes.array,
  onLegendEntriesChange: PropTypes.func,
  apiParams: PropTypes.object,
  templateId: templateIdPropTypes,
  isCurrencyDimensionField: PropTypes.bool,
  renderType: PropTypes.oneOf(_.map(OVERTIME_VISUALIZATION_TYPES, 'type')),
  isDimensionHighToLow: PropTypes.bool,
  onDisplayLegendItems: PropTypes.func,
  showMetricTotal: PropTypes.bool,
  onUpdateShowLegendTotal: PropTypes.func
}


export default LegendPicker;
