import moment from 'moment';
import jsPDF from 'jspdf';
import { getAdvanceSearchTableContainer } from 'helpers/exportImageHelper';
import { getCurrentTemplateEntryById } from 'common/config/templateConfiguration';

export const pdfDownload = async (container, downloadOption) => {
  const { templateId } = downloadOption;
  const summaryTableImage = await getAdvanceSearchTableContainer(container.current);

  const { summaryTitle, pdfSummaryHeight } = getPdfPageDimensions(summaryTableImage, templateId);

  const isOverFlowHeight = pdfSummaryHeight > 210;
  const pdfOption = {
    orientation: isOverFlowHeight ? '' : "l",
    unit: "mm",
    format: isOverFlowHeight ? [summaryTableImage.width + 10, summaryTableImage.height + 10] : "a1"
  }

  const pdf = new jsPDF(pdfOption);
  if (!isOverFlowHeight) {
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const imgSummaryProps = pdf.getImageProperties(summaryTableImage);
    const pdfTableHeight = (imgSummaryProps.height * pdfWidth) / imgSummaryProps.width;
    pdf.setFontSize(30);
    pdf.text(summaryTitle, 10, 15);
    pdf.addImage(summaryTableImage, 'jpeg', 5, 25, pdfWidth - 5, pdfTableHeight - 5);
  } else {
    pdf.setFontSize(50);
    pdf.text(summaryTitle, 70, 40);
    pdf.addImage(summaryTableImage, 'jpeg', 65, 55,
      summaryTableImage.width - 30, summaryTableImage.height - 20);
  }
  pdf.save(getFileName(templateId));

}

const getPdfPageDimensions = (summaryTableImage, templateId) => {
  const doc = new jsPDF('l', "mm");
  const pdfWidth = doc.internal.pageSize.getWidth();
  const imgSummaryProps = doc.getImageProperties(summaryTableImage);
  const pdfSummaryHeight = (imgSummaryProps.height * pdfWidth) / imgSummaryProps.width;

  const templateName = getTemplateName(templateId);

  return {
    pdfSummaryHeight,
    mapTitle: `${templateName} - Map Report`,
    summaryTitle: `${templateName} - Comparison Report`
  }
}

const getTemplateName = (templateId) => {
  return _.get(getCurrentTemplateEntryById(templateId), 'name');
}

export const downloadMapPdf = (map, templateId) => {
  const mapImageCanvas = map.getCanvas('image/jpeg');
  const mapPdf = new jsPDF('l');
  const { mapTitle } = getPdfPageDimensions(mapImageCanvas, templateId);

  mapPdf.text(mapTitle, 10, 10);
  mapPdf.addImage(mapImageCanvas, 'jpeg', 10, 15, 275, 0);
  mapPdf.save(getFileName(templateId, true));
}

export const pdfDownloadBoth = async (downloadOption, map) => {
  const { tableContainer, templateId } = downloadOption;
  const mapImageCanvas = map.getCanvas('image/jpeg');
  const summaryTableImage = await getAdvanceSearchTableContainer(tableContainer.current);
  const { pdfSummaryHeight, mapTitle, summaryTitle } = getPdfPageDimensions(summaryTableImage, templateId);

  const isOverFlowHeight = pdfSummaryHeight > 210;
  const pdfOption = {
    orientation: isOverFlowHeight ? '' : "l",
    unit: "mm",
    format: isOverFlowHeight ?
      [(summaryTableImage.width * 0.9)  + 10, (summaryTableImage.height * 0.9) + 10] : "a1"
  }

  const pdf = new jsPDF(pdfOption);
  if (!isOverFlowHeight) {
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const imgSummaryProps = pdf.getImageProperties(summaryTableImage);
    const pdfTableHeight = ((imgSummaryProps.height * 0.9)  * pdfWidth) / (imgSummaryProps.width * 0.9);

    pdf.text(summaryTitle, 10, 10);
    pdf.addImage(summaryTableImage, 'jpeg', 5, 15, pdfWidth - 5, pdfTableHeight - 5);

    pdf.addPage('a4', 'l');
    pdf.text(mapTitle, 10, 20);
    pdf.addImage(mapImageCanvas, 'jpeg', 10, 30, 275, 0);
  } else {
    pdf.setFontSize(50);
    pdf.text(summaryTitle, 60, 40);
    pdf.addImage(summaryTableImage, 'jpeg', 60, 55,
      summaryTableImage.width * 0.9, summaryTableImage.height * 0.9);

    pdf.addPage('a4', 'l');
    pdf.setFontSize(20);
    pdf.text(mapTitle, 10, 20);
    pdf.addImage(mapImageCanvas, 'jpeg', 10, 30, 275, 0);
  }

  pdf.save(getFileName(templateId));
}

const getFileName = (templateId, isMap = false) => {
  const templateName = getTemplateName(templateId);
  const currentDate = moment().format('YYYYMMDD');
  if (isMap) {
    return `${templateName}_${currentDate}_map.pdf`;
  } else {
    return `${templateName}_${currentDate}.pdf`;
  }
}