import {
  tylIconOpenInNew,
  tylIconEvent,
  tylIconBookmark,
  tylIconDirections,
  tylIconAccessAlarm,
  tylIconArrowUpward,
  tylIconKeyboardArrowDown,
  tylIconAddCircleOutline,
  tylIconFilterList,
  tylIconClose,
  tylIconArrowBack,
  tylIconSettings,
  tylIconCheck,
  tylIconMap,
  tylIconMapsUgc,
  tylIconLocationPin,
  tylIconCompare,
  tylIconModeEdit,
  tylIconCategory,
  tylIconBarChart,
  tylIconArrowBackIos,
  tylIconArrowForwardIos,
  tylIconHelp,
  tylIconHelpOutline,
  tylIconSupervisorAccount,
  tylIconPeople,
  tylIconSchool,
  tylIconHeadsetMic,
  tylIconInfoOutline,
  tylIconInfo,
  tylIconLightbulb,
  tylIconSearch,
  tylIconControlPoint,
  tylIconDateRange,
  tylIconArrowDownward,
  tylIconArrowDropDown,
  tylIconViewWeek,
  tylIconFormatListBulleted,
  tylIconLocationOn,
  tylIconHome,
  tylIconVideoLibrary,
  tylIconRedo,
  tylIconUndo,
  tylIconMoreVert,
  tylIconKeyboardArrowRight,
  tylIconCheckCircleOutline
} from '@tylertech/tyler-icons/standard';

// Extended Icons
import {
  tylIconAnimation,
  tylIconPlusThick,
  tylIconCalendar,
  tylIconMapMarkerMultiple,
  tylIconChartBar,
  tylIconChartScatterPlot,
  tylIconChartTimelineVariant,
  tylIconChartPie,
  tylIconChartAreaspline,
  tylIconTimeline,
  tylIconLink,
  tylIconFilterVariantPlus,
  tylIconTableLarge,
  tylIconNoteText,
  tylIconAlertOutline,
  tylIconTable,
  tylIconSortVariantLock,
  tylIconTimer,
  tylIconPencilOutline,
  tylIconCameraOutline
} from '@tylertech/tyler-icons/extended';

// Some Custom Icons
import {
  tylIconFilterSortAscending,
  tylIconFilterSortDescending,
  tylIconFilterVariantCheck,
  tylIconTylerTalkingTLogo,
} from '@tylertech/tyler-icons/custom';

import { IconRegistry } from '@tylertech/forge';

IconRegistry.define([
  tylIconOpenInNew,
  tylIconEvent,
  tylIconCheck,
  tylIconMap,
  tylIconMapMarkerMultiple,
  tylIconLocationPin,
  tylIconCompare,
  tylIconMapsUgc,
  tylIconBookmark,
  tylIconArrowUpward,
  tylIconKeyboardArrowDown,
  tylIconKeyboardArrowRight,
  tylIconDirections,
  tylIconAccessAlarm,
  tylIconCalendar,
  tylIconDateRange,
  tylIconAddCircleOutline,
  tylIconFilterList,
  tylIconAnimation,
  tylIconPlusThick,
  tylIconClose,
  tylIconArrowBack,
  tylIconSettings,
  tylIconModeEdit,
  tylIconChartBar,
  tylIconChartScatterPlot,
  tylIconChartTimelineVariant,
  tylIconChartPie,
  tylIconBarChart,
  tylIconChartAreaspline,
  tylIconLightbulb,
  tylIconCameraOutline,
  tylIconLink,
  tylIconHelp,
  tylIconHelpOutline,
  tylIconPeople,
  tylIconSchool,
  tylIconHeadsetMic,
  tylIconInfoOutline,
  tylIconInfo,
  tylIconTylerTalkingTLogo,
  tylIconTimeline,
  tylIconCategory,
  tylIconArrowBackIos,
  tylIconArrowForwardIos,
  tylIconFilterVariantCheck,
  tylIconFilterVariantPlus,
  tylIconSearch,
  tylIconControlPoint,
  tylIconTableLarge,
  tylIconFilterSortAscending,
  tylIconFilterSortDescending,
  tylIconNoteText,
  tylIconArrowDownward,
  tylIconArrowUpward,
  tylIconSupervisorAccount,
  tylIconAlertOutline,
  tylIconArrowDropDown,
  tylIconViewWeek,
  tylIconFormatListBulleted,
  tylIconLocationOn,
  tylIconHome,
  tylIconVideoLibrary,
  tylIconRedo,
  tylIconUndo,
  tylIconMoreVert,
  tylIconTable,
  tylIconSortVariantLock,
  tylIconTimer,
  tylIconCheckCircleOutline,
  tylIconPencilOutline
]);
