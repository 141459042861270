import $ from 'jquery';
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import {
  showGeoCoderControl,
  getGeocodeBoundingbox,
  isMaxBoundsEnable,
  isEnableEsriBaseMap
} from 'common/config/templateConfiguration';
import { isMetricHeatChartEnabled } from 'common/config/viewConfiguration';
import MouseInteractionHandler from './handlers/MouseInteractionHandler';
import HeatMapPartial from './partials/HeatMapPartial';
import MetricHeatMapPartial from './partials/MetricHeatMapPartial';
import ChoroplethMapPartial from './partials/ChoroplethMapPartial';
import ShapeOutlinePartial from './partials/ShapeOutlinePartial';
import ShapeLayerPartial from './partials/ShapeLayerPartial';
import PointAndStackPartial from './partials/PointsAndStackPartial';
import PolygonPartial from './partials/PolygonPartial';
import DrawControl from './controls/DrawControl';
import GeoCoderControl from './controls/GeoCoderControl';
import DropPin from './controls/DropPin';
import { setIframeHeight } from 'modules/visualization/SnapshotVisualization/snapshotAfterPlotHelper';
import { getTemplateMapStyle } from 'modules/Map/helpers/mapHelper';
import { drilldownPropTypes, viewEntryPropTypes } from 'common/propTypes';
import { getShapeTileUrl } from 'common/api/map';
import { toggleShapeIdsFilter } from 'actions/mapOptionsActions';
import { getShapeWiseData } from 'common/api/drilldown';
import { SCROLL_EVENT } from "modules/visualization/constants";
import { VIEW_MODE } from 'modules/visualization/constants';
import { getMapPanConfig, setScrollZoom } from 'helpers/mapHelper';
import GlobalEvent from 'common/components/GlobalEvents';
import { updateCardLoadingStatusForImageGenerator } from 'helpers/visualizationHelper';
import { isChoroplethMap } from './helpers/MapOptionsHelper';
import ChoroplethDrawControl from './controls/ChoroplethDrawControl';
import SearchPointsAndStackPartial from './searchPartials/SearchPointsAndStackPartial';
import SearchSubjectPoints from './searchPartials/SearchSubjectPoints';
import { SEARCH_METHOD } from 'appConstants';
import StreetViewMap from './StreetViewMap';
import MapControls from './partials/MapControls';
import AdvanceSearchDrawControl from './controls/AdvanceSearchDrawControl';
import {
  updateSelectedReportIds,
  updateReportPageData
} from 'actions/advanceSearchActions';
import MapAccessibility from './MapAccessiblity';

const MAP_SOURCE_DATE_LOADING_INTERVAL_TIME = 200;
const MAP_RESIZE_WAITING_TIME = 100;
const MAP_CENTER_AND_ZOOM_WAITING_TIME = 200;

class Map extends Component {
  static propTypes = {
    apiParams: PropTypes.object,
    viewEntry: viewEntryPropTypes,
    isComparisonEnabled: PropTypes.bool,
    selectedShapeIds: PropTypes.array,
    polygonsGeojson: PropTypes.object,
    shapeGroupId: PropTypes.string,
    compareApiParams: PropTypes.object,
    showNoteModel: PropTypes.func,
    center: PropTypes.array,
    currentMapStyleEntry: PropTypes.object,
    currentMapView: PropTypes.object,
    drilldown: drilldownPropTypes,
    dispatchUpdateLegends: PropTypes.func,
    updateStreetMapCoordinates: PropTypes.func,
    zoom: PropTypes.number,
    selectedShapesExtent: PropTypes.object,
    dispatchUpdateCenterAndZoom: PropTypes.func,
    isDrilldownVisualizationMap: PropTypes.bool,
    isCurrencyDimensionField: PropTypes.bool,
    isDrawingEnabled: PropTypes.bool,
    onMapCreated: PropTypes.func,
    onDataLoad: PropTypes.func,
    onMapLoaded: PropTypes.func,
    dispatchToggleShapeIdsFilter: PropTypes.func,
    isSideBar: PropTypes.bool,
    showSearchInput: PropTypes.bool,
    onHideSearchInput: PropTypes.func,
    containerHeight: PropTypes.number,
    viewMode: PropTypes.string,
    cardImageId: PropTypes.string,
    isAdvancedSearch: PropTypes.bool,
    showSearchPoints: PropTypes.bool,
    selectedReportRowIds: PropTypes.array,
    mapBounds: PropTypes.array,
    showOnlySelectedRows: PropTypes.bool,
    searchProperty: PropTypes.object,
    polygonGeoJson: PropTypes.object,
    isSubjectProperty: PropTypes.bool,
    onUpdateMapLoadingStatus: PropTypes.func,
    isComparisonMap: PropTypes.bool
  };

  static defaultProps = {
    isDrilldownVisualizationMap: false,
    isCurrencyDimensionField: false,
    isAdvancedSearch: false,
    compareApiParams: {},
    showSearchPoints: false,
    selectedReportRowIds: [],
    showOnlySelectedRows: false,
    isComparisonMap: false,
    onMapCreated: _.noop,
    onDataLoad: _.noop,
    onMapLoaded: _.noop,
    onUpdateMapLoadingStatus: _.noop,
    updateStreetMapCoordinates: _.noop
  };

  constructor(props) {
    super(props);

    this.state = {
      hasMapInitialized: false,
      streetViewCoordinates: []
    };
    this.debouncedCenterAndZoomChange = _.debounce(
      this.mapCenterAndZoomChange,
      MAP_CENTER_AND_ZOOM_WAITING_TIME
    );
    this._isMapDrawStart = false;
    this.root = null;
    this._pointMapDraw = null;
    this._choroplethMapDraw = null;
  }

  componentDidUpdate(prevProps) {
    const {
      apiParams, center, zoom, drilldown, isCurrencyDimensionField,
      isSideBar, isAdvancedSearch, selectedReportRowIds, mapBounds,
      advanceSearchTemplateId, advanceSearchMethod, reportPageData, subjectData,
      currentMapView
    } = this.props;
    if (!_.isEqual(prevProps.currentMapStyleEntry, this.props.currentMapStyleEntry) ||
      !_.isEqual(prevProps.drilldown.currentDrilldownTemplateId, drilldown.currentDrilldownTemplateId)) {
      this.onMapStyleChange();
    }
    if (!_.isEqual(prevProps.apiParams, apiParams) && this._mouseInteractionHandler) {
      this._mouseInteractionHandler.updateApiParams({
        ...apiParams, isCurrencyDimensionField, ignore_view_entry: isAdvancedSearch,
        selectedReportRowIds, isAdvancedSearch, advanceSearchTemplateId, advanceSearchMethod, subjectData
      });
    }
    if( !_.isEqual(prevProps.selectedReportRowIds, selectedReportRowIds)
      && this._mouseInteractionHandler){
        this._mouseInteractionHandler.updateAdvanceSearchParams({
          selectedReportRowIds, reportPageData
        });
    }

    const thisDrilldownViewEntry = _.get(drilldown, 'currentDrilldownViewEntry', '');
    const previousDrilldownViewEntry = _.get(prevProps, 'drilldown.currentDrilldownViewEntry', '');
    if (
      !_.isEqual(prevProps.drilldown.currentDrilldownTemplateId, drilldown.currentDrilldownTemplateId) ||
      (!_.isEqual(previousDrilldownViewEntry, thisDrilldownViewEntry))
    ) {
      this.updateMapPosition(center, zoom);
      this.setMapMaxBounds();
    }

    if (!_.isEqual(isSideBar, prevProps.isSideBar)) {
      this.resizeMap();
    }

    if (!_.isEqual(mapBounds, prevProps.mapBounds)) {
      this.panMapToBounds();
    }
    this._mapAccessibility.updateOptions(this.props);

    if (!_.isEqual(currentMapView, prevProps.currentMapView)) {
      this._mapAccessibility.updateMapAccessibility();
    }

    window.addEventListener('map_resize', () => this.resizeMap());
  }

  componentWillUnmount() {
    // Removing it in the next tick, so that the child controls/components can finish cleaning up.
    // (Some of them might require the map for cleaning up.)
    setTimeout(() => this.map.remove());
    if (this._mouseInteractionHandler) { this._mouseInteractionHandler.destroy() }
    window.removeEventListener('map_resize', this.resizeMap);
    GlobalEvent.off(SCROLL_EVENT, this.onPageScroll);
    this.mapElement.removeEventListener('mousemove', this.onMouseMove);
    this.onRemoveDrawControl();
  }

  onRemoveDrawControl = () => {
    if(this._choroplethMapDraw) {
      this.map.removeControl(this._choroplethMapDraw);
    }
    if(this._pointMapDraw) {
      this.map.removeControl(this._pointMapDraw);
    }
  }

  updateMapPosition = (center, zoom) => {
    this.map.flyTo({ center, zoom });
  }

  onMapStyleChange = () => {
    const { currentMapStyleEntry, drilldown } = this.props;
    const templateId = drilldown.currentDrilldownTemplateId;
    const mapStyle = getTemplateMapStyle(currentMapStyleEntry, templateId);

    this.setState({ hasMapInitialized: false });
    this.map.setStyle(mapStyle, { diff: false });
    this.map.on('style.load', () => {
      if (!this.map) { return; }
      this.setState({ hasMapInitialized: true });
    });
  }

  componentDidMount() {
    this.map = this.createMap();
    this.addControls();
    this.addAttributeControl();
    this.map.once('style.load', () => {
      let loadingChecker;
      if (!this.map) { return; }

      this.initHandlers();
      this.setState({ hasMapInitialized: true });
      this.setMapMaxBounds();
      this.panMapToBounds();
      this.map.on('moveend', this.debouncedCenterAndZoomChange);
      this.map.on('mousedown', () => {
        this._isMapDrawStart = true;
      });
      this.map.on('sourcedataloading', (mapDataEvent) => {
        const isMapboxGlDrawLayer = _.get(mapDataEvent, 'sourceId') == "mapbox-gl-draw-hot";
        if (loadingChecker || isMapboxGlDrawLayer) {
          return;
        }
        loadingChecker = setInterval(() => {
          const loaded = this.map.areTilesLoaded();

          if (loaded) {
            clearInterval(loadingChecker);
            loadingChecker = null;
            $(this.loaderElement).removeClass('loader');
            this.props.onDataLoad(false);
            this.props.onUpdateMapLoadingStatus(false);
            this.updateLoadingStatusForScreenshot(false)
            this._mapAccessibility.updateFeatureForAudit();
            this.props.onMapLoaded(this.map);
          } else {
            this.props.onUpdateMapLoadingStatus(true);
            $(this.loaderElement).addClass('loader');
          }
        }, MAP_SOURCE_DATE_LOADING_INTERVAL_TIME);
      });
      this.map.on('load',() => {
        this._mapAccessibility.addMapFeaturesForAudit();
      });
      setIframeHeight();
    });
    GlobalEvent.on(SCROLL_EVENT, this.onPageScroll);
    this.mapElement.addEventListener('mousemove', this.onMouseMove);
    this._mapAccessibility = new MapAccessibility(this.map, this.props);
  }

  updateLoadingStatusForScreenshot = (status) => {
    const { viewMode, cardImageId } = this.props;

    if (viewMode === VIEW_MODE.SMALL) {
      setTimeout(() => {
        updateCardLoadingStatusForImageGenerator(cardImageId, status);
      }, 100);
    }
  }

  onMouseMove = (event) => {
    const { isDrawingEnabled, currentMapView } = this.props;
    if (isChoroplethMap(currentMapView) && isDrawingEnabled && this._isMapDrawStart) {
      const mapPanConfig = getMapPanConfig(this.mapElement, event);
      if (!_.isEmpty(mapPanConfig)) {
        this.map.panBy(mapPanConfig.offset, mapPanConfig.options);
      }
    }
  }

  onPageScroll = (type) => {
    console.log(SCROLL_EVENT, type);
    setScrollZoom(type, this.map);
  }

  initHandlers = () => {
    const {
      apiParams, showNoteModel, isCurrencyDimensionField, isAdvancedSearch,
      dispatchUpdateReportPageData, dispatchUpdateSelectedReportIds,
      advanceSearchTemplateId, selectedReportRowIds, reportPageData, advanceSearchMethod,
      subjectData, subjectSearchField
    } = this.props;

    this._mouseInteractionHandler = new MouseInteractionHandler(
      this.map,
      { ...apiParams, isCurrencyDimensionField, ignore_view_entry: isAdvancedSearch },
      { updateStreetMapCoordinates: this.updateStreetMapCoordinates, showNoteModel,
        isAdvancedSearch, advanceSearchTemplateId, selectedReportRowIds, advanceSearchMethod,
        reportPageData, dispatchUpdateReportPageData, subjectSearchField,
        dispatchUpdateSelectedReportIds, subjectData }
    );
    this.dropPin = new DropPin(this.map);
  }

  updateStreetMapCoordinates = (streetViewCoordinates) => {
    this.setState({ streetViewCoordinates }, () => {
      this.props.updateStreetMapCoordinates(streetViewCoordinates);
    })
  }
  handleStreetMapClose = () => {
    this.setState({ streetViewCoordinates: [] });
  }

  mapCenterAndZoomChange = () => {
    const { isDrilldownVisualizationMap } = this.props;
    const center = this.map.getCenter();
    const zoom = this.map.getZoom();
    const centerAndZoom = {
      center: [center.lng, center.lat],
      zoom: zoom
    };

    if (isDrilldownVisualizationMap) {
      this.props.dispatchUpdateCenterAndZoom(centerAndZoom);
    }
  }

  onLegendsChange = (buckets, type) => {
    const { isDrilldownVisualizationMap } = this.props;

    if (isDrilldownVisualizationMap) {
      this.props.dispatchUpdateLegends(buckets, type);
    }
  }

  createMap = () => {
    const { center, zoom, onMapCreated, currentMapStyleEntry, drilldown } = this.props;
    const templateId = drilldown.currentDrilldownTemplateId;
    const mapStyle = getTemplateMapStyle(currentMapStyleEntry, templateId);
    const map = new mapboxgl.Map({
      container: this.mapElement,
      style: mapStyle,
      center: center,
      zoom,
      preserveDrawingBuffer: true,
      attributionControl: false,
      transformRequest: (tileUrl) => {
        const transformedUrl = tileUrl.replace(
          'api.mapbox.com/fonts/v1/mapbox',
          'api.mapbox.com/fonts/v1/socrata'
        );

        return { url: transformedUrl };
      }
    });

    onMapCreated(map);
    return map;
  }

  setMapMaxBounds = () => {
    const { drilldown } = this.props;
    if (this.map) {
      const geocodeBoundingbox = getGeocodeBoundingbox(drilldown.currentDrilldownTemplateId);
      if (!_.isEmpty(geocodeBoundingbox) && isMaxBoundsEnable(drilldown.currentDrilldownTemplateId)) {
        this.map.setMaxBounds(geocodeBoundingbox);
      } else {
        this.map.setMaxBounds(null);
      }
    }
  }

  addControls() {
    const { isDrilldownVisualizationMap } = this.props;

    if (isDrilldownVisualizationMap) {
      const navigationControl = new mapboxgl.NavigationControl();
      this.map.addControl(navigationControl, 'bottom-left');
    }
  }

  onDrawUpdate = () => {
    setTimeout(() => {
      this._isMapDrawStart = false;
    }, 100)
  }

  onUpdateDraw = (draw, isChoroplethMapDraw) => {
    if(isChoroplethMapDraw) {
      this._choroplethMapDraw = draw;
    } else{
      this._pointMapDraw = draw;
    }
  }

  renderDrawControl = () => {
    const { isDrilldownVisualizationMap,
      currentMapView, selectedShapeIds,
      dispatchToggleShapeIdsFilter,  updatePolygonFeatures} = this.props;

    if (!isDrilldownVisualizationMap) {
      return null;
    }
    if (isChoroplethMap(currentMapView)) {
      return <ChoroplethDrawControl map={this.map}
        onDrawUpdate={this.onDrawUpdate}
        onUpdateDraw={this.onUpdateDraw}
        choroplethMapDraw={this._choroplethMapDraw}
        pointMapDraw={this._pointMapDraw}
        toggleShapeIdsFilter={dispatchToggleShapeIdsFilter}
        selectedShapeIds={selectedShapeIds}>
      </ChoroplethDrawControl>
    } else {
      return <DrawControl
        choroplethMapDraw={this._choroplethMapDraw}
        pointMapDraw={this._pointMapDraw}
        onUpdateDraw={this.onUpdateDraw}
        map={this.map} updatePolygonFeatures={updatePolygonFeatures}
      ></DrawControl>
    }
  }

  renderAdvanceSearchDrawControl = () => {
    return <AdvanceSearchDrawControl map={this.map}></AdvanceSearchDrawControl>
  }

  addAttributeControl() {
    const { isDrilldownVisualizationMap } = this.props;

    if (isDrilldownVisualizationMap) {
      const attributionControl = new mapboxgl.AttributionControl({ compact: true });
      this.map.addControl(attributionControl);
    }
  }

  resizeMap = _.debounce(() => {
    if (this.map) {
      this.map.resize();
    }
  }, MAP_RESIZE_WAITING_TIME);

  panMapToBounds() {
    const { mapBounds, isSubjectProperty, showOnlySelectedRows } = this.props;
    if(_.isEmpty(mapBounds)){
      return;
    }

    if (this.map) {
      let maxZoomValue = 22; // default maxZoom
      if (isSubjectProperty){
        maxZoomValue = 13;
      } else if (showOnlySelectedRows){
        maxZoomValue = 16;
      }
      this.map.fitBounds(mapBounds, { padding: 80, maxZoom: maxZoomValue });
    }
  }

  renderSearchMapContent = () => {
    const {
      currentMapView,
      apiParams,
      showSearchPoints,
      selectedReportRowIds,
      showOnlySelectedRows,
      searchProperty,
      isComparisonEnabled,
      polygonGeoJson
    } = this.props;
    const { streetViewCoordinates } = this.state;
    const defaultSearchMethod = _.get(searchProperty, 'defaultSearchMethod');
    const searchMethod = _.get(searchProperty, 'searchMethod')
    let streetMapViewPartial = null;
    let polygonGeojson;
    if (!_.isEmpty(streetViewCoordinates)) {
      streetMapViewPartial =
        <StreetViewMap
          coordinates={streetViewCoordinates}
          isComparisonEnabled={isComparisonEnabled}
          onStreetViewClose={this.handleStreetMapClose} />;
    }
    if (!_.isUndefined(polygonGeoJson) && Object.keys(polygonGeoJson).length > 0) {
      polygonGeojson = JSON.stringify(polygonGeoJson);
    }
    return (
      <div className="child-components">
        <MapControls>
          {this.renderAdvanceSearchDrawControl()}
        </MapControls>
        <SearchPointsAndStackPartial
          selectedReportRowIds={selectedReportRowIds}
          showSearchPoints={showSearchPoints}
          currentMapView={currentMapView}
          showOnlySelectedRows={showOnlySelectedRows}
          map={this.map}
          tileParams={{ ...apiParams, exclude_search_value: true, polygonGeoJson: polygonGeojson }}
        ></SearchPointsAndStackPartial>
        {searchMethod == SEARCH_METHOD.SUBJECT_PROPERTY && !showOnlySelectedRows &&
          defaultSearchMethod == SEARCH_METHOD.SUBJECT_PROPERTY &&
          <SearchSubjectPoints
            currentMapView={currentMapView}
            map={this.map}
            showOnlySelectedRows={showOnlySelectedRows}
            selectedReportRowIds={selectedReportRowIds}
            tileParams={{ ...apiParams, polygonGeoJson: polygonGeojson }}>
          </SearchSubjectPoints>
        }
        <PolygonPartial
          map={this.map}
          polygonsGeojson={polygonGeoJson}>
        </PolygonPartial>
        {streetMapViewPartial}

      </div>
    );
  }

  renderVisualizationMap = () => {
    const {
      apiParams, compareApiParams, currentMapView, drilldown, shapeGroupId, isDrilldownVisualizationMap,
      polygonsGeojson, selectedShapeIds, selectedShapesExtent, isComparisonEnabled, viewEntry,
      showSearchInput, onHideSearchInput, dispatchToggleShapeIdsFilter, isDrawingEnabled,
      onUpdateMapLoadingStatus, isComparisonMap
    } = this.props;
    const { currentDrilldownTemplateId } = drilldown;
    const shouldShowGeoCoderControl = showGeoCoderControl(currentDrilldownTemplateId);
    const currentDrilldownViewEntry = drilldown.currentDrilldownViewEntry || viewEntry;
    const isEsriBaseMapStyle = isEnableEsriBaseMap(currentDrilldownTemplateId);
    const isMetricHeatMap = isMetricHeatChartEnabled(currentDrilldownViewEntry);

    return (
      <div className="child-components">
        {isMetricHeatMap ?
          <MetricHeatMapPartial
            map={this.map}
            currentMapView={currentMapView}
            isMetricHeatMap={isMetricHeatMap}
            tileParams={apiParams}
            isEsriBaseMapStyle={isEsriBaseMapStyle} /> :
          <HeatMapPartial
            map={this.map}
            currentMapView={currentMapView}
            isMetricHeatMap={isMetricHeatMap}
            tileParams={apiParams}
            isEsriBaseMapStyle={isEsriBaseMapStyle} />
        }
        <ChoroplethMapPartial
          currentMapView={currentMapView}
          currentDrilldownViewEntry={currentDrilldownViewEntry}
          currentDrilldownTemplateId={currentDrilldownTemplateId}
          shapeGroupId={shapeGroupId}
          selectedShapeIds={selectedShapeIds}
          map={this.map}
          tileParams={apiParams}
          shapeTileUrl={getShapeTileUrl}
          shapeWiseDataApi={getShapeWiseData}
          isDrawingEnabled={isDrawingEnabled}
          compareApiParams={compareApiParams}
          toggleShapeIdsFilter={dispatchToggleShapeIdsFilter}
          mouseInteractionHandler={this._mouseInteractionHandler}
          onLegendsChange={this.onLegendsChange}
          onUpdateMapLoadingStatus={onUpdateMapLoadingStatus}
          isComparisonMap={isComparisonMap}
        ></ChoroplethMapPartial>
        <ShapeOutlinePartial
          currentDrilldownTemplateId={currentDrilldownTemplateId}
          map={this.map} />
        <ShapeLayerPartial
          map={this.map}
          apiParams={apiParams}
          shapeTileUrl={getShapeTileUrl}
          currentDrilldownTemplateId={currentDrilldownTemplateId}
          shapeGroupId={shapeGroupId}
          selectedShapeIds={selectedShapeIds}
          selectedShapesExtent={selectedShapesExtent}
        >
        </ShapeLayerPartial>
        <PointAndStackPartial
          currentMapView={currentMapView}
          map={this.map}
          tileParams={apiParams}>
        </PointAndStackPartial>
        {isDrilldownVisualizationMap && shouldShowGeoCoderControl && !isComparisonEnabled &&
          showSearchInput &&
          <GeoCoderControl
            onHideSearchInput={onHideSearchInput}
            map={this.map} dropPin={this.dropPin} />}
        {this.renderDrawControl()}
        <PolygonPartial
          map={this.map}
          polygonsGeojson={polygonsGeojson}
          isDrilldownVisualizationMap={isDrilldownVisualizationMap}>
        </PolygonPartial>
      </div>
    );
  }

  render() {
    const { hasMapInitialized } = this.state;
    const { isAdvancedSearch, containerHeight, isComparisonMap } = this.props;

    let childContent = null;
    if (hasMapInitialized) {
      childContent = isAdvancedSearch ?
        this.renderSearchMapContent() :
        this.renderVisualizationMap();
    }

    const style = !_.isNil(containerHeight) ? { height: (containerHeight) } : {};

    return (
      <div className="map-container comparison-map">
        {!isComparisonMap && <div ref={el => this.loaderElement = el}></div>}
        <div className="map-instance" ref={el => this.mapElement = el} style={style}></div>
        {childContent}
      </div>
    );
  }
}

Map.propTypes = {
  isDrawingEnabled: PropTypes.bool,
  polygonGeoJson: PropTypes.object,
  advanceSearchTemplateId: PropTypes.string,
  selectedReportRowIds: PropTypes.array,
  reportPageData: PropTypes.object,
  advanceSearchMethod: PropTypes.string,
  comparisonPeriod: PropTypes.string,
  subjectData: PropTypes.object,
  dispatchToggleShapeIdsFilter: PropTypes.func,
  dispatchUpdateReportPageData: PropTypes.func,
  dispatchUpdateSelectedReportIds: PropTypes.func,
  subjectSearchField: PropTypes.object,
  updatePolygonFeatures: PropTypes.func
}

const mapDispatchToProps = {
  dispatchToggleShapeIdsFilter : toggleShapeIdsFilter,
  dispatchUpdateReportPageData : updateReportPageData,
  dispatchUpdateSelectedReportIds : updateSelectedReportIds
};

const mapStateToProps = (state) => {
  return {
    isDrawingEnabled: _.get(state, 'visualization.mapOptions.isDrawingEnabled'),
    polygonGeoJson: _.get(state, 'visualization.mapOptions.filteredGeojson'),
    advanceSearchTemplateId: _.get(state, 'advanceSearch.templateId'),
    selectedReportRowIds: _.get(state, 'advanceSearch.selectedReportRowIds'),
    reportPageData: _.get(state, 'advanceSearch.reportPageData'),
    advanceSearchMethod: _.get(state, 'advanceSearch.searchProperty.searchMethod'),
    subjectData: _.get(state, 'advanceSearch.subjectData'),
    subjectSearchField: _.get(state, 'advanceSearch.searchField')
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Map);
