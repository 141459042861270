import {
  getQuickFilterEntriesFromTemplate,
  getViewEntryQuickFilters
} from 'pages/drilldown/components/QuickFilterBar/helper';
import {
  getDefaultVisualizationView,
  getDefaultGroupByColumn,
} from 'common/config/visualizationConfiguration';
import {
  getDefaultVisualizationChartView,
  getDefaultDimensionField,
  getAvailableVisualizationType,
  isGeoLocationOrUrlColumn,
  getDefaultCompareYearRange
} from 'helpers/visualizationHelper';
import {
  GROUP_BY_NONE_ENTRY,
  AXIS_GRANULARITY_TYPES,
  VISUALIZATION_TYPES,
  DATE_COMPARISON_TYPE,
  OVERTIME_TIME_FRAME_OPTIONS,
  OVERTIME_VISUALIZATION_TYPES,
  COLLECTION_ID
} from 'appConstants';
import { getGroupByEntries } from 'common/config/templateConfiguration';
import { getDefaultSortOptionForTable } from 'modules/DetailsTable/TableHelper';
import { isShowDistributionChart } from 'helpers/templateHelper';
import {
  updateGlobalFilter,
  updateDateFilters,
  updateComparisonModeOn,
  updateComparisonType,
  updateComparisonDateRanges
} from 'actions/commonFiltersActions';
import {
  getGlobalFiltersFromTemplate, isPrivate
} from 'common/config/customerConfiguration';
import { getNewQuickFiltersAndGlobalFilters } from 'helpers/FilterHelper';
import { getCardId } from 'pages/embed/helper';
import {
  getOvertimeDimensionSortOrder
} from 'modules/visualization/LineChart/Helpers/overtimeHelper';
import {
  getAdditionalDateFiltersConfigs,
  isDateIgnored
} from 'pages/dashboard/components/Collections/collectionHelper';
import { showDateFilter } from 'common/config/viewConfiguration';
import {
  updateAxisGranularity,
  updateVisualizationDefaults,
  updateOvertimeDimensionSortOrder,
  updateOvertimeTimeFrame
} from 'actions/overtimeActions';
import {
  updateQuickFilters,
  updateDrilldownViewEntry,
  updateTemplateEntryChange,
  setCurrentVisualizationType,
  updateNewLabelCardId
} from 'actions/drilldownActions';

import {
  setDefaultDrilldownMapOptions,
  toggleShapeIdsFilter,
} from 'actions/mapOptionsActions';
import {
  updateTableSortColumns,
  updateShowLeafPage,
  updateShowTableViewOnDrilldown,
} from 'actions/tableActions';
import { updateCurrentBookmark } from 'actions/bookmarkActions';
import {
  setCurrentCardId,
  setAnalysisPageCollectionId,
  setAnalysisPageCollectionTagId
} from 'actions/dashboardActions';
import { getCollectionId } from './collectionIdHelper';

export const dispatchMetricSelection = (
  dispatch,
  templateEntry,
  viewEntry,
  drilldownEntry,
  commonFilters,
  globalFilters,
  isNewCard = false,
  userCollectionsWithCards,
  currentCollectionId,
  currentCollectionTagId,
  isMetricCardClick,
) => {
  const templateId = templateEntry.template_id;
  const quickFilters = getViewEntryQuickFilters(templateId, viewEntry);
  let defaultVizView = getDefaultVisualizationView(viewEntry);

  if (defaultVizView == 'none') {
    defaultVizView = getAvailableVisualizationType(
      templateId,
      'table',
      viewEntry
    );
  }

  const snapshotView = getDefaultVisualizationChartView(
    viewEntry,
    VISUALIZATION_TYPES.SNAPSHOT.type
  );
  const groupEntries = getGroupByEntries(templateId);
  const defaultSort = getDefaultSortOptionForTable(
    viewEntry,
    _.get(templateEntry, 'name', '')
  );
  let currentVisualizationType = defaultVizView;
  if (
    !isShowDistributionChart(viewEntry) &&
    defaultVizView === VISUALIZATION_TYPES.DISTRIBUTION.type
  ) {
    currentVisualizationType = VISUALIZATION_TYPES.SNAPSHOT.type;
  }
  let drilldownGroupByEntry = GROUP_BY_NONE_ENTRY;
  if (currentVisualizationType === VISUALIZATION_TYPES.SNAPSHOT.type) {
    const defaultGroupByColumn = getDefaultGroupByColumn(viewEntry);
    const groupByColumn = isGeoLocationOrUrlColumn(defaultGroupByColumn)
      ? _.get(groupEntries, '0.column')
      : defaultGroupByColumn;
    drilldownGroupByEntry = _.find(groupEntries, { column: groupByColumn });
  }

  const drilldownDimensionField = getDefaultDimensionField(
    templateEntry.dimension_entries,
    viewEntry
  );

  dispatch(updateDateFilters(commonFilters));
  const snapshotDefaultOptions = {
    currentDrilldownTemplateName: _.get(templateEntry, 'name'),
    currentDrilldownTemplateId: templateId,
    currentDrilldownViewEntry: viewEntry,
    currentDrilldownDimensionField: drilldownDimensionField,
    currentDrilldownGroupByEntry: drilldownGroupByEntry,
    currentSnapshotView: snapshotView,
    commonFilters,
  };
  const globalFilterConfigEntries = getGlobalFiltersFromTemplate(templateEntry);
  const quickFilterEntries = getQuickFilterEntriesFromTemplate(templateId, viewEntry);
  const { newQuickFilters, newCollectionFilters } = getNewQuickFiltersAndGlobalFilters({
    collectionFilters: _.cloneDeep(globalFilters),
    globalFilterConfigEntries,
    quickFilters: _.cloneDeep(quickFilters),
    quickFilterEntries,
    isCardClick: true
  });

  const overtimeChartView = getDefaultVisualizationChartView(
    _.get(drilldownEntry, 'currentDrilldownViewEntry'),
    VISUALIZATION_TYPES.OVERTIME.type
  );

  const cardId = getCardId(templateEntry, viewEntry);
  dispatch(setCurrentCardId(cardId));

  if (isMetricCardClick) {
    const isPublicApp = !isPrivate();
    const collectionId = getCollectionId(userCollectionsWithCards, cardId);
    if ((_.isEqual(currentCollectionId, COLLECTION_ID.ALL_METRICS))){
      dispatch(setAnalysisPageCollectionId(collectionId));
    }
    if(isPublicApp){
      dispatch(setAnalysisPageCollectionTagId(currentCollectionTagId));
    }
  }

  const dimensionSortOrder = getOvertimeDimensionSortOrder(viewEntry, overtimeChartView);
  const compareYearRanges = getDefaultCompareYearRange(
    viewEntry, commonFilters, templateId);
  const overtimeView = getDefaultVisualizationChartView(
    viewEntry,
    VISUALIZATION_TYPES.OVERTIME.type
  );
  const showComparisonMode = (_.isEmpty(compareYearRanges) ||
    currentVisualizationType !== VISUALIZATION_TYPES.OVERTIME.type ||
    overtimeView === OVERTIME_VISUALIZATION_TYPES.AREA.type);

  const cards = [{ templateEntry, viewEntry }];
  const additionalDateFilterConfigs = getAdditionalDateFiltersConfigs(cards);
  const isDateFilterEnabled = !isDateIgnored(viewEntry);
  const isDateFilterDisabled = (!showDateFilter(templateEntry, viewEntry) ||
    (_.isEmpty(additionalDateFilterConfigs) && !isDateFilterEnabled));

  if (showComparisonMode || isDateFilterDisabled) {
    // dispatch(updateComparisonModeOn(false));
  } else {
    dispatch(updateComparisonModeOn(true));
    dispatch(updateComparisonType(DATE_COMPARISON_TYPE.SAME_PERIOD));
    dispatch(updateComparisonDateRanges(compareYearRanges));
    dispatch(updateOvertimeTimeFrame(OVERTIME_TIME_FRAME_OPTIONS.YEAR_ON_YEAR));
  }
  dispatch(setCurrentVisualizationType(currentVisualizationType));
  dispatch(toggleShapeIdsFilter([], null));
  dispatch(updateAxisGranularity(AXIS_GRANULARITY_TYPES[0].value));
  dispatch(updateOvertimeDimensionSortOrder(dimensionSortOrder));
  dispatch(updateTemplateEntryChange(templateEntry));
  dispatch(updateDrilldownViewEntry(viewEntry));
  dispatch(updateQuickFilters(newQuickFilters));
  dispatch(setDefaultDrilldownMapOptions(templateId, viewEntry, true)); //Default Map Options
  dispatch(updateTableSortColumns(
    _.isEmpty(defaultSort) ? [] : [defaultSort]
  ));
  dispatch(updateShowLeafPage(false));
  dispatch(updateShowTableViewOnDrilldown(false));
  dispatch(updateVisualizationDefaults(snapshotDefaultOptions));
  dispatch(updateGlobalFilter(newCollectionFilters));
  dispatch(updateCurrentBookmark());

  if (isNewCard) {
    dispatch(updateNewLabelCardId(cardId));
  }
};