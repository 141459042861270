import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import ComparisonDateChipDropDown from './ComparisonDateChipDropDown';
import { DATE_COMPARISON_TYPE_OPTIONS } from 'appConstants';
import { applyFilters } from 'common/components/Filters/FilterDomHelper';
import ForgePopupOption from 'common/components/ForgePopupOption/ForgePopupOption';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';

const ComparisonDateFilterChip = (props) => {
  const defaultComparisonTypeOption = DATE_COMPARISON_TYPE_OPTIONS[1];
  const refCompareFilter = useRef(null);

  const [showFilterChip, toggleFilterChip] = useState(false);

  const {
    comparisonDateRanges,
    currentDrilldownTemplateId,
    comparisonType,
    onComparisonDateChange,
    dateRange,
    dateType,
    onCompareDateRangesChange,
    onRadarCompareDateRangesChange,
    isDashboardView,
    onComparePopUpToggle
  } = props;

  const handleApplyClick = (compareType, dateRanges) => {
    if (_.isUndefined(onRadarCompareDateRangesChange)){
      onComparisonDateChange(compareType);
      onCompareDateRangesChange(dateRanges);
    } else {
      onRadarCompareDateRangesChange({comparisonPeriod: compareType, dateRange: dateRanges[0], dateType});
    }
    toggleFilterChip(false);
  }

  const onKeyDownToggleDropdown = (e) => {
    if (isEnterButtonPressed(e)) {
      toggleFilterChip(!showFilterChip)
    }
  }

  const onChangeToggleFilterChip = (showFilterChip) => {
    applyFilters();
    toggleFilterChip(showFilterChip)
  }

  const onPopUpToggle = (toggle) => {
    toggleFilterChip(toggle);
    if(!_.isUndefined(onComparePopUpToggle)){
      onComparePopUpToggle(toggle)
    }
  }

  const renderForgePopupContent = () => {
    return (
      <ForgePopupOption
        showPopup={showFilterChip}
        targetPopupRef={refCompareFilter}
        closeFilterChip={onChangeToggleFilterChip}
        isIncludeOutsideClose={true}>
        <ComparisonDateChipDropDown
          className={''}
          dateType={dateType}
          templateId={currentDrilldownTemplateId}
          comparisonType={comparisonType}
          primaryDateRange={dateRange}
          comparisonDateRanges={comparisonDateRanges}
          onApplyClick={handleApplyClick}
          onCompareDateRangesChange={onCompareDateRangesChange}
          onCancelClick={() => onPopUpToggle(false)}
          isDashboardView={isDashboardView}
        />
      </ForgePopupOption>
    )
  }

  const title = (_.find(DATE_COMPARISON_TYPE_OPTIONS, (option) => {
    return (option['value'] == comparisonType);
  }) || defaultComparisonTypeOption)['name'];
  const className = "color-theme-primary cursor-pointer d-flex align-items-center gap-6";
  return (
    <>
      <div ref={refCompareFilter}>
        <>
          <div
            className={`forge-typography--body1 ${className}`}
            tabIndex="0"
            onKeyDown={(e) => onKeyDownToggleDropdown(e)}
            onClick={() => onPopUpToggle(!showFilterChip)}>
            <div className='text-nowrap'>{title}</div>
            <i class="icons-chevron-down"></i>
          </div>
          {showFilterChip &&
            renderForgePopupContent()
          }
        </>
      </div>
    </>
  )
}

ComparisonDateFilterChip.propTypes = {
  currentDrilldownTemplateId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  comparisonType: PropTypes.string,
  comparisonDateRanges: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string
    })),
  dateRange: PropTypes.shape({
    startDate: PropTypes.string,
    endDate: PropTypes.string
  }),
  onComparisonDateChange: PropTypes.func,
  onCompareDateRangesChange: PropTypes.func,
  onRadarCompareDateRangesChange: PropTypes.func,
  onComparePopUpToggle: PropTypes.func,
  dateType: PropTypes.string,
  isDashboardView: PropTypes.bool,
}

export default ComparisonDateFilterChip;
