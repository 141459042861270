import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';

import LoadingSpinner from 'common/components/LoadingSpinner';
import { getBookMarks } from 'common/api/bookmarksApi';
import ForecastIndex from './ForecastIndex';
import ForecastCards from './ForecastCards';
import { updateCurrentBookmark } from 'actions/bookmarkActions';
import {
  commonFiltersPropTypes,
  userPropTypes,
} from 'common/propTypes';
import { FORECAST_PAGE_TYPES, OVERTIME_VISUALIZATION_TYPES, VISUALIZATION_TYPES } from 'appConstants';
import { getEligibleForecastMetrics } from 'common/config/customerConfiguration';
import {
  updateToDefaultSetting,
  updateForecastDateRange,
  updateForecastSelectedMetric,
  updateForecastTemplateId,
  updateFutureForecastDateRange,
  updateForecastPageView,
  updateForecastOptions
} from 'actions/forecastingActions';
import { updateAxisGranularity, updateOvertimeChartView } from 'actions/overtimeActions';
import {
  getAxisGranularity,
  getDefaultFutureForecastRange,
  getForecastDateRange
} from 'pages/Forecasting/ForecastHelper';
import {
  setCurrentVisualizationType
} from 'actions/drilldownActions';
import { updateDateRange } from 'actions/commonFiltersActions';
import ForecastMetricWiseList from './ForecastMetricWiseList';
class Forecasting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isCardsLoading: false,
      savedForecasts: [],
      isBack: false
    }
    this.abortFetchController = new AbortController();
  }

  componentDidMount() {
    const { currentForecastPage } = this.props;
    if (
      currentForecastPage == FORECAST_PAGE_TYPES.SELECT_METRIC_PAGE
      || currentForecastPage == FORECAST_PAGE_TYPES.SAVED_FORECASTS_PAGE
      ) {
      this.fetchBookMarks();
    }
  }

  componentDidUpdate(prevProps) {
    const { currentForecastPage } = this.props;
    const isShowcurrentForecastViewChange = !_.isEqual(currentForecastPage, prevProps.currentForecastPage);

    const forecastPages = [
      FORECAST_PAGE_TYPES.SELECT_METRIC_PAGE,
      FORECAST_PAGE_TYPES.SAVED_FORECASTS_PAGE
    ]
    if (isShowcurrentForecastViewChange) {
      if (_.includes(forecastPages, currentForecastPage)) {
        this.fetchBookMarks();
      }
    }
  }

  fetchBookMarks = () => {
    const eligibleForecastMetrics = getEligibleForecastMetrics();
    const currentForecastPage = this.props.currentForecastPage;
    const selectedForecastMetric = this.props.selectedForecastMetric;
    this.setState({ isLoading: true });
    this.abortFetchController.abort();
    this.abortFetchController = new AbortController();

    getBookMarks({ signal: this.abortFetchController.signal })
      .then((response) => response.json())
      .then((response) => {
        const savedForecasts = _.orderBy(
          _.filter(response, (data) => data['is_forecast']),
          'updated_at', 'desc');
        this.defaultMetricSelector(savedForecasts);
        if (currentForecastPage == FORECAST_PAGE_TYPES.SAVED_FORECASTS_PAGE) {
          const modifiedSavedForecasts = _.filter(savedForecasts, (savedForecast) => {
            const savedForecastName = _.get(
                savedForecast, ['forecastOptions', 'selectedForecastMetric', 'name']
              )
            return selectedForecastMetric.name == savedForecastName;
          });
          if (_.isEmpty(modifiedSavedForecasts)) {
            this.setState({
              isLoading: false
            })
            this.createNewForecast();
          } else {
            this.setState(
              {
                isLoading: false,
                savedForecasts: modifiedSavedForecasts
              }
            )
          }
        } else if (_.size(eligibleForecastMetrics) == 1) {
          const modifiedSavedForecasts = _.filter(savedForecasts, (savedForecast) => {
            const savedForecastName = _.get(
                savedForecast, ['forecastOptions', 'selectedForecastMetric', 'name']
              )
            return eligibleForecastMetrics[0].name == savedForecastName;
          });
          this.setState(
            {
              isLoading: false,
              savedForecasts: modifiedSavedForecasts
            });
          if (_.isEmpty(modifiedSavedForecasts)) {
            this.props.dispatchUpdateForecastPageView(FORECAST_PAGE_TYPES.CREATE_FORECASTS_PAGE);
          }
        } else {
          this.setState(
            {
              isLoading: false,
              savedForecasts
            });
        }
      })
      .catch((error) => {
        console.log('Error on fetching bookmarks', error);
        this.setState({
          isLoading: false,
          savedForecasts: []
        });
      });
  };

  // Selecting the first metric as metric are sorted by Bookmark,
  // Default is set after the bookmarks have been fetched.
  defaultMetricSelector = (savedForecasts) => {
    const {
      dispatchUpdateForecastTemplateId,
      dispatchUpdateForecastSelectedMetric,
      selectedForecastTemplateId,
      selectedForecastMetric
    } = this.props;
    if (_.isEmpty(selectedForecastTemplateId) && _.isEmpty(selectedForecastMetric)){
      const firstForecastMetrics = _.first(getEligibleForecastMetrics(savedForecasts));
      dispatchUpdateForecastSelectedMetric(firstForecastMetrics);
      dispatchUpdateForecastTemplateId(_.get(firstForecastMetrics, 'template_id'));
    }
  };

  onCardClick = (cardType, cardConfig) => {
    this.props.dispatchForecastOptions(cardConfig);
  }

  createNewForecast = () => {
    const viewEntry = this.props.selectedForecastMetric;
    const templateId = this.props.selectedForecastTemplateId;
      this.props.dispatchUpdateToDefaultSetting();
      this.updateForecastDateRange(templateId);
      this.props.dispatchUpdateCurrentBookmark();
      const forecastOptions = {
        currentForecastPage: FORECAST_PAGE_TYPES.CREATE_FORECASTS_PAGE,
        selectedForecastMetric: viewEntry,
        selectedForecastTemplateId: templateId
      }
      this.props.dispatchUpdateForecastOptions(forecastOptions);
      this.props.dispatchUpdateChartView(OVERTIME_VISUALIZATION_TYPES.TIMELINE.type);
      this.props.dispatchSetCurrentVisualizationType(VISUALIZATION_TYPES.OVERTIME.type);
    }

  onDeleteReloadSavedForecasts = (cardEntry) => {
    const clonedSavedForecasts = _.cloneDeep(this.state.savedForecasts);
    _.remove(clonedSavedForecasts, cardEntry);
    if (_.isEmpty(clonedSavedForecasts)) {
      this.props.dispatchUpdateForecastPageView(FORECAST_PAGE_TYPES.SELECT_METRIC_PAGE);
    }
    this.setState({
      savedForecasts: clonedSavedForecasts
    })
  }

  updateForecastDateRange = (templateId) => {
    const { minDatesTemplateForForecast } = this.props;
    let newDateRanges = getForecastDateRange({
      templateId,
      minDatesTemplateForForecast
    });
    const defaultFutureForecastRange = getDefaultFutureForecastRange();
    this.props.dispatchUpdateFutureForecastDateRange(defaultFutureForecastRange);

    if (!_.isEmpty(newDateRanges)) {
      const updateDateRange = {
        dateRange: _.cloneDeep(newDateRanges)
      }

      this.props.dispatchUpdateDateRange(newDateRanges);
      this.props.dispatchUpdateForecastDateRange(updateDateRange);
      const axisGranularity = getAxisGranularity(newDateRanges);
      this.props.dispatchUpdateAxisGranularity(axisGranularity);
    }
  }

  updateForeCastCards = (savedForecasts) => {
    this.setState({
      savedForecasts: savedForecasts,
    })
    if(_.isEmpty(savedForecasts)) {
      this.createNewForecast();
    } else {
      this.props.dispatchUpdateForecastPageView(FORECAST_PAGE_TYPES.SAVED_FORECASTS_PAGE);
    }
  }

  handleBackToIndexPage = () => {
    const eligibleForecastMetrics = getEligibleForecastMetrics();
    if (_.size(eligibleForecastMetrics)> 1) {
      this.props.dispatchUpdateForecastPageView(FORECAST_PAGE_TYPES.SELECT_METRIC_PAGE);
    } else {
      this.props.dispatchUpdateForecastPageView(FORECAST_PAGE_TYPES.SAVED_FORECASTS_PAGE);
    }
  }

  renderIndexPage () {
    const { currentForecastPage } = this.props;
    const { savedForecasts } = this.state;
    const metricWiseSavedForecasts = _.groupBy(savedForecasts, (savedForecast) => {
      return _.get(savedForecast, ['forecastOptions', 'selectedForecastMetric', 'name'])
    })
    const eligibleForecastMetrics = getEligibleForecastMetrics(savedForecasts);

    if (_.size(eligibleForecastMetrics) > 1 &&
        currentForecastPage == FORECAST_PAGE_TYPES.SELECT_METRIC_PAGE) {
      return(
        <ForecastMetricWiseList
          eligibleForecastMetrics={eligibleForecastMetrics}
          metricWiseSavedForecasts={metricWiseSavedForecasts}
          updateForeCastCards={this.updateForeCastCards}
          handleBackButtonClick={() => this.setState({ isBack: true})}
        />
      )
    } else if (
      !_.isEmpty(savedForecasts) &&
      (currentForecastPage == FORECAST_PAGE_TYPES.SELECT_METRIC_PAGE)) {
      this.props.dispatchUpdateForecastPageView(FORECAST_PAGE_TYPES.SAVED_FORECASTS_PAGE)
    }
  }
  renderForecastCardsPage() {
    const {
      currentForecastPage, commonFilters, currentUser, templateEntries, selectedForecastMetric } = this.props;
    const { savedForecasts } = this.state;

    if (currentForecastPage == FORECAST_PAGE_TYPES.SAVED_FORECASTS_PAGE && !_.isEmpty(savedForecasts)) {
      return (<ForecastCards
        savedForecasts={savedForecasts}
        commonFilters={commonFilters}
        currentUser={currentUser}
        templateEntries={templateEntries}
        onCardClick={this.onCardClick}
        handleBackButtonClick={() => this.setState({ isBack: true})}
        handleCreateNewForecast={() => this.createNewForecast()}
        onDeleteReloadSavedForecasts={this.onDeleteReloadSavedForecasts}
        handleBackToIndexPage={this.handleBackToIndexPage}
        selectedForecastMetric={selectedForecastMetric}
    />);
    }
  }

  renderForeCastPage() {
    const { currentForecastPage } = this.props;
    if (currentForecastPage == FORECAST_PAGE_TYPES.CREATE_FORECASTS_PAGE) {
      return (
      <ForecastIndex
        savedForecasts={this.state.savedForecasts}
      />
      );
    }
  }

  renderBack = () => {
    const origin = _.get(window, 'forecastFromPage', '/');
    return (
      <Redirect push to={origin} />
    )
  }

  render() {
    const { isLoading, isBack } = this.state;

    if (isBack) {
      return this.renderBack();
    }

    return (
      <div>
        <LoadingSpinner isLoading={isLoading} />
        {this.renderIndexPage()}
        {this.renderForecastCardsPage()}
        {this.renderForeCastPage()}
      </div>
    );
  }
}

Forecasting.propTypes = {
  currentForecastPage: PropTypes.string,
  dispatchUpdateForecastPageView: PropTypes.func,
  commonFilters: commonFiltersPropTypes,
  currentUser: userPropTypes,
  dispatchForecastOptions: PropTypes.func,
  templateEntries: PropTypes.array,
  minDatesTemplateForForecast: PropTypes.array,
  selectedForecastMetric: PropTypes.object,
  dispatchUpdateHistoryTabHash: PropTypes.func,
  dispatchUpdateUserMenu: PropTypes.func,
  dispatchDrillDownEntry: PropTypes.func,
  dispatchUpdateDateRange: PropTypes.func,
  dispatchUpdateChartView: PropTypes.func,
  dispatchUpdateForecastDateRange: PropTypes.func,
  dispatchUpdateForecastTemplateId: PropTypes.func,
  dispatchUpdateForecastSelectedMetric: PropTypes.func,
  dispatchUpdateFutureForecastDateRange: PropTypes.func,
  dispatchUpdateAxisGranularity: PropTypes.func,
  dispatchSetCurrentVisualizationType: PropTypes.func,
  dispatchUpdateCurrentBookmark: PropTypes.func,
  dispatchUpdateToDefaultSetting: PropTypes.func,
  dispatchUpdateForecastOptions: PropTypes.func,
  selectedForecastTemplateId: PropTypes.any
};

function mapStateToProps(state) {
  return {
    currentForecastPage: _.get(state, 'forecasting.currentForecastPage', ''),
    commonFilters: _.get(state, 'commonFilters', {}),
    currentUser: _.get(state.currentUser, 'user', {}),
    templateEntries: _.get(state, 'configurations.template_entries', []),
    minDatesTemplateForForecast: _.get(state, 'forecasting.minDatesTemplateForForecast', []),
    selectedForecastMetric: _.get(state, ['forecasting', 'selectedForecastMetric'], {}),
    selectedForecastTemplateId: _.get(state, ['forecasting', 'selectedForecastTemplateId'], '')
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateToDefaultSetting: () => {
      dispatch(updateToDefaultSetting());
    },
    dispatchUpdateCurrentBookmark: () => {
      dispatch(updateCurrentBookmark());
    },
    dispatchUpdateForecastPageView: (currentForecastPage) => {
      dispatch(updateForecastPageView(currentForecastPage));
    },
    dispatchForecastOptions: (bookmarkConfig) => {
      const { id, forecastOptions } = bookmarkConfig;
      dispatch(updateForecastOptions({
        ...forecastOptions,
        bookmarkId: id,
        currentForecastPage: FORECAST_PAGE_TYPES.CREATE_FORECASTS_PAGE,
      }));
    },
    dispatchUpdateChartView: (selectedView) => {
      dispatch(updateOvertimeChartView(selectedView));
    },
    dispatchUpdateForecastDateRange: (dateRange) => {
      dispatch(updateForecastDateRange(dateRange));
    },
    dispatchUpdateForecastSelectedMetric: (forecastViewEntry) => {
      dispatch(updateForecastSelectedMetric(forecastViewEntry));
    },
    dispatchUpdateForecastTemplateId: (templateId) => {
      dispatch(updateForecastTemplateId(templateId));
    },
    dispatchUpdateFutureForecastDateRange: (futureDate) => {
      dispatch(updateFutureForecastDateRange(futureDate));
    },
    dispatchUpdateAxisGranularity: (axisGranularity) => {
      dispatch(updateAxisGranularity(axisGranularity));
    },
    dispatchSetCurrentVisualizationType: (visualizationType) => {
      dispatch(setCurrentVisualizationType(visualizationType))
    },
    dispatchUpdateDateRange: (dateRange) => {
      dispatch(updateDateRange(dateRange));
    },
    dispatchUpdateForecastOptions: (forecastOptions) => {
      dispatch(updateForecastOptions(forecastOptions));
    }
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Forecasting));