import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { ForgeCard } from '@tylertech/forge-react';

import * as commonPropTypes from 'common/propTypes';
import NoDataPage from './NoDataPage';
import Header from './Header';
import AccuracyTable from './AccuracyTable';
import AccuracyChart from './AccuracyChart/AccuracyChart';
import Legend from './AccuracyChart/Legend';
import PlotlyTooltip from 'modules/PlotlyTooltip';
import LoadingSpinner from 'common/components/LoadingSpinner';
import { getForecastAccuracy } from 'common/api/drilldown';
import { getApiParams } from 'helpers/apiParamsHelper';
import { fetchApiData } from 'helpers/apiResponseHelper';
import { getTipsyPosition, getMargins } from 'modules/PlotlyTooltip/helper';
import { getFormattedNumberValue } from 'helpers/chartDataHelper';
import { PLOTLY_HOVER_DEBOUNCE_WAIT_TIME } from 'modules/visualization/constants';
import {
  OVERTIME_VISUALIZATION_TYPES,
} from 'appConstants';
class ForecastAccuracy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      forecastAccuracyData: {}
    };
    this.abortFetchController = new AbortController();
  }

  componentDidUpdate(prevProps) {
    const {
      currentForecastDateRange,
      forecastModelOptions,
      futureForecastDateRange,
      projectionAdjustedValues
    } = this.props;
    const adjustedValues = this.getChangedAdjustedValues(projectionAdjustedValues);
    const previousAdjustedValues = this.getChangedAdjustedValues(prevProps.projectionAdjustedValues || []);
    const isCurrentDateRangeChanged = !_.isEqual(
      prevProps.currentForecastDateRange, currentForecastDateRange);
    const isFutureDateRangeChanged = !_.isEqual(
      prevProps.futureForecastDateRange, futureForecastDateRange);
    const isModelChanged = !_.isEqual(
      prevProps.forecastModelOptions, forecastModelOptions);
    const isAdjustedValueChanged = !_.isEqual(adjustedValues, previousAdjustedValues);
    if(
      (isCurrentDateRangeChanged || isModelChanged || isFutureDateRangeChanged || isAdjustedValueChanged) &&
      this.isShowAccuracy()
    ) {
      this.fetchData();
    }
  }

  componentDidMount() {
    this.fetchData();
    if (this.popupContainer) {
      this.plotlyTooltip = new PlotlyTooltip(this.popupContainer);
    }
  }

  componentWillUnmount() {
    this.abortFetchController.abort();
  }

  getChangedAdjustedValues = (projectionAdjustedValues) => {
    return _.compact(_.map(projectionAdjustedValues, (entry) => {
      if(!_.isEmpty(_.get(entry, 'adjustValue', ''))){
        return {period: entry.period, value: entry.adjustValue}
      } else {
        return null;
      }
    }));
  }

  fetchData = async () => {
    const {
      currentForecastDateRange,
      forecastModelOptions,
      projectionAdjustedValues,
      currentDrilldownTemplateId,
      currentDrilldownViewEntry
    } = this.props;
    this.abortFetchController.abort();
    this.abortFetchController = new AbortController();
    this.setState({ isLoading: true });
    const options = {
      commonFilters: {...currentForecastDateRange},
      mapOptions: {},
      drilldown: {currentDrilldownTemplateId, currentDrilldownViewEntry }
    };
    const apiParams = getApiParams(options, {});
    const currentProjections = _.map(forecastModelOptions, 'type');
    const projectionValues = _.map(forecastModelOptions, 'value');

    const totalAdjustedValues = this.getChangedAdjustedValues(projectionAdjustedValues);
    const queryParams =  _.merge({},
      apiParams,
      {
        'selected_forecast_type': JSON.stringify(currentProjections),
        'adjusted_values': JSON.stringify(totalAdjustedValues),
        'is_forecast_accuracy': true,
        'projection_percent': _.compact(projectionValues)
      }
    );
    try{
      const forecastAccuracyUrl = getForecastAccuracy(queryParams);
      const response = await fetchApiData(forecastAccuracyUrl, this.abortFetchController);
      if (this.popupContainer) {
        this.plotlyTooltip = new PlotlyTooltip(this.popupContainer);
      }
      this.setState({
        isLoading: false,
        forecastAccuracyData: response
      });
    }catch(e){
      this.setState({
        isLoading: false,
        forecastAccuracyData: {}
      });
    }
  }

  isShowAccuracy  = () => {
    const { currentForecastDateRange } = this.props;
    const { startDate, endDate } = currentForecastDateRange['dateRange'];
    const diffMonths = moment(endDate).diff(startDate, 'month');
    return diffMonths > 36
  }

  renderSpinner() {
    const { isLoading } = this.state;

    return (
      <LoadingSpinner isLoading={isLoading} />
    );
  }

  onPlotlyHover = _.throttle((event) => {
    this.setMouseCursor('inherit');
    const point = event.points[0];
    const yaxis = point.yaxis;
    const xaxis = point.xaxis;
    const margins = getMargins(this.chartContainer);

    const anchor = {
      x: xaxis.d2p(point.x) + margins.x - 6,
      y: yaxis.d2p(yaxis.range[0])  + margins.y
    };
    const position = getTipsyPosition(anchor, this.chartContainer);
    const tipsyPosition = position == 'left' ? 'left' : 'bottom'  ;

    let lineChartPopupConfigs = [{
      isNeedBottomPadding: true,
      viewMode: 'large',
      position: tipsyPosition,
      anchor,
      chartContainer: this.chartContainer,
      html: this.getFlyoutContent(event.points)
    }];
    this.plotlyTooltip.showPopups(lineChartPopupConfigs);
  }, PLOTLY_HOVER_DEBOUNCE_WAIT_TIME);

  onPlotlyUnhover = () => {
    this.setMouseCursor('inherit');
    this.plotlyTooltip.hidePopups();
  };

  setMouseCursor(cursor) {
    if (this.chartContainer.querySelector('g.draglayer')) {
      this.chartContainer.querySelector('g.draglayer').style['cursor'] = cursor;
    }
  }

  getFlyoutContent = (points) => {
    const { currentDrilldownViewEntry } = this.props;
    const orderedPoints = _.orderBy(points, 'data.meta.dimension', 'desc');
    const groupedPoints = _.groupBy(orderedPoints, (point) => _.get(point, 'data.meta.dimension'));
    const content = _.map(groupedPoints, (groupedPoint, category) =>{
      const point = _.first(groupedPoint);
      const dimension = category == 'Total' ? 'Historical Data' : category;
      return (
        <tr key={category}>
          <td className="text-left">
              {dimension} : {getFormattedNumberValue(point.y, currentDrilldownViewEntry)}
          </td>
        </tr>
      )
    });
    return (
      <table className="flyout-table">
        <tbody>
          {content}
        </tbody>
      </table>
    )
  }

  renderTableAndChart() {
    const {forecastAccuracyData} = this.state;
    const {
      forecastModelOptions,
      currentDrilldownViewEntry,
      currentForecastDateRange,
      currentChartView,
      isSmallView
    } = this.props;
    const dateRange = _.get(currentForecastDateRange, 'dateRange');
    const extraPlotlyParams = {
      onHover: this.onPlotlyHover,
      onUnhover: this.onPlotlyUnhover
    };
    return (
      <>
        {this.renderSpinner()}
        <div>
          <AccuracyTable
            forecastAccuracyData={forecastAccuracyData}
            forecastModelOptions={forecastModelOptions}
            viewEntry={currentDrilldownViewEntry}
          ></AccuracyTable>

        </div>
        <div
          className='position-relative'
          ref={(ref) => this.chartContainer = ref}
          onMouseOut={this.onContainerMouseOut}>
          <div
            className="forge-typography--subtitle2 mt-5">
            Performance over past 12 months </div>
            <div className='row p-0'>
            <div className='col-md-9 mb-3 mb-lg-0'>
              <AccuracyChart
                forecastAccuracyData={forecastAccuracyData}
                forecastModelOptions={forecastModelOptions}
                viewEntry={currentDrilldownViewEntry}
                dateRange={dateRange}
                currentChartView={currentChartView}
                extraPlotlyParams={extraPlotlyParams}
                isSmallView={isSmallView}
              ></AccuracyChart>
              <div className="popup-container" ref={(ref) => this.popupContainer = ref}></div>
            </div>
            <div className='col-md-3'>
              <div className='border rounded-lg'>
                <div className='legend-heading pl-2 pt-2'>Legend</div>
                <Legend legendItems={forecastModelOptions}></Legend>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  renderBody() {
    if (this.isShowAccuracy()) {
      return this.renderTableAndChart();
    } else {
      return <NoDataPage></NoDataPage>
    }
  }



  render() {
    const { forecastModelOptions } = this.props;

    if ( forecastModelOptions.length == 0 ) {
      return null;
    }

    return (
      <div className="forecast-accuracy">
        <ForgeCard>
          <Header></Header>
          <div>
            { this.renderBody()}
          </div>
        </ForgeCard>
      </div>
    )
  }
}

ForecastAccuracy.propTypes = {
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  currentDrilldownViewEntry: commonPropTypes.viewEntryPropTypes,
  axisGranularity: PropTypes.string,
  currentChartView: PropTypes.string,
  compareToDateRange: PropTypes.object,
  futureForecastDateRange: PropTypes.object,
  currentForecastDateRange: PropTypes.object,
  forecastModelOptions: PropTypes.array,
  forecastPrepareDataTime: PropTypes.string,
  projectionAdjustedValues: PropTypes.array,
  isSmallView: PropTypes.bool,
}

function mapDispatchToProps() {
  return {
  }
}

function mapStateToProps(state) {
  return {
    currentDrilldownViewEntry: _.get(state, 'forecasting.selectedForecastMetric', {}),
    currentDrilldownTemplateId: _.get(state, 'forecasting.selectedForecastTemplateId', ''),
    currentForecastDateRange: _.get(state, 'forecasting.currentForecastDateRange', {}),
    forecastModelOptions: _.cloneDeep(_.get(state, 'forecasting.forecastModelOptions', [])),
    forecastPrepareDataTime: _.get(state, 'forecasting.forecastPrepareDataTime', ''),
    futureForecastDateRange: _.get(state, 'forecasting.futureForecastDateRange', {}),
    axisGranularity: _.get(state, 'forecasting.axisGranularity'),
    currentChartView: _.get(
      state, 'visualization.overtime.currentChartView', OVERTIME_VISUALIZATION_TYPES.TIMELINE.type),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForecastAccuracy);
