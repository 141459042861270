import _ from 'lodash';

import $ from 'jquery';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { stringOrObjectProps } from 'common/propTypes';
import PropTypes from 'prop-types';

import { ForgeButton } from '@tylertech/forge-react';
import * as commonPropTypes from 'common/propTypes';
import LoadingSpinner from 'common/components/LoadingSpinner';
import BookmarkEmailOptions from './BookmarkEmailOptions';

import { getCurrentVizBasedChartType } from 'helpers/visualizationHelper';
import { trackEvent } from 'helpers/eventTracking';
import { getNotifyText, getBookmarkDefaultParams } from '../bookmarkHelper';
import Recipients from 'pages/SubscriptionsManager/components/Recipients';
import { createBookmark, deleteBookmark } from 'common/api/bookmarksApi';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import {
  alertUndoMessage,
  alertUndoErrorMessage,
  subscriptionCreatedMessage,
  subscriptionEmailErrorMessage
} from 'helpers/toastMessages';
import { alertConfirmationSendEmail } from 'common/api/commonApi';
import { userPropTypes } from 'common/propTypes';
import {
  EMAIL_STRATEGY_OPTIONS,
  EMAIL_STRATEGY_TYPES,
  WEEK_NUMBER_OPTIONS,
  WEEKDAYS_OPTIONS,
  SUBSCRIPTION_FREQUENCY_OPTIONS,
  DEFAULT_TIME,
  MONTHLY_REGULARITY_TYPES,
  MONTH_DAY_OPTIONS,
  TIMEZONE_DEFAULT_ALERT,
  DATE_FORMAT
} from 'appConstants';
import { dateWithTimeZone } from 'helpers/dateHelper';
import WatchCount from 'common/components/WatchCount';
import {
  alertEmailDeliveryTimeZone,
  enableTemplateAndMetricPermission } from 'common/config/customerConfiguration';
import { isManageCollections } from 'pages/dashboard/components/ManageCollection/collaboratorHelper';

class BookmarkNotifyButton extends Component {
  constructor(props, context) {
    super(props, context);
    const { currentBookmarkId } = props;
    this.state = {
      showNotifyDropDown: false,
      description: "",
      isLoading: false,
      isSaveState: !_.isEmpty(currentBookmarkId),
      currentBookmark: {},
      showNotifyEmailOptionDropDown: false,
      isMonthlyFrequency: false,
      isRecipientsEmailError: false,
      recipientsEmails: [],
      updateWatchCount: false,
    };
    this.abortFetchController = new AbortController();
    this.notifyTimer = null;
  }

  componentDidMount() {
    // this.fetchBookmark();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    const { currentDrilldownViewEntry } = this.props;
    if (!_.isEqual(prevProps.currentDrilldownViewEntry, currentDrilldownViewEntry)) {
      this.setState({ recipientsEmails: [] });
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    const isClickWithinCalendarPicker = $(e.target).closest("forge-calendar").length > 0;
    if (this.NotifyButtonRef && !this.NotifyButtonRef.contains(e.target) && !isClickWithinCalendarPicker) {
      this.setState({
        showNotifyDropDown: false,
        showNotifyEmailOptionDropDown: false,
        isRecipientsEmailError: false,
        recipientsEmails: [],
      });
    }
  }

  setTrackEvent = () => {
    if (!_.isEmpty(this.state.currentBookmark)) {
      const emailStrategy = _.get(this.state.currentBookmark, 'email_strategy');
      this.alertConfirmationEmail();
      if (emailStrategy === EMAIL_STRATEGY_TYPES.ON_SCHEDULE) {
        trackEvent('confirm_metric_subscribe');
      } else {
        trackEvent('confirm_metric_alert');
      }
    }
  }

  enableNotifyModalTimeout = () => {
    this.notifyTimer = setTimeout(() => {
      this.setState({ showNotifyDropDown: false });
      this.setTrackEvent();
    }, 10000);
  }

  cancelNotifyTimeOut = () => {
    clearInterval(this.notifyTimer)
  }

  getCurrentDimensionTotal = () => {
    const { metricTotalData, currentDrilldownViewEntry } = this.props;
    const totalValue = _.get(metricTotalData, 'total', 0);
    const precision = _.get(currentDrilldownViewEntry, 'precision');

    return Number(totalValue).toFixed(precision);
  }

  alertConfirmationEmail = () => {
    const { currentBookmark } = this.state;
    const alert_params = {
      name: _.get(currentBookmark, "name"),
      bookmark_id: _.get(currentBookmark, "id"),
      include_image: _.get(currentBookmark, "include_image"),
      shared_url: window.location.href
    }

    this.setState({ isLoading: true });
    toast.success(subscriptionCreatedMessage);
    alertConfirmationSendEmail(alert_params)
      .then((response) => {
        this.setState({ isLoading: false });
        if (response.status === 500) {
          toast.error(subscriptionEmailErrorMessage);
          toast.error(`Error while creating alert`);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        console.error(err); // eslint-disable-line no-console
      });
  };

  // TODO: Move this to helper
  getBookmarkParams = () => {
    const currentDimensionTotal = this.getCurrentDimensionTotal();
    const { description } = this.state;
    const {
      currentBookmarkName,
      plotlyChart,
      currentDrilldownViewEntry
    } = this.props;

    let name = currentBookmarkName;
    if (_.isEmpty(currentBookmarkName)) {
      const chart = plotlyChart();
      if (chart.querySelector('.visualization-title .viz-title')) {
        name = chart.querySelector('.visualization-title .viz-title').innerText;
      } else {
        name = _.get(currentDrilldownViewEntry, 'name');
      }

    }

    return getBookmarkDefaultParams(
      this.props,
      {
        name: name + ' alert',
        email_strategy: EMAIL_STRATEGY_OPTIONS[1].type,
        email_options: {
          emailSendingTime: DEFAULT_TIME,
          frequency: _.get(SUBSCRIPTION_FREQUENCY_OPTIONS, '1.value'),
          frequencyInterval: 1,
          timeZone: TIMEZONE_DEFAULT_ALERT[alertEmailDeliveryTimeZone],
          monthlyWeekNumber: _.get(WEEK_NUMBER_OPTIONS, '0.value'),
          emailSendingDays: [_.get(WEEKDAYS_OPTIONS, '1.day')],
          startDate:  dateWithTimeZone().format(DATE_FORMAT),
          regularityType: MONTHLY_REGULARITY_TYPES.WEEK,
          timePeriodDay: _.get(MONTH_DAY_OPTIONS, '0.value')
        },
        currentDimensionTotal,
        send_alerts: true,
        description
      }
    );
  }

  handleDeleteBookmark = () => {
    const { currentBookmark } = this.state;
    const bookmarkId = _.get(currentBookmark, 'id');
    this.setState({ isLoading: true });

    deleteBookmark(bookmarkId)
      .then((response) => {
        this.setState({
          isLoading: false,
          showNotifyDropDown: false,
          currentBookmark: {},
          showNotifyEmailOptionDropDown: false
        });
        trackEvent('undo_metric_subscribe');
        if (response.ok) {
          toast.success(alertUndoMessage);
        } else {
          toast.error(alertUndoErrorMessage);
        }
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toast.error(alertUndoErrorMessage);
        console.error(err); // eslint-disable-line no-console
      });
  }

  handlePressedUndoWatch = (e) => {
    if (isEnterButtonPressed(e)) {
      this.handleNotifyClick();
    }
  }

  handleNotifyClick = () => {
    const params = {
      shareEmails: [],
      bookmark: this.getBookmarkParams(),
      shareFieldInputs: {},
      include_current_user: true
    };
    const { currentVisualizationType, onLoad } = this.props;

    this.setState({ isLoading: true, updateWatchCount: false });
    onLoad(true);
    createBookmark(params).
      then(response => response.json()).
      then((response) => {
        onLoad(false);
        this.setState({
          isLoading: false,
          showNotifyEmailOptionDropDown: false,
          isMonthlyFrequency: false,
          showNotifyDropDown: true,
          updateWatchCount: true,
          currentBookmark: _.get(_.values(response), '[0]', response)
        });
        this.enableNotifyModalTimeout();
      }).
      catch((err) => {
        this.setState({ isLoading: false });
        console.error(err);   // eslint-disable-line no-console
      }).
      then(() => {
        trackEvent('initiate_metric_subscribe', {
          visualizationType: currentVisualizationType,
          ..._.pick(params, 'send_alerts', 'email_strategy')
        });
      });
  }

  handleSaveBookmark = (bookmark) => {
    this.setState({
      currentBookmark: bookmark,
      showNotifyEmailOptionDropDown: false,
      showNotifyDropDown: true,
      isMonthlyFrequency: false,
      isRecipientsEmailError: false,
      updateWatchCount: true,
    });
    this.enableNotifyModalTimeout();
  }

  handleChangeClick = () => {
    this.setState({
      showNotifyEmailOptionDropDown: true,
      showNotifyDropDown: false,
      updateWatchCount: false,
    });
    this.cancelNotifyTimeOut();
  }

  onCloseNotify = () => {
    const { showNotifyEmailOptionDropDown } = this.state;

    if (showNotifyEmailOptionDropDown) {
      trackEvent('confirm_metric_subscribe');
    }
    this.setState({
      showNotifyDropDown: false,
      showNotifyEmailOptionDropDown: false,
      isRecipientsEmailError: false,
      recipientsEmails: [],
    });
  }

  onEmailUpdate = (emails, isError) => {
    this.setState({
      isRecipientsEmailError: isError ||_.isEmpty(emails),
      recipientsEmails: emails
    });
  }

  renderSpinner() {
    return (
      <LoadingSpinner isLoading={this.state.isLoading} />
    )
  }

  onFreqOptionsChange = (emailsOptions) => {
    const isMonthlyFrequency = (_.get(emailsOptions, 'frequency', '') === 'month');
    this.setState({ isMonthlyFrequency });
  }

  renderRecipientsSection() {
    const { recipientsEmails } = this.state;
    const {
      currentUser,
      userFromBellerophon,
      currentDrilldownViewEntry
    } = this.props;

    if (!isManageCollections()) {
      return null;
    }

    let allowedUserRoles = [];
    const isLaunchpadAdmin = _.get(userFromBellerophon, 'isLaunchpadAdmin', false);
    if (!isLaunchpadAdmin && enableTemplateAndMetricPermission()) {
      allowedUserRoles = _.map(_.get(currentDrilldownViewEntry, 'user_list', []), 'role_id');
    }

    return (
      <Recipients
        currentUser={currentUser}
        editMode={!_.isEmpty(recipientsEmails)}
        recipientsEmails={recipientsEmails}
        allowedUserRoles={allowedUserRoles}
        onEmailUpdate={this.onEmailUpdate}
      />
    );
  }

  renderNotifyOptionsDropdown() {
    const { currentUser } = this.props;
    const { showNotifyEmailOptionDropDown,
      currentBookmark, isRecipientsEmailError, recipientsEmails } = this.state;
    if (!showNotifyEmailOptionDropDown) {
      return null;
    }
    const optionClassName = classNames('show-notify-email-option', {
      'is-show': showNotifyEmailOptionDropDown
    });
    return (
      <div className={optionClassName}>
        {this.renderRecipientsSection()}
        <BookmarkEmailOptions
          onCancel={this.handleDeleteBookmark}
          currentUser={currentUser}
          currentDimensionTotal={this.getCurrentDimensionTotal()}
          onSaveBookmark={this.handleSaveBookmark}
          isRecipientsEmailError={isRecipientsEmailError}
          recipientsEmails={recipientsEmails}
          onClose={() => this.setState({ showNotifyEmailOptionDropDown: false })}
          currentBookmark={currentBookmark}
          onFreqOptionsChange={this.onFreqOptionsChange} />
      </div>
    )
  }

  renderNotifyDropDown = () => {
    const { showNotifyDropDown, currentBookmark } = this.state;
    if (!showNotifyDropDown) {
      return null;
    }
    const susccessMessage = getNotifyText(currentBookmark);

    return (
      <>
        {this.renderSpinner()}

        <div className="d-flex gap-10 align-items-center overflow-hidden">
          <i className="icons-check-circle text-success tx-25" />
          <span>{susccessMessage}</span>
        </div>
        <div className="d-flex align-items-center mt-auto">
          <ForgeButton>
            <button
              onClick={this.handleDeleteBookmark}>
              Cancel
            </button>
          </ForgeButton>
          <ForgeButton type="outlined" class="ml-auto">
            <button
              className="ml-auto"
              tabindex="0"
              onClick={() => this.handleChangeClick()}>
              More options
            </button>
          </ForgeButton>
        </div>
      </>
    )
  }

  renderNotifyButton() {
    const { currentDrilldownViewEntry, currentDrilldownTemplateId } = this.props;
    const { showNotifyDropDown, showNotifyEmailOptionDropDown, updateWatchCount } = this.state;
    const watchClassName = classNames({
      'watch-btn-group': isManageCollections()
    });

    return (
      <div className={watchClassName}>
        <ForgeButton type="outlined">
          <button
            disabled={showNotifyDropDown || showNotifyEmailOptionDropDown}
            className="view-watch-btn collection-watch-btn"
            onKeyDown={(e) => this.handlePressedUndoWatch(e)}
            onClick={() => this.handleNotifyClick()}>
            Watch
          </button>
        </ForgeButton>
        <WatchCount
          type='metric'
          updateWatchCount={updateWatchCount}
          templateId={currentDrilldownTemplateId}
          viewEntry={currentDrilldownViewEntry} />
      </div>
    )
  }

  render() {
    const {
      showNotifyEmailOptionDropDown,
      showNotifyDropDown,
      isMonthlyFrequency
    } = this.state;
    const showNotifyClass = classNames('bookmark-dropdown-wrapper dropdown-menu', {
      'show notify-msg': showNotifyDropDown,
      'show show-email-option': showNotifyEmailOptionDropDown,
      'monthly-frequency': isMonthlyFrequency
    });


    return (
      <div className="d-flex bookmark-notify-options" ref={(ref) => this.NotifyButtonRef = ref}>
        {this.renderNotifyButton()}
        <div className={showNotifyClass}>
          <div className="dropdown-header">
            <div
              onClick={this.onCloseNotify}
              className="close-btn">
              <i className="icons-close ml-auto text-muted" tabIndex="0" />
            </div>
          </div>
          {this.renderNotifyDropDown()}
          {this.renderNotifyOptionsDropdown()}
        </div>
      </div>
    )
  }
}

BookmarkNotifyButton.propTypes = {
  drilldown: PropTypes.object,
  mapOptions: PropTypes.object,
  commonFilters: PropTypes.object,
  currentDrilldownViewEntry: PropTypes.object,
  visualization: PropTypes.object,
  currentBookmarkId: PropTypes.string,
  currentVizBasedChartType: PropTypes.string,
  currentBookmarkName: PropTypes.string,
  currentVisualizationType: PropTypes.string,
  onLoad: PropTypes.func,
  plotlyChart: PropTypes.func,
  metricTotalData: stringOrObjectProps,
  currentDrilldownTemplateId: PropTypes.string,
  currentUser: userPropTypes,
  userFromBellerophon: commonPropTypes.userPropTypes,
}

const mapDispatchToProps = {
};

function mapStateToProps(state) {
  const currentVisualizationType = _.get(state, 'drilldown.currentVisualizationType');
  const visualization = _.get(state, 'visualization', {});
  const viewEntry = _.get(state, 'drilldown.currentDrilldownViewEntry', {});
  return {
    currentUser: _.get(state.currentUser, 'user', {}),
    currentBookmarkId: _.get(state, 'bookmark.currentBookmarkId', ''),
    currentBookmarkName: _.get(state, 'bookmark.currentBookmarkName', viewEntry.name),
    currentDrilldownViewEntry: viewEntry,
    currentVizBasedChartType: getCurrentVizBasedChartType(currentVisualizationType, visualization),
    mapOptions: _.get(state, 'visualization.mapOptions'),
    drilldown: _.get(state, 'drilldown', {}),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId'),
    visualization,
    commonFilters: _.get(state, 'commonFilters', {}),
    metricTotalData: _.get(state, 'metricTotal.totals.currentPeriodMetricTotals', {}),
    currentVisualizationType,
    userFromBellerophon: _.get(state, 'userFromBellerophon', {}),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BookmarkNotifyButton);
