import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ForgeIcon, ForgeButton, ForgeMenu } from '@tylertech/forge-react';

class ForgeMenuOptions extends Component {

  render() {
    const { menuOptions, menuLabelName, customIconName , onSelectOption } = this.props;


    return (
      <ForgeMenu
        open={false}
        placement='bottom'
        dense={false}
        persistSelection={false}
        options={menuOptions}
        on-forge-menu-select={onSelectOption}>
        <ForgeButton type="outlined" className="map-forge-btn">
          <button type="button">
            <i className={customIconName}></i>
            <span>{menuLabelName}</span>
            <ForgeIcon name='keyboard_arrow_down' className="mr-0" /></button>
        </ForgeButton>
      </ForgeMenu>
    )
  }
}

ForgeMenuOptions.defaultProps = {
  customIconName: '',
  className: '',
  menuOptions:[]
};

ForgeMenuOptions.propTypes = {
  menuLabelName:PropTypes.string,
  trailingIconName: PropTypes.string,
  customIconName: PropTypes.string,
  onSelectOption: PropTypes.func,
  menuOptions: PropTypes.array,
  selectIndex: PropTypes.number
};

export default ForgeMenuOptions;
