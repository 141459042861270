import _ from 'lodash';
import React from 'react';

import { getMargins } from 'modules/PlotlyTooltip/helper';
import { OVERTIME_TIME_FRAME_OPTIONS } from 'appConstants';
import { getFlyoutPosition, getFlyoutPositionByWindowSize } from './helper';
import { getFlyoutContent } from 'common/contentFormatter/lineChartFlyoutTableContentFormatter';
import { getAbbreviatedStringValue } from 'helpers/numberHelper';

// TODO: refactoring this function.
export const getLineChartPopupConfigs = (options) => {
  const {
    chartContainer,
    data,
    dateType,
    dateRangeMode,
    renderTimeFrame,
    secondaryMetricEntry,
    viewEntry,
    viewMode,
    dateRange,
    compareYearRanges,
    currentSelectedTimeFrame,
    renderType,
    templateId,
    isCurrencyDimensionField,
    projectionEnabled,
    dimensionConfigs,
    isDimensionHighToLow,
    isForecastingView,
    axisGranularity,
    forecastingOption
  } = options;
  const point = data.points[0];
  const margins = getMargins(chartContainer);
  const yaxis = point.yaxis;
  const xaxis = point.xaxis;
  const isRolling = (currentSelectedTimeFrame === OVERTIME_TIME_FRAME_OPTIONS.ROLLING);

  const filterWithoutBenchMarkData = _.filter(data.points, (point) => {
    return _.get(point, 'data.customdata[0][0]') !== 'benchMark';
  });
  const filterBenchMarkData = _.filter(data.points, (point) => {
    return _.get(point, 'data.customdata[0][0]') === 'benchMark';
  });
  const vizOptions = {
    dateRange,
    dateType,
    dateRangeMode,
    compareYearRanges,
    renderTimeFrame,
    renderType,
    viewEntry,
    secondaryMetricEntry,
    isRolling,
    templateId,
    projectionEnabled,
    isCurrencyDimensionField,
    dimensionConfigs,
    isDimensionHighToLow,
    isForecastingView,
    axisGranularity,
    forecastingOption
  };
  const flyoutContent = getFlyoutContent(filterWithoutBenchMarkData, vizOptions);
  const groupedDimensions = _.groupBy(filterWithoutBenchMarkData, (point) =>
     _.get(point, 'data.meta.dimension')
  );

  let lineChartPopupConfigs = _.isEmpty(flyoutContent) ? [] : [{
    isTimelineChart: true,
    isNeedBottomPadding: _.size(_.keys(groupedDimensions)) < 10,
    viewMode,
    position: isForecastingView ?
      getFlyoutPositionByWindowSize(data, 'small') : getFlyoutPosition(data, viewMode),
    anchor: {
      x: xaxis.d2p(point.x) + margins.x - 6,
      y: yaxis.d2p(yaxis.range[0]) - margins.y
    },
    chartContainer,
    html: flyoutContent
  }];

  if (!_.isEmpty(filterBenchMarkData)) {
    const benchMarkPopupConfigs = getBenchMarkPopupConfigs(chartContainer, filterBenchMarkData);
    lineChartPopupConfigs = lineChartPopupConfigs.concat(benchMarkPopupConfigs);
  }

  return lineChartPopupConfigs;
};

export const getBenchMarkPopupConfigs = (chartContainer, benchMarkData) => {
  const margins = getMargins(chartContainer);
  return _.map(benchMarkData, (point) => {
    const firstXAxisValue = _.get(point, 'data.x[0]', point.y);
    const benchMarkValue = _.get(point, 'data.customdata[0][1]', '');
    const yValue = getAbbreviatedStringValue(_.get(point, 'y', 0));

    return {
      position : 'bottom',
      anchor: {
        x: point.xaxis.d2p(firstXAxisValue) + margins.x,
        y: point.yaxis.d2p(point.y) + margins.y
      },
      chartContainer,
      html: (
        <div>
          <div className="pb-1 text-nowrap"><b>{benchMarkValue}</b>: {yValue}</div>
        </div>
      )
    };
  });
}
