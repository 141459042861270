import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';

import Table from 'modules/DetailsTable/Table';
import LeafPageButton from './LeafPageButton';
import { formattedValueByDataType } from 'modules/DetailsTable/TableHelper';
import { fetchApiData } from 'helpers/apiResponseHelper';
import { getAggregateRelatedData } from 'common/api/drilldown';
import { getApiParams } from 'helpers/apiParamsHelper';
import NotesPage from '../NotesPage/NotesPage';
import { getCurrentTemplateEntry,
  showAssociatedRecords,
  getLeafPageEntries,
  getLeafPageTitleEntry
} from 'common/config/templateConfiguration';
import { updateLeafPageRecordIndex, updateLeafPageNoteId, updateShowLeafPage } from 'actions/tableActions';
import { getNullValueLabel  } from 'common/config/templateConfiguration';
import { GEO_LOCATION_COLUMN_TYPES, DATE_RENDER_TYPES } from 'appConstants';
import ViewMetricLink from 'pages/embed/ViewMetricLink';
import { updateStoreToUrl } from 'helpers/UrlParamsHelper';
import { getDistinctValue } from 'common/api/drilldown';
import classNames from 'classnames';
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';

class LeafPage extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      leafPageData: this.props.leafPageData,
      rowIdField: this.props.rowIdField,
      totalRecordsCount: this.props.totalRecordsCount,
      offset: this.getOffset(),
      limit: 100,
      isLoading: false,
      tableColumnResizerOption:{
        resizeMode: 'overflow',
        headerOnly: true,
        liveDrag: true
      },
      relatedRecordsData: [],
      isLoadingRelatedRecords: false
    };
    this.abortFetchController = new AbortController();
    this.relatedAbortFetchController = new AbortController();
  }

  // In leaf page showing only one record per page and storing 100 data in state.
  getOffset () {
    const { currentRecordIndex } = this.props;
    const limit = 100;
    let offset = 0;
    if(currentRecordIndex >= limit){
      let index = currentRecordIndex;
      for(;limit < index;){
        offset = offset + 1;
        index = index - limit;
      }
    }
    return offset;
  }

 async componentDidMount() {
    const { currentDrilldownTemplateId, isMapLeafPage } = this.props;

    if(isMapLeafPage){
      await this.fetchRowExtraData();
    }
    if(showAssociatedRecords(currentDrilldownTemplateId)){
      await this.fetchRelatedRecordsData();
    }
  }

  componentWillUnmount(){
    this.abortFetchController.abort();
    this.relatedAbortFetchController.abort();
  }

  async componentDidUpdate(prevProps) {
    const isCurrentRowIdChanged = !_.isEqual(
      prevProps.rowIdField, this.props.rowIdField);
    const {currentDrilldownTemplateId} = this.props;

    if(isCurrentRowIdChanged && showAssociatedRecords(currentDrilldownTemplateId)){
      this.fetchRelatedRecordsData();
    }
    if(isCurrentRowIdChanged){
      await this.fetchRowExtraData();
    }
  }

  fetchRowExtraData = async () => {
    const { apiParams, rowIdField, isMapLeafPage } = this.props;
    const currentRowEntry = this.getCurrentLeafPageDetail();
    const rowId = isMapLeafPage ? rowIdField : currentRowEntry['row_id_field'];

    if(_.isEmpty(rowId)){
      return
    }
    const queryParams = _.merge({}, apiParams, {
      row_id_field: rowId,
      isLeafPage: true
    });
    const distinctDataApi = getDistinctValue(queryParams);
    this.abortFetchController.abort();
    this.abortFetchController = new AbortController();

    this.setState({ isLoading: true, rowExtraData: [] });
    fetchApiData(distinctDataApi, this.abortFetchController).then( (response) => {
      this.setState({
        rowExtraData: response,
        isLoading: false
      });
    }, (error) => {
      if (error.name !== 'AbortError') {
        console.error("Error on fetching data", error);
        this.setState({ isLoading: false });
      }
    });
  }

  fetchRelatedRecordsData = async () => {
    const { apiParams } = _.cloneDeep(this.props);

    const leafPageData = this.getCurrentLeafPageDetail();
    const queryParams = _.merge({}, apiParams, {
      aggregate_column_value: _.get(leafPageData, 'table_aggregate_column'),
      isLeafPage: true
    });

    this.setState({ isLoadingRelatedRecords: true })
    this.relatedAbortFetchController.abort();
    this.relatedAbortFetchController = new AbortController();
    const aggregateRelatedApiUrl = getAggregateRelatedData(queryParams);
    const response = await fetchApiData(aggregateRelatedApiUrl, this.relatedAbortFetchController);

    this.setState({
      relatedRecordsData: response.data,
      isLoadingRelatedRecords: false
    });
  }

  handleSwitchPage = (switchDirection) => {
    let { currentRecordIndex } = this.props;
    if(switchDirection === 'next') {
      currentRecordIndex += 1
    } else {
      currentRecordIndex -= 1
    }
    this.updateLeafPageRecord(currentRecordIndex);
  }

  updateLeafPageRecord = (currentRecordIndex) => {
    const { dispatchUpdateLeafPageRecordIndex } = this.props;
    const { offset, limit } = this.state;
    // total records shown so far.
    const totalRecords = (offset+1) * limit;

    if(currentRecordIndex >= totalRecords){
      this.setState({offset: offset+1});
    } else if(currentRecordIndex < totalRecords -limit ) {
      this.setState({offset: offset-1});
    }
    dispatchUpdateLeafPageRecordIndex(currentRecordIndex);
  }

  renderSpinner(isLoading) {
    if(!isLoading) {
      return null;
    }

    return (
      <div className="spinner-overlay">
        <Spinner variant="primary" className="loading-spinner" animation="border" />
      </div>
    );
  }

  renderColumnAndValue(leafPageRowDetails, leafPageEntry, index) {
    const { rowExtraData } = this.state;
    const column = _.get(leafPageEntry, 'column');

    if ((_.isEmpty(rowExtraData) && _.isEmpty(leafPageRowDetails)) ||
         _.includes(GEO_LOCATION_COLUMN_TYPES, column)) {
      return null;
    }

    const field = leafPageEntry['field']
    const uniquKey = `${index}-${field}`
    return (
      <div key={uniquKey}>
        {this.renderLeafPageLabel(leafPageEntry, leafPageRowDetails)}
      </div>
    );
  }

  getLeafpageEntryValue = (leafPageRowDetails, field, renderType) => {
    const { currentDrilldownTemplateId } = this.props;
    const nullValueLabel = getNullValueLabel(currentDrilldownTemplateId);
    const defaultNullValueLabel = _.includes(DATE_RENDER_TYPES, renderType) ? '' : nullValueLabel;
    return _.get(leafPageRowDetails, [field], defaultNullValueLabel);
  }

  renderLeafPageEntry(leafPageRowDetails) {
    const { currentDrilldownTemplateId, isMapLeafPage } = _.cloneDeep(this.props);
    const leafPageEntries = getLeafPageEntries(currentDrilldownTemplateId);
    const leafPageRightSideEntry = (leafPageEntries.map((leafPageEntry, index) => {
      if(index % 2 != 0){
        return this.renderColumnAndValue(leafPageRowDetails, leafPageEntry, index)
      }
    }));
    const leafPageLeftSideEntry = (leafPageEntries.map((leafPageEntry, index) => {
      if(index % 2 == 0){
        return this.renderColumnAndValue(leafPageRowDetails, leafPageEntry, index)
      }
    }));
    const detailsContainerClassName = classNames('leaf-page-details',
      { "d-flex": isMapLeafPage });
    const detailsClassNames = classNames('',
      { "col py-4": isMapLeafPage });

    return (
      <div className={detailsContainerClassName}>
        <div className={detailsClassNames}> {leafPageLeftSideEntry}</div>
        <div className={detailsClassNames}> {leafPageRightSideEntry}</div>
      </div>
    );
  }

  getCurrentLeafPageDetail = () => {
    const { offset, limit, isLoading } = this.state;
    const { currentRecordIndex, leafPageCurrentNoteId,
      dispatchUpdateLeafPageRecordIndex, currentLeafData } = this.props;

    // Related Table data loaded based on deeplink data
    const leafPageList = currentLeafData ;
    let currentIndex = currentRecordIndex;
    if(leafPageCurrentNoteId && !isLoading && !_.isEmpty(leafPageList)) {
      currentIndex = _.findIndex(leafPageList, {parent_dataset_join_column: leafPageCurrentNoteId})

      if(currentIndex == -1){
        currentIndex = 0
      } else {
        // assigning offset & currentRecordIndex on noteID results
        this.setState({ offset: Math.floor(currentIndex / limit) })
        dispatchUpdateLeafPageRecordIndex(currentIndex);
      }
    }
    let recordIndex = currentIndex;
    if(recordIndex >= limit){
      recordIndex = currentIndex - (offset* limit);
    }
    return _.get(leafPageList, recordIndex, {});
  }

  renderViewSource() {
    const { state, isEmbed } = this.props;
    const url = updateStoreToUrl('!/analysis', state);
    const embedUrl = `${window.location.origin}/#${url}`;

    return (<ViewMetricLink isEmbed={isEmbed} viewMetricLink={embedUrl} />);
  }

  renderLeafPageEntries() {
    const { isLoading, rowExtraData } = this.state;
    const currentLeafPageRowDetails = this.getCurrentLeafPageDetail();
    if ( !isLoading && _.isEmpty(currentLeafPageRowDetails) && _.isEmpty(rowExtraData)) {
      return <div>No results found. Change your filters and try again.</div>;
    }

    return (
      <div>
        {this.renderLeafPageEntry(currentLeafPageRowDetails)}
      </div>
    );
  }

  getLeafPageLabel(leafPageDetails, field, leafPageEntry){
    const renderType = _.get(leafPageEntry, 'renderType', '');
    const value = this.getLeafpageEntryValue(leafPageDetails, field, renderType);
    const leafPageEntryValue = _.isObject(value) ? _.get(value, 'url') : value;
    const formatLeafPageValue = formattedValueByDataType(leafPageEntryValue, leafPageEntry);
    return formatLeafPageValue ? (<div className="leaf-value">{formatLeafPageValue}</div>) : null;
  }

  renderLeafPageLabel(leafPageEntry, leafPageRowDetails){
    const { currentDrilldownTemplateId, isMapLeafPage } = this.props;
    const name = _.get(leafPageEntry, 'name');
    const field = _.get(leafPageEntry, 'field');
    const { rowExtraData } = this.state;
    const templateEntry = getCurrentTemplateEntry(currentDrilldownTemplateId);

    let leafPageDetails = leafPageRowDetails;
    let leafValueContent;
    if((templateEntry['dedupe_last_startegy_option'] != 'all') && isMapLeafPage){
      leafPageDetails = _.first(rowExtraData);
      leafValueContent = this.getLeafPageLabel(leafPageDetails, field, leafPageEntry);
    } else if(_.isEmpty(rowExtraData) ||  _.isEmpty(leafPageEntry['column_dataset'])){

      if(_.isEmpty(leafPageDetails) && isMapLeafPage){
        leafPageDetails = _.first(rowExtraData) ;
      }

      leafValueContent = this.getLeafPageLabel(leafPageDetails, field, leafPageEntry);
    } else {
      leafValueContent = _.map(rowExtraData, (entry, index) => {
        const value = this.getLeafpageEntryValue(entry, field);
        const leafPageValue = _.isObject(value) ? _.get(value, 'url') : value;
        const formatLeafPageValue = formattedValueByDataType(leafPageValue, leafPageEntry);
        return(<div className="leaf-value" key={value+index}>{formatLeafPageValue}</div>)
      });
    }

    return leafValueContent && (
      <div className="leaf-page-values">
        <label className="custom-label">{name}</label>
        {leafValueContent}
      </div>
    );
  }

  renderLeafPageTitle() {
    const { currentDrilldownTemplateId, isMapLeafPage } = this.props;
    const { rowExtraData } = this.state;

    const titleEntry = getLeafPageTitleEntry(currentDrilldownTemplateId);
    if(_.isEmpty(titleEntry['field'])){
      return null;
    }
    let leafPageEntry = this.getCurrentLeafPageDetail();
    if(isMapLeafPage) {
      leafPageEntry = _.isEmpty(leafPageEntry) ? _.first(rowExtraData) : leafPageEntry;
    }
    let value = this.getLeafpageEntryValue(leafPageEntry, titleEntry['field']);
    const leafPageValue = _.isObject(value) ? _.get(value, 'url') : value;

    return (
      <div className="leaf-page-values">
        <label className="custom-label">{titleEntry['name']}</label>
        <div className="leaf-value">{leafPageValue}</div>
      </div>
    );
  }

  renderNotes() {
    const { isLoading } = this.state;
    const currentLeafPageRowDetails = this.getCurrentLeafPageDetail();
    const noteId = _.get(currentLeafPageRowDetails, 'parent_dataset_join_column');

    if (_.isEmpty(currentLeafPageRowDetails) || !noteId) {
      return null;
    }

    return (
      <NotesPage
        noteId={noteId}
        isTableDataLoading={isLoading} />
    )
  }

  excludeSameValueRelatedRecords() {
    const { currentDrilldownTemplateId } = this.props;
    const { relatedRecordsData } = this.state;
    const currentLeafPageRowDetails = this.getCurrentLeafPageDetail();
    const leafPageEntries = getLeafPageEntries(currentDrilldownTemplateId);
    const leafColumnFields = _.map(leafPageEntries, 'field');

    const filterLeafPageRowDetails = _.pick(currentLeafPageRowDetails, leafColumnFields)
    return _.filter(relatedRecordsData, function(datum) {
      const leafPageDatum = _.pick(datum, leafColumnFields)
      return !_.isEqual(leafPageDatum, filterLeafPageRowDetails);
    });
  }

  renderRelatedRecords() {
    const { currentDrilldownTemplateId, quickFilters } = this.props;
    const { tableColumnResizerOption, isLoadingRelatedRecords} = this.state;
    if(!showAssociatedRecords(currentDrilldownTemplateId)){
      return null;
    }
    const relatedRecords = this.excludeSameValueRelatedRecords();
    const leafPageEntries = getLeafPageEntries(currentDrilldownTemplateId);

    if(_.isEmpty(relatedRecords)){
      return null;
    }

    return (
      <div className="position-relative">
        {this.renderSpinner(isLoadingRelatedRecords)}
        <h5>Related Records</h5>
        { !isLoadingRelatedRecords && <Table
          templateEntry={getCurrentTemplateEntry(currentDrilldownTemplateId)}
          tableData={relatedRecords}
          isTableResizable={true}
          tableColumnResizerOption={tableColumnResizerOption}
          customColumnEntries={leafPageEntries}
          quickFilters={quickFilters}
         />
        }
      </div>
    );
  }

  renderLeafPageHead() {
    const { isMapLeafPage, onClose, currentRecordIndex, currentLeafData } = this.props;
    const isDisabledPreviousButton = (currentRecordIndex + 1) === 1
    const isDisabledNextButton = ((currentRecordIndex + 1) * 1) >= _.size(currentLeafData);
    if(isMapLeafPage){
      return null;
    }
    return (
      <div className="leaf-page-head">
        <div className="d-flex justify-content-between">
          <div className="navigate">
            <LeafPageButton
              ariaLabel="previous"
              disabled={isDisabledPreviousButton}
              iconName="arrow_back_ios"
              onClick={this.handleSwitchPage}
            />
            <LeafPageButton
              ariaLabel="next"
              disabled={isDisabledNextButton}
              iconName="arrow_forward_ios"
              onClick={this.handleSwitchPage}
            />
          </div>
          <div>
            <LeafPageButton ariaLabel="close button"
              className="text-muted"
              iconName="close"
              onClick={onClose} />
          </div>
        </div>
        {/*{this.renderViewSource()}*/}
      </div>
    )
  }

  render() {
    const { isLoading } = this.state;
    const { isMapLeafPage } = this.props;
    const leafPageCardClassNames = classNames('',
      { "exclude-box-shadow": !isMapLeafPage });

    return (
      <div className="leaf-page-container">
        {this.renderSpinner(isLoading)}
        <div className={leafPageCardClassNames}>
          {this.renderLeafPageHead()}
          <div className="mb-6">
            {this.renderLeafPageTitle()}
          </div>
          {this.renderLeafPageEntries()}
          {this.renderRelatedRecords()}
          {this.renderNotes()}
        </div>
      </div>
    );
  }
}

LeafPage.propTypes = {
  leafPageData: PropTypes.array,
  rowIdField: PropTypes.string,
  totalRecordsCount: PropTypes.number,
  currentRecordIndex: PropTypes.number,
  isLeafPage: PropTypes.bool,
  currentDrilldownTemplateId: commonPropTypes.templateIdPropTypes,
  leafPageCurrentNoteId: commonPropTypes.stringOrNumberProps,
  isMapLeafPage: PropTypes.bool,
  apiParams: PropTypes.object,
  sortColumns: PropTypes.array,
  dispatchUpdateLeafPageRecordIndex: PropTypes.func,
  dispatchUpdateShowLeafPage: PropTypes.func,
  state: PropTypes.object,
  isEmbed: PropTypes.bool,
  quickFilters: commonPropTypes.quickFiltersPropTypes,
  currentLeafData: PropTypes.array,
  onClose: PropTypes.func
}

function mapDispatchToProps(dispatch) {
  return {
    dispatchUpdateLeafPageRecordIndex: (currentRecordIndex) => {
      dispatch(updateLeafPageRecordIndex(currentRecordIndex));
      dispatch(updateLeafPageNoteId(null));
    },
    dispatchUpdateShowLeafPage: (isLeafPage) => {
      dispatch(updateShowLeafPage(isLeafPage));
    }
  }
}

function mapStateToProps(state) {
  const apiParamsOptions = _.pick(state, ['commonFilters', 'drilldown', 'visualization.mapOptions']);
  return {
    state,
    templateEntries: _.get(state, 'configurations.template_entries', []),
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId', ''),
    apiParams: getApiParams(apiParamsOptions, {}),
    sortColumns: _.get(state, 'visualization.table.sortColumns', []),
    currentLeafData: _.get(state, 'visualization.table.currentLeafData', []),
    currentRecordIndex: _.parseInt(_.get(state, 'visualization.table.leafPageCurrentRecordIndex', 0)),
    leafPageCurrentNoteId: _.get(state, 'visualization.table.leafPageCurrentNoteId', null),
    isLeafPage: _.get(state, 'visualization.table.isLeafPage', false),
    quickFilters: _.get(state, 'drilldown.quickFilters', []),
    isEmbed: _.get(state, 'embedOptions.isEmbed', false)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LeafPage);
