import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { getMargins } from 'modules/PlotlyTooltip/helper';
import { getFormattedValue } from 'helpers/chartDataHelper';
import { getBenchMarkPopupConfigs } from './lineChartContentFormatter';
import { getFlyoutPosition, getDurationFlyoutText } from './helper';
import { VIEW_MODE } from 'modules/visualization/constants';
import { formatValueToCurrency } from 'helpers/numberHelper';
import { isTimeDurationEnabled } from 'common/config/customerConfiguration';

export const getComboChartPopupConfigs = ({
  chartContainer,
  data,
  viewMode,
  isCurrencyDimensionField,
  viewEntry,
  isForecastingView,
  forecastingOption
}) => {
  const point = data.points[0];
  const margins = getMargins(chartContainer);
  const xaxis = point.xaxis;
  const yaxis = point.yaxis;
  const filterWithoutBenchMarkData = _.filter(data.points, (point) => {
    return _.get(point, 'data.customdata[0][0]') !== 'benchMark';
  });
  const filterBenchMarkData = _.filter(data.points, (point) => {
    return _.get(point, 'data.customdata[0][0]') === 'benchMark';
  });
  const groupedPoints = _.groupBy(filterWithoutBenchMarkData, (point) => _.get(point, 'data.meta.dimension'));
  let tbodyContent = [];
  if(isForecastingView){
    const orderedPoints = _.orderBy(filterWithoutBenchMarkData, ['data.meta.dimension']);
    const groupedForecastPoints =  _.groupBy(orderedPoints, (point) => _.get(point, 'data.meta.dimension'));
    tbodyContent = getForecastFlyout(
      groupedForecastPoints, forecastingOption, viewMode, viewEntry, orderedPoints);
  } else {
    tbodyContent = _.map(groupedPoints, (groupedPoint, categoryName) => {
      const groupedPointDetails = VIEW_MODE.SMALL === viewMode ?
                                  [_.get(groupedPoint, '0', {})] : groupedPoint;
      const tableColumnContent = getTableColumnContent(groupedPointDetails, viewEntry);
      const newCategoryName = formatValueToCurrency(categoryName, isCurrencyDimensionField);

      return (
        <tr key={categoryName}>
          {tableColumnContent}
          <td className="text-left">{newCategoryName}</td>
        </tr>
      );
    });
  }


  let comboChartPopupConfigs = _.isEmpty(tbodyContent) ? [] : [{
    position: getFlyoutPosition(data, viewMode),
    viewMode,
    anchor: {
      x: xaxis.d2p(point.x) + margins.x - 10,
      y: yaxis.d2p(yaxis.range[0]) - margins.y
    },
    chartContainer,
    html: (
      isForecastingView ? tbodyContent :
        <table className="flyout-table">
          <tbody>{_.without(tbodyContent, null)}</tbody>
        </table>
    )
  }];

  if (!_.isEmpty(filterBenchMarkData)) {
    const benchMarkPopupConfigs = getBenchMarkPopupConfigs(chartContainer, filterBenchMarkData);
    comboChartPopupConfigs = comboChartPopupConfigs.concat(benchMarkPopupConfigs);
  }

  return comboChartPopupConfigs;
};

function getForecastFlyout(groupedPoints, forecastingOption, viewMode, viewEntry, filterBenchMarkData){
  return (
    <table className="flyout-table">
      <thead>
        {getTableHeader(forecastingOption, filterBenchMarkData)}
      </thead>
      <tbody>
        {getTableBody(groupedPoints, forecastingOption, viewMode, viewEntry)}
      </tbody>
    </table>
  );
}

function getTableHeader(forecastingOption, filterWithoutBenchMarkData){
  const points = _.filter(filterWithoutBenchMarkData, (point) => {
    return _.get(point, 'data.type') != 'bar' }
  );
  const projectedData = _.filter(points, (groupedPointItem) => {
    return _.get(groupedPointItem, 'data.meta.isProjection') == true;
  });
  const metricData = _.find(points, (groupedPointItem) => {
    return _.get(groupedPointItem, 'data.meta.isProjection', false) != true;
  });

  const ignoreAdjustData = isAvailableAdjustData(projectedData);

  const hasProjectedData = !_.isEmpty(projectedData) && !ignoreAdjustData;
  const hasMetricData = !_.isEmpty(metricData);
  const projectionHeader = _.map(_.get(forecastingOption, 'projectionTypes', []), (projectionType) => {
      return (<th>{projectionType}</th>);
  });
  const headers = ['Original value', 'Adjusted value', 'Note']
  const metricHeader = _.map(headers, (header) => {
    return (<th>{header}</th>);
  });

  return (
    <tr>
      <th>Metric</th>
      {hasMetricData && metricHeader}
      {hasProjectedData && projectionHeader}
    </tr>
  );
}

function isAvailableAdjustData(projectedData){
  const projectionObject = _.find(projectedData, (datum) => {
    return !_.isEmpty(datum['x']);
  });

  const ignoreProjectionData = _.filter( _.get(projectionObject, 'data.customData'), (proData) => {
    const adjustValue = !_.isNaN(Number(proData['adjustValue'])) ? true : false;
    const ignoreProjection =  _.get(proData, 'ignoreProjection', false);

    return moment(projectionObject.x).format("YYYY-MM-DD") == moment(proData.period).format("YYYY-MM-DD") &&
      (adjustValue || ignoreProjection)
  });

  return !_.isEmpty(ignoreProjectionData);
}

function getTableBody(groupedPoints, forecastingOption, viewMode, viewEntry){
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);
  const projectionTypes = _.get(forecastingOption, 'projectionTypes', []);

  return _.map(groupedPoints, (groupedPoint, categoryName) => {
    const prepareData = _.find(groupedPoint, (groupedPointItem) => {
      return _.get(groupedPointItem, 'data.meta.prePareData') == true;
    });

    const projectedData = _.filter(groupedPoint, (groupedPointItem) => {
      return _.get(groupedPointItem, 'data.meta.isProjection') == true;
    });

    const metricData = _.find(groupedPoint, (groupedPointItem) => {
      return _.get(groupedPointItem, 'data.meta.isProjection', false) != true;
    });

    let originalValue, adjustedValue = 'None', note = 'None', projectedValue = [], ignoreProjection = false;
    const metricEntry = _.get(metricData, 'data.meta.metricEntry');
    if(_.isEmpty(prepareData)){
      originalValue = _.get(metricData, 'y', 0);
    } else {
      const customData = matchedCustomData(_.get(prepareData, 'data.customData'), metricData);
      originalValue = _.get(customData, 'value');
      adjustedValue = _.get(customData, 'adjustValue');
      note = _.get(customData, 'note');
      adjustedValue = isSecondsFormat ? getDurationFlyoutText(adjustedValue, metricEntry) :
      getFormattedValue(adjustedValue, metricEntry);
    }

    originalValue = isSecondsFormat ? getDurationFlyoutText(originalValue, metricEntry) :
      getFormattedValue(originalValue, metricEntry);

    const ignoreAdjustData = isAvailableAdjustData(projectedData);

    if(!ignoreProjection && !ignoreAdjustData){
      projectedValue = _.map(projectionTypes, (type) => {
        let projectedValue = _.find(projectedData, (data) => {
          return _.get(data, 'data.meta.projectionType') == type
        });
        const customdata = _.get(projectedValue, 'data.customData');
        const headCustomData = _.head(customdata);
        const matchedData = matchedCustomData(customdata, metricData);
        // To ignore the projection for current or completed periods.
        ignoreProjection = (_.get(matchedData, 'ignoreProjection') &&
                           (_.toNumber(_.get(metricData, 'y')) == _.toNumber(headCustomData.value)));
        if (ignoreProjection){
          return;
        }
        projectedValue = isSecondsFormat ? getDurationFlyoutText(_.get(projectedValue, 'y'), metricEntry) :
          getFormattedValue(_.get(projectedValue, 'y'), metricEntry);
        return <td>{projectedValue}</td>;
      });
    }

    return (
      <tr key={categoryName}>
        <td>{categoryName}</td>
        {
          !_.isEmpty(metricData) &&
          (
            <>
              <td>{originalValue}</td>
              <td>{adjustedValue}</td>
              <td>{note}</td>
            </>
          )
        }
        {!_.isEmpty(projectedData) && projectedValue}
      </tr>
    );
  });
}

function getTableColumnContent(groupedPoint, viewEntry) {
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);
  return _.map(groupedPoint, (point, index) => {
    const lineColor = _.get(point, 'data.line.color', '#fff');
    let color = _.get(point, 'data.marker.color', lineColor);
    if (_.get(point, 'data.type') === 'bar'){
      color = _.get(point, 'data.marker.line.color', lineColor);
    }
    const isProjection = _.get(point, 'data.meta.isProjection', false);
    const customdata = _.get(point, 'data.customData');
    const matchedData = matchedCustomData(customdata, point);
    const headCustomData = _.head(customdata);
    const ignoreProjection = (_.get(matchedData, 'ignoreProjection') && (point.y == headCustomData.value));
    const metricEntry = _.get(point, 'data.meta.metricEntry');
    const rawValue = _.get(point, 'y', 0);
    const value = isSecondsFormat ? getDurationFlyoutText(rawValue, metricEntry) :
      getFormattedValue(rawValue, metricEntry);
    let colorContent;
    if (_.isEmpty(color) || ignoreProjection) {
      return null;
    }

    if(isProjection) {
      colorContent = (
        <div className="pl-3 projection-color">
          <svg><line x1="30" y1="0" style={{ stroke: color }}></line></svg>
        </div>
      );
    } else {
      colorContent = (<span className="legend-color" style={{ backgroundColor: color }}></span>);
    }

    return (
      <td key={index}>
        <div className="legend-values">
          <div className="mr-3">{value}</div>
          {colorContent}
        </div>
      </td>
    );
  });
}


const matchedCustomData = (customData, metricData) => {
  const xValue = _.get(metricData, 'x') || '';
  const splittedXValue = _.get(xValue.split(' - '), '0');

  const matchedData = _.find(customData, (datum) => {
    return (
      moment(datum.period).format('MMM DD') === splittedXValue ||
      moment(datum.period).format('YYYY-MM-DD') === xValue ||
      splittedXValue == _.get(datum, 'quarterText')
    );
  });
  return matchedData;
}