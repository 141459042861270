import React from 'react';

import { NoDataWrapper } from 'common/components/NoDataFound/NoDataWrapper';

const EmptyBookmarkText = () => {
  return (
    <div role="listitem" className="helpful-text">
      <img alt="No saved view" className="mb-6" src="../images/query_failed.png" />
      <div className='help-info-text'>
        You currently have no saved views. When viewing a metric in Analysis mode,
        you can click &quot;Save As&quot; to create a saved view with your current
        filters and chart preferences. After saving a view, it will appear here.
      </div>
    </div>
  );
}

EmptyBookmarkText.propTypes = {}

EmptyBookmarkText.defaultProps = {}

export default NoDataWrapper(EmptyBookmarkText);
