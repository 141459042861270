import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import {
  ForgeCheckbox,
  ForgeBadge,
  ForgeTooltip,
  ForgeIconButton,
  ForgeIcon,
  ForgeCard,
  ForgeButton
} from '@tylertech/forge-react';
import useDynamicRefs from 'use-dynamic-refs';

import Paginator from './Paginator';
import PropertyPhoto from '../PropertyPhoto';
import PropertyPdf from '../PropertyPdf';
import { formattedValueByDataType } from 'modules/DetailsTable/TableHelper';
import { TOOLTIP_CONSTANTS } from '@tylertech/forge';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentTemplateEntryById, getPropertyColumnForPdf } from 'common/config/templateConfiguration';
import { showExternalProperty } from 'common/config/customerConfiguration';
import { getDistinctData } from 'common/api/advanceSearchApi';
import LoadingSpinner from 'common/components/LoadingSpinner';
import classNames from 'classnames';
import SearchDetailsSecondaryTable from './SearchDetailsSecondaryTable';
import { updateSecondarySortColumns } from 'actions/advanceSearchActions';
import { SEARCH_METHOD } from 'appConstants';
import { canShowAllRecords, isValidPdfColumn } from '../advanceSearchHelper';
import ForgePopupOption from 'common/components/ForgePopupOption/ForgePopupOption';
import CompFinderIcon from '../CompFinderIcon';

const SearchDetailsTable = (props) => {
  const {
    tableHeaders,
    setTableOptions,
    tableData,
    showPagination,
    onlyShowTableRow,
    totalRowsCount,
    children,
    tableId,
    sortColumns,
    onClickTableSortedHeader,
    onSelectTableCheckbox,
    selectedReportRowIds,
    isCustomSearch,
    isSmallView
  } = props;
  const dispatch = useDispatch();
  const searchTemplateId = useSelector(state => state.advanceSearch.templateId);
  const searchField = useSelector(state => state.advanceSearch.searchField);
  const searchProperty = useSelector(state => state.advanceSearch.searchProperty);
  const searchMethod = _.get(searchProperty, 'searchMethod')
  const secondarySortColumns = useSelector(store => _.get(store, 'advanceSearch.secondarySortColumns', []));

  const templateEntry = getCurrentTemplateEntryById(searchTemplateId);
  const scrollContainerRef = useRef(null);
  const [rowData, setRowData] = useState({});
  const [showSecondaryTable, setShowSecondaryTable] = useState(false);
  const [showPhotoContent, setShowPhotoContent] = useState(false);
  const [photoPopupRef, setPhotoPopupRef] = useState(false);
  const [photoDatum, setPhotoDatum] = useState({});
  const [selectedRowField, setSelectedRowField] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const isSubjectSearch = searchMethod == SEARCH_METHOD.SUBJECT_PROPERTY;
  const [getPhotoRef, setPhotoRef] = useDynamicRefs();

  const params = {
    advanceSearchSubjectField: JSON.stringify(searchField),
    limit: 50000,
    currentDrilldownTemplateId: searchTemplateId,
    isCustom: true,
    sortColumns: JSON.stringify(secondarySortColumns || [])
  };

  const REPORT_LIMIT = isCustomSearch ? 5 : 4;

  useEffect(() => {
    if (!_.isEmpty(selectedRowField)) {
      fetchRowExtraData(selectedRowField);
    }
  }, [JSON.stringify(secondarySortColumns)])

  const primaryTableHeader = _.filter(tableHeaders, (datum) => {
    return _.isEmpty(datum['column_dataset']);
  })
  const secondaryTableHeaders = _.filter(tableHeaders, (datum) => {
    return !_.isEmpty(datum['column_dataset']);
  });

  const isDedupeStrategy = templateEntry['dedupe_last_startegy_option'] == 'all' &&
    !_.isEmpty(secondaryTableHeaders);

  const updateFilterSecondaryTableSortColumns = (columns) => {
    dispatch(updateSecondarySortColumns(columns));
  };

  const renderTableHeaderWithoutSort = () => {
    return (
      <thead>
        <tr>
          {!isSmallView && <th></th>}
          {isDedupeStrategy && <th></th>}
          {primaryTableHeader.map((entry, index) =>
            <th key={index} className='forge-typography--subtitle2'><div>{entry.name}</div></th>
          )}
        </tr>
      </thead>
    );
  };

  const renderTableHeaderWithSort = () => {
    return (
      <thead>
        <tr>
          {!isSmallView && <th className='forge-typography--subtitle2'>Compare</th>}
          {showExternalProperty() && <th className='forge-typography--subtitle2'>Photo</th>}
          {isDedupeStrategy && <th></th>}
          {
            primaryTableHeader.map((entry, index) => {
              return (
                // Added '1' to index , There is one empty column added before the Headers.
                <th style={{ Width: setWidth(index + 1) }}
                  onClick={() => onClickTableHeader(entry)}
                  key={index} className='forge-typography--subtitle2'>
                  <div>
                    {entry.name}
                    {renderHeaderSortIcon(entry)}
                  </div>
                </th>
              )
            })
          }
        </tr>
      </thead>
    );
  };

  const setWidth = (index) => {
    // Both tables Need to be aligned exactly.
    const thead = $('#subject-table thead th');
    if (_.isEmpty(thead) || _.isEmpty(thead[index])) {
      return false;
    }
    const boundingRect = thead[index].getBoundingClientRect();
    return boundingRect.width;
  };

  const renderHeaderSortIcon = (entry) => {
    const sortBy = _.find(sortColumns, (column) => { return entry.column === column.columnName });
    if (_.isEmpty(sortBy)) {
      return null;
    }
    const iconName = _.get(sortBy, 'ascending', true) ? "icons-arrow-up2" : "icons-arrow-down2";

    return (
      <span className="column-icon align-self-center"> <i className={iconName}></i></span>
    );
  };

  const onClickTableHeader = (entry) => {
    let newSortColumns = [];
    const sortBy = _.find(sortColumns, (column) => { return entry.column === column.columnName });

    if (_.isEmpty(sortBy)) {
      // On first click Added
      newSortColumns.push({
        columnName: entry.column,
        ascending: true
      });
    } else if (!_.isEmpty(sortBy) && sortBy.ascending) {
      // On second click Set to descending
      newSortColumns.push({
        columnName: entry.column,
        ascending: false
      });
    }
    onClickTableSortedHeader(newSortColumns);
  };

  const renderTableHeader = () => {
    if (_.isNil(sortColumns)) {
      return renderTableHeaderWithoutSort();
    } else {
      return renderTableHeaderWithSort();
    }
  };

  const renderRowExtraData = (rowEntry) => {
    const rowId = rowEntry['row_id_field'];
    const rowDetails = _.get(rowData, rowId, []);

    return (
      <SearchDetailsSecondaryTable
        tableHeaders={secondaryTableHeaders}
        tableData={rowDetails}
        secondarySortColumns={secondarySortColumns}
        onClickSecondaryTableSortedHeader={(sortColumns) => {
          updateFilterSecondaryTableSortColumns(sortColumns)
        }}
      />
    );
  }

  const isMultipleValueColumn = (rowEntry, columnEntry) => {
    const field = columnEntry.field;
    const isMultipleValueColumn = (!_.isEmpty(columnEntry['column_dataset']) &&
      Number(_.get(rowEntry, `${field}_count__`, 0)) > 1);
    return (isMultipleValueColumn && templateEntry['dedupe_last_startegy_option'] == 'all');
  }

  const renderTableColumn = (datum, entry, index) => {
    const value = _.get(datum, entry.field, '');
    const columnValue = _.isObject(value) ? _.get(value, 'url') : value;
    const delay = TOOLTIP_CONSTANTS.numbers.DEFAULT_DELAY;
    const isMultiValueColumn = isMultipleValueColumn(datum, entry);
    let tdValue = '';
    if (isMultiValueColumn && templateEntry['dedupe_last_startegy_option'] == 'all') {
      tdValue = _.get(datum, `${entry.field}_count__`, '');
    } else {
      tdValue = formattedValueByDataType(columnValue, entry, true);
    }

    const tableDataClassNames = classNames('text-truncate pr-2', {
      'multiline-ellipses': _.size(tdValue) > 50,
      'is-multiple-value-column': isMultiValueColumn
    });

    if(isValidPdfColumn(isSubjectSearch, searchTemplateId, index, entry, datum)){
      return (
        <td key={index}>
          <PropertyPdf value={tdValue} propertyDetails={datum}></PropertyPdf>
        </td>
      )
    } else if (!_.isNil(sortColumns)) {
      return (
        <td key={index} style={{ maxWidth: setWidth(index + 1) }}>
          <div className={tableDataClassNames}
            style={{ width: setWidth(index + 1) }}>
            <span className="accordion-area">{tdValue}</span>
          </div>
          <ForgeTooltip text={tdValue} delay={delay} position="top" />
        </td>
      );
    } else {
      return (<td key={index}><div>{tdValue}</div></td>);
    }
  };

  const renderSubjectBadge = () => {
    return (<td style={{ maxWidth: setWidth(0) }}>
      <ForgeBadge theme="warning">Subject property</ForgeBadge>
    </td>)
  };

  const handleCheckboxChange = (event, data) => {
    const isChecked = event.target.checked;
    onSelectTableCheckbox(isChecked, data);
  };

  const renderCheckBox = (data) => {
    const isChecked = _.indexOf(selectedReportRowIds, _.get(data, 'row_id_field')) >= 0;
    return (
      <td className="table-checkbox" style={{ maxWidth: setWidth(0) }}>
        <ForgeCheckbox key={_.get(data, 'row_id_field')}>
          <input type="checkbox"
            aria-label="Add to compare"
            checked={isChecked}
            onClick={(e) => handleCheckboxChange(e, data)}
            disabled={selectedReportRowIds.length >= REPORT_LIMIT && !isChecked}
            id={_.get(data, 'row_id_field')}
            key={_.get(data, 'row_id_field')}
          />
        </ForgeCheckbox>
      </td>
    );
  };

  const renderSpinner = () => {
    return (
      <LoadingSpinner isLoading={isLoading} />
    );
  }

  const fetchRowExtraData = async (rowId) => {
    const queryParams = _.merge({}, params, {
      row_id_field: rowId
    });
    setIsLoading(true);
    const response = await getDistinctData(queryParams);
    setRowData((prevData) => {
      return { ...prevData, [rowId]: response };
    });
    setIsLoading(false);
  }

  const handleColumnValueClick = (e, isMultipleValueColumn, isHyperLinkColumn, rowEntry) => {
    const rowId = rowEntry['row_id_field'];
    setSelectedRowField(rowId);

    if ((!_.isEmpty(rowData[rowId]) && isMultipleValueColumn) || isHyperLinkColumn) {
      e.stopPropagation();
      const tempRowData = rowData
      delete tempRowData[rowId];
      setRowData(tempRowData);
      setShowSecondaryTable(true);
    } else {
      if (isMultipleValueColumn) {
        setShowSecondaryTable(true);
        fetchRowExtraData(rowId);
        e.stopPropagation();
      }
    }
  }

  const handlePhotoClick = (e, datum, index) => {
    const refId = "photo_" + index;
    setShowPhotoContent(true);
    setPhotoPopupRef(refId);
    setPhotoDatum(datum);
  };

  const renderPhotoPopupContent = () => {
    return (
      <div tabIndex={0}>
        <ForgePopupOption
          showPopup={showPhotoContent}
          targetPopupRef={getPhotoRef(photoPopupRef)}
          placement="right"
          childrenClass='property-photo-popup'
          closeFilterChip={() => setShowPhotoContent(false)}
          isIncludeOutsideClose={true}>
          {renderPhotoPropertyContent()}
          {renderPhotoContent()}
        </ForgePopupOption>
      </div>
    )
  }

  const renderPhotoContent = () => {
    return (<PropertyPhoto photoDetails={photoDatum}></PropertyPhoto>);
  }

  const renderPhotoPropertyContent = () => {
    const title = _.get(photoDatum, 'comp_finder_quick_ref_id', '');
    const propertyColumn = getPropertyColumnForPdf(isSubjectSearch, searchTemplateId);
    const propertyNumberEntry = _.find(primaryTableHeader, { column: propertyColumn });
    const subTitle = _.get(photoDatum, _.get(propertyNumberEntry, 'field'), '');

    return (
      <div className='property-content d-flex align-items-center justify-content-between gap-5'>
        <CompFinderIcon templateId={searchTemplateId} searchMethod={searchMethod} />
        <div className='property-content-container'>
          <h6>{title}</h6>
          <p>{subTitle}</p>
        </div>
        <ForgeButton className='property-content-close'>
          <button type="button" onClick={() => setShowPhotoContent(false)}>
            <ForgeIcon name='close' />
          </button>
        </ForgeButton>
      </div>
    )
  }

  const shouldShowAccordionIcon = (rowEntry, secondaryColumnEntries) => {
    if (canShowAllRecords(isSubjectSearch, searchTemplateId)) {
      return (Number(rowEntry['secondary_entries_count']) > 0);
    } else {
      return _.some(secondaryColumnEntries, (entry) => {
        return (Number(_.get(rowEntry, `${entry['field']}_count__`, 0)) > 0);
      })
    }
  }

  const renderAccordionIcon = (rowEntry) => {
    if (templateEntry['dedupe_last_startegy_option'] != 'all' || _.isEmpty(secondaryTableHeaders)) {
      return null;
    }
    if (!shouldShowAccordionIcon(rowEntry, secondaryTableHeaders)) {
      return <td className="accordion-icon">{null}</td>
    }
    let iconContent;
    const roWId = _.get(rowEntry, 'row_id_field');
    if (_.isEmpty(rowData[roWId])) {
      iconContent = (<ForgeIcon name="keyboard_arrow_right"></ForgeIcon>);
    } else {
      iconContent = (<ForgeIcon name="keyboard_arrow_down"></ForgeIcon>);
    }
    return (
      <td className="accordion-icon">
        <ForgeIconButton className="mt-1" dense={true}
          onClick={(e) => handleColumnValueClick(e, true, false, rowEntry)}>
          <button type="button" aria-label="View user profile">
            {iconContent}
          </button>
        </ForgeIconButton>
      </td>
    )
  }

  const renderSecondaryTable = (datum) => {
    const rowId = datum['row_id_field'];
    if (_.isEmpty(rowData[rowId])) {
      return
    }
    const colSpan = primaryTableHeader.length + 2;
    return (
      <tr className='secondary-table-row'>
        <td colSpan={colSpan} className="secondary-row">
          <ForgeCard>
            {showSecondaryTable ? renderRowExtraData(datum) : null}
          </ForgeCard>
        </td>
      </tr>
    );
  }

  const renderPhoto = (datum, index) => {
    return (
      <ForgeIconButton className="mt-1" dense={true}
        onClick={(e) => handlePhotoClick(e, datum, index)}>
        <button type="button" aria-label="View Primary photo">
          <ForgeIcon name="camera_outline"></ForgeIcon>
        </button>
      </ForgeIconButton>
    )
  }

  const renderTableRow = () => {
    return tableData.map((datum, detailIndex) => {
      const refId = "photo_" + detailIndex;
      return (
        <>
          <tr className={detailIndex} key={detailIndex}>
            {isSmallView ?
              "" : !_.isUndefined(onSelectTableCheckbox) ? renderCheckBox(datum) : renderSubjectBadge()}
            {showExternalProperty() && <td ref={setPhotoRef(refId)}>{
              _.get(datum, 'is_property_details_available', false) ? renderPhoto(datum, detailIndex) :''
              }</td>}
            {renderAccordionIcon(datum)}
            {primaryTableHeader.map((entry, index) => renderTableColumn(datum, entry, index))}
          </tr>
          {renderSecondaryTable(datum)}
        </>
      )
    });
  };

  const renderTable = () => {
    return (
      <>
        {renderSpinner()}
        <div className={`table-responsive search-details-table ${tableId}`}
          ref={scrollContainerRef}>
          <table className='table' id={tableId}>
            {renderTableHeader()}
            <tbody>
              {renderTableRow()}
              {children}
            </tbody>
          </table>
          {renderPhotoPopupContent()}
        </div>
        <div className="d-flex table-footer">
          <div className="ml-auto">
            {showPagination &&
              <Paginator
                totalRowsCount={totalRowsCount}
                setTableOptions={setTableOptions}
              />
            }
          </div>
        </div>
      </>
    );
  };

  return onlyShowTableRow ? renderTableRow() : renderTable();
}

SearchDetailsTable.propTypes = {
  tableHeaders: PropTypes.array,
  children: PropTypes.any,
  totalRowsCount: PropTypes.number,
  showPagination: PropTypes.bool,
  onlyShowTableRow: PropTypes.bool,
  setTableOptions: PropTypes.func,
  tableData: PropTypes.array,
  tableId: PropTypes.string,
  sortColumns: PropTypes.array,
  onClickTableSortedHeader: PropTypes.func,
  isCustomSearch: PropTypes.bool,
  isSmallView: PropTypes.bool,
};

SearchDetailsTable.defaultProps = {
  showPagination: true,
  onlyShowTableRow: false,
  children: null,
  totalRowsCount: 0,
  isCustomSearch: false,
  isSmallView: false,
}

export default SearchDetailsTable;
