import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import Filters from 'common/components/Filters';
import ResetButton from 'common/components/TimeOfDayFilter/ResetButton';
import MiniMapShapeFilter from 'pages/drilldown/MiniMapShapeFilter';

import { getTemplateDatasetNames,
  getAdvanceSearchTemplateId
} from 'common/config/customerConfiguration';
import {
  updateSearchFilters,
  updateSearchResultCount,
  updateSelectedShapeIds,
  updateShapeDatasetEntry,
  updateSortColumns,
  updateTemplateId,
  updatePolygonGeojson
} from 'actions/advanceSearchActions';

import {
  getCurrentTemplateEntryById,
  getDefaultShapeDatasetEntry,
  getQuickFilterEntries
} from 'common/config/templateConfiguration';
import TemplateDropdown from 'common/components/TemplateDropDown/TemplateDropdown';
import BookmarkList from '../BookmarkSave/BookmarkList';
import PolygonFilterCard from 'modules/Map/PolygonFilterCard';
import { updateCurrentBookmark } from 'actions/bookmarkActions';
import QuickFilter from '../QuickFilter/QuickFilter';

const LeftSideCustomSearch = ({ map, onCreateBookmark }) => {
  const searchFilters = useSelector(store => _.get(store, 'advanceSearch.filters', []));
  const templateId = useSelector(store =>
      _.get(store, 'advanceSearch.templateId', getAdvanceSearchTemplateId()));
  const shapeDatasetEntry = useSelector(store => _.get(store, 'advanceSearch.shapeDatasetEntry'));
  const selectedShapeIds = useSelector(store => _.get(store, 'advanceSearch.selectedShapeIds', []));
  const searchMethod = useSelector(state => _.get(state.advanceSearch, 'searchMethod', ''));
  const isDrawingEnabled = useSelector(store =>
    _.get(store, 'advanceSearch.isDrawingEnabled', false));
  const filteredGeojson = useSelector(store =>
    _.get(store, 'advanceSearch.polygonsGeojson', {}));

  const dispatch = useDispatch();
  const filterFieldEntries = getQuickFilterEntries(templateId);
  const templateEntries = _.filter(getTemplateDatasetNames(), (entry) => {
    return _.toString(entry['enable_advance_search']) == 'true';
  });
  const apiParams = {
    ignore_view_entry: true,
    currentDrilldownTemplateId: templateId,
    availableTemplateIds: [templateId],
    drilldownEntry: JSON.stringify({ quickFilters: [] })
  };

  const resetSelection = () => {
    dispatch(updateSortColumns([]));
    dispatch(updateSearchFilters([]));
    dispatch(updatePolygonGeojson({ featuresGeojson: {} }));
    dispatch(updateCurrentBookmark(''));
    dispatch(updateSearchResultCount(0));
  }

  const handleCollectionFiltersChange = (filters) => {
    dispatch(updateSearchFilters(filters));
    if (_.isEmpty(selectedShapeIds) && _.isEmpty(filters)) {
      resetSelection();
    }
  }

  const toggleShapeIdsFilter = (newShapeIds = [], shapeExtent = null) => {
    if (_.isEmpty(shapeDatasetEntry)) {
      const newShapeDatasetEntry = getDefaultShapeDatasetEntry(templateId)
      dispatch(updateShapeDatasetEntry(newShapeDatasetEntry));
    }

    dispatch(updateSelectedShapeIds(newShapeIds, shapeExtent));

    if (_.isEmpty(newShapeIds) && _.isEmpty(searchFilters)) {
      resetSelection();
    }
  }

  const onUpdateShapeDatasetEntry = (shapeDatasetEntry) => {
    dispatch(updateShapeDatasetEntry(shapeDatasetEntry));
  }

  const onTemplateChange = (template) => {
    resetSelection();
    const selectedTemplateId = _.get(template, 'template_id');
    dispatch(updateTemplateId(selectedTemplateId));
    const newShapeDatasetEntry = getDefaultShapeDatasetEntry(selectedTemplateId)
    dispatch(updateShapeDatasetEntry(newShapeDatasetEntry));
  }

  const renderTemplateDropDown = () => {
    return (
      <TemplateDropdown
        searchMethod={searchMethod}
        templateId={templateId}
        onTemplateChange={onTemplateChange}
        templateEntries={templateEntries} />
    )
  }

  const isFiltersChanged = !_.isEmpty(searchFilters);
  const templateEntry = getCurrentTemplateEntryById(templateId);
  const currentMapStyleEntry = _.first(
    _.get(templateEntry, 'map.style_entries', [{}])
  );
  const defaultShapeDatasetEntry = _.isEmpty(shapeDatasetEntry) ?
    getDefaultShapeDatasetEntry(templateId) : shapeDatasetEntry;

  return (
    <div className="left-side-section drawer-panel-wrapper">
      <div className="sentence-builder">
        <div className="section-titles forge-typography--overline">Dataset</div>
          {renderTemplateDropDown()}
      </div>
      <hr className="dividers" />
      <div className="section-titles forge-typography--overline d-flex align-items-center">
        Filters
        <div className="ml-auto">
          {isFiltersChanged && <ResetButton onUpdate={resetSelection} />}
        </div>
      </div>
      <div className="custom-save-filter">
      <BookmarkList onCreateBookmark={onCreateBookmark}></BookmarkList>
      </div>
      {!isDrawingEnabled && !_.isEmpty(_.get(filteredGeojson, 'features')) &&
          <div className="filters-container">
            <PolygonFilterCard />
          </div>
        }
      <Filters
        disabledFilters={false}
        isCustomSearch={false}
        isDisabledAddFilterButton={true}
        type="quickFilters"
        filterFieldEntries={filterFieldEntries}
        filters={searchFilters}
        templateId={templateId}
        apiParams={apiParams}
        onFiltersChange={handleCollectionFiltersChange}
      />
      <QuickFilter />
      <div className="mapshape-filters-section">
        <MiniMapShapeFilter
          map={map}
          currentDrilldownTemplateId={templateId}
          currentMapStyleEntry={currentMapStyleEntry}
          selectedShapeEntry={defaultShapeDatasetEntry}
          selectedShapeIds={selectedShapeIds}
          dispatchToggleShapeIdsFilter={toggleShapeIdsFilter}
          updateShapeDatasetEntry={onUpdateShapeDatasetEntry}
        />
      </div>
    </div>
  );
}

LeftSideCustomSearch.propTypes = {
  map: PropTypes.object,
  onCreateBookmark: PropTypes.func
};

export default LeftSideCustomSearch;
