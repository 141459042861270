import $ from 'jquery';
import React, { Component } from "react";
import PropTypes from "prop-types";

import MouseInteractionHandler from 'modules/Map//handlers/MouseInteractionHandler';
import ChoroplethMapPartial from 'modules/Map/partials/ChoroplethMapPartial';
import ShapeLayerPartial from 'modules/Map/partials/ShapeLayerPartial';

import {
  getGeocodeBoundingbox,
  getMapCenter,
  getMapStyleEntries,
  getMapzoom,
  isMaxBoundsEnable,
} from 'common/config/templateConfiguration';
import { setIframeHeight } from 'modules/visualization/SnapshotVisualization/snapshotAfterPlotHelper';
import { getTemplateMapStyle } from 'modules/Map/helpers/mapHelper';
import { getShapeWiseChoroplethData } from '../radarHelper';
import { RADAR_MAP } from 'appConstants';
import GlobalEvent from 'common/components/GlobalEvents';
import { getRadarShapeTileUrl, getRadarData } from 'common/api/commonApi';
import { setScrollZoom } from 'helpers/mapHelper';
import { SCROLL_EVENT, MAP_MOVE_END, MAP_EVENTS } from "modules/visualization/constants";

const MAP_SOURCE_DATE_LOADING_INTERVAL_TIME = 200;
const MAP_RESIZE_WAITING_TIME = 100;
const MAP_CENTER_AND_ZOOM_WAITING_TIME = 200;
const MAP_HEADER_HEIGHT = 43;

class Map extends Component {
  static propTypes = {
    apiParams: PropTypes.object,
    metricEntry: PropTypes.object,
    selectedShapeIds: PropTypes.array,
    comparisonShapeIds: PropTypes.array,
    shapeGroupId: PropTypes.string,
    currentMapView: PropTypes.object,
    isCurrencyDimensionField: PropTypes.bool,
    onMapCreated: PropTypes.func,
    toggleShapeIdsFilter: PropTypes.func,
    currentDrilldownTemplateId: PropTypes.string,
    onLoadLegendData: PropTypes.func,
    showControl: PropTypes.bool,
    onMapDataLoading: PropTypes.func,
    isCensusTract: PropTypes.bool,
    containerHeight: PropTypes.number
  };

  static defaultProps = {
    isDrilldownVisualizationMap: false,
    isCurrencyDimensionField: true,
    onMapCreated: _.noop,
    onLoadLegendData: _.noop,
    onDataLoading: _.noop,
    currentMapView: {type: 'choropleth'},
    comparisonShapeIds: [],
    showControl: true,
    isCensusTract: false,
  };

  constructor(props) {
    super(props);
    const { currentDrilldownTemplateId } = props;

    this.state = {
      hasMapInitialized: false,
      legends: [],
      center: getMapCenter(currentDrilldownTemplateId),
      zoom: getMapzoom(currentDrilldownTemplateId),
      currentMapStyleEntry: getMapStyleEntries(currentDrilldownTemplateId)[0],
    };
    this.debouncedCenterAndZoomChange = _.debounce(
      this.mapCenterAndZoomChange,
      MAP_CENTER_AND_ZOOM_WAITING_TIME
    );
  }

  componentDidUpdate() {
    window.addEventListener('map_resize', () => this.resizeMap());
  }

  componentWillUnmount() {
    // Removing it in the next tick, so that the child controls/components can finish cleaning up.
    // (Some of them might require the map for cleaning up.)
    setTimeout(() => this.map?.remove());
    if (this._mouseInteractionHandler) { this._mouseInteractionHandler.destroy() }
    window.removeEventListener('map_resize',  this.resizeMap);
    GlobalEvent.off(SCROLL_EVENT, this.onPageScroll);
    GlobalEvent.off(MAP_MOVE_END, this.onMapMoveEnd);
    GlobalEvent.off(MAP_EVENTS.ZOOM_IN, this.onMapZoomIn);
    GlobalEvent.off(MAP_EVENTS.ZOOM_OUT, this.onMapZoomOut);
    GlobalEvent.off(MAP_EVENTS.RE_CENTER, this.onMapReCenter);
  }

  fetchTileApi = () => {
    const { apiParams, shapeGroupId } = this.props;
    const extraParams = {
      ...apiParams,
      ignore_view_entry: true,
      area_entry_id: shapeGroupId,
    }
    return getRadarShapeTileUrl(extraParams);
  }

  updateMapPosition = (center, zoom) => {
    this.map.flyTo({ center, zoom });
  }

  onMapStyleChange = () => {
    const { currentDrilldownTemplateId } = this.props;
    const { currentMapStyleEntry } = this.state;
    const mapStyle = getTemplateMapStyle(currentMapStyleEntry, currentDrilldownTemplateId);

    this.setState({ hasMapInitialized: false });
    this.map.setStyle(mapStyle, { diff: false });
    this.map.on('style.load', () => {
      if (!this.map) { return; }
        this.setState({ hasMapInitialized: true });
    });
  }

  componentDidMount() {
    this.map = this.createMap();
    if(this.map){
      if(this.props.showControl) {
        this.addControls();
      }
      this.addAttributeControl();
      this.map.once('style.load', () => {
        let loadingChecker;
        if (!this.map) { return; }

        this.initHandlers();
        this.setState({ hasMapInitialized: true });
        this.setMapMaxBounds();
        this.map.on('moveend', this.debouncedCenterAndZoomChange);
        this.map.on('dragend', this.onDragMap);
        this.map.on('zoomend', this.onZoomEnd);
        this.map.on('sourcedataloading', (mapDataEvent) => {
          const isMapboxGlDrawLayer = _.get(mapDataEvent, 'sourceId') == "mapbox-gl-draw-hot";
          if (loadingChecker || isMapboxGlDrawLayer) {
            return;
          }
          loadingChecker = setInterval(() => {
            const loaded = this.map.areTilesLoaded();

            if (loaded) {
              clearInterval(loadingChecker);
              loadingChecker = null;
              this.props.onMapDataLoading(false);
              $(this.loaderElement).removeClass('loader');
            } else {
              $(this.loaderElement).addClass('loader');
            }
          }, MAP_SOURCE_DATE_LOADING_INTERVAL_TIME);
        });
        setIframeHeight();
      });

      GlobalEvent.on(SCROLL_EVENT, this.onPageScroll);
      GlobalEvent.on(MAP_MOVE_END, this.onMapMoveEnd);
      GlobalEvent.on(MAP_EVENTS.ZOOM_IN, this.onMapZoomIn);
      GlobalEvent.on(MAP_EVENTS.ZOOM_OUT, this.onMapZoomOut);
      GlobalEvent.on(MAP_EVENTS.RE_CENTER, this.onMapReCenter);
    }
  }

  getZoom = (zoom) => {
    return _.clamp(zoom , 0, 24);
  }

  onMapZoomIn = () => {
    if(this.map) {
      this.map.flyTo({ center: this.map.getCenter(), zoom: this.getZoom(this.map.getZoom() + 1) });
    }
  }

  onMapZoomOut = () => {
    if(this.map) {
      this.map.flyTo({ center: this.map.getCenter(), zoom: this.getZoom(this.map.getZoom() - 1) });
    }
  }

  onMapReCenter = () => {
    const { currentDrilldownTemplateId } = this.props;
    if(this.map) {
      const center = getMapCenter(currentDrilldownTemplateId);
      const zoom = getMapzoom(currentDrilldownTemplateId);
      this.map.flyTo({ center, zoom });
    }
  }

  onDragMap = () => {
    GlobalEvent.emit(MAP_EVENTS.DRAG_END);
  }

  onZoomEnd = () => {
    if(this.map) {
      GlobalEvent.emit(MAP_EVENTS.ZOOM_END, this.map.getZoom());
    }
  }

  onPageScroll = (type) => {
    setScrollZoom(type, this.map);
  }

  getShapeWiseData = () => {
    const { apiParams, metricEntry } = this.props;
    return getRadarData(apiParams, this.abortFetchController)
      .then((response) => {
        return getShapeWiseChoroplethData(response, metricEntry);
      })
  }

  initHandlers = () => {
    const { apiParams, isCurrencyDimensionField } = this.props;

    this._mouseInteractionHandler = new MouseInteractionHandler(
      this.map,
      {...apiParams, isCurrencyDimensionField},
      { isRadarMap : true }
    );
  }

  mapCenterAndZoomChange = () => {
    const center = this.map.getCenter();
    const zoom = this.map.getZoom();

    GlobalEvent.emit(MAP_MOVE_END, [center.lng, center.lat], zoom);
    this.setState({ center: [center.lng, center.lat], zoom });
  }

  onMapMoveEnd = (center, zoom) => {
    if(!_.isEqual(center, this.center) || zoom !== this.zoom) {
      this.map.jumpTo({ center, zoom });
      this.center = center;
      this.zoom = zoom;
    }
  }

  onLegendsChange = (legends) => {
    const { onLoadLegendData, metricEntry } = this.props;
    onLoadLegendData(metricEntry.name, legends);
    this.setState({legends: legends});
  }

  createMap = () => {
    try{
      const { onMapCreated, currentDrilldownTemplateId } = this.props;
      const { currentMapStyleEntry } = this.state;
      const mapStyle = getTemplateMapStyle(currentMapStyleEntry, currentDrilldownTemplateId);
      const map = new mapboxgl.Map({
        container: this.radarMapElement,
        style: mapStyle,
        center: getMapCenter(currentDrilldownTemplateId),
        zoom: getMapzoom(currentDrilldownTemplateId),
        preserveDrawingBuffer:true,
        attributionControl: false,
        transformRequest: (tileUrl) => {
          const transformedUrl = tileUrl.replace(
            'api.mapbox.com/fonts/v1/mapbox',
            'api.mapbox.com/fonts/v1/socrata'
          );

          return { url: transformedUrl };
        }
      });

      onMapCreated(map);
      return map;
    } catch (err){
      console.log(err);
    }
  }

  setMapMaxBounds = () => {
    const { currentDrilldownTemplateId } = this.props;
    if (this.map) {
      const geocodeBoundingbox = getGeocodeBoundingbox(currentDrilldownTemplateId);
      if(!_.isEmpty(geocodeBoundingbox) && isMaxBoundsEnable(currentDrilldownTemplateId)) {
        this.map.setMaxBounds(geocodeBoundingbox);
      } else {
        this.map.setMaxBounds(null);
      }
    }
  }

  addControls(){
    const navigationControl = new mapboxgl.NavigationControl();
    this.map.addControl(navigationControl, 'top-right');
  }

  addAttributeControl(){
    const attributionControl = new mapboxgl.AttributionControl({compact : true});
    this.map.addControl(attributionControl);
  }

  resizeMap = _.debounce(() => {
    if(this.map) {
      this.map.resize();
    }
  }, MAP_RESIZE_WAITING_TIME)

  render() {
    const { hasMapInitialized } = this.state;
    const {
      currentDrilldownTemplateId,
      apiParams, currentMapView, shapeGroupId, isCensusTract,
      selectedShapeIds, metricEntry, toggleShapeIdsFilter, comparisonShapeIds, containerHeight
    } = this.props;
    // Choropleth map percentage change values in radar page should have '2' decimal precision
    // And suffix to be '%'
    const newMetricEntry = {
      ...metricEntry,
      precision: '2',
      singular_suffix: '%',
      plural_suffix: '%'
    };

    let childContent = null;
    if (hasMapInitialized){
      childContent = (
        <div className="child-components">
          <ChoroplethMapPartial
            currentMapView={currentMapView}
            currentDrilldownViewEntry={newMetricEntry}
            currentDrilldownTemplateId={currentDrilldownTemplateId}
            shapeGroupId={shapeGroupId}
            selectedShapeIds={[...selectedShapeIds, ...comparisonShapeIds]}
            map={this.map}
            tileParams={apiParams}
            toggleShapeIdsFilter={toggleShapeIdsFilter}
            shapeWiseDataApi={this.getShapeWiseData}
            shapeTileUrl={this.fetchTileApi}
            mouseInteractionHandler={this._mouseInteractionHandler}
            onLegendsChange={this.onLegendsChange}
            paletteName="radar"
            legendMidPoint="0"
            mapClassificationMethod='linear'
            numberOfDataClasses={10}
            mapDrawType={RADAR_MAP}
            ></ChoroplethMapPartial>
            <ShapeLayerPartial
              isCensusTract={isCensusTract}
              map={this.map}
              shapeTileUrl={this.fetchTileApi}
              currentDrilldownTemplateId={currentDrilldownTemplateId}
              shapeGroupId={shapeGroupId}
              selectedShapeIds={selectedShapeIds}
              comparisonShapeIds={comparisonShapeIds}
            ></ShapeLayerPartial>
        </div>
        );
    }

    const style = !_.isNil(containerHeight) ? { height: (containerHeight - MAP_HEADER_HEIGHT) } : {};

    return (
      <>
        <div className="radar-map">
          <div className="map-container">
            <div ref={el => this.loaderElement = el}></div>
            <div className="map-instance" ref={el => this.radarMapElement = el} style={style}>
            </div>
            {childContent}
          </div>
        </div>
      </>
    );
  }
}

export default Map;
