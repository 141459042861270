// Vendor Imports
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';

// Project Imports
import { ForgeIcon, ForgeButton, ForgeTooltip } from '@tylertech/forge-react';
import FilterEditor from './FilterEditor';
import SingleSelectFilter from './SingleSelectFilter';
import {
  addFilterChipTitle,
  getWidthAndHeightForFilterBadge,
  isClickOutSideFilterPopup
} from './FilterDomHelper';
import GlobalEvent from 'common/components/GlobalEvents';
import { isEnterButtonPressed } from 'helpers/mouseEventsHelper';
import {
  removeFilterAndGetFilters,
  getFilterConditions,
  isCurrencyRenderType,
  isValidFilter,
  isNotValidNumericValue
} from './helper';
import {
  isSingleSelectFilter,
  getLogicalOperatorEntry,
  updateFilterItems,
  isDefaultCollectionFilter
} from 'pages/drilldown/components/QuickFilterBar/helper';
import {
  STRING_TYPES_FIELD, BOOLEAN_TYPES_FIELD, CURRENCY_TYPE,
  NUMBER_TYPES_FIELD,
  FILTER_SELECT_ALL
} from 'appConstants';
import { OTHER_BUCKET_VALUE } from 'modules/visualization/constants';
import { getNullValueLabel } from 'common/config/templateConfiguration';
import { isMobileView } from 'helpers/DomPageHelper';
import { formatValueToCurrency } from 'helpers/numberHelper';
import ForgePopupOption from 'common/components/ForgePopupOption/ForgePopupOption';

const FILTER_ITEM_WIDTH = 360;

class FilterItem extends Component {
  constructor(props) {
    super(props);
    this.addFilterButtonRef = React.createRef();
    this.filterChipRef = React.createRef();
    this.filterPopupRef = React.createRef();
    this._filterContainerRef = React.createRef();
    const { filter, filterFieldEntries } = props;
    const matchedFilterEntry = _.find(filterFieldEntries, { field: filter.field }) || filter;
    const selectedFilter = isSingleSelectFilter(matchedFilterEntry) ? {} : filter;

    this.state = {
      showFilterContent: false,
      showFilterPosition: false,
      selectedFilter,
      scrollTop: 0,
      showDefaultFilterValue: false
    };
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    GlobalEvent.on('LEFT_SIDE_SCROLL_TOP', this.handlePopupPosition);
  }

  componentWillUnmount() {
    GlobalEvent.off('LEFT_SIDE_SCROLL_TOP', this.handlePopupPosition);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (isClickOutSideFilterPopup(e, this.filterRef, ['forge-popup'])) {
      this.handleClickOutsideAndApply();
    }
  }

  handlePopupPosition = ({ scrollTop }) => {
    if (this.filterRef){
      this.setState({ scrollTop: scrollTop });
    }
  };

  handleClickOutsideAndApply = () => {
    const { selectedFilter, showFilterContent } = this.state;
    const { isFilterBadge, filter, isFilterActive } = this.props;
    const isFilterChangedAndDropDownOpen = (!_.isEqual(filter, selectedFilter) &&
      !isValidFilter(selectedFilter) && !_.isEmpty(filter) && showFilterContent
    );
    if (((!_.isEmpty(selectedFilter) &&
      !isFilterBadge &&
      showFilterContent &&
      isValidFilter(selectedFilter))) ||
      isFilterChangedAndDropDownOpen
    ) {
      this.onApply(selectedFilter);
    } else {
      this.setState({ showFilterContent: false }, () => {
        isFilterActive(false);
      });
    }
  }

  onMouseEnter = (filterName, filterValuesContent) => {
    addFilterChipTitle(this.filterChipRef, filterName, filterValuesContent, true);
  }

  isDefaultFilterForCollection = () => {
    const { filter, filterFieldEntries, currentCollection } = this.props;
    const matchedFilterEntry = _.find(filterFieldEntries, { field: filter.field }) || filter;
    return ( isDefaultCollectionFilter(matchedFilterEntry, currentCollection));
  }

  toggleAddFilterButton = (e, isFilterChipClick = false) => {
    const { showFilterContent, selectedFilter } = this.state;
    const { isFilterActive, canStopPropagation, disabledFilters, isCustomSearch } = this.props;
    const isDefaultCollectionFilters = this.isDefaultFilterForCollection();    
    
    if(isDefaultCollectionFilters){
      this.setState({showDefaultFilterValue : !this.state.showDefaultFilterValue});
    }

    if (isDefaultCollectionFilters || disabledFilters || isCustomSearch){
      return;
    }

    if(!canStopPropagation) {
      e.stopPropagation();
    }
    const boundingClientRect = this.addFilterButtonRef.current.getBoundingClientRect()
    let showFilterPosition = false;

    if (boundingClientRect.x + FILTER_ITEM_WIDTH > window.innerWidth && !isMobileView()) {
      showFilterPosition = true;
    }

    this.setState({
      showFilterContent: !showFilterContent,
      showFilterPosition
    }, () => {
      isFilterActive(!showFilterContent);
      if (showFilterContent && isFilterChipClick) {
        if (isValidFilter(selectedFilter)) {
          this.onApply(selectedFilter);
        }
      }
    });
  }

  handleKeyDown = (e) => {
    const { selectedFilter } = this.state;
    let hideFilterContent = false;
    if (isEnterButtonPressed(e)) {
      if (selectedFilter.type === NUMBER_TYPES_FIELD) {
        if (!isNotValidNumericValue([selectedFilter])) {
          this.onApply(selectedFilter);
          hideFilterContent = true;
        }
      } else {
        if (isValidFilter(selectedFilter)) {
          hideFilterContent = true;
        }
      }
      if (hideFilterContent) {
        this.setState({ showFilterContent: !this.state.showFilterContent });
      }
      if(_.isEmpty(selectedFilter)){
        this.setState({ showFilterContent: true });
      }
      this.filterPopupRef.current.blur();
    }
  }

  onFilterChange = (filter) => {
    this.setState({ selectedFilter: filter });
  }

  onFilterItemClick = () => {
    if(this.props.placement === 'right-start') {
      document.dispatchEvent(new CustomEvent('scroll'));
    }
  }

  onKeyDownFilterChip = (e) => {
    if (isEnterButtonPressed(e)) {
      this.removeFilter(e);
    }
  }

  onApply = (newFilter) => {
    const { filters, onFiltersChange } = this.props;
    const newFilters = updateFilterItems(_.cloneDeep(filters), newFilter);

    this.setState({ showFilterContent: false, selectedFilter: {} }, () => {
      if (!_.isUndefined(onFiltersChange) && !isNotValidNumericValue(newFilters)) {
        onFiltersChange(newFilters);
      }
    });
  }

  getEmptyValue = () => {
    const { templateId } = this.props;
    return getNullValueLabel(templateId);
  }

  removeFilter = (event) => {
    event.stopPropagation();
    const { filter, onFiltersChange, filters, type } = this.props;
    const newFilters = removeFilterAndGetFilters(filters, filter, type);

    onFiltersChange(newFilters);
  }

  renderRemoveIcon = () => {
    const { filter, filterFieldEntries, disabledFilters, currentCollection } = this.props;
    const matchedFilterEntry = _.find(filterFieldEntries, { field: filter.field }) || filter;
    if(isDefaultCollectionFilter(matchedFilterEntry, currentCollection) || disabledFilters){
      return null;
    }
    if (isSingleSelectFilter(matchedFilterEntry)) {
      return (<i className="icons-chevron-down align-middle text-primary"></i>);
    }
    return (
      <span
        onKeyDown={this.onKeyDownFilterChip}
        tabIndex={0}
        className="tag-close rounded-circle icons-times"
        onClick={this.removeFilter} />
    );
  }

  renderFilterValue = () => {
    const { filter, filterFieldEntries } = this.props;
    const { field } = filter;
    const defaultEntry = { renderType: STRING_TYPES_FIELD, name: '' };
    const { name, renderType } = _.find(filterFieldEntries, { field }) || defaultEntry;
    const filterConditions = getFilterConditions(this.props);

    if(_.isEmpty(_.compact(filterConditions))){
      return null;
    }

    return _.map(filterConditions, (filterCondition, index) => {
      const { value: filterValues, operator: filterOperator, isOthersBucket } = filterCondition;
      let operatorEntry = {};
      const newRenderType = (renderType === BOOLEAN_TYPES_FIELD || renderType === CURRENCY_TYPE) ?
        STRING_TYPES_FIELD :
        renderType;
      if (filterOperator) {
        operatorEntry = getLogicalOperatorEntry(filterOperator, newRenderType || STRING_TYPES_FIELD);
      }
      const operatorName = isOthersBucket ? 'is' : _.lowerCase(_.get(operatorEntry, 'name', ''));
      const filterName = `${name} ${operatorName} `;
      const filterValuesWithoutSelectAll = _.filter(filterValues, (datum) => { 
        return datum != FILTER_SELECT_ALL 
      });
      const filterValuesContent = _.map(filterValuesWithoutSelectAll, (valueItem) => {
        return _.isNil(valueItem) ?
          this.getEmptyValue() :
          formatValueToCurrency(valueItem, isCurrencyRenderType(filterFieldEntries, filter));
      }).join(' or ');

      const { showDefaultFilterValue, showFilterContent } = this.state;
      let chipTextClass = showDefaultFilterValue && !showFilterContent ? '' : "chip-values-text";

      return (
        <div className={chipTextClass} key={index}
          onMouseEnter={() => this.onMouseEnter(filterName, filterValuesContent)}
          ref={this.filterChipRef}>{filterName}
          <span className="font-weight-bold">
            {isOthersBucket ? OTHER_BUCKET_VALUE : filterValuesContent}
          </span>
        </div>
      );
    });
  }

  renderFilterToolTip = () => {
    const { isCollaborated, disabledFilters } = this.props;
    
    if(!isCollaborated || !disabledFilters){
      return null;
    }

    return <ForgeTooltip
      text="This filter is set by the collection administrator and cannot be changed"
      position="top"/>

  }
  
  renderFilterLabel() {
    const { filter, isAnalysisPageFilter } = this.props;

    if (_.isEmpty(filter)) {
      return null
    }

    const isDefaultCollectionFilters = this.isDefaultFilterForCollection();    
    const chipIconName = isDefaultCollectionFilters ? 'sort_variant_lock' : "filter_list";
    const chipWidthClass = isAnalysisPageFilter ? 'chip-text-max-width': ''; 

    return (
      <div
        className={`filter-chip-values ${chipWidthClass}`}
        ref={this.addFilterButtonRef}
        onClick={(e) => this.toggleAddFilterButton(e, true)}>
        <>
          <ForgeIcon name={chipIconName} className="tx-18" />

          {this.renderFilterValue()}
          {this.renderRemoveIcon()}
        </>
      </div>
    );
  }

  renderAddFilterLabel() {
    return (
      <ForgeButton type="flat" ref={this.addFilterButtonRef}
        onClick={(e) => this.toggleAddFilterButton(e, true)}>
        <button type="button">
          <span className="text-primary">
            <ForgeIcon name="plus_thick" className="tx-12 mr-1" />Add a filter
          </span>
        </button>
      </ForgeButton>
    );
  }

  renderFilterBodyContent = () => {
    const {
      filters, filterFieldEntries, filter, type, apiParams, templateId, filterKey, isEditMode, viewEntry
    } = this.props;
    const { selectedFilter } = this.state
    const matchedFilterEntry = _.find(filterFieldEntries, { field: filter.field }) || filter;
    if (!this.state.showFilterContent) {
      return null;
    }

    if (isSingleSelectFilter(matchedFilterEntry)) {
      return (<SingleSelectFilter {...this.props} onApply={this.onApply} />);
    }
    const isModifiedFilter = !_.isEqual(selectedFilter, filter);

    return (
      <FilterEditor
        viewEntry={viewEntry}
        filterKey={filterKey}
        isEditMode={isEditMode}
        templateId={templateId}
        apiParams={apiParams}
        type={type}
        onClose={this.toggleAddFilterButton}
        filter={filter}
        onApply={this.onApply}
        allFilters={filters}
        onFilterChange={this.onFilterChange}
        onFilterItemClick={this.onFilterItemClick}
        filterFieldEntries={filterFieldEntries}
        isModifiedFilter={isModifiedFilter}
        filterPopupRef={this.filterPopupRef}
      />
    );
  }

  renderAddForgePopupContent = () => {
    const { showFilterContent } = this.state;
    const { placement } = this.props;

    return (
      <div tabIndex={0} ref={this.filterPopupRef} onKeyDown={this.handleKeyDown}>
        {this.renderAddFilterLabel()}
        <ForgePopupOption
          placement = {placement}
          showPopup={showFilterContent}
          targetPopupRef={this.addFilterButtonRef}
          closeFilterChip={this.handleClickOutsideAndApply}
          isIncludeOutsideClose={true}>
          {this.renderFilterBodyContent()}
        </ForgePopupOption>
      </div>
    )
  }

  render = () => {
    const { showFilterContent, showFilterPosition, scrollTop } = this.state;
    const { filter, isDisabledAddFilterButton, isUnSelectFilters, isEditMode, isCustomSearch } = this.props;
    const filterClassNames = classNames('global-filter',
      { 'show-filter-chip': showFilterContent },
      { 'unselect': isUnSelectFilters },
      { 'filter-chip-position': showFilterContent },
      { 'filter-position': showFilterPosition },
      { 'add-filter-btn': _.isEmpty(this.props.filter) }
    );
    const style = isEditMode && showFilterContent ? getWidthAndHeightForFilterBadge(this.filterRef) : {};
    if (_.isEmpty(filter) && isDisabledAddFilterButton) {
      return null;
    }

    let popupStyle = {};
    if(showFilterContent && scrollTop > 0) {
      popupStyle = {
        position: 'absolute',
        transform: `translate3d(0px, -${scrollTop}px, 0px)`
      }
    }

    return (
      <div>
      {_.isEmpty(filter) && this.renderAddForgePopupContent()}
        {isEditMode &&
          <div className={filterClassNames} ref={(ref) => this.filterRef = ref} style={style}>
            <div
              style={popupStyle}
              className="filter-chip"
              tabIndex={0}
              ref={this.filterPopupRef}
              onKeyDown={this.handleKeyDown}>
                {this.renderFilterLabel()}

            { !isCustomSearch && <div className="filter-chip-show">
                {this.renderFilterBodyContent()}
              </div> }
            </div>
          </div>}
      </div>
    );
  }
}

FilterItem.propTypes = {
  isDisabledAddFilterButton: PropTypes.bool,
  filterFieldEntries: PropTypes.array,
  filters: PropTypes.array,
  templateId: commonPropTypes.templateIdPropTypes,
  onFiltersChange: PropTypes.func,
  filter: PropTypes.object,
  isUnSelectFilters: PropTypes.bool,
  isEditMode: PropTypes.bool,
  isFilterBadge: PropTypes.bool,
  type: PropTypes.string,
  apiParams: PropTypes.object,
  filterKey: PropTypes.string,
  viewEntry: commonPropTypes.viewEntryPropTypes,
  // to find filter is active in current metric -- radar page metric card filter over lap issue
  isFilterActive: PropTypes.func,
  canStopPropagation: PropTypes.bool,
  placement: PropTypes.string,
  isCustomSearch: PropTypes.bool,
  isCollaborated: PropTypes.bool,
  disabledFilters: PropTypes.bool,
  currentCollection: PropTypes.object,
  isAnalysisPageFilter: PropTypes.bool
};

FilterItem.defaultProps = {
  isDisabledAddFilterButton: false,
  isUnSelectFilters: false,
  isEditMode: false,
  isFilterBadge: false,
  canStopPropagation: false,
  isFilterActive: _.noop,
  placement: "bottom-start",
  isCustomSearch: false,
  isCollaborated: false,
  disabledFilters: false,
  isAnalysisPageFilter: false
};

export default FilterItem;
