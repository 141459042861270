import React, { lazy, Suspense, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  ForgeBadge,
  ForgeCard,
  ForgeDrawer,
  ForgeIcon,
  ForgePopup
} from '@tylertech/forge-react'

import CardDrag from '../../CardList/CardDrag';
import { PopupAnimationType } from '@tylertech/forge';
import MetricIcon from './MetricIcon';
import { getCardId } from '../collectionHelper';
import MetricSearch from './MetricSearch';
import { getAllMetricEntries } from 'helpers/templateHelper';

const MetricResultNoData = lazy(() => import('./MetricResultNoData'));

function MetricDrawer(props) {
  const { metricLibraryOption } = props;
  const {
    isManageMetricLibrary,
    onHandleMetricLibrary,
    cardEntries,
    userCards,
    templateEntries
  } = metricLibraryOption;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState('');
  const [metricCardEntries, setMetricCardEntries] = useState(cardEntries);

  const targetInfoRef = useRef();

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    }
  })

  const handleClickOutside = (e) => {
    if (targetInfoRef && targetInfoRef.current
      && !targetInfoRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  }

  const handleMouseDown = (e, uniqueKey) => {
    setSelectedCard(uniqueKey);
  }

  const handleSearchMetric = (searchMetricName) => {
    const metricCards = getAllMetricEntries(templateEntries, searchMetricName);
    setMetricCardEntries(metricCards);
  }

  const renderMetricItem = (cardEntry, uniqueKey) => {
    const { templateEntry, viewEntry } = cardEntry;
    const viewName = _.get(viewEntry, 'name', '');
    const templateName = _.get(templateEntry, 'name', '');
    const cardDescription = _.get(templateEntry, 'description', '');
    const cardId = getCardId(templateEntry, viewEntry);
    const isCardExist = _.some(userCards, { card_id: cardId });
    let cardBgClass = selectedCard == uniqueKey ? 'metric-card-active-color' : '';
    cardBgClass = isCardExist ? 'metric-card-color' : cardBgClass;

    const cardProps = {
      userCardEntry: {},
      viewEntry,
      templateEntry,
      isMetricLibrary: true,
      customClass: 'card-drag'
    };

    return (
      <CardDrag {...cardProps} key={`${uniqueKey}-drag`} >
        <div className='cursor-pointer' onMouseDown={(e) => handleMouseDown(e, uniqueKey)}>
          <ForgeCard key={uniqueKey} className={cardBgClass} onClick={() => setSelectedCard(uniqueKey)}>
            <div className="d-flex align-items-center gap-10">
              <MetricIcon templateEntry={templateEntry} viewEntry={viewEntry} isCardExist={isCardExist} />
              <div className='d-flex flex-column gap-5 overflow-hidden'>
                <div className='metric-card-title'>{viewName}</div>
                <div className='metric-card-subtitle'>{templateName}</div>
                <div className='metric-card-description'>{cardDescription}</div>
              </div>
            </div>
          </ForgeCard>
        </div>
      </CardDrag>
    )
  }

  const getUniqueKey = (cardEntry, index) => {
    const { templateEntry, viewEntry } = cardEntry;
    let templateId = _.get(templateEntry, 'template_id');
    const viewId = _.get(viewEntry, 'view_id');

    return `allMetric-${templateId}-${viewId}-${index}`
  }

  const renderAllMetricItems = () => {
    if (_.isEmpty(metricCardEntries)) {
      return (
        <Suspense>
          <MetricResultNoData />
        </Suspense>);
    }

    return _.map(metricCardEntries, (cardEntry, index) => {
      return renderMetricItem(cardEntry, getUniqueKey(cardEntry, index));
    });
  }

  const renderMetricInfo = () => {
    const defaultOption = {
      placement: 'bottom',
      animationType: PopupAnimationType.Dropdown,
      offset: { x: 0, y: 0 }
    }
    return (
      <div className='mt-1 cursor-pointer'>
        <ForgeIcon name="info" ref={targetInfoRef} onClick={() => setIsOpen(!isOpen)}></ForgeIcon>
        <ForgePopup
          targetElementRef={targetInfoRef}
          open={isOpen}
          onDismiss={() => setIsOpen(false)}
          options={defaultOption}>
          <div style={{ width: '256px', padding: '15px' }} className="forge-typography--body1">
            To add a card to your collection,
            simply drag it from the library and drop it into the collection workspace.
          </div>
        </ForgePopup>
      </div>
    );
  }

  return (
    <div className='forge-metric-drawer'>
      <ForgeDrawer direction="right" slot="right" open={isManageMetricLibrary}>
        <div className='metric-drawer-wrapper'>
          <div className='metric-drawer-head'>
            <div className='d-flex align-items-center gap-10'>
              <div className='forge-typography--title title'>Metric library</div>
              {renderMetricInfo()}
              <ForgeBadge>Beta</ForgeBadge>
            </div>
            <div className='cursor-pointer' onClick={() => onHandleMetricLibrary(false)}>
              <ForgeIcon name="close"></ForgeIcon>
            </div>
          </div>
          <MetricSearch onHandleSearchMetric={handleSearchMetric} />
          <div className='metric-card-list'>
            {renderAllMetricItems()}
          </div>
        </div>
      </ForgeDrawer>
    </div>
  )
}

MetricDrawer.propTypes = {
  metricLibraryOption: PropTypes.object,
}

export default MetricDrawer
