import Moment from 'moment';
import { extendMoment } from 'moment-range';
import _ from 'lodash';
import {
  DATE_OPTIONS_TYPE,
  TIME_PICKER_OPTIONS,
  DEFAULT_GLOBAL_FILTER_TYPE,
  BIENNIUM_FISCAL_YEAR,
  DEFAULT_DATE_TYPES,
  SHAPE_AREA_TABS,
  DEFAULT_DISPLAY_ORDER,
  FORECASTING_TYPE
} from 'appConstants';
import { getCurrentTemplateEntry, getCurrentViewEntry } from './templateConfiguration';
import { isAnyModalEnabledForAdvanceForecasting } from './viewConfiguration';

import { isValidColorCode } from 'helpers/colorHelper';
import pluralize from 'pluralize';
import { isDateBetweenTwoDates, getCustomPeriodDateRange, dateRangeExpireCheck } from 'helpers/dateHelper';

const moment = extendMoment(Moment);

export const getConfig = _.memoize(() => {
  return Object.freeze(window.serverConfig.customerConfiguration)
});
export const getUserFromBellerophon = () => window.userFromBellerophon;
export const recaptchaPublicKey = window.serverConfig.recaptchaPublicKey;
export const googleStreetViewMapKey = () => window.serverConfig.googleStreetViewMapKey;
export const pendoApiKey = window.serverConfig.pendoApiKey;
export const environment = window.serverConfig.environment;
export const userIpAddress = window.serverConfig.userIpAddress;
export const gaExecutiveInsightsAccount = window.serverConfig.gaExecutiveInsightsAccount;
export const verticalSolutionsGaAccount = window.serverConfig.verticalSolutionsGaAccount;
export const applicationUsedFor = _.get(getConfig(), '__application_meta_data.app_used_for');
export const versionName = _.get(getConfig(), '__application_meta_data.current_application_version');
export const isApplicationExpired = _.get(getConfig(), 'is_app_expired', '') == 'true';

export const getRadarOptions = () => {
  const radarOptions = _.get(window, 'radarOptions');

  return !_.isEmpty(radarOptions) ? radarOptions : {
    selectAreaName: SHAPE_AREA_TABS[0].name,
    comparisonAreaName: SHAPE_AREA_TABS[1].name,
  };
}

export const showShareViaEmail = () => {
  const showShareButton = _.get(getConfig(), 'show_share_via_email', 'true');
  return showShareButton === 'true';
}

export const isTylerClientId = () => {
  const isTyler = _.get(getConfig(), 'is_tyler');
  const isNotEmptyTylerClientId = !_.isEmpty(_.get(getConfig(), 'tyler_client_id'));
  return isTyler && isNotEmptyTylerClientId;
}

export const isTemplateLevelMapSettingEnable = () => {
  return JSON.parse(_.get(getConfig(), 'enable_all_template_map_setting', true));
}

export const showReadMoreLink = () => {
  const showReadMore = _.get(getConfig(), 'show_read_more', 'true');
  return showReadMore === 'true';
}

export const showQuickStartVideosLink = () => {
  const showQuickStartVideos = _.get(getConfig(), 'branding.show_quick_start_videos', 'false');
  return showQuickStartVideos === 'true';
}

export const getAlertEmailDeliveryTime = () => {
  return _.get(getConfig(), 'alert_email_delivery_time', TIME_PICKER_OPTIONS[8]);
}

export const showExternalProperty = () => {
  return _.get(getConfig(), 'show_external_property_details', 'false') == 'true';
}

export const showAdvanceSearch = () => {
  const canShowAdvanceSearch = _.get(getConfig(), 'show_advanced_search', 'false');
  return canShowAdvanceSearch === 'true';
}

export const getAdvanceSearchName = () => {
  return _.get(getConfig(), 'advance_search.title', '');
}

export const getAdvanceSearchDisplayName = () => {
  return _.get(getConfig(), 'advance_search_entries.0.search_entries.0.name', '');
}

export const getAdvanceSearchField = () => {
  return _.get(getConfig(), 'advance_search_entries.0.search_entries.0.field');
}

export const getAdvanceSearchTemplateId = () => {
  return _.get(getConfig(), 'advance_search_entries.0.template_id', '');
}

export const getAdvanceSearchTemplates = () => {
  return _.get(getConfig(), 'advance_search_entries', '');
}

export const showCaptcha = () => {
  return _.get(getConfig(), 'enable_captcha', 'false') === 'true';
}

export const isPrivate = () => {
  return _.get(getConfig(), 'is_private', 'false') === 'true';
}

export const securityUsers = () => {
  return JSON.parse(_.get(getConfig(), 'executive_insights_app_users', '[]'));
}

export const getUserRole = (userEmail) => {
  const userRoleId = _.get(_.find(securityUsers(), { 'email': userEmail }), 'role', '');
  return _.get(_.find(getSecurityRoles(), { 'role_id': userRoleId }), 'name', '');
}

export const getCustomerDomainId = () => {
  return _.get(getConfig(), 'customer_domain100');
}

export const customReadMoreLabel = _.get(getConfig(), 'custom_readmore_label', 'Read More')

export const getReadMoreContent = _.get(getConfig(), 'readmore_page_content')

export const getForecastingConceptTitle = () => {
  return _.get(getConfig(), 'forecast_concepts_page_title')
}

export const getForecastingConceptIntroTitle = () => {
  return _.get(getConfig(), 'forecast_concepts_section_title')
}

export const getForecastingConceptIntro = () => {
  return _.get(getConfig(), 'forecast_concepts_overview')
}

export const getForecastingConceptModalTitle = () => {
  return _.get(getConfig(), 'forecast_concepts_models_title')
}

export const getForecastingConceptProphetModal = () => {
  return _.get(getConfig(), 'forecast_concepts_prophet')
}

export const getForecastingConceptHistoricalModal = () => {
  return _.get(getConfig(), 'forecast_concepts_historical')
}

export const getForecastingConceptExponentialModal = () => {
  return _.get(getConfig(), 'forecast_concepts_exponential')
}

export const getForecastingConceptAccuracyTitle = () => {
  return _.get(getConfig(), 'forecast_concepts_accuracy_statistics')
}

export const getForecastingConceptAccuracyMAPE = () => {
  return _.get(getConfig(), 'forecast_concepts_mape')
}

export const getForecastingConceptAccuracyMAE = () => {
  return _.get(getConfig(), 'forecast_concepts_mae')
}

export const getSupportLink = () => {
  return _.get(getConfig(), 'branding.link_to_support')
}

export const isShowExportButton = () => {
  const showExportButton = _.get(getConfig(), 'enable_export', 'true');
  return showExportButton === 'true';
}

export const allowLeafPageOnTableClick = () => {
  const allowLeafPageFromTable = _.get(getConfig(), 'allow_leaf_page_from_table', 'false');
  return allowLeafPageFromTable === 'true';
}

export const getTemplateEntries = () => _.get(getConfig(), 'template_entries', []);
export const getTemplateDatasetNames = () => {
  const templateEntries = getTemplateEntries();
  return _.map(templateEntries, (entry) => {
    return {
      name: _.isEmpty(_.get(entry, 'dataset_name')) ? _.get(entry, 'name') : _.get(entry, 'dataset_name'),
      template_id: _.get(entry, 'template_id'),
      enable_advance_search: _.get(entry, 'enable_advance_search')
    }
  })
}
export const getExplorationCardEntries = () => _.get(getConfig(), 'exploration_card_entries', []);

export const getBrandingTitle = _.get(getConfig(), 'branding.title', 'Executive Insights');
export const getBrandingLogoUrl = _.get(getConfig(), 'branding.customer_logo_url', '');
export const getGlobalFilterTitle = _.get(getConfig(), 'branding.global_filter_title', 'Collection filter');
export const getHeaderBarTheme = _.get(getConfig(),
  'branding.header_bar_theme', 'blue_background_white_text');

export const getConfiguredDefaultDate = () => {
  return _.get(getConfig(), 'date', {});
}

export const getGlobalFilters = () => {
  const templateEntries = getTemplateEntries();
  let globalFilters = [];

  _.forEach(templateEntries, (template) => {
    const templateGlobalFilter = _.get(template, 'global_filters', []);
    if (_.size(globalFilters) < _.size(templateGlobalFilter)) {
      globalFilters = templateGlobalFilter;
    }
  });

  return formatGlobalFilters(globalFilters);
};

export const formatGlobalFilters = (globalFilters) => {
  return _.map(_.compact(globalFilters), (globalFilter) => {
    const { filter_type } = globalFilter;
    globalFilter['filter_type'] = filter_type ? filter_type : DEFAULT_GLOBAL_FILTER_TYPE;
    return globalFilter;
  });
}

export const getGlobalFiltersFromTemplate = (templateEntry) => {
  return _.compact(_.get(templateEntry, 'global_filters', []));
}

export const getDefaultGlobalFilterEntries = () => {
  return JSON.parse(_.get(getConfig(), 'default_collection_filters', '[]'));
}

export const getTags = () => {
  return _.get(getConfig(), 'tag_list', []);
}

export const isDefaultCollectionSortOrder = () => {
  return _.get(getConfig(), 'reset_to_default_collection_order', '') == 'true';
}

export const showAdvancedForecasting = () => {
  return _.get(getConfig(), 'show_advanced_forecasting', '') == 'true';
}

export const showForecastingBetaTag = () => {
  return _.get(getConfig(), 'show_beta_tag_forecasting', '') == 'true';
}

export const isEligibleAdvancedForecasting = () => {
  const isAvailableForecasting = _.some(getTemplateEntries(), (templateEntry) => {
    return isAnyModalEnabledForTemplate(templateEntry)
  });

  return (showAdvancedForecasting() && isAvailableForecasting)
}

const isAnyModalEnabledForTemplate = (templateEntry) => {
  return _.compact(_.filter(templateEntry['advanced_forecasting_modal_status'], (modal) => {
    if (modal['value'] === 'true' || modal['value'] == 'indeterminate') {
      return true;
    }
  })).length > 0;
}

export const isModalAvailable = (templateId, viewId, type) => {
  const viewEntry = getCurrentViewEntry(templateId, viewId);
  return _.get(
    _.find(viewEntry['advanced_forecasting_modals'], {type: type.toLowerCase()}),
  'value', 'false') == 'true';
}

export const getEligibleForecastMetrics = (savedForecasts = []) => {
  const templateEntries = _.cloneDeep(getTemplateEntries());
  const eligibleForecastMetrics = [];
  _.forEach(templateEntries, (templateEntry) => {
    _.each(_.get(templateEntry, 'view_entries', []), (viewEntry) => {
      if(isAnyModalEnabledForAdvanceForecasting(viewEntry)) {
        const bookmarkByMetric = _.filter(savedForecasts, (savedForecast) => {
          return _.get(savedForecast, 'forecastOptions.selectedForecastMetric.field') ==
            _.get(viewEntry, 'field', '');
        })
        const newEntry = {
          ...viewEntry,
          template_id: templateEntry['template_id'],
          isProphetAvailable: isModalAvailable(
            templateEntry['template_id'], viewEntry['view_id'], FORECASTING_TYPE.PROPHET),
          savedForecastLength: _.size(bookmarkByMetric)
        }
        eligibleForecastMetrics.push(newEntry);
      }
    })
  })
  return _.orderBy(eligibleForecastMetrics,
    ['isProphetAvailable', 'savedForecastLength', 'name'], ['desc', 'desc', 'asc']);
}

export const getFirstTemplateViewEntryForForecasting = () => {
  const templateEntries = _.cloneDeep(getTemplateEntries());

  let templateEntry = _.find(templateEntries, (datum) => {
    return _.find(datum.view_entries, (entry) => {
      return (isAnyModalEnabledForAdvanceForecasting(entry))
    })
  });

  if (_.isEmpty(templateEntry)) {
    return {}
  }

  var viewEntry = _.find(templateEntry['view_entries'], (entry) => {
    if (isAnyModalEnabledForAdvanceForecasting(entry)) {
      return entry
    }
  });

  if (!_.isEmpty(templateEntry)) {
    templateEntry['view_entries'] = [viewEntry];
  }

  return { viewEntry, templateEntry }
}

export const isDemoApplication = () => {
  const applicationUse = _.get(getConfig(), 'application_use', 'demo');
  return applicationUse === 'demo';
}

export const isSkipHierarchyEnabled = () => {
  const skipHierarchyConfig = _.get(getConfig(), 'skip_hierarchy', 'no');
  return skipHierarchyConfig === 'yes';
}

export const getGoogleStreetViewMapKey = () => window.serverConfig.googleStreetViewMapKey;

export const getConfiguredDataEndDate = () => {
  const dataEndDate = _.get(getConfig(), 'date_options.data_end_date');
  return _.isEmpty(dataEndDate) ? Moment() : Moment(dataEndDate);
}

export const getConfiguredDateBeginType = () => {
  return _.get(getConfig(), 'date_begin_type', 'fixed_date');
}

export const enableFeatureDates = () => {
  return JSON.parse(_.get(getConfig(), 'enable_feature_dates', 'false'));
}

export const getFeatureEndDate = () => {
  return _.get(getConfig(), 'feature_end_date', false);
}

export const getDateBeginPeriodCount = () => {
  return _.get(getConfig(), 'relative_custom_date_period_count', 0);
}

export const getDateBeginPeriodType = () => {
  return _.get(getConfig(), 'relative_filter_date_period_type', 'days');
}

export const getConfiguredDateType = () => {
  return _.get(getConfig(), 'date_options.type', 'custom_range');
}

const getDateType = () => {
  const configuredDateType = getConfiguredDateType();
  const defaultDateType = _.get(getConfig(), 'date_options.default_date_type', configuredDateType);

  if (configuredDateType === BIENNIUM_FISCAL_YEAR && defaultDateType === DATE_OPTIONS_TYPE.YEARLY) {
    return BIENNIUM_FISCAL_YEAR;
  }
  return defaultDateType;
}

export const configuredDefaultDateType = getDateType();

export const isYearlyTypeDateConfigured = () => {
  return (getConfiguredDateType() == DATE_OPTIONS_TYPE.YEARLY)
}

export const getConfiguredDateDataBeginsFrom = () => {
  if (isCustomDateBeginType()) {
    const dateBeginPeriodCount = getDateBeginPeriodCount();
    const dateBeginPeriodType = getDateBeginPeriodType();
    const { startDate } = getCustomPeriodDateRange(dateBeginPeriodCount, dateBeginPeriodType);
    return startDate;
  } else {
    return _.get(getConfig(), 'date_options.min_start_date');
  }
}

export const isAvailableConfigMinDate = () => {
  const minDate = _.get(getConfig(), 'date_options.min_start_date', '');
  return !_.isEmpty(minDate)
}

export const getDateDataBeginsFrom = (templateId) => {
  const templateEntry = getCurrentTemplateEntry(templateId);
  return _.get(templateEntry, 'date_options.min_start_date') || getConfiguredDateDataBeginsFrom();
}

export const getDayOfWeekStarts = () => {
  return _.get(getConfig(), 'date_options.week_starts_from', 'sunday');
}

export const getDateRangeStartMonth = () => {
  // moment.js months are 0-indexed so subtract with '1'.
  return Number(_.get(getConfig(), 'date_options.year_start_month', 1)) - 1;
}

export const getBienniumStartFiscalYear = () => {
  return Number(_.get(getConfig(), 'date_options.biennium_fiscal_year'));
}

export const enableComparisonMode = () => {
  return JSON.parse(_.get(getConfig(), 'enable_comparison_mode', true));
}

export const shouldShowEmbedLinkInKabob = () => {
  return JSON.parse(_.get(getConfig(), 'show_embed_link_in_kabob', false));
}

export const getCustomerGAAccount = () => {
  return _.get(getConfig(), 'ga.custom');
};

export const getGaG4Account = () => {
  return _.get(getConfig(), 'ga.ga_gtag_tracking_id');
};

export const getConfiguredDefaultYear = () => {
  let currentDate = moment();
  const startMonth = getDateRangeStartMonth();
  const month = Number(currentDate.format('M'));
  if (startMonth >= 1 && month > startMonth) {
    return Number(currentDate.format('YYYY')) + 1;
  } else {
    return currentDate.format('YYYY');
  }
}

export const getConfiguredDefaultBienniumFiscalYear = () => {
  return _.get(getConfig(), 'date_options.default_biennium_fiscal_year');
}

export const getDelimiterString = () => {
  return _.get(getConfig(), 'branding.delimiter', ',');
}

export const getHeaderBackgroundColor = () => {
  const headerColor = _.get(getConfig(), 'branding.header_background_color', '');
  return (!_.isEmpty(headerColor) && isValidColorCode(headerColor)) ? headerColor : '#1d2124';
}

export const getHeaderTextColor = () => {
  const headerTextColor = _.get(getConfig(), 'branding.header_text_color', '');
  return (!_.isEmpty(headerTextColor) && isValidColorCode(headerTextColor)) ? headerTextColor : '#ffffff';
}

export const getHeaderIconColor = () => {
  // '#1d2124'
  const headerIconColor = _.get(getConfig(), 'branding.header_icon_color', '');
  return (!_.isEmpty(headerIconColor) && isValidColorCode(headerIconColor)) ?
    headerIconColor : getHeaderTextColor();
}

export const getInsightsAppUsers = () => {
  return JSON.parse(_.get(getConfig(), 'executive_insights_app_users', '[]'));
}

export const getDateTypeOptions = () => {
  const dateOptionInFilter = _.get(getConfig(), 'date_options.date_option_in_filter');
  return dateOptionInFilter ? JSON.parse(dateOptionInFilter) : DEFAULT_DATE_TYPES;
}

export const enableSecurityRoles = () => {
  return _.get(getConfig(), 'enable_security_roles', 'false') === 'true';
}

export const enableTemplateAndMetricPermission = () => {
  return _.get(getConfig(), 'enable_template_and_metric_permission', 'false') === 'true';
}

export const viewLastLoginInfo = () => {
  return _.get(getConfig(), 'show_last_login', 'true') === 'true';
}

export const getSecurityRoles = () => {
  return _.get(getConfig(), 'security_roles', []);
}

export const getDefaultSecurityRole = () => {
  const securityRoles = getSecurityRoles();
  const deafultSecurityRole = _.find(securityRoles, function (securityRole) {
    return securityRole['default_security_role'] == 'true'
  });
  return _.get(deafultSecurityRole, 'role_id', null);
}

export const enableCollectionLevelDateFilters = () => {
  return _.get(getConfig(), 'date_filter_level', 'global') === 'collection';
}

export const getGlobalDateAdditionalInfo = () => {
  return _.get(getConfig(), 'global_date_additional_info', '');
}

export const showGlobalDateAdditionalInfo = () => {
  const isGlobal = _.get(getConfig(), 'date_filter_level', '') === 'global';
  const showAdditionalInfo = _.get(getConfig(), 'show_global_date_additional_info', 'false') === 'true';
  return (showAdditionalInfo && isGlobal);
}

export const getHeaderLinks = () => {
  const order = { one: 1, two: 2, three: 3, four: 4, five: 5, six: 6, seven: 7 };
  return _.chain(_.get(getConfig(), 'header.links', {}))
    .map(function (linkConfig, indexWord) {
      if (_.isEmpty(linkConfig.name) || _.isEmpty(linkConfig.url)) {
        return;
      } else {
        return { name: linkConfig.name, url: linkConfig.url, position: order[indexWord] };
      }
    })
    .compact()
    .sortBy('position')
    .value();
}

export const showAppWarningBanner = () => {
  const expiryStartDate = _.get(getConfig(), 'expiryStartDate'),
    expiryEndDate = _.get(getConfig(), 'expiryEndDate'),
    showBanner = _.get(getConfig(), 'showWarningBanner');

  return showBanner == 'true' && dateRangeExpireCheck(expiryStartDate, expiryEndDate)

}

export const getGenericDatePickerLabel = () => {
  return _.get(getConfig(), 'generic_date_picker_label', '');
}

export const getSavedViewFilterLabel = () => {
  return _.get(getConfig(), 'saved_view_filter_label', 'Saved view date');
}

export const getAppExpirationWarningMessage = () => {
  return _.get(getConfig(), 'app_expiration_warning_message', '');
}

export const getAppExpirationBannerColor = () => {
  return _.get(getConfig(), 'app_expiry_banner_color', '#FFD97F');
}

export const getAppExpiredMessage = () => {
  return _.get(getConfig(), 'app_expired_message', '');
}

export const isRowLevelPermissionEnabled = () => {
  return (_.get(getConfig(), 'enable_row_level_permission', 'false') == 'true');
}
export const getPermissionMappingEntries = () => {
  return _.get(getConfig(), 'permission_field_mappings', {});
}

export const getInsights = () => {
  return _.get(getConfig(), 'insights.insights_entries', []);
}

export const getInsightTitle = () => {
  return _.get(getConfig(), 'insights.title', 'Insights Report');
}

export const showInsight = () => {
  return _.get(getConfig(), 'insights.show_insights', 'false') == 'true';
}

export const showRadar = () => {
  const isEmptyShapeDatasetEntries = _.isEmpty(appLevelShapeDatasetEntries);

  if(isTemplateLevelMapSettingEnable() || (useAppLevelShapeConfigs && isEmptyShapeDatasetEntries)){
    return false;
  }
  return useAppLevelShapeConfigs && _.get(getConfig(), 'radar.show_radar', 'false') == 'true';
}

export const showRadarBetaTag = () => {
  return _.get(getConfig(), 'radar.show_beta_tag', 'false') == 'true';
}


export const getInsightTableHeaders = () => {
  return _.get(getConfig(), 'insights.insights_table_headers', []);
}

export const getSlaWatchTitle = () => {
  return _.get(getConfig(), 'slaWatch.title', 'SLA Watch');
}

export const getSlaInformationText = () => {
  return _.get(getConfig(), 'slaWatch.entry.information_text');
}

export const slaLegendTitle = () => {
  return _.get(getConfig(), 'slaWatch.entry.legend_title', 'Distance from SLA');
}

export const getPassConditionText = () => {
  return _.get(getConfig(), 'slaWatch.entry.condition_pass_text', 'case type meet standards');
}

export const getFailConditionText = () => {
  return _.get(getConfig(), 'slaWatch.entry.condition_fail_text', 'case type miss standards');
}

export const showCategoriesNoDataConfig = () => {
  return _.get(getConfig(), 'slaWatch.entry.show_categories_return_no_data', false) == true;
}

export const getCategoriesWithNoDataLabel = () => {
  return _.get(getConfig(), 'slaWatch.entry.categories_with_no_data_label', '');
}

export const getSLAWatchAllChecks = () => {
  return _.get(getConfig(), 'slaWatch.entry.allChecks', []);
}

export const getSLAWatchChartType = () => {
  return _.get(getConfig(), 'slaWatch.entry.chart_type', 'bullet_chart');
}

export const getSLAWatchCalculationType = () => {
  return _.get(getConfig(), 'slaWatch.entry.calculation_type', 'percentage');
}

export const isErpCalculationType = () => {
  return (getSLAWatchCalculationType() == 'erp');
}

export const getSelectedTemplateForSLAWatch = () => {
  return _.get(getConfig(), 'slaWatch.entry.template_id');
}

export const getSummarizeByField = () => {
  return _.get(getConfig(), 'slaWatch.entry.group_by', '');
}

export const getSLAWatchFlyoutEntries = () => {
  return JSON.parse(_.get(getConfig(), 'slaWatch.flyout_entries', ''));
}

export const getFormattedDimensionEntry = (dimensionName) => {
  if (_.get(getInsightTableDimensionConfigEntry(), 'show') === 'false') {
    return;
  }

  const dimensionHeaderName = getInsightTableDimensionHeader();
  const dimensionNameConvention = _.includes(dimensionHeaderName, '{dimension_singular}') ?
    pluralize.singular(dimensionName) : pluralize(dimensionName);

  return dimensionHeaderName.replace(/{(.*?)}/g, dimensionNameConvention);
}

const getInsightTableDimensionHeader = () => {
  const insightTableDimensionHeaderEntry = getInsightTableDimensionConfigEntry();

  return _.get(insightTableDimensionHeaderEntry,
    'displayName',
    _.get(insightTableDimensionHeaderEntry, 'name', ''));
}

export const getInsightTableDimensionConfigEntry = () => {
  return _.find(_.get(getConfig(), 'insights.insights_table_headers', []),
    { id: 'avg_of_remainig_columns' });
}

export const isTimeDurationEnabled = (metricEntry) => {
  const displayValueAs = _.get(metricEntry, 'display_value_as', undefined);
  return (displayValueAs === "duration");
}

export const isNearestRoundOffDisplayAsEnabled = (metricEntry) => {
  const displayValueAs = _.get(metricEntry, 'display_value_as', undefined);
  return _.startsWith(displayValueAs, 'nearest-');
}

export const timeDurationDataUnit = (metricEntry) => {
  const dataUnit = _.get(metricEntry, 'data_unit', '');
  return isTimeDurationEnabled(metricEntry) ? dataUnit : 'seconds';
}

export const slaHeatChartBuckets = () => {
  return _.chain(getConfig()).
    get('slaWatch.entry.bucket_breakpoints', '[]').
    values().sortBy().value();
}

export const isCustomDateBeginType = () => {
  return (getConfiguredDateBeginType() === 'custom_date');
}

export const shouldShowComparison = (dateRange) => {
  if (isCustomDateBeginType()) {
    return (enableComparisonMode() && isDateBetweenTwoDates(dateRange));
  }

  return enableComparisonMode();
}

export const getUserPermissionEmailColumn = () => {
  return _.get(getConfig(), 'permission_user_column', null);
}

export const isStrictPermissionEnable = () => {
  return _.get(getConfig(), 'enable_strict_permission', 'false') === 'true';
}

export const getStrictPermissionColumn = () => {
  const isStrictPermissionEnabled = isStrictPermissionEnable();
  const column = _.get(getConfig(), 'strict_permission_column', null);
  return isStrictPermissionEnabled ? column : null;
}

export const getConditionNames = () => {
  const slaWatchAllChecks = _.first(getSLAWatchAllChecks());
  const conditionOneLabel = _.get(slaWatchAllChecks, 'metricLabel', '');
  const conditionSecondLabel = _.get(slaWatchAllChecks, 'secondMetricLabel', '');
  const conditionThirdLabel = _.get(slaWatchAllChecks, 'thirdMetricLabel', '');

  return [conditionOneLabel, conditionSecondLabel, conditionThirdLabel];
}

export const defaultTemplateEntry = () => {
  const templateEntries = getTemplateEntries();
  return templateEntries[0];
}

export const getCensusEntry = () => {
  const censusEntry = _.get(getConfig(), 'radar.census_entry', {});
  if(_.get(censusEntry, 'dataset_id')){
    return censusEntry;
  }else{
    return  {};
  }
}

export const getRadarAreaEntries = () => {
  let areaEntries = [];
  let censusEntry = _.get(getConfig(), 'radar.census_entry', {});

  if (_.get(censusEntry, 'dataset_id')) {
    censusEntry['shape_name'] = censusEntry['name'];
    censusEntry['shape_dataset_id'] = censusEntry['dataset_id'];
    censusEntry['is_census_tract'] = true;
    areaEntries.push(censusEntry);
  }

  areaEntries = areaEntries.concat(appLevelShapeDatasetEntries);
  return _.cloneDeep(_.compact(areaEntries));
}

export const getDefaultRadarAreaEntry = () => {
  const areaEntries = getRadarAreaEntries();
  const shapeEntries = appLevelShapeDatasetEntries
  const defaultShapeEntryId = _.get(getConfig(), 'radar.default_shape_entry', '');
  if (_.isEmpty(defaultShapeEntryId)) {
    return _.first(shapeEntries);
  }
  return _.find(areaEntries, { id: defaultShapeEntryId }) || areaEntries[0];
}

export const getRadarAreaEntryById = (shapeEntryId) => {
  const areaEntries = getRadarAreaEntries();
  return _.find(areaEntries, { id: shapeEntryId }) || areaEntries[0];
}

export const getSelectedRadarMetricEntriesByIds = (metricEntryIds) => {
  const metricEntries = getRadarMetricEntries();
  let selectedMetricEntries = [];
  if (_.isEmpty(metricEntryIds)) {
    selectedMetricEntries = _.take(_.cloneDeep(getDefaultRadarMetricEntries()), 3);
  }else{
    selectedMetricEntries = _.chain(metricEntries)
    .keyBy('id')
    .at(metricEntryIds)
    .value();
  }

  const entries = _.chain(selectedMetricEntries)
  .compact()
  .map((entry, index) => {
    entry.metricIndex = index;
    return _.cloneDeep(entry);
  })
  .value();

  return entries;
}

export const getSelectedRadarMapMetricById = (selectedRadarMetricIds, mapMetricId) => {

  const metricEntries = getSelectedRadarMetricEntriesByIds(selectedRadarMetricIds);
  if (_.isEmpty(mapMetricId)) {
    return metricEntries[0]
  }
  return _.find(metricEntries, { id: mapMetricId })
}

const getRadarMetricEntries = () => {
  let metricEntries =  _.get(getConfig(), 'radar.metric_entries', []);
  return _.filter(metricEntries, (entry) =>{
    return entry['hideMetric'] != 'true' && entry['showWarning'] == 'false';
  });
}

export const getPermissionRadarMetricEntries = () => {
  return _.filter(getRadarMetricEntries(), (metricEntry) => {
    return !_.get(metricEntry, 'is_hidden_metric', false);
  });
}

export const getOrderByRadarMetricEntries = () => {
  const { defaultEntries, withoutDefaultEntries } = formatRadarMetricEntries();

  return [...defaultEntries, ...withoutDefaultEntries];
}

const formatRadarMetricEntries = (usePermissionMetric = true) => {
  let defaultEntries = [];
  let withoutDefaultEntries = [];
  const metricEntries = usePermissionMetric ? getPermissionRadarMetricEntries() : getRadarMetricEntries();

  _.each(metricEntries, (metricEntry) => {
    const isDefault = JSON.parse(_.get(metricEntry, 'is_default', 'false'));
    const templateEntry = getCurrentTemplateEntry(metricEntry.template_id);
    metricEntry['templateName'] = templateEntry['name'];
    if (isDefault) {
      defaultEntries.push(metricEntry);
    } else {
      withoutDefaultEntries.push(metricEntry);
    }
  });

  return { defaultEntries, withoutDefaultEntries };
}

export const getDefaultRadarMetricEntries = () => {
  const { defaultEntries } = formatRadarMetricEntries(false);
  const newRadarCardEntries = getOrderByRadarMetricEntries();
  const cardCount = _.isEmpty(defaultEntries) ? 3 : _.size(defaultEntries);
  let defaultMetricEntries = _.take(_.cloneDeep(newRadarCardEntries), cardCount);
  defaultMetricEntries = _.map(defaultMetricEntries, (entry, index) => {
    entry.metricIndex = index;
    return entry;
  });
  return defaultMetricEntries;
}

export const defaultRadarEntry = () => {
  return getDefaultRadarMetricEntries()[0];
}

export const getDefaultRadarMetricFilters = (metricEntries) => {
  let defaultMetricFilters = [];
  _.each(metricEntries, (entry) => {
    defaultMetricFilters.push({
      id: entry['id'],
      metricIndex: entry['metricIndex'],
      filters: []
    });
  });
  return defaultMetricFilters;
}

export const getRadarPageTitle = () => {
  return _.get(getConfig(), 'radar.page_title', '');
}

export const getRadarSubCardHeader = () => {
  return _.get(getConfig(), 'radar.card_sub_header', '');
}

export const getRadarPageDescription = () => {
  return _.get(getConfig(), 'radar.page_description', '');
}

export const isNewRadarCard = () => {
  return JSON.parse(_.get(getConfig(), 'radar.is_new_card', 'false'));
}

export const getPermissionDescription = () => {
  return isRowLevelPermissionEnabled() ?
    _.get(getConfig(), 'permission_description', '') :
    '';
}

export const isCollectionFilterAscOrder = () => {
  return _.get(getConfig(),
    'collection_filter_display_order', DEFAULT_DISPLAY_ORDER) !== DEFAULT_DISPLAY_ORDER;
}

export const hamburgerMenuLabel = _.get(getConfig(), 'hamburger_menu_label', 'Advanced forecasting');
export const hamburgerMenuTagHoverText = _.get(getConfig(), 'hamburger_menu_tag_hover_text',
  'Introducing our new advanced forecasting experience, built specifically for government revenue '+
  'forecasters! Please use the feedback button in the lower right corner to let us know how we can '+
  'improve it, and what else you’d like to be able to forecast.');
export const analysisModeBetaBannerText = _.get(getConfig(), 'analysis_mode_beta_banner_text',
  'Introducing our new advanced forecasting experience, ' +
  'built specifically for government revenue forecasters.');
export const prophetModelLabel = _.get(getConfig(), 'prophet_model_label', 'Prophet');
export const prophetModelDescription = _.get(getConfig(), 'prophet_model_description',
  'Prophet is an open-source machine learning based forecasting service initially developed by Facebook. '+
  'Prophet requires at least 25 months of historical data to create a projection and will account ' +
  'for seasonality and other changes in your data. For more information, click {here}.');
export const prophetDocsLink = _.get(getConfig(), 'prophet_docs_link',
  'https://facebook.github.io/prophet/');
export const historicalAverageModelLabel = _.get(getConfig(), 'historical_average_model_label',
  'Historical average');
export const historicalAverageModelDescription = _.get(getConfig(), 'historical_average_model_description',
  'A historical average forecast data from past years, averages it month-by-month, and inflates '+
  'it by the percentage you specify. The default inflation percentage is 3%, but you can change ' +
  'that in the next step.');
export const exponentialModelLabel = _.get(getConfig(), 'exponential_model_label',
  'Simple exponential smoothing');
export const exponentialModelDescription = _.get(getConfig(), 'exponential_model_description',
  'Simple exponential smoothing is a time series forecasting method that uses a weighted '+
  'sum of past observations, with more weight placed on more recent observations. '+
  'It typically works best on datasets without trend or seasonality.');
export const forecastAccuracyTitle = _.get(getConfig(), 'forecast_accuracy_card_title',
  'Forecast accuracy');
export const forecastAccuracyDescription = _.get(getConfig(), 'forecast_accuracy_card_description',
  '');
export const forecastAccuracyPastPerformanceTooltip = _.get(getConfig(),
'forecast_accuracy_past_performance_tooltip', '');
export const forecastAccuracyMapeScoreTooltip = _.get(getConfig(), 'forecast_accuracy_mape_score_tooltip',
  '');
export const forecastAccuracyMaeScoreTooltip = _.get(getConfig(), 'forecast_accuracy_mae_score_tooltip',
  '');
export const allowDefaultWelcomeEmail = _.isEqual(
  _.get(getConfig(), 'allow_default_welcome_email', 'true'), 'true');
export const useAppLevelShapeConfigs = !_.isEqual(
  _.get(getConfig(), 'enable_template_level_shape_dataset_settings', ''), 'true');

export const appLevelShapeDatasetEntries = _.get(getConfig(), 'global_shape_dataset_entries', []);
export const appLevelShapeOutlineEntries = _.get(getConfig(), 'global_shape_outline_dataset_entries', []);
export const appLevelShapeFlyoutEntries = _.get(getConfig(), 'global_shape_flyout_entries', []);
export const alertEmailDeliveryTimeZone = _.get(getConfig(), 'alert_email_delivery_time_zone', '-7.00');
export const showOnboardWizardFromConfig = _.get(getConfig(), 'is_onboarding_wizard', 'false') === 'true';

export const isKMeanClusteringEnable = () => {
  const disableSnapToGrid = _.get(getConfig(), 'enable_snap_to_grid', 'true') == 'false';
  const enabledKMeanClustering = _.get(getConfig(), 'enable_k_mean_clustering', 'false') == 'true';
  return disableSnapToGrid ? enabledKMeanClustering : true;
}
