import _ from 'lodash';
import moment from 'moment';

import {
  DEFAULT_DROPDOWN_OPTION,
  COMPARE_VIEW_DRILLDOWN_OPTIONS,
  NO_OF_BENCH_MARKS,
  DRILLDOWN_VIEW_TYPE,
  PRIMARY_BAR_WIDTH,
  PARALLEL_BAR_WIDTH,
  BENCHMARK_TEXT,
  LINE_CHART_TOTAL_TRACE_COLOR,
  LINE_CHART_TOTAL_COMPARE_TRACE_COLOR,
  LINE_CHART_TRACE_COLORS,
  SNAPSHOT_VISUALIZATION_TYPES
} from 'appConstants';
import { isIndependentAxesValues, getFormattedValue } from 'helpers/chartDataHelper';
import { getPrimaryMetricName } from 'helpers/displayNameHelper';
import { getNumericRangeData } from 'helpers/rangeHelper';
import { VIEW_MODE } from 'modules/visualization/constants';
import { getNullValueLabel } from 'common/config/templateConfiguration';
import { getConfiguredMetricColor } from 'common/config/visualizationConfiguration';
import { formatValueToCurrency } from 'helpers/numberHelper';
import { getInBetweenYearsForDateRange } from 'helpers/dateHelper';
const BAR_CHART_API_LIMIT = 6;
const BAR_CHART_LIMIT = BAR_CHART_API_LIMIT - 1;

export const isBenchMarkIsEmpty = (benchMarkEntry) => {
  return _.isEmpty(benchMarkEntry) || _.isEqual(benchMarkEntry, DEFAULT_DROPDOWN_OPTION)
}

export const isBenchMarkEmpty = (benchMarkEntries) => {
  return _.isEmpty(benchMarkEntries) || _.isEqual(_.get(benchMarkEntries, '[0]'), DEFAULT_DROPDOWN_OPTION)
}

export const isBulletTypeChart = (vizOptions) => {
  const { secondaryMetricEntry } = vizOptions;
  return _.get(secondaryMetricEntry, 'render_type') === 'bullet';
}

export const isParallelTypeChart = (vizOptions) => {
  const { secondaryMetricEntry } = vizOptions;
  return _.get(secondaryMetricEntry, 'render_type') === 'parallel';
}

export const isStackTypeChart = (vizOptions) => {
  const { secondaryMetricEntry } = vizOptions;
  return _.get(secondaryMetricEntry, 'render_type') === 'stack';
}

export const isGroupByStackBar = (vizOptions) => {
  const { groupByEntry, groupType } = vizOptions;
  const isGroupByColumnConfigured = !_.isEmpty(groupByEntry) && _.get(groupByEntry, 'name') !== 'None';
  return isCompareView(groupType) && isStackTypeChart(vizOptions) && isGroupByColumnConfigured;
}

export const isGroupByBar = (vizOptions) => {
  const { groupByEntry, groupType } = vizOptions;
  const isGroupByColumnConfigured = !_.isEmpty(groupByEntry) && _.get(groupByEntry, 'name') !== 'None';
  return isCompareView(groupType) && !isStackTypeChart(vizOptions) && isGroupByColumnConfigured;
}

export const isGroupByAndNoneStackBar = (vizOptions) => {
  const { groupByEntry, groupType } = vizOptions;
  return isCompareView(groupType) && isStackTypeChart(vizOptions) && !_.isEmpty(groupByEntry);
}

export const isCompareView = (groupType) => {
  return (groupType === DRILLDOWN_VIEW_TYPE[0].type);
}

export const getChartType = (vizOptions) => {
  const secondaryBarOptions = _.get(vizOptions, 'secondaryMetricEntry', {});
  return _.get(secondaryBarOptions, 'render_type');
}

export const isParallelOrBulletChart = (vizOptions) => {
  return isParallelTypeChart(vizOptions) || isBulletTypeChart(vizOptions);
}

export const formatDimensionText = (dimension, isTruncateText = false, templateId) => {
  const nullValueLabel = getNullValueLabel(templateId);
  return _.isNil(dimension) || dimension === '' ? nullValueLabel :
    (isTruncateText ? _.truncate(dimension, { 'length': 40 }) : dimension);
};

export const getBarWidth = (groupByEntry, secondaryMetricEntry, isSecondaryBar) => {
  if (isParallelTypeChart({ secondaryMetricEntry })) {
    return PARALLEL_BAR_WIDTH
  } else {
    return isSecondaryBar ? (Number(PRIMARY_BAR_WIDTH) / 2) - 0.1 : PRIMARY_BAR_WIDTH
  }
}

export const getBarOffset = (groupByEntry, secondaryMetricEntry, isSecondaryBar) => {
  if (isParallelTypeChart({ secondaryMetricEntry })) {
    return isSecondaryBar ? -0.3 : 0;  // -0.4
  } else {
    return '';
  }
}

export const getMaxValueFromBenchMarks = (benchMarkEntries) => {
  if (_.isEmpty(benchMarkEntries)) {
    return 0;
  }

  const benchMarksRange = _.range(0, NO_OF_BENCH_MARKS);
  const valueKeys = _.map(benchMarksRange, (benchMarkIndex) => {
    return benchMarkIndex > 0 ? `value${benchMarkIndex}` : 'value';
  });

  let benchMarkValues = [];
  _.map(benchMarkEntries, (benchmark) => {
    const benchMarkValue = _.values(_.pick(benchmark, valueKeys));
    benchMarkValues = benchMarkValues.concat(benchMarkValue);
  });

  return Number(_.maxBy(benchMarkValues, function (value) { return Number(value); }));
}

export const getRange = (formattedData, field, vizOptions) => {
  const benchMarkEntries = _.get(vizOptions, 'benchMarkEntries', [])
  const benchMarkMaxValue = getMaxValueFromBenchMarks(benchMarkEntries);
  let minValue = 0, maxValue;
  const ranges = _.map(_.flatten(formattedData), (data) => {
    return Number(data[field]);
  });

  if (_.min(ranges) < 0) {
    minValue = _.min(ranges);
  }
  maxValue = _.max(ranges);
  if (maxValue < benchMarkMaxValue) {
    // Added constant 15 for showing benchmark lines.
    maxValue = benchMarkMaxValue + 15;
  }
  return [minValue, maxValue];
}

export const getAxisRange = (formattedData, vizOptions) => {
  const primaryBarRange = getRange(formattedData, 'label', vizOptions);
  const secondaryBarRange = getRange(formattedData, 'secondaryLabel', vizOptions);

  if (_.isEmpty(primaryBarRange)) {
    return {};
  }
  const minRange = _.min([primaryBarRange[0], secondaryBarRange[0]]);
  const maxRange = _.max([primaryBarRange[1], secondaryBarRange[1]]);
  const xaxisEnd = primaryBarRange[1];
  const xaxis2End = secondaryBarRange[1];
  let xaxisRange;
  let xaxis2Range

  // if data has negative values, then we setting range as for
  // both axis even if its isIndependentAxesValues,
  // so center position of axis(0) wont move
  if (isIndependentAxesValues(vizOptions) && minRange >= 0) {
    xaxisRange = [minRange, xaxisEnd];
    xaxis2Range = [minRange, xaxis2End];
  } else {
    xaxisRange = [minRange, maxRange];
    xaxis2Range = [minRange, maxRange];
  }

  return {
    xaxis: { range: xaxisRange },
    xaxis2: { range: xaxis2Range }
  };
}

export function getApiDataEntries(apiData, vizOptions) {
  const { groupByEntry, viewMode, dimensionName, currentSnapshotView } = vizOptions;
  const isSmallView = (viewMode === VIEW_MODE.SMALL);
  const newApiData = combineCompareDateRangeData(apiData);
  let responseEntries = _.isEmpty(groupByEntry) && vizOptions.isNumericDimensionField ?
    getNumericRangeData(newApiData) :
    _.get(newApiData, 'entries', []);
  if (_.isEmpty(dimensionName) && currentSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.BAR_CHART.type) {
    _.each(responseEntries, (entry) => {
      entry['dimension'] = _.isEmpty(entry['dimension']) ? 'Total' : entry['dimension'];
    })
  }

  if (isSmallView) {
    return getLimitedResponseEntries(responseEntries);
  } else {
    return responseEntries;
  }
}

export const getTraceName = (vizOptions, isSecondaryBar) => {
  const { viewEntry, secondaryMetricEntry } = vizOptions;
  const primaryMetricName = getPrimaryMetricName(viewEntry);
  const secondaryMetricName = _.get(secondaryMetricEntry, 'name');
  return isSecondaryBar ? secondaryMetricName : primaryMetricName;
}

export const getBenchMarkValues = (benchMarkEntry) => {
  if (_.isEqual(benchMarkEntry, DEFAULT_DROPDOWN_OPTION)) {
    return [];
  }
  const benchMarksRange = _.range(0, NO_OF_BENCH_MARKS);
  const valueKeys = _.map(benchMarksRange, (benchMarkIndex) => {
    return benchMarkIndex > 0 ? `value${benchMarkIndex}` : 'value';
  });

  return _.values(_.pick(benchMarkEntry, valueKeys));
}

export const getColor = (vizOptions, isSecondaryBar, formattedData) => {
  const { templateId } = vizOptions;
  const primaryMetricColor = getConfiguredMetricColor(templateId, 'primary');
  const secondaryMetricColor = getConfiguredMetricColor(templateId, 'secondary');
  let color = '';
  if (isParallelOrBulletChart(vizOptions)) {
    // We have interchanged the metric colors purposively based on client requirements.
    color = isSecondaryBar ? primaryMetricColor : secondaryMetricColor;
  } else {
    color = primaryMetricColor;
  }
  return isStackTypeChart(vizOptions) ? _.map(formattedData, 'color') : color;
}

export const getNoOfBarsCount = (rowOption, maxCount) => {
  if (_.isUndefined(rowOption)) {
    return maxCount;
  }
  // here we get the Top 20 data or all data based on selected DRILLDOWN_VIEW_OPTION.
  if (rowOption.rowCount === COMPARE_VIEW_DRILLDOWN_OPTIONS[0].rowCount) {
    return rowOption.rowCount;
  } else {
    return maxCount;
  }
}

export const getViewLabel = (view, nullValueLabel) => {
  view  = _.toString(view)
  return _.isEmpty(view) || (view === 'undefined') || (view === 'null') ? nullValueLabel : view;
}

export const formatScatterChartTickLabel = (
  data,
  secondaryMetricOptions,
  isTruncateText = false,
  templateId,
  isSmallView = false,
  isCurrencyDimensionField = false
) => {
  const nullValueLabel = getNullValueLabel(templateId);
  let actualLabel = formatValueToCurrency(_.get(data, 'dimension', nullValueLabel), isCurrencyDimensionField);
  actualLabel = isTruncateText ? getWrapTickText(actualLabel, templateId) : actualLabel;
  const isDateComparisonEntry = _.get(secondaryMetricOptions, 'isComparisonEntry', false);
  if (data['secondary_count'] && !isSmallView && !isDateComparisonEntry) {
    const secondaryCount = getFormattedValue(data['secondary_count'], secondaryMetricOptions);
    actualLabel += `<span style="font-size:12px; font-weight: bold; fill: black;">
    ${' ' + secondaryCount} </span>`;
  }
  return actualLabel;
}

export const getWrapTickText = (actualLabel, templateId) => {
  const nullValueLabel = getNullValueLabel(templateId);
  let labelWords = _.isNil(actualLabel) || actualLabel === '' ? nullValueLabel : actualLabel;
  const letterLimitCount = 30;

  if (labelWords.length > letterLimitCount) {
    const labelSplitWords = _.split(actualLabel, ' ');
    if (labelSplitWords.length > 1 && labelSplitWords[0].length <= letterLimitCount) {
      return getFormatTickLabel(labelSplitWords, letterLimitCount);
    } else {
      return _.truncate(labelWords, { 'length': letterLimitCount })
    }
  } else {
    return _.truncate(labelWords, { 'length': letterLimitCount })
  }
}

export const getFormatTickLabel = (labelSplitWords, letterLimitCount) => {
  let firstTextCount = 0;
  let secondTextCount = 0;
  let canMoveToSecondLine = false;
  let firstLineString = "";
  let secondLineString = "";
  let thirdLineString = "";

  _.forEach(labelSplitWords, (word) => {
    const isWordLengthExceeded = ((letterLimitCount - firstTextCount) > word.length);
    if (word.length <= letterLimitCount && firstTextCount == 0) {
      firstTextCount = word.length;
      firstLineString = word
    } else {
      // continue to same line
      if (isWordLengthExceeded && canMoveToSecondLine === false && secondTextCount == 0) {
        firstLineString = `${firstLineString} ${word}`;
        firstTextCount = firstLineString.length;
      } else if (word.length <= letterLimitCount && secondTextCount == 0) {
        if (!canMoveToSecondLine) {
          firstLineString = `${firstLineString}<br>`;
        }
        secondTextCount = word.length;
        secondLineString = word
      } else {
        if ((letterLimitCount - secondTextCount) > word.length && canMoveToSecondLine === false) {
          secondLineString = `${secondLineString} ${word}`;
          secondTextCount = secondLineString.length;
        } else {
          if (!canMoveToSecondLine) {
            secondLineString = `${secondLineString}<br>`;
          }
          thirdLineString = `${thirdLineString} ${word}`;
          canMoveToSecondLine = true;
        }
      }
    }
  });

  //final result
  if (!_.isEmpty(thirdLineString)) {
    thirdLineString = _.truncate(thirdLineString, { 'length': letterLimitCount });
  }

  return `${firstLineString} ${secondLineString} ${thirdLineString}`;
}

export const getReverseDataForPlotlyChart = (data) => {
  if (_.isArray(data)) {
    return _.reverse(data);
  } else {
    return reverseObject(data);
  }
}

function reverseObject(object) {
  var newObject = {};
  const keys = _.keys(object);

  _.forEachRight(keys, function (key) {
    newObject[key] = object[key];
  });

  return newObject;
}

export const getLimitedResponseEntries = (responseEntries) => {
  return _.chain(responseEntries).
    groupBy('dimension').
    map((dimensionEntries, category) => {
      return {
        category,
        count: _.sumBy(dimensionEntries, (entry) => Number(entry.count)),
        entries: dimensionEntries
      }
    }).
    orderBy('count', 'desc').
    map('entries').
    take(BAR_CHART_LIMIT).
    flattenDeep().
    value();
}

const combineCompareDateRangeData = (apiData) => {
  const compareData = _.get(apiData, 'comparisonDateRangeData.api_data.entries', []);
  const entries = _.get(apiData, 'entries', []);
  if (_.isEmpty(compareData)) {
    return apiData;
  }
  const groupedCombineData = _.groupBy(compareData, (d) => {
    return d['dimension'] + d['group_by_field'];
  });
  _.each(entries, (entry) => {
    const key = (entry['dimension'] + entry['group_by_field'])
    entry['secondary_count'] = _.get(groupedCombineData[key], '[0].count');
  });
  return {
    ...apiData,
    entries
  }
}

export const getBetweenYearRange = (vizOptions) => {
  const { compareYearRanges, commonFilters } = vizOptions;
  const { dateRange } = commonFilters;

  let compareYearWithLabels = [];
  const inBetweenYearsForDateRange = getInBetweenYearsForDateRange(dateRange);
  if (!_.isEmpty(inBetweenYearsForDateRange)) {
    const yearObject = getRangeLabel(inBetweenYearsForDateRange);
    compareYearWithLabels.push(yearObject);
  }

  _.forEach(compareYearRanges, (range) => {
    const inBetweenYearsForComparisonDateRange = getInBetweenYearsForDateRange(range);
    if (!_.isEmpty(inBetweenYearsForComparisonDateRange)) {
      const yearObject = getRangeLabel(inBetweenYearsForComparisonDateRange);
      compareYearWithLabels.unshift(yearObject);
    }
  });

  return compareYearWithLabels
}

const getRangeLabel = (betweenYearRange) => {
  const year = _.first(betweenYearRange);
  const endYear = _.last(betweenYearRange)

  return {
    betweenYears: betweenYearRange,
    year,
    label: _.size(betweenYearRange) > 1 ? `${year}-${endYear}` : year
  }
}

export const getCompareRangeYears = (formattedData, vizOptions = {}) => {

  const compareYearLabelRange = getBetweenYearRange(vizOptions)

  const compareFormatData = _.flatten(formattedData);
  let compareRanges = [];
  _.forEach(compareFormatData, (comp) => {
    const { period } = comp;
    let year = moment(comp['period']).format('YYYY');

    const yearLabelObject = _.find(compareYearLabelRange, { year: year });
    const yearLabel = _.isEmpty(yearLabelObject) ? year : _.get(yearLabelObject, 'label');
    if (year !== "Invalid date" || comp['period'] == BENCHMARK_TEXT) {
      compareRanges.push({ period: period, year: year, yearLabel: yearLabel })
    }
  });

  return getRangeSortOrders(_.uniqBy(compareRanges, 'period'));
}

const getRangeSortOrders = (compareRanges) => {
  const findIndex = _.findIndex(compareRanges, { period: BENCHMARK_TEXT });
  let filterRanges = _.filter(compareRanges, (range) => {
    return !_.isUndefined(range.period) && range.period !== BENCHMARK_TEXT
  });

  const colors = _.size(filterRanges) > 2 ?
    LINE_CHART_TRACE_COLORS :
    [LINE_CHART_TOTAL_TRACE_COLOR, LINE_CHART_TOTAL_COMPARE_TRACE_COLOR];

  filterRanges = _.sortBy(filterRanges, 'year');

  filterRanges = _.map(filterRanges, (datum, index) => {
    const colorIndex = _.size(filterRanges) - (index + 1);
    datum['color'] = colors[colorIndex];
    return datum
  });

  if (findIndex >= 0) {
    filterRanges.push({ period: BENCHMARK_TEXT });
  }

  return filterRanges;
}
