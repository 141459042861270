import {
  getCurrentTemplateEntryById,
  getAdvancedSearchTemplateEntryById,
  getPropertyColumnForPdf
} from 'common/config/templateConfiguration';
import { showExternalProperty } from 'common/config/customerConfiguration';
import { formattedValueByDataType } from 'modules/DetailsTable/TableHelper';
import { getFeatureCollectionBounds } from 'modules/Map/helpers/mapHelper';

export const getReportDataBoundingPoints = (reportEntries, subjectDatum) => {
  if(_.isEmpty(reportEntries) && _.isEmpty(subjectDatum)){
    return [];
  }
  let mapData = _.map(reportEntries, (entry) => {
    return {
      ...entry,
      geometry: {
        ...entry['location'],
        coordinates: [[[_.get(entry, 'location.coordinates', [])]]]
      }
    }
  })
  if(!_.isEmpty(_.get(subjectDatum, 'location'))){
    mapData.push({
      ...subjectDatum,
      geometry: {
        ...subjectDatum['location'],
        coordinates: [[[_.get(subjectDatum, 'location.coordinates', [])]]]
      }
    });
  }
  mapData = _.filter(mapData, (datum) => {
    return !_.isEmpty(datum['location']);
  });
  return _.isEmpty(mapData) ? [] : getFeatureCollectionBounds({features: mapData});
}

export const addSortTextForReportData = (reportPageData, selectedReportRowIds, isCustomSearch) => {
  const incrementIndex = isCustomSearch ? 0 : 1;
  const newData = _.map(reportPageData, (datum) => {
    let index = _.findIndex(selectedReportRowIds,  (id) => {return id == datum['row_id_field']});
    index = index == -1 ? 27 : index;
    return{
      ...datum,
      'sort_text': String.fromCharCode(index + incrementIndex + 65)
    }
  });
  return _.sortBy(newData, 'sort_text');
}

export const canShowAllRecords = (isSubjectSearch, templateId) => {
  let templateEntry =  getCurrentTemplateEntryById(templateId);
  if(isSubjectSearch){
    templateEntry = getAdvancedSearchTemplateEntryById(templateId);
  }
  return templateEntry['comp_finder_dedupe_strategy'] == 'all';
}

export const getSecondaryValuesList = (secondaryValues, tableEntry) => {
  if(_.isEmpty(secondaryValues)){
    return [];
  }

  return _.compact(_.map(secondaryValues, (secondaryDatum) => {
    const value = _.toString(_.get(secondaryDatum, tableEntry.field, ''));
    if(_.isEmpty(value)){
      return '';
    } else {
      return formattedValueByDataType(value, tableEntry, true);
    }
  }));
}

export const isValidPdfColumn = (isSubjectSearch, searchTemplateId, index, entry, data) => {
  const propertyColumn = getPropertyColumnForPdf(isSubjectSearch, searchTemplateId);
  return (
    (( showExternalProperty() && _.isEqual(propertyColumn, _.get(entry, 'column'))) ||
    ( showExternalProperty() && index == 0 && _.isEmpty(propertyColumn))) &&
    _.get(data, 'is_property_details_available', false)
  )
}
