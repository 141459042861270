export const UPDATE_DATA_RANGE_FILTERS = 'UPDATE_DATA_RANGE_FILTERS';
export const UPDATE_GLOBAL_FILTER = 'UPDATE_GLOBAL_FILTER';
export const UPDATE_SLA_DATA_RANGE_FILTERS = 'UPDATE_SLA_DATA_RANGE_FILTERS';
export const UPDATE_SLA_GLOBAL_FILTER = 'UPDATE_SLA_GLOBAL_FILTER';
export const UPDATE_RELATIVE_DATE_FILTER = 'UPDATE_RELATIVE_DATE_FILTER';
export const UPDATE_DATE_TYPE = 'UPDATE_DATE_TYPE';
export const UPDATE_COMPARISON_TYPE = 'UPDATE_COMPARISON_TYPE';
export const UPDATE_COMPARISON_MODE_ON = 'UPDATE_COMPARISON_MODE_ON';
export const UPDATE_ALL_METRICS_FILTER = 'UPDATE_ALL_METRICS_FILTER';
export const UPDATE_ALL_METRICS_DATE_FILTER = 'UPDATE_ALL_METRICS_DATE_FILTER';
export const UPDATE_COMMON_DATE_FILTERS = 'UPDATE_COMMON_DATE_FILTERS';
export const UPDATE_COLLECTION_DATE_FILTERS = 'UPDATE_COLLECTION_DATE_FILTERS';
export const UPDATE_COMPARISON_DATE_RANGES = 'UPDATE_COMPARISON_DATE_RANGES';

export const updateDateRange = (dateRange) => ({
  type: UPDATE_DATA_RANGE_FILTERS,
  dateRange
});

export const updateDateFilters = (dateFilters) => ({
  type: UPDATE_COMMON_DATE_FILTERS,
  dateFilters
});

export const updateSlaDateFilters = (dateFilters) => ({
  type: UPDATE_SLA_DATA_RANGE_FILTERS,
  dateFilters
});

export const updateSlaGlobalFilter = (globalFilters) => ({
  type: UPDATE_SLA_GLOBAL_FILTER,
  globalFilters
});

export const updateAllMetricsDateFilters = (dateFilters) =>({
  type: UPDATE_ALL_METRICS_DATE_FILTER,
  dateFilters
})

export const updateDateType = (dateType) => ({
  type: UPDATE_DATE_TYPE,
  dateType
});

export const updateComparisonModeOn = (comparisonModeOn) => ({
  type: UPDATE_COMPARISON_MODE_ON,
  comparisonModeOn
});

export const updateComparisonType = (comparisonType, dateRange) => ({
  type: UPDATE_COMPARISON_TYPE,
  comparisonType,
  dateRange
});

export const updateComparisonDateRanges = (dateRanges) => ({
  type: UPDATE_COMPARISON_DATE_RANGES,
  dateRanges
});

export const updateGlobalFilter = (globalFilters) => ({
  type: UPDATE_GLOBAL_FILTER,
  globalFilters
});

export const updateAllMetricsFilter = (allMetricFilters) => ({
  type: UPDATE_ALL_METRICS_FILTER,
  allMetricFilters
})

export const updateRelativeDateFilterEntry = (relativeDateFilterEntry) => ({
  type: UPDATE_RELATIVE_DATE_FILTER,
  relativeDateFilterEntry
});

export const updateCollectionDateFilters = (collectionDateFilters) => ({
  type: UPDATE_COLLECTION_DATE_FILTERS,
  collectionDateFilters
});
