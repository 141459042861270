// vendor Imports
import React, { Component } from 'react';

// Project Imports
import * as commonPropTypes from 'common/propTypes';
import PropTypes from 'prop-types';
import {
  numberOperatorEntries,
} from 'pages/drilldown/components/QuickFilterBar/helper';
import DropdownGroup from 'common/components/DropdownGroup/DropdownGroup';
import MonitorConditionValueSelector from
  'pages/drilldown/components/Bookmark/BookmarkNotifyButton/MonitorConditionValueSelector';

class NumberFilter extends Component {

  constructor(props) {
    super(props);
    const { filter } = this.props;

    this.state = {
      logicalOperatorEntry: numberOperatorEntries[0],
      value: this.props.isExpanded ? filter['value'] : '',
      toValue: this.props.isExpanded ? filter['to'] : '',
    }
  }

  componentDidMount() {
    const { filter } = this.props;
    const { value, to } = filter;
    const filterOperator = _.get(filter, 'operator', numberOperatorEntries[0].value);
    const logicalOperatorEntry = _.find(numberOperatorEntries, { value: filterOperator });
    this.setState({ logicalOperatorEntry, value, toValue: (to || "") });
  }

  componentDidUpdate(prevProps) {
    const { filter } = this.props;
    const isFilterChanged = !_.isEqual(prevProps.filter, filter);

    if(isFilterChanged) {
      const { value, to } = filter;
      const filterOperator = _.get(filter, 'operator', numberOperatorEntries[0].value);
      const logicalOperatorEntry = _.find(numberOperatorEntries, { value: filterOperator });
      this.setState({ logicalOperatorEntry, value, toValue: (to || "") });
    }
  }

  onChangeOperator = (option) => {
    const { value, toValue } = this.state;
    this.setState({ logicalOperatorEntry: option }, () => {
      this.props.onOperatorChange({ operator: option.value, value, to: toValue });
    });
  }

  handleInputChange = (e) => {
    const { filter } = this.props;
    const value = e.target.value;
    this.setState({ value }, () => {
      this.props.onSearchChange({ ...filter, value: value });
    });
  }

  handleToValueChange = (e) => {
    const { filter } = this.props;
    const value = e.target.value;
    this.setState({ toValue: value }, () => {
      this.props.onSearchChange({ ...filter, to: value });
    });
  }

  renderToValueTextBox() {
    const { logicalOperatorEntry, toValue } = this.state;
    const { viewEntry, isExpanded } = this.props;
    if (logicalOperatorEntry.value != 'between' && logicalOperatorEntry.value != 'not between') {
      return null;
    }

    return (
      <div key="to-text-box" className="monitor-value">
        <label className="mb-2"> To: </label>
        <MonitorConditionValueSelector
          className='form-control-sm'
          isExpanded={isExpanded}
          value={toValue}
          index={1}
          viewEntry={viewEntry}
          handleMonitorValueChange={this.handleToValueChange}
          placeholder={(this.state.logicalOperatorEntry.name === 'Is between') 
          || (this.state.logicalOperatorEntry.name === 'Is not between')? "Max":'Number'}
        />
      </div>
    );
  }

  renderFromTextBox() {
    const { viewEntry, isExpanded } = this.props;
    const { value } = this.state;
    return (
      <MonitorConditionValueSelector
        className='mt-2 mb-2 form-control-sm'
        value={value}
        index={0}
        viewEntry={viewEntry}
        isExpanded={isExpanded}
        handleMonitorValueChange={this.handleInputChange}
        placeholder={(this.state.logicalOperatorEntry.name === 'Is between') 
        || (this.state.logicalOperatorEntry.name === 'Is not between')? "Min":'Number'}
      />
    );
  }

  renderLogicalOperator() {
    const { logicalOperatorEntry } = this.state;

    return (
      <DropdownGroup
        key="condition-operator"
        value={_.get(logicalOperatorEntry, 'value')}
        label="Condition"
        optionDisplayField="name"
        activeOptionField="value"
        toggleButtonName="Select a value"
        options={numberOperatorEntries}
        onChange={this.onChangeOperator} />
    );
  }

  render() {
    const { isExpanded } = this.props;
    if (isExpanded){
      return (
        <div className='group-filter-number'>
          {this.renderLogicalOperator()}
          {this.renderFromTextBox()}
          {this.renderToValueTextBox()}
        </div>
      );
    } else {
      return [this.renderLogicalOperator(), this.renderFromTextBox(), this.renderToValueTextBox()];
    }
  }
}

NumberFilter.propTypes = {
  filter: PropTypes.object,
  onOperatorChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  viewEntry: commonPropTypes.viewEntryPropTypes,
  isExpanded: PropTypes.bool,
};

export default NumberFilter;
