import React, { useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { ForgeAutocomplete, ForgeIcon, ForgeTextField } from '@tylertech/forge-react';

import SearchButton from './SearchButton';
import BackButton from './BackButton';

import {
  updateIsComparisonListOpen,
  updateSearchField,
  updateSearchPage,
  updateSearchProperty,
  updateTemplateId,
  updateShapeDatasetEntry
} from 'actions/advanceSearchActions';
import { getAdvanceSearchValues } from 'common/api/advanceSearchApi';
import {
  getAdvanceSearchTemplateId,
  getTemplateDatasetNames,
  getAdvanceSearchTemplates
} from 'common/config/customerConfiguration';
import { ADVANCE_SEARCH_PAGE } from  'appConstants';
import TemplateDropdown from 'common/components/TemplateDropDown/TemplateDropdown';
import { getDefaultShapeDatasetEntry } from 'common/config/templateConfiguration';
const SearchInputBox = () => {
  const templateId = useSelector(store => _.get(store, 'advanceSearch.templateId',
    getAdvanceSearchTemplateId()));
  const currentSearchField = useSelector(state => _.get(state.advanceSearch, 'searchField', {}));
  const searchMethod = useSelector(state => _.get(state.advanceSearch, 'searchMethod', ''));

  const [searchText, setSearchText] = useState(currentSearchField);
  const [values, setValues] = useState([]);

  const dispatch = useDispatch();
  const searchButtonRef = useRef();

  const onSelectTypeHeadOption = (e) => {
    const selectedItem = e.currentTarget.value;
    if (_.isObject(selectedItem)) {
      setValues({ label: _.last(_.values(selectedItem)), value: _.last(_.keys(selectedItem)) })
      setSearchText(selectedItem);
    }
  }

  const selectedTemplate = () => {
    return _.find(getAdvanceSearchTemplates(), (templateEntry) => {
      return _.toString(templateEntry['template_id']) == _.toString(templateId)
    });
  }

  const onSearch = () => {
    const selectedItem = _.find(_.get(selectedTemplate(), 'search_entries'),
      {'field': _.last(_.keys(searchText))});
    if (!_.isEmpty(selectedItem)) {
      dispatch(updateSearchField({ ...selectedItem, value: _.last(_.values(searchText))}));
    }

    dispatch(updateSearchPage(ADVANCE_SEARCH_PAGE.DETAIL_PAGE));
    dispatch(updateIsComparisonListOpen(true));
  }

  const fetchColumnValues = async (searchText) => {
    const params = {
      searchText,
      currentDrilldownTemplateId: templateId,
      ignore_view_entry: true
    };
    const response = await getAdvanceSearchValues(params);

    return _.compact(_.map(response, (responseItem) => {
      if (!_.isEmpty(_.last(_.values(responseItem)))){
        return { label: _.last(_.values(responseItem)), value: responseItem };
      }
    }));
  }

  const onTemplateChange = (template) => {
    const selectedTemplateId = _.get(template, 'template_id');
    dispatch(updateTemplateId(selectedTemplateId));
    const newShapeDatasetEntry = getDefaultShapeDatasetEntry(selectedTemplateId)
    dispatch(updateShapeDatasetEntry(newShapeDatasetEntry));
    setSearchText({});
    setValues([]);
  }

  const renderTemplateDropdown = () => {
    const selectedTemplates = _.map(getAdvanceSearchTemplates(), (entry) => _.toString(entry['template_id']));
    const templateEntries = _.filter(getTemplateDatasetNames(), (entry) => {
      return _.includes(selectedTemplates, _.toString(entry['template_id']));
    });

    return (
      <TemplateDropdown
        templateId={templateId}
        searchMethod={searchMethod}
        onTemplateChange={onTemplateChange}
        templateEntries={templateEntries} />
    )
  };

  const getAdvanceSearchDisplayName = () => {
    const displayNames = _.map(_.get(selectedTemplate(), 'search_entries'), (entry) => {
      return entry['name']
    });
    return displayNames.length == 1 ? displayNames[0] : displayNames.join(' or ');
  };

  const renderForgeAutocomplete = () => {
    const searchDisplayName = getAdvanceSearchDisplayName();

    return (
      <ForgeAutocomplete
        filter={fetchColumnValues}
        className="autocomplete-input"
        onSelect={onSelectTypeHeadOption}
        value={values}
        filterOnFocus={false}
      >
        <ForgeTextField className="search-input" shape="rounded">
          <ForgeIcon slot="leading" name="search"></ForgeIcon>
          <input autoComplete="off"
                type="text"
                onChange={onSelectTypeHeadOption}
                defaultValue={_.get(currentSearchField, 'value')}
                id="search-input-text-id"
                placeholder={`Search by ${searchDisplayName}`} />
        </ForgeTextField>
      </ForgeAutocomplete>
    );
  }

  const onBackClick = () => {
    dispatch(updateSearchProperty({}));
  }

  return (
    <>
      <div className="property-page-header">
        <div className='d-flex align-items-center gap-15'>
          <BackButton onClick={() => onBackClick(false)} />
            <div className='forge-typography--title'>
              {`Subject Property Search`}
            </div>
        </div>
      </div>
      <div className='property-page-container'>
        <div className='property-search-container'>
          <div className='property-wrapper'>
            <img src='https://cdn.forge.tylertech.com/v1/images/spot-hero/real-estate-spot-hero.svg'
                alt='property' />
            <div className='search-wrapper d-flex'>
              <div className='d-flex gap-20 align-items-center'>
                <span className='dataset-lable'>Dataset</span>
                {renderTemplateDropdown()}
              </div>
              <div className="d-flex gap-20 align-items-center">
                <span>Subject Property</span>
                {renderForgeAutocomplete()}
                <SearchButton searchText={searchText} onSearch={onSearch} searchButtonRef={searchButtonRef}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

SearchInputBox.propTypes = {};

export default SearchInputBox;
