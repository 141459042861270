import _ from 'lodash';
import React from "react";
import { isScatterChart } from './plotlyScatterChartHelper';
import { isBarchart } from './barChartHelper';
import { isStackTypeChart, isParallelOrBulletChart } from './vizOptionsHelper';
import { getNullValueLabel  } from 'common/config/templateConfiguration';
import { getFormattedValue } from 'helpers/chartDataHelper';
import { formatValueToCurrency } from 'helpers/numberHelper';
import { getPrimaryMetricName } from 'helpers/displayNameHelper';

export const renderLabelFlyout = (container, event, removeTooltip = false ) => {
 const chartContainer = container.closest('.bar-chart');
 const popupContainer = chartContainer.querySelector('.popup-container');
 const dataContainer = chartContainer.querySelector('.visualization-charts');
 const plotlyTooltip =  _.get(dataContainer, 'plotlyTooltip');

  if (removeTooltip){
   plotlyTooltip.hidePopups();
  } else {
    const propData =  _.get(dataContainer,'plotProps');
    const vizOptions =  _.get(dataContainer,'vizOptions');
    const hoverData =  _.get(propData,'data');
    const { currentSnapshotView, groupByEntry, groupByViewType,
      templateName, viewEntry, templateId, isCurrencyDimensionField, isCurrencyGroupByField } = vizOptions;
    const hoverIndex = _.get(event.currentTarget,'__data__.x');
    let withoutBenchMarkData = _.filter(hoverData, (datum) => {
      return _.get(datum, 'customdata[0][0]') !== 'benchMark';
    });

    let labelContent = _.get(event, 'target.textContent', '');
    if (isBarchart(currentSnapshotView)) {
      const options = {
        data: withoutBenchMarkData, hoverIndex, templateName, templateId,
        isCurrencyDimensionField, isCurrencyGroupByField
      };
      const showColorContent = isStackTypeChart(vizOptions) || isParallelOrBulletChart(vizOptions);
      labelContent = getBarChartContent(options, showColorContent);
    } else if (isScatterChart(currentSnapshotView)) {
      const isGroupByNone = _.isEmpty(groupByViewType) || groupByEntry['name'] == 'None';
      const options = {
        hoverData, hoverIndex, isGroupByNone, templateName, viewEntry,
        isCurrencyDimensionField, isCurrencyGroupByField, templateId
      };
      labelContent = getScatterPlotContent(options, labelContent);
    }

    const windowQuarterWidth = (window.innerWidth) / 4;
    const flyoutPosition = event.offsetX > (2 * windowQuarterWidth) ? 'left' : 'right';

    const plotlyLabelParams = {
      data :  labelContent,
      anchor: {
        x: event.offsetX,
        y: event.offsetY
      },
      position: flyoutPosition
    };

    renderTickLabelTipsy(plotlyLabelParams, popupContainer, chartContainer, plotlyTooltip);
  }
}

const renderTickLabelTipsy = (plotlyLabelParams, popupContainer, chartContainer, plotlyTooltip) => {
  const popupConfigs = [{
    position: plotlyLabelParams.position,
    chartContainer,
    anchor: plotlyLabelParams.anchor,
    popupContainer,
    html: (<div>{plotlyLabelParams.data}</div>)
  }];
  plotlyTooltip.showPopups(popupConfigs);
}

function getBarChartContent(options, showColorContent = true) {
  const { data, hoverIndex, templateId, isCurrencyDimensionField, isCurrencyGroupByField } = options;
  const dimensionConfigs = getBarDimensionConfigs(data, isCurrencyDimensionField);
  const nullValueLabel = getNullValueLabel(templateId);
  const { customdata, yAxisValue, colorConfigs } = _.get(dimensionConfigs, hoverIndex, {});
  const titleContent = getBarTitleContent(
    customdata, yAxisValue, nullValueLabel, isCurrencyDimensionField, isCurrencyGroupByField
  );

  const tbodyContent = _.map(colorConfigs, (colorConfig, index) => {
    const { color, text, formattedViewCount } = colorConfig;
    if (!text) { return null; }

    return (
      <tr key={index}>
        <td>
          {showColorContent && <div style={{ backgroundColor: color }} className="line-chart-color"></div>}
        </td>
        <td><div className="bar-chart-category">{text}</div></td>
        {formattedViewCount &&
          <td className="align-top"><div className="float-right">{formattedViewCount}</div></td>
        }
      </tr>
    );
  });

  return (
    <div>
      {titleContent}
      <table className="flyout-table">
        <tbody>
          {_.without(tbodyContent, null)}
        </tbody>
      </table>
    </div>
  );
}

function getBarTitleContent(
  customdata, yAxisValue, nullValueLabel, isCurrencyDimensionField, isCurrencyGroupByField
) {
  const dimensionValue = _.get(customdata, '1', yAxisValue);
  const groupByValue = _.get(customdata, '0');

  if (_.isEmpty(customdata) || _.isEqual(groupByValue, { isTitlePlaceholder: true })) {
    return (
      <div className="bar-chart-y-value pb-1 mb-2 border-bottom text-nowrap">
        <b>{formatValueToCurrency(dimensionValue, isCurrencyGroupByField)}</b>
      </div>
    );
  } else {
    return _.map(customdata, (customdatum, index) => {
      const isCurrencyField = _.isEqual(index, 0) ?  isCurrencyGroupByField : isCurrencyDimensionField ;
      return (
        <div key={index} className="bar-chart-y-value pb-1 mb-2 border-bottom text-nowrap">
          <b>{formatValueToCurrency(customdatum || nullValueLabel, isCurrencyField)}</b>
        </div>
      );
    });
  }
}

function getScatterPlotContent(options, labelContent) {
  const {
    hoverData, hoverIndex, isGroupByNone, templateId, viewEntry,
    isCurrencyDimensionField, isCurrencyGroupByField
  } = options;
  const nullValueLabel = getNullValueLabel(templateId);
  const scatterChartHoverData = getScatterChartHoverData(hoverData);
  const scatterEntry = _.get(scatterChartHoverData, `${hoverIndex}.scatterEntry`, {});
  const groupByLabel = _.get(scatterChartHoverData, `${hoverIndex}.label`, {});
  const dimensionLabel = _.isEmpty(scatterEntry)? (groupByLabel || nullValueLabel): labelContent;
  const dimensionValue = formatValueToCurrency(
    _.get(scatterEntry, 'dimension', dimensionLabel),
    isCurrencyDimensionField || isCurrencyGroupByField
  );
  const groupByValue = _.get(scatterEntry, 'group_by_field', nullValueLabel);
  const primaryCount = _.get(scatterEntry, 'count');
  let minCount = _.get(scatterEntry, 'min_count');
  let maxCount = _.get(scatterEntry, 'max_count');
  minCount = _.isNaN(Number(minCount)) ? 0 : minCount;
  maxCount = _.isNaN(Number(maxCount)) ? 0 : maxCount;
  const formattedPrimaryCount = getFormattedValue(primaryCount, viewEntry);
  const formatedLowerValue = getFormattedValue(minCount, viewEntry);
  const formatedUpperValue = getFormattedValue(maxCount, viewEntry);
  const prefixLabelLower = _.get(viewEntry.visualization,
    'snapshot.scatterplot.flyout_prefix_label_lower_range', 'Lower range')
  const prefixLabelUpper = _.get(viewEntry.visualization,
    'snapshot.scatterplot.flyout_prefix_label_upper_range', 'Upper range')
  const metricName = getPrimaryMetricName(viewEntry);

  let groupNameContent = '';
  if(!isGroupByNone && !_.isEmpty(scatterEntry)) {
    groupNameContent = (<div className="scatter-chart-group-value pb-1 mb-2 text-nowrap border-bottom">
        <b>{formatValueToCurrency(groupByValue, isCurrencyGroupByField)}</b>
      </div>
    );
  }

  return (
    <div>
      {groupNameContent}
      <div className="scatter-chart-y-value pb-1 mb-2 text-nowrap border-bottom">
        <b>{dimensionValue}</b>
      </div>
      {!_.isEmpty(scatterEntry) &&
       <table className="flyout-table">
       <tbody>
         <tr>
           <td>{prefixLabelLower}: {formatedLowerValue}</td>
         </tr>
         <tr>
           <td>{metricName}: {formattedPrimaryCount}</td>
         </tr>
         <tr>
           <td>{prefixLabelUpper}: {formatedUpperValue}</td>
         </tr>
       </tbody>
     </table>
     }
    </div>
  );
}

function getScatterChartHoverData(hoverData) {
  let formattedData = [];
  _.each(hoverData, (hoverDatum) => {
    formattedData = formattedData.concat(_.get(hoverDatum, 'meta', []));
  });
  return formattedData;
}

export const getBarDimensionConfigs = (data, isCurrencyDimensionField = false) => {
  let yAxisValues = [];
  let dimensionConfigs = {};

  _.each(data, (datum) => {
    const yValues = _.get(datum, 'y', []);
    const customdata = _.get(datum, 'customdata', []);
    const markerColors = _.get(datum, 'marker.color', []);
    const text = _.get(datum, 'text', []);

    _.each(yValues, (yValue, index) => {
      const newColor = _.isArray(markerColors) ? markerColors[index]: markerColors;
      let newTextValue = _.get(datum, ['meta', index, 'view'], text[index]);
      newTextValue = removeHtmlTags(newTextValue);
      const formattedViewCount = _.get(datum, ['meta', index, 'formattedViewCount']);
      if (!_.includes(yAxisValues, yValue)) {
        dimensionConfigs[_.size(yAxisValues)] = {
          yAxisValue: formatValueToCurrency(yValue, isCurrencyDimensionField),
          customdata: customdata[index],
          colorConfigs: [{ color: newColor, text: newTextValue, formattedViewCount }]
        }
        yAxisValues.push(yValue);
      } else {
        const newIndex = _.findIndex(yAxisValues, (axisValue) => axisValue === yValue);
        dimensionConfigs[newIndex].colorConfigs.push({
          color: newColor, text: newTextValue, formattedViewCount
        });
      }
    });
  });

  return dimensionConfigs;
}

function removeHtmlTags(value){
  if(!_.isEmpty(value)){
    let convertSting = value.toString();
    const trimLabelValue = _.trim(convertSting.replace( /(<([^>]+)>)/ig, ''));
    return _.includes(['null', 'undefined'], trimLabelValue) ? null : trimLabelValue;
  } else {
    return value
  }
}
