// Vendor Imports
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux'
import PropTypes from 'prop-types';
import { templateEntriesPropTypes } from 'common/propTypes';
import moment from 'moment';

// Project Imports
import { getComparisonPeriodDateRanges } from 'helpers/dateHelper';
import { DateRangeHandlerWrapper } from 'common/components/Hoc/DateRangeHandlerWrapper';
import { DATE_COMPARISON_TYPE } from 'appConstants';
import ComparisonModeSelector from 'common/components/ComparisonDateSelector/ComparisonModeSelector';
import { configuredDefaultDateType } from 'common/config/customerConfiguration';
import { getCompareSamePeriodLastYearOptions } from 'helpers/fiscalPeriodUtils';
import { getMinDate } from 'helpers/dateUtils';
class ComparisonModeDateRangeSelector extends Component {
  renderComparisonMode = () => {
    const {
      commonFilters,
      onComparisonTypeChange,
      onChangeComparisonModeOn,
      currentDrilldownTemplateId,
      onCompareDateRangesChange,
      isDashboardView,
      axisGranularity
    } = this.props;
    const comparisonType = _.get(commonFilters, 'comparisonType', DATE_COMPARISON_TYPE.SAME_PERIOD);
    const comparisonDateRanges = _.get(commonFilters, 'comparisonDateRanges', []);
    const comparisonModeOn = _.get(commonFilters, 'comparisonModeOn', false);
    const dateRange = _.get(commonFilters, 'dateRange', {});
    const dateType = _.get(commonFilters, 'dateType', configuredDefaultDateType);
    const minDate = getMinDate(currentDrilldownTemplateId)
    const minYear = moment(minDate).format('YYYY');
    const startDateYear = moment(_.get(dateRange, 'startDate')).format('YYYY');
    const yearOptions = getCompareSamePeriodLastYearOptions(
      { primaryDateRange: dateRange,
        primaryDateType: dateType, templateId: currentDrilldownTemplateId });
    let newComparisonDateRanges = getComparisonPeriodDateRanges(
      { comparisonType, dateRange, dateType, comparisonDateRanges });
    // If selected year is min year, then default compare year is set from the compare year option.
    // as default compare type is same period last year.
    if (minYear == startDateYear && comparisonType !==  DATE_COMPARISON_TYPE.PRIOR ) {
      newComparisonDateRanges = [_.get(yearOptions, '0.range')];
    }
    const comparisonSelectorAttributes = {
      dateRange,
      dateType,
      comparisonModeOn,
      comparisonType,
      dispatchUpdateComparisonModeOn: onChangeComparisonModeOn,
      dispatchUpdateComparisonType: onComparisonTypeChange,
      comparisonDateRanges: newComparisonDateRanges,
      onCompareDateRangesChange: onCompareDateRangesChange,
      isDashboardView,
      axisGranularity
    };

    return (
    <ComparisonModeSelector
        key="drildown-compare-date-filter"
        currentDrilldownTemplate={currentDrilldownTemplateId}
        {...comparisonSelectorAttributes} />
    );
  }

  render() {
    return (
      <>
        { this.renderComparisonMode()}
      </>
    );
  }
}

ComparisonModeDateRangeSelector.propTypes = {
  currentDrilldownTemplateId: templateEntriesPropTypes,
  commonFilters: PropTypes.object,
  onComparisonTypeChange: PropTypes.func,
  onChangeComparisonModeOn: PropTypes.func,
  onCompareDateRangesChange: PropTypes.func,
  isDashboardView: PropTypes.bool,
  axisGranularity: PropTypes.string
}

function mapStateToProps(state) {
  return {
    currentDrilldownTemplateId: _.get(state, 'drilldown.currentDrilldownTemplateId'),
    commonFilters: _.get(state, 'commonFilters', {}),
    axisGranularity: _.get(state, 'visualization.overtime.axisGranularity', '')
  };
}

export default connect(mapStateToProps, null)(DateRangeHandlerWrapper(ComparisonModeDateRangeSelector));
