import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { FILTER_SELECT_ALL } from 'appConstants';
import { ForgeAutocomplete, ForgeIcon, ForgeTextField } from '@tylertech/forge-react';
import { formatForgeAutocompleteOption } from 'helpers/DomPageHelper';

function ForgeTypeHeadAutocomplete(props) {
  const DROPDOWN_LIMIT = 50;
  const [autoSelectParam, setAutoSelectParam] = useState({
    topValues: [],
    offset: 0,
    totalCount: 0,
    searchText: ''
  });

  const {
    selectedValues,
    field,
    values,
    filterName,
    apiParams,
    fetchSearchInputValues,
    onForgeSelect
  } = props;
  var defaultSearchText = "";
  const forgeAutocomplete = useRef(null);

  const fetchForgeColumnValues = async (searchText) => {
    const value = { offset: 0, searchText }
    setAutoSelectParam((prevObject) => {
      return { ...prevObject, ...value };
    });
    const params = { field, searchText, ...apiParams, limit: DROPDOWN_LIMIT + 1, offset: 0 };
    const response = await fetchSearchInputValues(params);
    const showSelectAll = _.size(response) <= DROPDOWN_LIMIT && _.size(response) > 1;

    let columnValues = _.compact(_.map(_.uniq(response), (responseItem) => {
      if (!_.isEmpty(responseItem)) {
        return { label: responseItem, value: responseItem, disabled: false };
      }
    }));

    if (showSelectAll) {
      columnValues.unshift({ label: FILTER_SELECT_ALL, value: FILTER_SELECT_ALL })
    }

    const updateValues = { topValues: columnValues, totalCount: _.size(response) };
    setAutoSelectParam((prevObject) => {
      return { ...prevObject, ...updateValues };
    });

    setTimeout(() => {
      formatForgeAutocompleteOption();
    }, 0);
    return columnValues
  }

  const onSelectTypeHeadOption = ({ detail }) => {
    const selectedListValues = getSelectedItems(detail)
    onForgeSelect(selectedListValues);
  }

  const getSelectedItems = (detail) => {
    const availableSelectAll = _.includes(detail, FILTER_SELECT_ALL);
    let selectedListValues = detail;
    const isExistSelectAll = _.includes(selectedValues, FILTER_SELECT_ALL);
    const detailSize = _.size(detail);
    const selectedValuesSize = _.size(selectedValues);
    const totalCount = autoSelectParam.totalCount;
    const dropdownLimit = DROPDOWN_LIMIT;
  
    if (!_.isEmpty(autoSelectParam.searchText)) {
      return getSearchTextSelectedItems(detail, availableSelectAll);
    }
  
    const isRemoveSelectAll = (availableSelectAll && 
      ((totalCount == detailSize && selectedValuesSize > totalCount) || 
      (isExistSelectAll && detailSize < totalCount && dropdownLimit < totalCount))
    );
  
    if ((availableSelectAll && detailSize === 1) || selectedValuesSize === totalCount || 
    (availableSelectAll && !isExistSelectAll)) {
      selectedListValues = _.take(_.map(autoSelectParam.topValues, 'value'), dropdownLimit + 1);
    } else if (!availableSelectAll && detailSize === totalCount && dropdownLimit >= totalCount 
      && selectedValuesSize < totalCount) {
      selectedListValues = _.take(_.map(autoSelectParam.topValues, 'value'), dropdownLimit + 1);
    } else if (isRemoveSelectAll) {
      _.remove(selectedListValues, value => value === FILTER_SELECT_ALL);
    } else if (detailSize === totalCount && selectedValuesSize > totalCount) {
      selectedListValues = [];
    }
  
    return selectedListValues;
  }  

  const getSearchTextSelectedItems = (detail, availableSelectAll) => {
    if (availableSelectAll) {
      return _.take(_.map(autoSelectParam.topValues, 'value'), (DROPDOWN_LIMIT + 1));
    } else {
      setAutoSelectParam((prevObject) => {
        return { ...prevObject, ...{ searchText: '' } };
      });
      return detail;
    }
  }

  const onScrollAutoComplete = async (e) => {
    const params = {
      field, searchText: autoSelectParam.searchText, ...apiParams,
      limit: DROPDOWN_LIMIT,
      offset: autoSelectParam.offset + 1
    };
    const response = await fetchSearchInputValues(params);
    const nextValues = _.map(response, (responseItem) => {
      return { label: responseItem, value: responseItem };
    });
    e.target.appendOptions(nextValues);
    const updateOffset = { offset: autoSelectParam.offset + 1 }
    setAutoSelectParam((prevObject) => {
      return { ...prevObject, ...updateOffset };
    });
  };

  const optionSelectedTextBuilder = (options) => {
    const selectedCount = _.size(_.filter(selectedValues, (value) => {
      return value != FILTER_SELECT_ALL;
    }));
    const optionsCount = _.size(_.filter(options, ({ value }) => value != FILTER_SELECT_ALL));

    if (optionsCount == 0) {
      return '';
    }
    const selectCount = optionsCount >= selectedCount ? optionsCount : selectedCount;

    if (selectCount === 1) {
      return selectCount + ' option selected';
    } else if (selectCount > 1) {
      return selectCount + ' options selected';
    } else {
      return '';
    }
  }

  return (
    <div className="auto-search-input">
      <ForgeAutocomplete
        key={field}
        filter={fetchForgeColumnValues}
        className="autocomplete-input"
        on-forge-autocomplete-change={onSelectTypeHeadOption}
        on-forge-autocomplete-scrolled-bottom={onScrollAutoComplete}
        multiple={true}
        observeScroll={true}
        value={values}
        debounce={500}
        selectedTextBuilder={optionSelectedTextBuilder}
        ref={forgeAutocomplete}>
        <ForgeTextField>
          <input autoComplete="off"
            type="text"
            aria-label={filterName}
            defaultValue={defaultSearchText}
            id="search-input-text-id" />
          <ForgeIcon slot="trailing" name="arrow_drop_down" data-forge-dropdown-icon></ForgeIcon>
        </ForgeTextField>
      </ForgeAutocomplete>
    </div>
  )
}

ForgeTypeHeadAutocomplete.propTypes = {
  apiParams: PropTypes.object,
  selectedValues: PropTypes.array,
  fetchSearchInputValues: PropTypes.func,
  field: PropTypes.string,
  values: PropTypes.array,
  onForgeSelect: PropTypes.func,
  filterName: PropTypes.string
}

export default ForgeTypeHeadAutocomplete
