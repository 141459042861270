import React from 'react';
import { ForgeDialog } from '@tylertech/forge-react'
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ModalHeader from './ModalHeader';
import ModalBody from './ModalBody';
import ModalFooter from './ModalFooter';
import CollaborateButton from './CollaborateButton';

import {
  removeBodyClassName,
  setBodyClassName,
  setZIndexForCollectionActionHeader,
  setZIndexForCollectionTabs,
} from 'helpers/DomPageHelper';
import { updateToggleModal } from './collaboratorActions';
import { isManageCollections, isViewerRole } from './collaboratorHelper';
import { DIALOG_PROPS } from './constants';
import LoadingSpinner from 'common/components/LoadingSpinner';

const CollaborateModal = ({ onCollaborateCollectionChange,
                            isLaunchpadAdmin,
                            onUpdateToggleModel,
                            autoConvertCollaborate }) => {
  const dispatch = useDispatch();
  const isOpenModal = useSelector((store) => store.isOpenModal);
  const collection = useSelector((store) => store.collection);
  const isCollaborateCollection = useSelector((state) => state.isCollaborateCollection);

  const toggleModalOpen = (isOpen, zIndex) => {
    onUpdateToggleModel(isOpen);
    dispatch(updateToggleModal(isOpen));
    if(!isOpen) {
      onCollaborateCollectionChange(collection);
      removeBodyClassName('forge-modal-open');
    } else {
      setBodyClassName('forge-modal-open');
    }

    const timeOutSeconds = zIndex ? 10 : 160;

    setTimeout(() => {
      setZIndexForCollectionTabs(zIndex ? zIndex : 10006);
      setZIndexForCollectionActionHeader(zIndex ? zIndex : 10006);
    }, timeOutSeconds);
  }

  return(isLaunchpadAdmin || isManageCollections()) && (
    <>
      <ForgeDialog open={isOpenModal} options={DIALOG_PROPS} onDismiss={() => toggleModalOpen(false)}>
        <LoadingSpinner isLoading={(autoConvertCollaborate && !isCollaborateCollection)} />
        <ModalHeader toggleModalOpen={toggleModalOpen} />
        <ModalBody />
        <ModalFooter toggleModalOpen={toggleModalOpen} />
      </ForgeDialog>
      {(isLaunchpadAdmin || !isViewerRole(collection)) &&
      <CollaborateButton toggleModalOpen={toggleModalOpen} />}
    </>
  );
}

CollaborateModal.propTypes = {
  isLaunchpadAdmin: PropTypes.bool,
  autoConvertCollaborate: PropTypes.bool,
  onCollaborateCollectionChange: PropTypes.func,
  onUpdateToggleModel: PropTypes.func
}

CollaborateModal.defaultProps = {
  isLaunchpadAdmin: false,
  autoConvertCollaborate: false,
  onCollaborateCollectionChange: _.noop
}

export default CollaborateModal;
