import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ForgeButton, ForgeBadge } from "@tylertech/forge-react";
import PropTypes from 'prop-types';
import Header from '../Header';
import LoadingSpinner from 'common/components/LoadingSpinner';
import MapView from '../Map/MapView';
import { pdfDownloadBoth } from '../Export/pdfExport';
import { getTableColumnEntries } from 'common/config/templateConfiguration';
import {
  updateSelectedReportIds,
  updateReportPageData,
  updateSubjectData,
  updateReportPageDistinctData,
  updateSubjectDistinctData
} from 'actions/advanceSearchActions';
import { getSubjectData, getReportData, getReportDistinctData } from 'common/api/advanceSearchApi';
import {
  addSortTextForReportData, getSecondaryValuesList, isValidPdfColumn
} from 'modules/AdvanceSearch/advanceSearchHelper';
import CompFinderIcon from '../CompFinderIcon';
import GlobalEvent from 'common/components/GlobalEvents';
import { buildQueryString } from 'helpers/HttpHelper';
import { formattedValueByDataType } from 'modules/DetailsTable/TableHelper';
import PropertyPhoto from '../PropertyPhoto';
import PropertyPdf from '../PropertyPdf';
import { SEARCH_METHOD } from 'appConstants';
const SingleRecordReport = (props) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { isCustomSearch } = props;

  const reportContainer = useRef();
  const templateId = useSelector(store => _.get(store, 'advanceSearch.templateId'));
  const subjectRowId = useSelector(state => state.advanceSearch.subjectRowId);
  const subjectData = useSelector(state => state.advanceSearch.subjectData);
  const reportPageData = useSelector(state => state.advanceSearch.reportPageData);
  const reportPageDistinctData = useSelector(state => state.advanceSearch.reportPageDistinctData);
  const subjectDistinctData = useSelector(state => state.advanceSearch.subjectDistinctData);
  const searchProperty = useSelector(state => state.advanceSearch.searchProperty);
  const searchMethod = _.get(searchProperty, 'searchMethod');
  const selectedReportRowIds = useSelector(state => state.advanceSearch.selectedReportRowIds);
  const formattedPageData = useMemo(() => {
    return addSortTextForReportData(reportPageData, selectedReportRowIds, isCustomSearch)
  }, [JSON.stringify(reportPageData), selectedReportRowIds]);

  const detailsEntries = getTableColumnEntries(templateId);
  const subjectSearchField = useSelector(state => _.get(state.advanceSearch, 'searchField', {}));
  const searchField = _.get(subjectSearchField, 'field');
  const searchDisplayName = _.get(subjectSearchField, 'name');
  const [isMapDownload, setIsMapDownload] = useState(false);
  const [map, setMap] = useState();
  const [downloadOption, setDownloadOption] = useState({
    tableContainer: null, orientation: ''
  });

  const updateMapDownload = (tableContainer, downloadOption) => {
    const { isMapViewDownload, orientation } = downloadOption;
    setIsLoading(isMapViewDownload);
    setIsMapDownload(isMapViewDownload);
    const updateOption = {
      tableContainer,
      orientation
    }
    setDownloadOption(updateOption);
  }
  const onMapLoaded = (newMap) => {
    setMap(newMap);
  }

  const onApiDataLoading = (isLoading) => {
    setIsLoading(isLoading);
    if(!isLoading && isMapDownload){
      setIsMapDownload(false);
      const downloadOptions = {...downloadOption, templateId, isSingleView: true };
      pdfDownloadBoth(downloadOptions, map);
    }
  }

  const fetchReportData = () => {
    setIsLoading(true);
    const params = {
      row_id_field: selectedReportRowIds,
      subject_row_id_field: subjectRowId,
      currentDrilldownTemplateId: templateId,
      advanceSearchSubjectField: JSON.stringify(subjectSearchField)
    }
    const subjectParams = {
      row_id_field: subjectRowId,
      currentDrilldownTemplateId: templateId,
      advanceSearchSubjectField: JSON.stringify(subjectSearchField)
    }
    let promises = [
      getReportData(params),
      getReportDistinctData(params)
    ];
    if(!isCustomSearch){
      promises.push(
        getSubjectData(subjectParams),
        getReportDistinctData(subjectParams)
      )
    }
    Promise.all(promises).
      then((results) => {
        dispatch(updateReportPageData(results[0]));
        dispatch(updateReportPageDistinctData(results[1]));
        if(!isCustomSearch){
          dispatch(updateSubjectData(_.first(results[2])));
          dispatch(updateSubjectDistinctData(results[3]));
        }
        setIsLoading(false);
      }).catch((error) => {
        console.log("Error on fetching user config ", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!_.isEmpty(selectedReportRowIds) || !_.isEmpty(subjectRowId)) {
      fetchReportData();
    }
  }, []);

  useEffect(() => {
    GlobalEvent.on('EXPORT_CSV_REPORT_PAGE', handleCsvDownload);

    return () => {
      GlobalEvent.off('EXPORT_CSV_REPORT_PAGE', handleCsvDownload);
    }
  }, [])

  const handleCsvDownload = () => {
    let reportRowIds = isCustomSearch ?
      selectedReportRowIds : _.compact(selectedReportRowIds.concat(subjectRowId));

    const params = {
      row_id_field: reportRowIds,
      currentDrilldownTemplateId: templateId,
      advanceSearchSubjectField: JSON.stringify(subjectSearchField),
      isCustom: isCustomSearch
    }
    window.location.href = `/api/advanced_search/report_result_download.csv?${buildQueryString(params)}`;
  }

  const renderValue = (datum, entry, index) => {
    const value = _.get(datum, entry.field, '');
    const secondaryValues = getSecondaryValues(datum, entry);
    const formattedValue = formattedValueByDataType(value, entry, true);
    const renderValue = _.isEmpty(secondaryValues) ? formattedValue : secondaryValues;
    const isSubjectSearch = SEARCH_METHOD.SUBJECT_PROPERTY == searchMethod;
    return _.isObject(value) ?
      <div className="text-break">{_.get(value, 'url')} </div> :
      <div className="text-break">{
        isValidPdfColumn(isSubjectSearch, templateId, index, entry, datum) ?
          <PropertyPdf value={renderValue} propertyDetails={datum} />
          : renderValue
      }</div>;
  };

  const getSecondaryValues = (datum, entry) => {
    // TODO complete refactor
    let secondaryValuesList = [];
    const rowId = _.get(datum, 'row_id_field', '');
    if(!_.isEmpty(subjectDistinctData) && !_.isEmpty(subjectDistinctData[rowId])){
      secondaryValuesList =  getSecondaryValuesList(subjectDistinctData[rowId], entry);
    }
    if(!_.isEmpty(reportPageDistinctData) && !_.isEmpty(reportPageDistinctData[rowId])){
      secondaryValuesList =  getSecondaryValuesList(reportPageDistinctData[rowId], entry);
    }

    let secondaryValues;
    if(!_.isEmpty(secondaryValuesList)){
      secondaryValues = _.map(secondaryValuesList, (datumValue) => {
        return (<> {datumValue} <br/>  </>)
      })
    }
    return secondaryValues
  }

  const renderTableBody = () => {
    return _.map(detailsEntries, (entry, index) => {
      return (
        <tr key={entry.field}>
          <td className="forge-typography--overline">{entry.name}</td>
          {!isCustomSearch && <td>{renderValue(subjectData, entry, index)}</td>}
          {
            renderCompareData(entry, index)
          }
        </tr>
      )
    });
  };

  const renderCompareData = (entry, columnIndex) => {
    return _.map(formattedPageData, (data, index) => {
      return <td key={index}>{renderValue(data, entry, columnIndex)}</td>
    })
  };

  const renderSubjectHeader = () => {
    return (
      <>
        <div className="parcel-id d-flex align-items-start gap-10">
          <div className='property-pin subject-property-pin'>A</div>
          <div>{searchDisplayName} - {_.get(subjectData, searchField, '')}</div>
        </div>
        { _.get(subjectData, 'is_property_details_available', false) &&
          <PropertyPhoto photoDetails={subjectData}></PropertyPhoto> }
        { !_.get(subjectData, 'is_property_details_available', false) &&
        <CompFinderIcon templateId={templateId}  searchMethod={searchMethod}/> }
      </>
    )
  };

  const renderCompareHeader = () => {
    return _.map(formattedPageData, (data, index) => {
      return (
        <th key={index}>
          <div className="comp-address">
            { isCustomSearch ?
              <div className="parcel-id d-flex align-items-start gap-10">
                <div className='sort-badge property-pin'>{data['sort_text']}</div>
                {_.get(data, _.get(detailsEntries, '0.field', ''), '')}
              </div>
              :
              <div className="parcel-id d-flex align-items-start gap-10">
                <div className='sort-badge property-pin'>{data['sort_text']}</div>
                {searchDisplayName} - {_.get(data, searchField, '')}
              </div>
            }
            <div className='mt-auto d-flex flex-column align-items-center'>
              { _.get(data, 'is_property_details_available', false) &&
                <PropertyPhoto photoDetails={data}></PropertyPhoto> }
              { !_.get(data, 'is_property_details_available', false) &&
                <CompFinderIcon templateId={templateId} searchMethod={searchMethod} /> }
              <ForgeButton className="btn-remove"
                type="outlined" onClick={(e) => onRemoveCompare(e, data)}>
                <button type="button">
                  <span>Remove</span>
                </button>
              </ForgeButton>
            </div>
          </div>
        </th>
      );
    });
  };

  const onRemoveCompare = (e, data) => {
    const id = _.get(data, 'row_id_field');
    dispatch(updateReportPageData(
      _.filter(reportPageData, (selectedItem) => selectedItem['row_id_field'] !== id)
    ));
    dispatch(updateSelectedReportIds(_.pull(selectedReportRowIds, id)));
  };

  return (
    <div className='property-page-container' ref={reportContainer}>
      <LoadingSpinner isLoading={isLoading} />
      <Header
        container={reportContainer}
        updateMapDownload={updateMapDownload}
        isCustomSearch={isCustomSearch}
        detailsEntries={detailsEntries}
        templateId={templateId}>
      </Header>
      <div className="view-comp-report-screen summary-table report-table px-8" id="comp-finder-summary">
        <div class="table-responsive">
          <table className="table table-bordered table-freeze-two">
            <thead>
              <tr>
                <th></th>
                {!isCustomSearch &&
                  <th>
                    <div className="comp-address">
                      {renderSubjectHeader()}
                      <ForgeBadge theme="warning">Subject property</ForgeBadge>
                    </div>
                  </th>
                }
                {renderCompareHeader()}
              </tr>
            </thead>
            <tbody>
              {renderTableBody()}
            </tbody>
          </table>
        </div>
      </div>
      {isMapDownload &&
        <div style={{ width: '1800px', height: '800px', overflow: 'hidden' }}>
          <MapView
            onMapLoaded={onMapLoaded}
            onApiDataLoading={onApiDataLoading}
            showOnlySelectedRows={true}
          />
        </div>
      }
    </div>
  );
};


SingleRecordReport.propTypes = {
  isCustomSearch: PropTypes.bool
};

SingleRecordReport.defaultProps = {
  isCustomSearch: false,
};

export default SingleRecordReport;
