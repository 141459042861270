import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import BackButton from '../BackButton';
import {
  updateSearchField,
  updateSearchProperty,
  updateSearchResultCount,
  updatePolygonGeojson
} from 'actions/advanceSearchActions';
import ShortenUrl from 'common/components/SecondaryHeader/ShortenUrl';
import { updateCurrentBookmark } from 'actions/bookmarkActions';
import { ForgeButton } from '@tylertech/forge-react';
import GlobalEvents from 'common/components/GlobalEvents';
import CompareListButton from '../CompareListButton';

const CustomSearchHeader = (props) => {
  const { selectedShapeIds, searchFilters, selectedTab } = props;
  const selectedReportRowIds = useSelector( state => state.advanceSearch.selectedReportRowIds);
  const isComparisonListOpen = useSelector(state => state.advanceSearch.isComparisonListOpen);
  const searchResultCount = useSelector( state => state.advanceSearch.searchResultCount);
  const dispatch = useDispatch();
  const onBackClick = () => {
    dispatch(updateSearchProperty({}));
    dispatch(updateCurrentBookmark(''));
  }

  const onClickStartSearch = () => {
    dispatch(updateSearchField({}));
    dispatch(updateCurrentBookmark(''));
    dispatch(updateSearchResultCount(0));
    dispatch(updatePolygonGeojson({ featuresGeojson: {} }));
  }

  const handleCsvDownload = () => {
    GlobalEvents.emit("EXPORT_SEARCH_PAGE", selectedTab);
  }

  const renderTitle = () => {
    return (
      <div className="forge-typography--title">
        General Search
      </div>
    )
  }

  const renderActionButtons = () => {
    const isEnableExportButton = searchResultCount > 0;
    return (
      <div className="action-buttons ml-auto d-flex gap-10">
        {renderStartNewSearch()}
        {renderCopyLinkButton()}
        { isEnableExportButton && <ForgeButton type="outlined" className="ml-auto">
          <button type="button" onClick={handleCsvDownload}>
            <span>Export</span>
          </button>
        </ForgeButton>}
        {
          (!_.isEmpty(selectedReportRowIds) && !isComparisonListOpen) && <CompareListButton/>
        }
      </div>
    )
  }

  const renderCopyLinkButton = () => {
    return (
      <div className='copy-link-button'>
        <ShortenUrl
          isDisabled = {isDisableButton()}
          buttonText='Copy link'
        />
      </div>
    );
  }

  const isDisableButton = () => {
    return _.isEmpty(searchFilters) && _.isEmpty(selectedShapeIds);
  }

  const renderStartNewSearch = () => {
    return (
      <ForgeButton type="outlined" className="ml-auto">
        <button type="button" disabled={isDisableButton()} onClick={() => onClickStartSearch()}>
          <span>Start new search</span>
        </button>
      </ForgeButton>
    )
  }

  return (
    <div className="custom-search-header">
      <div className='d-flex align-items-center gap-15'>
        <BackButton onClick={() => onBackClick(false)} />
        {renderTitle()}
        <div className="forge-typography--body2">Data reflects the current tax year</div>
      </div>
      {renderActionButtons()}
    </div>
  );
}

CustomSearchHeader.propTypes = {
  selectedShapeIds: PropTypes.array,
  searchFilters: PropTypes.array,
  selectedTab: PropTypes.string
};

export default CustomSearchHeader;
