import {
  OVERTIME_VISUALIZATION_TYPES,
  SNAPSHOT_VISUALIZATION_TYPES,
  VISUALIZATION_TYPES,
  OVERTIME_TIME_FRAME_OPTIONS
} from 'appConstants';
import {
  getDefaultGroupBySortOption,
  getDefaultShowByValue,
  getDefaultSortOption,
  getDefaultVisualizationChartView,
  getDefaultBenchMarkEntries,
  getDefaultSecondaryMetricEntry,
  getSelectedLineChartViewEntry
} from 'helpers/visualizationHelper';
import {
  isShowProjectionDefault,
  shouldShowAreaChart,
  showAnnotationValueInBar
} from 'common/config/visualizationConfiguration';
import { getDefaultAxisGranularity } from 'modules/visualization/LineChart/Helpers/overtimeHelper';
import { getInitialState as defaultOvetimeReducer } from './overtimeReducer';
import { getInitialState as defaultSnapshotReducer } from './snapshotReducer';
import { getInitialState as defaultLeafPageReducer } from './leafPageReducer';
import { getInitialState as defaultDistributionReducer } from './distributionReducer';
import { mapOptionsReducerDefaultState } from './mapOptionsReducer';
import { getCardEntry } from 'pages/dashboard/components/CardList/cardHelper';

export const getSnapshotState = (options) => {
  const {
    currentDrilldownViewEntry,
    currentDrilldownDimensionField,
    currentDrilldownGroupByEntry,
    currentDrilldownTemplateId,
    currentSnapshotView
  } = options;
  const defaultSecondaryMetric = getDefaultSecondaryMetricEntry(
    currentDrilldownViewEntry, 'snapshot', currentSnapshotView);
  const defaultBenchMarkEntries = getDefaultBenchMarkEntries(
    currentDrilldownViewEntry, 'snapshot', currentSnapshotView,
    currentDrilldownTemplateId, currentDrilldownDimensionField);
  const defaultGroupByViewType = getDefaultShowByValue(currentDrilldownViewEntry);
  let newSnapshotView;

  if (_.isEmpty(currentSnapshotView)) {
    newSnapshotView = getDefaultVisualizationChartView(
      currentDrilldownViewEntry, VISUALIZATION_TYPES.OVERTIME.type);
  } else {
    newSnapshotView = currentSnapshotView;
  }

  return {
    currentSnapshotView: newSnapshotView,
    currentSecondaryMetricField: _.get(defaultSecondaryMetric, 'field', ''),
    currentBenchMarkMetricNames: _.compact(_.map(defaultBenchMarkEntries, 'name')),
    currentBarChartSortType: _.get(getDefaultSortOption(
      currentDrilldownDimensionField,
      currentDrilldownViewEntry, null, currentDrilldownTemplateId), 'type', ''),
    currentGroupBySortType: _.get(getDefaultGroupBySortOption(
      currentDrilldownGroupByEntry, currentDrilldownViewEntry, currentDrilldownTemplateId), 'type', ''),
    currentDimensionSortType: _.get(getDefaultSortOption(
      currentDrilldownDimensionField,
      currentDrilldownViewEntry, null, currentDrilldownTemplateId), 'type', ''),
    showScatterPlotRange: (
      newSnapshotView === SNAPSHOT_VISUALIZATION_TYPES.SCATTER_PLOT.type
    ),
    currentGroupByViewType: defaultGroupByViewType,
    showSnapshotChartValues: showAnnotationValueInBar(currentDrilldownViewEntry)
  }
}

export const getOvertimeState = (options) => {
  const {
    currentOvertimeView,
    currentDrilldownViewEntry,
    currentDrilldownDimensionField,
    currentDrilldownTemplateId
  } = options;
  const chartType = getDefaultVisualizationChartView(
    currentDrilldownViewEntry, VISUALIZATION_TYPES.OVERTIME.type);
  // TODO reverse logic Remove it.
  let selectedChartOption = getSelectedLineChartViewEntry(chartType);
  const selectedTimeFrame = OVERTIME_TIME_FRAME_OPTIONS.ROLLING;
  const defaultSecondaryMetric = getDefaultSecondaryMetricEntry(
    currentDrilldownViewEntry, 'overtime', selectedChartOption.type);
  const defaultBenchMarkEntries = getDefaultBenchMarkEntries(
    currentDrilldownViewEntry,
    'overtime',
    selectedChartOption.type,
    currentDrilldownTemplateId,
    currentDrilldownDimensionField);

  if (!shouldShowAreaChart(currentDrilldownViewEntry)) {
    if (_.isEqual(selectedChartOption, OVERTIME_VISUALIZATION_TYPES.AREA)) {
      selectedChartOption = OVERTIME_VISUALIZATION_TYPES.TIMELINE;
    }
  }

  return {
    axisGranularity: getDefaultAxisGranularity(currentDrilldownTemplateId),
    currentChartView: currentOvertimeView || chartType,
    isShowProjection: isShowProjectionDefault(currentDrilldownViewEntry, chartType),
    currentSelectedTimeFrame: selectedTimeFrame,
    currentSecondaryMetricField: _.get(defaultSecondaryMetric, 'field', ''),
    currentBenchMarkMetricNames: _.compact(_.map(defaultBenchMarkEntries, 'name')),
    toggleLegendEntries: {},
    sliderRange: [],
    dimensionConfigsByRenderType: {},
    currentForecastOption: _.get(currentDrilldownViewEntry,
      'visualization.overtime.default_enabled_projection_metric', '')
  }
}

export const getDistributionState = (options, isEmbedPage = false) => {
  const distributionState = {
    currentBenchMarkMetricNames: [],
  };

  if (isEmbedPage) {
    return _.merge({}, defaultDistributionReducer(), distributionState);
  }

  return distributionState;
}

export const visualizationReducerDefaultState = () => {
  return {
    snapshot: defaultSnapshotReducer(),
    leafPage: defaultLeafPageReducer(),
    overtime: defaultOvetimeReducer(),
    distribution: defaultDistributionReducer(),
    mapOptions: mapOptionsReducerDefaultState(),
  };
}

export const removeCardByCardId = (collectionsWithCards, collectionId, cardId) => {
  const updatedCollections = collectionsWithCards?.map(collection => {
    if (collection.id === collectionId) {
      return {
        ...collection,
        cards: collection.cards.filter(card => card.card_id !== cardId),
      };
    }
    return collection;
  });
  return updatedCollections;
}

export const addCardInCollection = (collections, templateEntry, viewEntry, collectionId, cardId) => {
  const sourceCollection = _.find(collections, collection =>
    _.some(collection.cards, { card_id: cardId })
  );
  const sourceCard = _.isUndefined(sourceCollection) ?
    getCardEntry(templateEntry, viewEntry, collectionId) :
    _.find(sourceCollection.cards, { card_id: cardId });

  const targetCollection = _.find(collections, { id: collectionId });
  if (sourceCard && targetCollection) {
    targetCollection.cards.push(sourceCard);
  }
  const userCollections = collections.map(collection => {
    if (collection.id === collectionId) {
      return targetCollection
    }
    return collection;
  });

  return userCollections;
}
