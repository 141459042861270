import React from 'react';
import PropTypes from 'prop-types';

import LeafPage from '../LeafPage/LeafPage';

const LeafPageDrawer = (props) => {
  const {
    onClose,
    totalRecordsCount,
    tableData
  } = props;

  return (
    <div className='leaf-drawer'>
      <div>
        <LeafPage
          onClose={onClose}
          totalRecordsCount={totalRecordsCount}
          leafPageData={tableData}
        />
      </div>
    </div>
  );
}

LeafPageDrawer.propTypes = {
  onClose: PropTypes.func,
  totalRecordsCount: PropTypes.number,
  tableData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ])
}

LeafPageDrawer.defaultProps = {
  onClose: _.noop,
  totalRecordsCount: 0,
  tableData: {}
}

export default LeafPageDrawer;
