import _ from 'lodash';
import React from 'react';

import { getFormattedValue } from 'helpers/chartDataHelper';
import { getFlyoutPosition } from './helper';
import { getAbbreviatedStringValue } from 'helpers/numberHelper';
import { getNullValueLabel } from 'common/config/templateConfiguration';
import { formatValueToCurrency } from 'helpers/numberHelper';
import { isTimeDurationEnabled } from 'common/config/customerConfiguration';
import { getPrimaryMetricName } from 'helpers/displayNameHelper';

export const getScatterChartPopupConfigs = (options) => {
  const { chartContainer, data, viewMode, viewEntry } = options;
  const flyoutPosition = getFlyoutPosition(data, viewMode);
  const isSecondsFormat = isTimeDurationEnabled(viewEntry);

  return _.map(data.points, (point) => {
    const xaxis = point.xaxis;
    const yaxis = point.yaxis;
    const isRangePlot = _.get(point, 'data.customdata[0]') === 'range';
    const isBenchMark = _.get(point, 'meta.clickMode') === false ||
      _.get(point, 'data.customdata[0][0]') === 'benchMark';
    let html;
    const xValue = isSecondsFormat ? xaxis.d2p(point.x) : xaxis.l2p(point.x)
    let anchor = {
      x: xValue + xaxis._offset,
      y: yaxis.d2p(point.pointIndex) + yaxis._offset
    };
    if (isRangePlot) {
      html = getRangeContent(point, options);
      anchor['y'] = yaxis.d2p(point.y) + yaxis._offset;
    } else if (isBenchMark) {
      html = getBenchMarkContent(point, options);
    } else {
      html = getPlotContent(point, options);
    }

    return {
      viewMode,
      position: flyoutPosition,
      anchor,
      chartContainer,
      html
    }
  });
};

function getPlotContent(point, { templateId, viewEntry, isCurrencyGroupByField, isCurrencyDimensionField }) {
  const nullValueLabel = getNullValueLabel(templateId);
  let dimensionValue = _.get(point, 'meta.scatterEntry.dimension', nullValueLabel);

  const groupByValue = _.get(point, 'meta.scatterEntry.group_by_field', nullValueLabel);
  const {
    formatedMetricValue,
    formatedLowerValue,
    formatedUpperValue,
    prefixLabelLower,
    prefixLabelUpper } = getFormattedLowerAndUpperValue(point, viewEntry);

  const metricName = getPrimaryMetricName(viewEntry);
  const groupNameContent = groupByValue ?
    <div className="scatter-chart-group-value pb-1 mb-2 text-nowrap border-bottom">
      <b>{formatValueToCurrency(groupByValue, isCurrencyGroupByField)}</b>
    </div> :
    '';

  return (
    <div>
      {groupNameContent}
      <div className="scatter-chart-y-value pb-1 mb-2 text-nowrap border-bottom">
        <b>{formatValueToCurrency(dimensionValue, isCurrencyDimensionField)}</b>
      </div>
      <table className="flyout-table">
        <tbody>
          <tr>
            <td>{prefixLabelLower}: {formatedLowerValue}</td>
          </tr>
          <tr>
            <td>{metricName}: {formatedMetricValue}</td>
          </tr>
          <tr>
            <td>{prefixLabelUpper}: {formatedUpperValue}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function getFormattedLowerAndUpperValue(point, viewEntry) {
  let xLowValue = (_.get(point, 'data.meta.scatterEntry.min_count') ||
    _.get(point, 'meta.scatterEntry.min_count'));
  let xUpperValue = (_.get(point, 'data.meta.scatterEntry.max_count') ||
    _.get(point, 'meta.scatterEntry.max_count'));
  const xValue = (_.get(point, 'data.meta.scatterEntry.count') ||
    _.get(point, 'meta.scatterEntry.count'));

  xLowValue = _.isNaN(Number(xLowValue)) ? 0 : xLowValue;
  xUpperValue = _.isNaN(Number(xUpperValue)) ? 0 : xUpperValue;
  const formatedMetricValue = getFormattedValue(xValue, viewEntry);
  const formatedLowerValue = getFormattedValue(xLowValue, viewEntry);
  const formatedUpperValue = getFormattedValue(xUpperValue, viewEntry);
  const prefixLabelLower = _.get(viewEntry.visualization,
    'snapshot.scatterplot.flyout_prefix_label_lower_range', 'Lower range')
  const prefixLabelUpper = _.get(viewEntry.visualization,
    'snapshot.scatterplot.flyout_prefix_label_upper_range', 'Upper range')

  return {
    formatedMetricValue,
    formatedLowerValue,
    formatedUpperValue,
    prefixLabelLower,
    prefixLabelUpper
  }
}

function getRangeContent(point, { templateId, viewEntry, isCurrencyGroupByField, isCurrencyDimensionField }) {
  const nullValueLabel = getNullValueLabel(templateId);
  const groupByValue = _.get(point, 'data.meta.scatterEntry.group_by_field', nullValueLabel);
  const dimensionValue = _.get(point, 'data.meta.scatterEntry.dimension', nullValueLabel);
  const {
    formatedMetricValue,
    formatedLowerValue,
    formatedUpperValue,
    prefixLabelLower,
    prefixLabelUpper } = getFormattedLowerAndUpperValue(point, viewEntry);

  const groupNameContent = groupByValue ?
    <div className="scatter-chart-group-value pb-1 mb-2 text-nowrap border-bottom">
      <b>{formatValueToCurrency(groupByValue, isCurrencyGroupByField)}</b>
    </div> :
    '';

  const metricName = getPrimaryMetricName(viewEntry);
  return (
    <div>
      {groupNameContent}
      <div className="scatter-chart-y-value pb-1 mb-2 text-nowrap border-bottom">
        <b>{formatValueToCurrency(dimensionValue, isCurrencyDimensionField)}</b>
      </div>
      <table className="flyout-table">
        <tbody>
          <tr>
            <td>{prefixLabelLower}: {formatedLowerValue}</td>
          </tr>
          <tr>
            <td>{metricName}: {formatedMetricValue}</td>
          </tr>
          <tr>
            <td>{prefixLabelUpper}: {formatedUpperValue}</td>
          </tr>
        </tbody>
      </table>
    </div>)
}

function getBenchMarkContent(point, options) {
  const { viewEntry } = options
  const benchmark = _.get(point, 'data.customdata[0][1]', '');
  const benchMarkContent = _.get(point, 'meta.plotlyDescription', benchmark);
  const isSecondsFormat = _.get(point, 'meta.scatterEntry.isSecondsFormat', false);
  let xValue = 0;
  if (isSecondsFormat) {
    xValue = _.get(point, 'meta.scatterEntry.count', 0);
    xValue = getFormattedValue(xValue, viewEntry);
  } else {
    xValue = getAbbreviatedStringValue(point.x);
  }


  return (<div><b>{benchMarkContent}</b>: {xValue}</div>);
}
